<template>
  <div>
    <div class="login" >
      <div class="login-bottom" style="width:700px;">
        <div class="col-md-6">
          <img style="margin-right: 20px" src="../../public/images/logo.png" />
        </div>

        <div class="col-md-6">
          <div style="text-align: center"><h2>系统登录</h2></div>
          <div class="login-mail">
            <input
              type="text"
              placeholder="登录账号"
              required=""
              id="userName"
              name="userName"
              v-model="loginusername"
            />
            <i class="fa fa-user"></i>
          </div>
          <div class="login-mail">
            <input
              type="password"
              placeholder="登录密码"
              required=""
              id="userPassword"
              name="userPassword"
              v-model="loginuserpwd"
            />
            <i class="fa fa-lock"></i>
          </div>

          <div class="login-do">
            <label class="hvr-shutter-in-horizontal login-sub">
              <input @click="login" type="submit" value="登      录" title="" />
            </label>
            <!-- <p>忘记登录密码?</p> -->
            <!-- <a href="signup.html" class="hvr-shutter-in-horizontal">重置密码</a> -->
          </div>
        </div>

        <div class="col-md-6">
          <span style="color: red">{{ errmsg }}</span>
        </div>

        <div class="clearfix"></div>
        <!-- </form> -->
      </div>
      <div style="text-align: center">
        <p>为了获得更好的体验，建议使用Chrome浏览器。</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LogIn",
  data() {
    return {
      loginusername: "",
      loginuserpwd: "",
      errmsg: "",
    };
  },
  watch: {
    loginusername(val, oldval) {
      if (val !== oldval) {
        this.errmsg = "";
      }
    },
    loginuserpwd(val, oldval) {
      if (val !== oldval) {
        this.errmsg = "";
      }
    },
  },
  methods: {
    async login() {

      if (this.loginusername) {
        if (this.loginuserpwd) {

          const res=await this.$get(this.GLOBAL.serverAddress + "/loginAdmin/",{account:this.loginusername,pwd:this.loginuserpwd,type:'erp'})
          if(!res.data){
            this.errmsg = "用户名或密码错误！";
          }else{
          // console.log(res)
          // return false
            this.GLOBAL.loginusername=this.loginusername
            this.GLOBAL.loginuserid=res.data.id
            this.GLOBAL.token=res.data.token
            this.GLOBAL.loginusertype=res.data.type
            this.GLOBAL.loginuserrole=res.data.role

            sessionStorage.setItem("loginuser", this.loginusername);
            sessionStorage.setItem("logintoken", res.data.token);
            sessionStorage.setItem("logintype", res.data.type);
            sessionStorage.setItem("loginrole", res.data.role);
            this.$router.replace("/desktop");
          }
        } else {
          this.errmsg = "请输入登录密码";
        }
      } else {
        this.errmsg = "请输入登录账号";
      }
    },
    // getToken() {
    //   //   if (days) {
    //   axios({
    //     method: "get",
    //     params: {
    //       spname: this.searchpname,
    //       spyxq: this.searchyxqdate,
    //     },
    //     url: this.GLOBAL.serverAddress + "/searchStorageIn/",
    //   }).then(
    //     (response) => {
    //       this.productList = response.data;
    //     },
    //     (error) => {
    //       console.log("出现错误", error.message);
    //       return false;
    //     }
    //   );
    //   //   }
    // },
    //处理回车时间，自动跳转到下一个输入框
    addEnterListener() {
      if (window.__completeEnterBind__) return;
      window.addEventListener("keydown", this.enterCallback);
      window.__completeEnterBind__ = true;
    },
    removeEnterListener() {
      window.removeEventListener("keydown", this.enterCallback);
      window.__completeEnterBind__ = false;
    },
    enterCallback(e) {
      function findFormItem(el) {
        const parent = el.parentElement;
        // console.log(parent);
        return parent;
        // if (!parent) return document.body;
        // if (
        //   parent.className.includes("form-group")
        //   // &&
        //   // parent.className.includes("el-form-item--small")
        // ) {
        //   return parent;
        // }
        // return findFormItem(parent);
      }
      function findInput(container) {
        let nextEl = container.nextElementSibling;
        // console.log(nextEl);
        if (!nextEl) {
          nextEl = container.parentElement.nextElementSibling;
        }
        // return;
        let input = nextEl.querySelector("input");
        // while (input.id === "el-select") {
        //   nextEl = nextEl.nextElementSibling;
        //   if (!nextEl) return;
        //   input = nextEl.querySelector("input");
        // }
        // if (input.className.includes("el-input__inner")) return input;
        return input;
      }
      // console.log(e.keyCode);
      if (e.keyCode === 13) {
        // console.log('5');
        const container = findFormItem(document.activeElement);
        findInput(container) && findInput(container).focus();
      }
    },
  },

  mounted() {
    this.addEnterListener();
  },
  destroy() {
    this.removeEnterListener();
    // console.log("out");
  },
};
</script>

<style>
</style>