<script>
const serverAddress="https://www.foodigo.xyz/api"
const wxserverAddress="https://www.foodigo.xyz/wxapi"

// const serverAddress="http://192.168.1.24:8080/api"
// const wxserverAddress="http://192.168.1.24:8080/api"
// const serverAddress="http://localhost:8080/api"


// const serverAddress="http://172.16.26.22:8080"
let token=''
let loginusername=''
let loginuserid=''
let loginusertype=''
let loginuserrole=''


function isInt(num){
    var numReg = /^[0-9]*$/
    var numRe = new RegExp(numReg)
    if (!numRe.test(num)) {        
        return false
    }else{
        return true
    }
}

function isFloat(num){
    var numReg = /^[0-9]*\.?[0-9]*$/
    var numRe = new RegExp(numReg)
    // console.log(numRe.test(num));
    if (!numRe.test(num)) {        
        return false
    }else{
        return true
    }
}

function getToday(){
    let d = new Date()
    return d.getFullYear()+'-'+formateDM(d.getMonth()+1)+'-'+formateDM(d.getDate())
}
function formateDM(num){
    return num<10?'0'+num:num
}


export default {
    serverAddress,
    wxserverAddress,
    token,
    loginusername,
    loginuserid,
    loginusertype,
    loginuserrole,
    isInt,
    isFloat,
    getToday
}
</script>
