<template>
  <div style="height: 800px">
    <ul id="keywords" ref="sKeywords" v-show="showNameList">
      <li
        v-for="(s, index) in nameList"
        :key="index"
        @click="getPName(s)"
        @mouseover="mouseOver(index)"
        :class="{ actived: currentIndex == index }"
      >
        {{ s.pname }}<br />商品编码：{{ s.posn }} | 规格：{{ s.pguige }} | 成本：{{s.pprice}}
      </li>
    </ul>

    <div class="grid-form1" style="padding: 0; height: 120px">
      <div class="form-group" style="vertical-align: middle">
        <div class="step1">第一步：选择产品</div>
        <div>
          <label
            for="txtContractNumber"
            class="col-sm-1 control-label"
            style="padding-top: 10px; text-align: right"
            >产品名称</label
          >
        </div>
        <div class="col-sm-3" style="vertical-align: middle">
          <input
            type="text"
            class="form-control1"
            v-model.trim="searchpname"
            @input="handleSearch($event)"
            autocomplete="off"
          />
        </div>
        <div>
          <label
            for="txtContractNumber"
            class="col-sm-1 control-label"
            style="padding-top: 10px; text-align: right"
            >商品条码</label
          >
        </div>
        <div class="col-sm-3" style="vertical-align: middle">
          <input
            type="text"
            class="form-control1"
            v-model.trim="searchpsn"
            @input="handleSearch($event)"
            autocomplete="off"
          />
        </div>
        <div class="col-sm-2"></div>
      </div>
      <!-- </form> -->
      <!-- </div> -->
      <!-- </div> -->

      <!-- <div style="padding-top: 0; padding-bottom: 8px">
        <div class="row">
          
        </div>
      </div> -->
    </div>

    <!-- <div class="content-main" style="padding:0;border:1px solid yellow">      -->

    <!-- <div class="grid-form" style="padding:0;border:1px solid red"> -->

    <div class="grid-form1" style="height: 670px; padding: 0">
      <!-- <h4>问题类别维护</h4> -->
      <!-- <div class="form-group" style="vertical-align: middle"> -->
      <div class="step1">第二步：配置价格</div>
      <div class="col-sm-7 infodisp">
        <table class="infotable">
          <tr>
            <td width="120px;">产品名称：</td>
            <td colspan="3" style="text-align: left">
              {{ currentSInfo[0].pname }}
            </td>
          </tr>
          <tr>
            <td width="120px;">商品编码：</td>
            <td width="300px;" style="text-align: left">
              {{ currentSInfo[0].posn }}
            </td>
            <td width="120px;">入库编码：</td>
            <td width="300px;" style="text-align: left">
              {{ currentSInfo[0].psn }}
            </td>
          </tr>
          <tr>
            <td>产品规格：</td>
            <td>{{ currentSInfo[0].pguige }}</td>
            <td>批发单位：</td>
            <td>
              <input
                type="text"
                class="form-control1"
                id="inputpunit"
                style="width: 80px"
                v-model.trim="currentSInfo[0].pwsunit"
              />
            </td>
          </tr>
          <tr>
            <td>起购数量：</td>
            <td>
              <input
                type="text"
                class="form-control1"
                style="width: 80px"
                id="inputminnum"
                v-int-only
                v-model.trim="currentSInfo[0].minnum"
              />（0表示无限制）
            </td>
            <td>限购数量：</td>
            <td>
              <input
                type="text"
                class="form-control1"
                style="width: 80px"
                id="inputmaxnum"
                v-int-only
                v-model.trim="currentSInfo[0].maxnum"
              />（0表示无限制）
            </td>
          </tr>
          <tr>
            <td>采购成本：</td>
            <td style="text-align: left">{{ currentSInfo[0].pprice }}</td>
            <td>批发单价：</td>
            <td>
              <input
                type="text"
                class="form-control1"
                style="width: 80px"
                id="inputwsprice"
                v-float-only
                v-model.trim="currentSInfo[0].pwsprice"
              />
            </td>
          </tr>
          <tr>
            <td colspan="4">
              <table class="infotable">
                <tr>
                  <td colspan="5" style="color: blue">
                    如果有阶梯批发价格，请在下边填写，若没有则不需要填写。
                  </td>
                </tr>
                <tr v-for="(s, index) in currentSInfo[0].pricestep" :key="index">
                  <td width="80px;">批发数量</td>
                  <td width="40px;">
                    <input
                      type="text"
                      class="form-control1"
                      :id="inputsetpnum(index)"
                      v-int-only
                      v-model.trim="currentSInfo[0].pricestep[index].num"
                    />
                  </td>
                  <td width="130px;" class="tdmtext">及以上，价格为：</td>
                  <td width="60px;">
                    <input
                      type="text"
                      class="form-control1"
                      :id="inputsetpprice(index)"
                      v-float-only
                      v-model.trim="currentSInfo[0].pricestep[index].price"
                    />
                  </td>
                  <td></td>
                </tr>
              </table>
            </td>
          </tr>
          <tr>
            <td colspan="5">
              <div class="col-sm-5">
                <button
                  class="btn btn-default"
                  type="button"
                  @click="goback"
                >
                  <i class="fa fa-arrow-left" aria-hidden="true"></i>&nbsp;返回可批发商品列表
                </button>
              </div>
              <div class="col-sm-7" style="text-align: center">
                <button
                  class="btn btn-default"
                  type="button"
                  @click="clearform"
                >
                  <i class="fa fa-refresh" aria-hidden="true"></i>&nbsp;清空
                </button>
                <button
                  class="btn btn-default"
                  id="btnSave"
                  :disabled="submitSaving"
                  @click="saveWSPriceInfo"
                >
                  <i class="fa fa-save" aria-hidden="true"></i>&nbsp;
                  {{ submitSaving ? "保存中.." : "保存" }}
                </button>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="4" class="errinfo">{{ errmessage }}</td>
          </tr>
          <!-- <tr><td width="100px;"></td><td>b</td></tr>
          <tr><td width="100px;"></td><td></td></tr>
          <tr><td width="100px;"></td><td><input
            type="text"
            class="form-control1"
            
            
            v-model.trim="searchpname"
          /></td></tr>
          <tr><td width="100px;"></td><td><input
            type="text"
            class="form-control1"
            
            
            v-model.trim="searchpname"
          /></td></tr>
          <tr><td width="100px;"></td><td><input
            type="text"
            class="form-control1"
            
            
            v-model.trim="searchpname"
          /></td></tr>
          <tr><td width="100px;" style="border:0;"></td><td style="border:0;"></td></tr> -->
        </table>
      </div>

      <!-- </div> -->
    </div>

    <!-- </div> -->
    <!-- </div> -->
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "WholeSaleSetup",
  components: {},
  data() {
    return {
      errmessage: "",
      nameList: [],
      searchpsn: "",
      searchpname: "",
      showNameList: false,
      currentSID: 0,
      currentSInfo: [{
        pid: "",
        pname: "",
        pwsunit: "",
        pwsprice: "",
        minnum: "0",
        maxnum: "0",
        pprice: "",
        opruser:this.GLOBAL.loginusername,
        pricestep: [
          { num: "", price: "" },
          { num: "", price: "" },
          { num: "", price: "" },
          { num: "", price: "" },
        ]
      }],
      submitSaving: false,
      currentIndex: 0,
    };
  },
  // directives: {
  //   intOnly: {
  //     bind(el) {
  //       el.handler = function () {
  //         el.value = el.value.replace(/\D+/g, "");
  //       };
  //       el.addEventListener("input", el.handler);
  //     },
  //     unbind(el) {
  //       el.removeEventListener("input", el.handler);
  //     },
  //   },
  // },
  methods: {
    clearform(){
      if(confirm("您确认要清空填写的所有信息吗？")){
        this.initData()
      }
    },
    getPName(pinfo) {
      // console.log(sname)
      this.currentSInfo[0].pid = pinfo.poid;
      this.currentSInfo[0].pname = pinfo.pname;
      this.currentSInfo[0].posn = pinfo.posn;
      this.currentSInfo[0].psn = pinfo.psn;
      this.currentSInfo[0].pguige = pinfo.pguige;
      this.currentSInfo[0].pwsunit = pinfo.punit;
      this.currentSInfo[0].pprice = pinfo.pprice;

      this.showNameList = false;
    },
    handleSearch(e) {
      // console.log('chufa')
      var search = this.searchpname;
      var tsearchpsn = this.searchpsn;
      this.showNameList = false;
      // console.log(e);
      if (search || tsearchpsn) {
        // console.log('开始提交1')
        axios({
          method: "get",
          params: {
            pname: search,
            psn: tsearchpsn,
          },
          url: this.GLOBAL.serverAddress + "/getWSProductNameList/",
        }).then(
          // axios.post('http://localhost:8080/getStorageList').then(
          (response) => {
            this.nameList = response.data;
            if (this.nameList.length > 0) {
              var ul = this.$refs.sKeywords; // document.getElementById("keywords");
              // console.log(e.target.getBoundingClientRect().top);
              ul.style.top = e.target.getBoundingClientRect().top + 40 + "px";
              ul.style.left = e.target.getBoundingClientRect().left + 8 + "px";
              this.showNameList = true;
            } else {
              this.showNameList = false;
            }
          },
          (error) => {
            console.log("出现错误", error.message);
            return false;
          }
        );
      }
    },
    goback(){
      this.$router.push('/searchwholesale');
    },
    submitData(){
      // console.log('tijiao');
      axios({
        method: "post",
        headers: {
          "Content-Type": "application/json;chartset=UTF-8",
          Authorization: "test",
        },
        data: this.currentSInfo,
        // [{id:1,psn:'test01'},
        //   {id:2,psn:'test02'}],
        url: this.GLOBAL.serverAddress + "/saveWSPrice/",
      }).then(
        // axios.post('http://localhost:8080/getStorageList').then(
        (response) => {
          // console.log('OK',response.data)
          // this.initData();
          alert("保存成功");
          this.submitSaving = false;
          this.$router.push('/searchwholesale');
        },
        (error) => {
          console.log("出现错误", error.message);
        }
      );
    },
    saveWSPriceInfo() {
      var submitflag=false
      // console.log(this.GLOBAL.isFloat(this.currentSInfo[0].pwsprice));
      // this.GLOBAL.showTest()
      if (this.currentSInfo[0].pid) {
        if (this.currentSInfo[0].pwsunit) {
          if (this.GLOBAL.isInt(this.currentSInfo[0].minnum)) {
            if (this.GLOBAL.isInt(this.currentSInfo[0].maxnum)) {
              if (this.currentSInfo[0].pwsprice && this.GLOBAL.isFloat(this.currentSInfo[0].pwsprice)) {
                // if(this.pricestep.forEach)
                submitflag=true
                this.currentSInfo[0].pricestep.forEach((item, index) => {
                  // console.log(item.pid===this.currentSID)
                  if (item.num !== "") {
                    if (this.GLOBAL.isInt(item.num)){
                      if(this.GLOBAL.isFloat(item.price)){
                        // this.submitSaving = true
                        // submitflag=true
                      }else{
                        // submitflag=false
                        this.errmessage = "请输入正确的批发价格。";
                      $("#inputsetpprice"+index).focus();
                      return false;
                      }
                    }else{
                      // submitflag=false
                      this.errmessage = "请输入正确的批发数量。";
                      $("#inputsetpnum"+index).focus();
                      return false;
                    }
                  }
                })

                if(submitflag){
                  this.submitSaving = true
                  this.submitData()
                }
              } else {
                this.errmessage = "请输入正确的批发单价。";
                $("#inputwsprice").focus();
                return false;
              }
            } else {
              this.errmessage = "请输入正确的限购数量。";
              $("#inputmaxnum").focus();
              return false;
            }
          } else {
            this.errmessage = "请输入正确的起购数量。";
            $("#inputminnum").focus();
            return false;
          }
        } else {
          this.errmessage = "请输入批发单位。";
          $("#inputpunit").focus();

          return false;
        }
      } else {
        this.errmessage = "请选择产品后进行价格配置。";
      }
    },

    inputsetpnum(id){
      return "inputsetpnum"+id
    },
    inputsetpprice(id){
      return "inputsetpprice"+id
    },

    getSDetail() {
      let tmpInfo = [];
      this.productList.forEach((item, index) => {
        // console.log(item.pid===this.currentSID)
        if (item.pid === this.currentSID) {
          tmpInfo = this.productList[index];
        }
      });
      return tmpInfo;
    },

    screen_click(pid) {
      // 显示筛选
      this.currentSID = pid;
      this.show = true;
      this.isshow = true;

      this.currentSInfo = this.getSDetail();

      //  console.log(this.currentSInfo)
    },

    initData() {
      this.errmessage = "";
      this.searchpname = "";
      this.searchpsn = "";
      this.currentSInfo = [
        {
          pid: "",
          pname: "",
          pwsunit: "",
          pwsprice: "",
          minnum: "0",
          maxnum: "0",
          pprice: "",
          opruser:this.GLOBAL.loginusername,
          pricestep: [
            { num: "", price: "" },
            { num: "", price: "" },
            { num: "", price: "" },
            { num: "", price: "" },
          ]
        },
      ]
    },
    mouseOver(index) {
      // alert(index)
      this.currentIndex = index;
    },
    mouseOut() {
      // alert(index)
      this.currentIndex = -1;
    },
  },
  mounted(){
    this.initData()
  },
};
</script>

<style scoped>
button {
  background-color: #f5f5f5;
  margin-right: 10px;
}

.actived {
  background-color: rgba(190, 221, 140, 0.9);
}
.step1 {
  margin: 20px;
}
.infotable {
  border: 0;
  width: 95%;
  /* border-left:0; */
}
td {
  height: 45px;
  /* border-top:0;
  border-left: 0;
  border-right: 0; */
  border: 0;
  text-align: left;
}

/* .infotable input{
  border:0;
} */

#keywords {
  position: absolute;
  padding-left: 5px;
  /* top: 40px; */
  background-color: rgba(228, 233, 220, 0.9);
  list-style: none;
  width: 400px;
  z-index: 9999;
  color: #616161;
  font-size: 15px;
  line-height: 25px;
}
.tdmtext {
  text-align: center;
}
.errinfo {
  text-align: center;
  color: red;
  font-size: 15px;
  font-weight: 600;
}
</style>