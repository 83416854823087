<template>
  <transition name="show_view">
    <div class="screen_cent" v-show="isshow">
      <div class="screen_title">&nbsp;
        会员：{{currentUserName}}
      </div>
      <div class="screen_data" transiton="show_view"> 
        <table class="dataintable">
          <tr><td style="width: 200px;">日期</td><td>操作类型</td><td style="width: 100px;">金额</td></tr>
          <tr v-for=" (item,index) in currentSInfo" :key=index>
            <td>{{item.oprdate}}</td><td>{{item.oprtype}}</td><td style="width: 100px;">{{item.money}}</td></tr>
          <tr  v-if="currentSInfo.length == 0" class="noresult"><td colspan="3" style="align:center;">没有余额操作记录！</td></tr>
        </table>
        
      </div>
      <div class="detail_btnsave">
          <button
            class="btn btn-default"
            @click="cancelUpdate"
          >
            <i class="fa fa-circle-thin" aria-hidden="true"></i>&nbsp;
            关闭
          </button>
          &nbsp;
          
        </div>
    </div>
  </transition>
</template>

<script>
import axios from "axios";
import qs from "qs";

export default {
  name: "MoneyHistory",
  data() {
    return {
      submitSaving: false,
    };
  },
  props: {
    currentSInfo: {
      type: [],
      required: true,
    },
    currentUserName:{
      type:String,
      required: true,
    },
    isshow: {
      type: Boolean,
      required: true,
    }
    
  },
  computed: {
    
  },
  
  methods: {
    setunitls(){
      this.currentSInfo.punitls=this.currentSInfo.punit
    },
    calCost() {
      if (this.currentSInfo.pprice && this.currentSInfo.pcount) {
        this.currentSInfo.pcost =
          this.currentSInfo.pprice * this.currentSInfo.pcount;
      }
      this.currentSInfo.pcostls=this.currentSInfo.pprice
      this.currentSInfo.pcountls=this.currentSInfo.pcount

      // if (this.currentSInfo.pcountls) {
      //   this.currentSInfo.pcostls = parseFloat(
      //     this.currentSInfo.pprice / this.currentSInfo.pcountls
      //   ).toFixed(2);
      // }
    },
    checkInput() {
      let oprflag = false;
      let iCount = 0;
      if (this.currentSInfo.pname === "") {
        alert("请输入商品名称！");
        return false;
      }
      if (this.currentSInfo.pprice === "") {
        alert("请输入商品的采购单价!！");
        return false;
      }
      if (this.currentSInfo.pcount === "") {
        alert("请输入商品的入库数量！");
        return false;
      }
      if (this.currentSInfo.pcountls === "") {
        alert("请输入商品的可零售数量！");
        return false;
      }
      if((this.currentSInfo.newcount-this.currentSInfo.pcount+this.currentSInfo.pkucun)<0){
        alert("库存减少数量超出了现有库存数" + this.currentSInfo.pkucun + ",请输入合理的入库数量！");
        this.currentSInfo.newcount=this.currentSInfo.pcount
        return false;
      }
      oprflag = true;
      return oprflag;
    },
    cancelUpdate() {
      this.$emit("clickT")
    },
    
    //处理回车时间，自动跳转到下一个输入框
    addEnterListener() {
      if (window.__completeEnterBind__) return;
      window.addEventListener("keydown", this.enterCallback);
      window.__completeEnterBind__ = true;
    },
    removeEnterListener() {
      window.removeEventListener("keydown", this.enterCallback);
      window.__completeEnterBind__ = false;
    },
    enterCallback(e) {
      function findFormItem(el) {
        const parent = el.parentElement;
        // console.log(parent);
        return parent;
        // if (!parent) return document.body;
        // if (
        //   parent.className.includes("form-group")
        //   // &&
        //   // parent.className.includes("el-form-item--small")
        // ) {
        //   return parent;
        // }
        // return findFormItem(parent);
      }
      function findInput(container) {
        let nextEl = container.nextElementSibling;
        // console.log(nextEl);
        if (!nextEl) {
          nextEl = container.parentElement.nextElementSibling;
        }
        // return;
        let input = nextEl.querySelector("input");
        // while (input.id === "el-select") {
        //   nextEl = nextEl.nextElementSibling;
        //   if (!nextEl) return;
        //   input = nextEl.querySelector("input");
        // }
        // if (input.className.includes("el-input__inner")) return input;
        return input;
      }
      // console.log(e.keyCode);
      if (e.keyCode === 13) {
        // console.log('5');
        const container = findFormItem(document.activeElement);
        findInput(container) && findInput(container).focus();
      }
    }     

  },
  mounted() {
    this.addEnterListener();
  },
  destroy() {
    this.removeEnterListener();
  },
};
</script>

<style scoped lang="less">
input {
  border: 0px solid #e0e0e0;
  padding: 5px 8px;
  /* color: #616161; */
  width: 100%;
  font-size: 0.85em;
  font-weight: 300;
  height: 30px;
  -webkit-appearance: none;
  outline: none;
  font-family: "Muli-Regular";
  background: none;
  box-shadow: none !important;
  text-align: center;
}


.search_page_list {
  width: 100%;
  position: relative;
}

.screen_cent {
  display:flex;
  flex-direction: column;
  width: 500px;
  height: 600px;
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 99;
  border-radius: 10px 0 0 10px;
  overflow: hidden;
  /* padding: 20px; */
  /* background: #ffffff; */
  .screen_title{
    background: #ffffff;
    text-align: center;
    padding-top: 20px;
  }
    
}
.detail_btnsave {
    text-align: center;
    /* margin-top: 10px; */
    background: #ffffff;
    padding: 20px;
  }
.screen_data {
  
  overflow-y:auto;
  padding: 20px;
  width: 100%;
  height: 100%;
  background: #ffffff;
}
.show_view-enter {
  animation: show_view-dialog-fade-in 0.2s ease;
}

.show_view-leave {
  animation: show_view-dialog-fade-out 0.2s ease forwards;
}

.show_view-enter-active {
  animation: show_view-dialog-fade-in 0.2s ease;
}

.show_view-leave-active {
  animation: show_view-dialog-fade-out 0.2s ease forwards;
}

@keyframes show_view-dialog-fade-in {
  0% {
    transform: translateX(500px);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes show_view-dialog-fade-out {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(500px);
  }
}

table.dataintable {
  /* margin-top: 15px; */
  border-collapse: collapse;
  border: 1px solid white;
  width: 98%;
  font-size: 0.95em;
  background-color: #e5ebdc;
  line-height: 40px;
}
table.dataintable th {
  vertical-align: middle;
  /* padding:5px 15px 5px 6px; */
  background-color: #e8e8e8;
  border: 1px solid white;
  text-align: right;
  color: rgb(173, 169, 169);
}
table.dataintable td {
  vertical-align: text-top;
  /* padding:6px 15px 6px 6px; */
  border: 1px solid white;
  line-height: 35px;
  /* text-align: right;
  padding-right: 5px; */
}
span {
  color: red;
}
</style>