<template>
  <div id="wrapper">
    <!----->
    <Navigator :loginusername="logedinuser" :loginuserrole="loginuserrole" :loginusertype="loginusertype" />
    <div
      id="page-wrapper"
      class="gray-bg dashbard-1"
      style="margin-left: 230px"
    >
      <div class="content-main">
        <div class="grid-form" style="padding-top: 0">
          <keep-alive>
            <router-view v-if="$route.meta.keepAlive"></router-view>
          </keep-alive>
          <router-view v-if="!$route.meta.keepAlive"></router-view>
        </div>
      </div>
      <CopyRight />
    </div>
  </div>
</template>

<script>
import Navigator from "./Navigator.vue";
import CopyRight from "./CopyRightInfo.vue";
import TopPosition from "./TopPosition.vue";


export default {
  name: "Home",
  data(){
      return{
          logedinuser:'',
          loginusertype:'',
          loginuserrole:''
      }
  },
  components: {
    Navigator,
    CopyRight,
    TopPosition,
  },
  mounted() {
    //   console.log(sessionStorage.getItem("loginuser"))
      if(sessionStorage.getItem("loginuser")){
          this.logedinuser=sessionStorage.getItem("loginuser")
          this.loginusertype=sessionStorage.getItem("logintype")
          this.loginuserrole=sessionStorage.getItem("loginrole")
          // this.$router.push('/desktop')
      }else{
          this.$router.push('/login')
      }
  }
};
</script>

<style>
table {
  border: 1px solid #9c9c9c;
  width: 95%;
  margin: auto;
  text-align: center;
  color: #9c9c9c;
  padding: 0;
  /* margin:0; */
}
td {
  border: 1px solid #9c9c9c;
  color: #9c9c9c;
  line-height: 30px;
  font-family: Arial, Helvetica, sans-serif;
  margin: 0;
  padding: 0;
}
</style>