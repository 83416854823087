<template>
  <div style="height: 100%;">
    <Toast ref="toast" />
    
    <div class="grid-form1" style="padding: 0">
      <div style="margin: 0; padding: 0; vertical-align: middle;height:160px;">    
           
          <div class="new_contents" style="white-space: nowrap;">公告内容：<input type="text"
                    class="form-control1"
                     v-model.trim="tmpNotes"  >
                     
              </div>   
          <div class="col-sm-2 btnexport">
              
                    <button
                        class="btn btn-default" 
                        @click="editBanner()"
                        >
                        <i class="fa fa-save" aria-hidden="true"></i>&nbsp;更新公告内容
                      
                        </button>
        </div>
      </div>
    </div>
        <div class="orderlist" >
            
            
        </div>
       
      
    </div>
  <!-- </div> -->
</template>

<script>
import axios from "axios";
import Toast from "../../components/Toast";


export default {
  name: "homenote",
  components: {
    
    Toast
  },
  data() {
    return {

        tmpNotes:'',
        
    };
  },
  methods: {
      
   
    
    async editBanner(){
        
        if(confirm("您确定要更新首页公告吗？")){
            const res = await this.$post(this.GLOBAL.serverAddress + "/updateHomeNote/", 
            {'note':this.tmpNotes});
            if (res.status === 200 && res.data) {
                this.$refs.toast.toast("更新成功");
                this.$router.go(0)
            }
        }
    },
      
      
      
    async search() {
    //   this.currentpage=1
      
      const res = await this.$get(this.GLOBAL.serverAddress + "/getHomepageNote/", {});
    //   console.log(res.data[0].note);
        
      if (res.status === 200) {
        this.tmpNotes = res.data[0].note;
        // console.log(this.orderList)
        // if(this.orderList.length>0){
        //     this.shownoresult=false
        // }else{
        //     this.shownoresult=true
        // }
      }
    },
  },
  computed: {
    
    
  },
  mounted() {
    this.haveRights('|0905|')
    this.search();
    // window.addEventListener("click", this.hideCalender);
    this.win_height = window.innerHeight;
  },
  beforeDestroy() {
  },
};
</script>

<style scoped lang="less">
.btnexport{
  padding-top: 10px;
  padding-left:50px;
  display:flex;
  align-items: center;
}
.printList{
  position: absolute;
  left:300px;
}
.bdate_value_time{
  color: #9c9c9c;
  border: 0px solid #9c9c9c;
}
.search_condition {
    display: flex;
    // white-space: nowrap;
    justify-content:center;
    align-items: center;
    text-align: right;
    padding:10px;
  .pname {
    //   display: flex;
    flex: 1;
    padding-right: 5px;
  }

  .pname_value {
    flex: 3;
  }

  .cname {
    flex: 1;
    padding-right: 5px;
  }

  .cname_value {
    flex: 2;
  }

  .bdate {
    flex: 1;
    padding-right: 5px;
  }

  .bdate_value {
    flex: 1;
  }

  .edate {
    //   border: 1px solid green;
    flex: 1;
    text-align: center;
    // display: flex;
    // align-items: center;
    // justify-content: center;
  }

  .edate_value {
    flex: 1;
  }
}

.orderlist {
  color: #9c9c9c;

  // padding:10px;
  .order_item {
    //   width:100%;
    margin-top: 15px;
    background-color: #fff;
    // padding-top:15px;
    .order_item_briefinfo {
      display: flex;
      border-bottom: 1px solid #eee;
      //   padding-bottom:10px;
      padding: 10px;
    //   text-align: center;
      align-items: center;
      justify-content: center;
      .order_item_briefinfo_orderchk{
        // input{
        //   outline: 1px solid red;
        //   border:0px solid black;
        // }
      }
      .order_item_briefinfo1{
          flex:1;
          text-align: center;
      }
      .order_item_briefinfo2
      {
          flex:6;
      }
      .order_item_briefinfo3
      {
          flex:1;
          text-align: center;
      }
      .order_item_briefinfo4
      {
          flex:3;
          text-align: center;
      }
      
      
    }

    .order_item_content {
      display: flex;
      .order_item_content_product {
        flex: 3;
        margin: 10px;
        padding-left: 10px;
        display: flex;
        .productinfo_img {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            width: 80%;
            max-height: 100px;
            object-fit: contain;
          }
        }

        .productinfo {
          flex: 3;
          margin: 10px;
          padding-left: 5px;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          // align-items: center;
          .productinfo_title {
          }

          .productinfo_guige {
          }

          .productinfo_num {
          }

          .productinfo_price {
          }
        }
      }

      .order_item_content_order {
        flex: 5;
        display: flex;
        .orderremark{
          flex:2;
          padding: 5px;
          display: flex;
          align-items: center;
          color:#FF4500;
        }
        .orderprice {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 1.2em;
          color: #6e8b3d;
        }

        // .orderstatus {
        //   flex: 1;
        //   display: flex;
        //   align-items: center;
        //   justify-content: center;
        // }

        .ordercontact {
          flex: 3;
          display: flex;
          // align-items: center;
          justify-content: center;
          flex-direction: column;
          padding-left: 10px;
          .ordercontact_name {
          }

          .ordercontact_tel {
          }

          .ordercontact_address {
            display: flex;
            .address_title {
              white-space: nowrap;
            }
          }
        }
      }

      .order_item_content_opr {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        // padding:15px;
        button {
          width: 80%;
          margin: 3px;
        }
      }
    }
  }
}

.sltStyle {
    
    padding-left: 5px;
    color:#999;
    select{
      border: 1px solid #E9E9E9;
    font-size: 0.9em;
    outline: none;
    padding: 0.5em 1em;
    color: #999;
    }
  }

button {
  background-color: #f5f5f5;
  margin-right: 10px;
}
.new_contents{
    margin: 30px;
    display:flex;
    align-items: center;
}
.btmInfo {
  background: none;
  border: none;
  outline: none;
  color: #333;
  font-size: 0.9em;
}
.noresult {
  text-align: center;
  font-size: 2em;
  color: rgb(160, 154, 154);
}
.pagenum_info{
    background:#fff;
    height: 50px;
    margin-top:20px;
    padding: 10px; color: #6c757d;
    // border-top: 1px dashed #9acd32;
}
.actived {
  background-color: rgba(190, 221, 140, 0.9);
}
table.dataintable {
  /* margin-top: 15px; */
  border-collapse: collapse;
  border: 1px solid white;
  width: 98%;
  font-size: 0.95em;
  background-color: #e5ebdc;
  line-height: 40px;
}
table.dataintable th {
  vertical-align: middle;
  /* padding:5px 15px 5px 6px; */
  background-color: #e8e8e8;
  border: 1px solid white;
  text-align: center;
  color: rgb(173, 169, 169);
}
table.dataintable td {
  vertical-align: text-top;
  /* padding:6px 15px 6px 6px; */
  border: 1px solid white;
  line-height: 35px;
}

.hidden_view {
  width: 100%;
  background: #698b22;
  opacity: 0.2;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 99;
}
</style>