<template>
  <div style="height: 100%">
    <Toast ref="toast" />
    <div class="orderlist">
      <div class="order_item" v-for="(item, index) in orderList" :key="index">
        <div class="order_title">订单详情</div>

        <div class="order_item_briefinfo_top">
          <div class="order_item_briefinfo_back">
            <button
              class="btn btn-default"
              type="button"
              @click="goback"
              >
              
              <i class="fa fa-arrow-circle-left" style="color: #9acd32" aria-hidden="true"></i>&nbsp;返回订单列表
              </button>
          </div>
          <div class="order_item_briefinfo_ordernum">
            订单号：{{ item.ordersno }}
          </div>
        </div>
        <div class="order_item_briefinfo">
              
          <div class="order_item_briefinfo_orderpay">
            下单时间：{{ item.orderdatetime }}
          </div>
          <div class="order_item_briefinfo_orderpay">
            支付方式：{{ item.paytype }}
          </div>
          <div class="order_item_briefinfo_orderpay">
            支付时间：{{ item.paytime===null?'暂无':item.paytime }}
          </div>
          <div class="order_item_briefinfo_orderpay">
            订单状态：{{ item.orderstatus }}
          </div>
        </div>
        <div class="order_item_briefinfo">
              
          <div class="order_item_briefinfo_orderpay">
            发货时间：{{ item.devlivertime ===null?'暂无':item.devlivertime}}
          </div>
          <div class="order_item_briefinfo_orderpay">
            收货时间：{{ item.arrivetime===null?'暂无':item.arrivetime}}
          </div>
          <div class="order_item_briefinfo_orderpay" >
            配送编号：{{ item.icard_name }} {{ item.boxsn }}
          </div>
          
          
          <div class="order_item_briefinfo_orderpay">
            所属店铺：{{ item.membername }} 
          </div>
        </div>
        <div class="order_notesinfo">
          <div class="order_notesinfo_title">订单备注：</div>
          <div class="order_notesinfo_content">
            <!-- {{parsedData(item.remarks)}} -->
            <!-- <serialized-editor :json-data="parsedData2" @output="updateOutput"></serialized-editor>-->
              <textarea name="txtRemark" id="txtRemark" cols="80" rows="3" class="form-control1" style="height: 120px;" v-model="item.remarks" ></textarea> 
          </div>
          <div>
          <button
            class="btn btn-default"
            type="button"
            @click="updateRemarks(item.orderid,item.remarks)"
            >
            <i class="fa fa-save" aria-hidden="true"></i>&nbsp;更新备注
            </button>
          </div>
        </div>
        <div class="order_item_contact_title">收货信息</div>
        <div class="order_item_contact" v-if="item.deliverytype==1">
          <div class="ordercontact_name">
            联&nbsp;&nbsp;系&nbsp;&nbsp;人：{{ item.contactname }}（{{item.userid}}）
          </div>
          <div class="ordercontact_name">联系电话：{{ item.mobile }}</div>
          <div class="ordercontact_name" >配送地址：{{ item.address }}</div>
        </div>
        <div class="order_item_contact" v-else>
          <div class="ordercontact_name">
            联&nbsp;&nbsp;系&nbsp;&nbsp;人：{{ item.pickupName }}（{{item.userid}}）
          </div>
          <div class="ordercontact_name">联系电话：{{ item.pickupTel }}</div>
          <div class="ordercontact_name" style="color:red;">自提地址：{{ item.pickupCity }}</div>
        </div>
        <div class="order_item_list">批发商品清单</div>

        <div class="order_item_list_title">
          <div class="product_img">商品图片</div>
          <div class="product_name">商品名称/商品条码</div>
          <div class="product_guige">规格</div>
          <div class="product_unitprice">单价</div>
          <div class="product_unitprice">税费</div>
          <div class="product_unitprice">押金</div>
          <div class="product_unitprice">小费</div>
          <div class="product_count">数量</div>
          <div class="product_allprie" style="color: #9c9c9c">小计</div>
        </div>
        <div
          class="order_item_list_title"
          v-for="(item2, index2) in item.plist"
          :key="index2"
        >
          <div class="product_img">
            <img :src="item2.imgurl" />
          </div>

          <div class="product_name">{{ item2.pname }}<br />{{ item2.psn }}</div>
          <div class="product_guige">{{ item2.pguige }}</div>
          <div class="product_unitprice">C${{ item2.pprice }}</div>
          <div class="product_unitprice">C${{ item2.taxes }}</div>
          <div class="product_unitprice">C${{ item2.deposit }}</div>
          <div class="product_unitprice">C${{ item2.tips }}</div>
          <div class="product_count">
            <div class="product_count_old">
              {{ item2.wscount }}
            </div>
          </div>
          <div class="product_allprie">
            <div class="product_allprie_o">
              C${{ item2.wsallprice }}
              
            </div>
            
          </div>
        </div>
        
        

        <div class="order_item_content_order">
          
          <div class="order_item_content_order_right">
            <button
              class="btn btn-default"
              type="button"
              @click="goback"
              >
              
              <i class="fa fa-arrow-circle-left" style="color: #9acd32" aria-hidden="true"></i>&nbsp;返回订单列表
              </button>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;总额：C${{
              item.ordertotalprice
            }}(运费：{{item.freight}}/优惠：{{ item.yh_price}})
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- </div> -->
</template>

<script>
import axios from "axios";
import Toast from "../../components/Toast";
// import { SerializedParser, SerializedEditor } from '@deliciousbrains/serialized-editor';

export default {
  name: "RetailOrderDetail",
  components: { 
    Toast
  
  },
  data() {
    return {
      shownoresult: true,
      orderList: [],
      currentIndex: -1,
      currentpage: 1,
      gotopage: "",
      win_height: "",
      serializedString:"",
      page:1,
      from:1,
      ordercredit:""
      // parsedData:{"type": "s", "value": ""}
      // updateOutput:''
    };
  },
  methods: {
    async getCreditByOrderID(id){ 
      const res = await this.$get(
        this.GLOBAL.serverAddress + "/getCreditByOrderID/",
        {
          id: id,
        }
      );
      //   console.log(res);

      if (res.status === 200) {
        this.ordercredit = res.data;
        // console.log(res.data)
      }
    },
    parsedData(serializedString) {
        var sp = new SerializedParser(serializedString);
        
        try {
            var json = sp.parse();
            console.log(json)
            return JSON.parse(json).values[0][1].value;
        } catch (e) {
            console.error(e);
        }

        return '';
    },
    updateOutput(e){
      console.log(e)
    },
    goback() {
      // this.$router.go(-1);
      if(this.from =="1"){

        this.$router.push({name:'/neworderlist',params:{page:this.page}})
      }else if(this.from =="3"){
        this.$router.go(-1)
      }
      else{

        this.$router.push({name:'/retailorderlist',params:{page:this.page}})
      }
    },
    
    async updateRemarks(orderid,newremarks){
          const res=await this.$post(this.GLOBAL.serverAddress + "/updateRemarksByID/",{id:orderid,remarks:newremarks})
          // console.log(res)
          if(res.data){
              this.$router.go(0)
              // this.$refs.toast.toast("备注更新成功")
          }else{
              this.$refs.toast.toast("设置失败，请重新尝试")
          }
    },
    getNewPage(pagenum) {
      if (pagenum >= 1 && pagenum <= this.pagecount) {
        this.currentpage = pagenum;
        return this.pages[this.currentpage - 1];
      }
    },
    async search(id) {
      const res = await this.$get(
        this.GLOBAL.serverAddress + "/getRetailOrderDetailByID/",
        {
          id: id,
        }
      );
      //   console.log(res);

      if (res.status === 200) {
        this.orderList = res.data;
        
        // console.log(res.data)
        if (this.orderList.length > 0) {
          this.shownoresult = false;
          this.serializedString=this.orderList[0].remarks
          // console.log(this.serializedString)
        } else {
          this.shownoresult = true;
        }
      }
    },
  },
  computed: {
    
    parsedData2() {
        var sp = new SerializedParser(this.serializedString);

        try {
            var json = sp.parse();
            console.log(JSON.parse(json).values[0][1].value)
            return JSON.parse(json);
        } catch (e) {
            console.error(e);
        }

        return {};
    },
    currentpagelist() {
      return this.pages[this.currentpage - 1];
    },
    recordcount() {
      return this.orderList.length;
    },
    pagecount() {
      return Math.ceil(this.orderList.length / 15);
    },
    pages() {
      const pages = [];
      this.orderList.forEach((item, index) => {
        const page = Math.floor(index / 15);
        if (!pages[page]) {
          pages[page] = [];
        }
        pages[page].push(item);
      });
      return pages;
    },
  },
  mounted() {
    this.page=this.$route.query.page
    this.from=this.$route.query.from
    this.search(this.$route.query.id);
    this.getCreditByOrderID(this.$route.query.id);
    // window.addEventListener("click", this.hideCalender);
    this.win_height = window.innerHeight;
    // const vm=this.$mountComponent(SerializedEditor,{
		// 	jsonData: {"type": "s", "value": "foo bar"}
		// });
    // console.log(vm.generateOutput())
  },
  beforeDestroy() {
    // 实例销毁之前对点击事件进行解绑
    // window.removeEventListener("click", this.hideCalender);
  },
};
</script>

<style scoped lang="less">
.orderlist {
  color: #9c9c9c;

  // padding:10px;
  .order_item {
    //   width:100%;
    margin-top: 15px;
    background-color: #fff;
    .order_title {
      text-align: center;
      font-size: 1.2em;
      padding: 10px;
    }
    .order_item_briefinfo_top {
      display: flex;
      border-bottom: 1px solid #eee;

      .order_item_briefinfo_back {
        font-size: 1.3em;
        padding-left: 50px;
        flex: 1;
        padding-bottom: 5px;
      }
      .order_item_briefinfo_ordernum {
        text-align: right;
        padding-right: 50px;
        flex: 1;
      }
    }
    // padding-top:15px;
    .order_item_briefinfo {
      //   padding-bottom:10px;
      padding: 10px 50px;

      display: flex;
      justify-content: space-between;
      align-items: center;

      .order_item_briefinfo_orderpay {
        // text-align: center;
        flex: 1;
        margin:0 10px;
      }
    }

    .order_notesinfo {
      display: flex;
      padding: 0 60px;
      .order_notesinfo_title {
      }
      .order_notesinfo_content {
      }
    }
    .order_item_contact_title {
      background-color: #eeeed1;
      line-height: 30px;
      padding-left: 40px;
      // border: 1px solid green;
    }
    .order_item_contact {
      padding: 10px 50px;
      .ordercontact_name {
        margin:0 10px;
      }
    }
    .order_item_list {
      background-color: #eeeed1;
      line-height: 30px;
      text-align: center;
      //   width: 50%;
      align-self: center;
      //   color: #666;
    }
    
    .order_item_list_title {
      margin: 5px 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-bottom: 1px solid #eee;
      padding-bottom: 10px;
      text-align: center;
      .product_img {
        flex: 1;
        img {
          width: 80%;
          object-fit: contain;
          height: 50px;
          //   object-fit: contain;
        }
      }
      .product_name {
        flex: 3;
      }
      .product_guige {
        flex: 2;
      }
      .product_unitprice {
        flex: 1;
      }
      .product_count {
        flex: 1;
        // border: 1px solid green;
        .product_count_newadd {
          .product_count_newadd_zhuijia {
            display: flex;
            justify-content: center;
            align-items: center;
            .product_count_newadd_zhuijia_l {
              flex: 1;
            }
            .product_count_newadd_zhuijia_r {
              flex: 1;
            }
          }
          .product_count_newadd_btn {
            color: #9acd32;
          }
        }
      }
      .product_allprie {
        flex: 1;
        color: #00bfff;
        .product_allprie_o {
          display: flex;
          align-items: center;
          justify-content: center;

          .product_allprie_btn {
            padding-left: 5px;
            color: #9acd32;
            cursor: pointer;
          }
        }
        .product_allprie_n {
          display: flex;
          align-items: center;
          white-space: nowrap;
          
        }
        .product_allprie_btnb{
          display: flex;
          align-items: center;
          justify-content: space-around;
          .product_allprie_btnb_btn{
            padding: 5px;
            cursor: pointer;
          }
        }
      }
    }

    .order_item_content_order {
      text-align: right;
      margin-right: 80px;
      padding-bottom: 10px;
      font-size: 1.2em;

      display: flex;
      .order_item_content_order_btn {
        flex: 3;
        // display: flex;
      }
      .order_item_content_order_right {
        color: #00bfff;
        flex: 1;
        i {
          cursor: pointer;
        }
      }
    }
  }
}

button {
  background-color: #f5f5f5;
  margin-right: 10px;
}

#keywords {
  position: absolute;
  padding-left: 5px;
  /* top: 40px; */
  background-color: rgba(228, 233, 220, 0.9);
  list-style: none;
  width: 400px;
  z-index: 9999;
  color: #616161;
  font-size: 15px;
  line-height: 25px;
}

.actived {
  background-color: rgba(190, 221, 140, 0.9);
}
</style>