<template>
  <div style="height: 100%;">
    <Toast ref="toast" />
    <div class="grid-form1" style="padding: 0">
      <!-- <h4>问题类别维护</h4> -->
      <!-- <div class="col-md-12" style="margin: 0; padding: 0"> -->
      <div style="margin: 0; padding: 0; vertical-align: middle">
        <!-- <form
                class="form-horizontal"
                action=""
                id="frmSRList"
                method="POST"
              > -->
        
        <div class="form-group" style="vertical-align: middle">
            <div class="search_condition">
            <div style="margin:20px;"><label
              for="txtMemberCode"
              class="control-label"
              >第一步：选择团长</label
            ></div>
            <div class="">&nbsp;&nbsp;
            <label
              for="txtNiCheng"
              class="control-label"
              >微信昵称：</label
            >
          </div>
          <div class="">
            <input
              type="text"
              class="form-control1"
              id="txtNiCheng"
              name="txtContractNumber"
              style="width:200px;"
              v-model.trim="wxname"      
              @keyup.enter="getReadyMember"        
            />
          </div>
            <!-- <div class="">&nbsp;&nbsp;
            <label
              for="txtMemberName"
              class="control-label"
              >姓名：</label
            >
          </div>
          <div class="">
            <input
              type="text"
              class="form-control1"
              id="txtMemberName"
              name="txtContractNumber"
              style="width:200px;"
              v-model.trim="membercontactname"              
            />
          </div> -->
          <div>&nbsp;&nbsp;&nbsp;&nbsp;</div>
        
            
            <button
              class="btn btn-default"
              type="button"
              @click="getReadyMember"
            >
              <i class="fa fa-search" aria-hidden="true"></i>&nbsp;查询客户信息
            </button>
            </div>
          <div
        class="tab-content"
        style=" margin-top: 5px; padding: 0"
      >
        <table class="dataintable" v-if="productList2.length > 0">
          <tr style="font-weight: bold">
            <th width="80px" >会员ID</th>
            <th width="200px">微信昵称</th>
            <th width="200px">微信头像</th>
            <!-- <th width="300px">姓名</th>      
            <th width="200px">配送城市</th>
            <th width="200px">电话</th> -->
            <!-- <th width="80px">操作</th> -->
          </tr>
          <tr
            v-for="(s, index) in currentpagelist2"
            :key="index"
            @mouseover="mouseOver2(index)"
            @mouseout="mouseOut2"
            @click="getMemberInfo(s.username,s.membercode)"
            :class="{ actived: currentIndex2 == index }"
          >
            <td>{{ s.userid }}</td>
            <td>{{ s.username }}</td>
            <td><img :src="s.imgurl" style="height:60px; width:60px"></td>
            <!-- <td>{{ s.membername }}</td>
            <td>{{ s.city }}</td>
            <td>{{ s.tel }}</td> -->

          </tr>
        </table>
        <div v-if="noResultFlag2" class="noresult">没有找到符合查询条件的客户信息！</div>
      </div>
      <div
        class="center_bottom"
        style="padding: 5px; color: #6c757d; border-top: 1px dashed #9acd32"
        v-if="!noResultFlag2"
      >
        <span class="btmInfo" style="vertical-align: middle"
          >&nbsp;&nbsp;共有 {{ recordcount2 }} 条记录，当前第
          {{ currentpage2 }}/{{ pagecount2 }} 页</span
        >
        <div style="float: right; padding-right: 30px">
          <button class="btn btn-default" type="button" @click="getNewPage2(1)">
            首页
          </button>
          <button
            class="btn btn-default"
            type="button"
            @click="getNewPage2(currentpage2 - 1)"
          >
            上页
          </button>
          <button
            class="btn btn-default"
            type="button"
            @click="getNewPage2(currentpage2 + 1)"
          >
            下页
          </button>
          <button
            class="btn btn-default"
            type="button"
            @click="getNewPage2(pagecount2)"
          >
            尾页
          </button>
          <span class="btmInfo" style="margin-right: 2px">跳转到</span>
          <input
            type="text"
            size="1"
            id="txtGotoPage"
            autocomplete="off"
            v-int-only
            style="margin-right: 10px; font-size: 0.9em"
            v-model.trim="gotopage2"
          />
          <button
            type="button"
            class="btn btn-default"
            @click="getNewPage2(gotopage2)"
          >
            跳转
          </button>
        </div>
      </div>
          
          <div class="search_condition">
             <div style="margin:20px;"><label
              for="txtMemberCode"
              class="control-label"
              style="white-space:nowrap;"
              >第二步：保存</label
            ></div> 
          <div class="pname">&nbsp;&nbsp;
            <label
              for="txtContractNumber"
              class="control-label"
              >店铺名称：</label
            >
          </div>
          <div class="pname_value">
            <input
              type="text"
              class="form-control1"
              id="txtContractNumber"
              name="txtContractNumber"
              style="width:180px;"      
              v-model.trim="membername"              
            />
          </div>
          <div class="pname">&nbsp;&nbsp;
            <label
              for="txtMemberCode"
              class="control-label"
              >店铺代码：</label
            >
          </div>
          <div class="pname_value">
            <input
              type="text"
              class="form-control1"
              id="txtMemberCode"
              name="txtMemberCode"
              readonly
              v-model.trim="membercode"        
              style="width:250px;"      
            />
          </div>  
          <div class="pname">&nbsp;&nbsp;
            <label
              for="txtContractNumber"
              class="control-label"
              >登录账号：</label
            >
          </div>
          <div class="pname_value">
            <input
              type="text"
              class="form-control1"
              id="txtContractNumber"
              name="txtContractNumber"
              style="width:200px;"      
              v-model.trim="loginname"   
              @input="handleSearch($event)"
              autocomplete="off"           
            /><div style="color:red;">（默认密码：@02!Vancouver）</div>
          </div>    
            <div>&nbsp;&nbsp;&nbsp;&nbsp;</div>
        
            <button
              class="btn btn-default"
              type="button"
              @click="saveExcept"
            >
              <i class="fa fa-save" aria-hidden="true"></i>&nbsp;新增店铺信息
            </button>
        </div>
          
        </div>
        <!-- </form> -->
      </div>
      <!-- </div> -->

      <!-- <div style="padding-top: 0; padding-bottom: 8px">
        <div class="row">
          <div class="col-sm-12 " style="padding-top: 10px;padding-left:50px;">
            <button class="btn btn-default" type="button" @click="setupWS">
              <i class="fa fa-plus-square-o" aria-hidden="true"></i>&nbsp;配置可批发产品
            </button>
            
          </div>
        </div>
      </div> -->
    </div>

    <div class="grid-form1" >
      <!-- <h4>问题类别维护</h4> -->
      <div
        class="tab-content"
        style=" margin-top: 5px; padding: 0"
      >
        <table class="dataintable" v-if="productList.length > 0">
          <tr style="font-weight: bold">
            <th width="50px" >ID</th>
            <th>店铺名称</th>
            <th width="150px">登录账号</th>      
            <th width="300px">店铺代码</th>      
            <th width="200px">添加时间</th>
            <th width="120px">绑定会员数</th>
            <th width="80px">操作</th>
          </tr>
          <tr
            v-for="(s, index) in currentpagelist"
            :key="index"
            @mouseover="mouseOver(index)"
            @mouseout="mouseOut"
            
            :class="{ actived: currentIndex == index }"
          >
            <td>{{ s.id }}</td>
            <td>{{ s.membername }}</td>
            <td>{{ s.loginname }}</td>
            <td>{{ s.membercode }}</td>
            <td>{{ s.adddate }}</td>
            <td>{{ s.membercount }}</td>

            <td style="vertical-align: middle">
              <!-- <a @click.stop="stopExcept(s.id)" title="停止此条限制，继续启用返佣" v-show="s.membercount==0"
                ><i class="fa fa-stop-circle-o" aria-hidden="true"></i
              ></a> -->
              &nbsp;&nbsp;
              <a @click.stop="delExcept(s.id)" title="删除该店铺" 
                ><i class="fa fa-trash" aria-hidden="true"></i
              ></a>
            </td>
          </tr>
        </table>
        <div v-if="noResultFlag" class="noresult">没有找到符合查询条件的店铺信息！</div>
      </div>
      <div
        class="center_bottom"
        style="padding: 5px; color: #6c757d; border-top: 1px dashed #9acd32"
        v-if="!noResultFlag"
      >
        <span class="btmInfo" style="vertical-align: middle"
          >&nbsp;&nbsp;共有 {{ recordcount }} 条记录，当前第
          {{ currentpage }}/{{ pagecount }} 页</span
        >
        <div style="float: right; padding-right: 30px">
          <button class="btn btn-default" type="button" @click="getNewPage(1)">
            首页
          </button>
          <button
            class="btn btn-default"
            type="button"
            @click="getNewPage(currentpage - 1)"
          >
            上页
          </button>
          <button
            class="btn btn-default"
            type="button"
            @click="getNewPage(currentpage + 1)"
          >
            下页
          </button>
          <button
            class="btn btn-default"
            type="button"
            @click="getNewPage(pagecount)"
          >
            尾页
          </button>
          <span class="btmInfo" style="margin-right: 2px">跳转到</span>
          <input
            type="text"
            size="1"
            id="txtGotoPage"
            autocomplete="off"
            v-int-only
            style="margin-right: 10px; font-size: 0.9em"
            v-model.trim="gotopage"
          />
          <button
            type="button"
            class="btn btn-default"
            @click="getNewPage(gotopage)"
          >
            跳转
          </button>
        </div>
      </div>
    </div>
    <!-- </div> -->
    <!-- </div> -->
  </div>
</template>

<script>
import axios from "axios";

import WSDetail from "../../components/WSDetail";
import UpdateWSDetail from "../../components/UpdateWSDetail";
import Toast from "../../components/Toast";

export default {
  name: "memberList",
  components: {
        
        WSDetail,
        UpdateWSDetail,
        Toast
  },
  data() {
    return {
        etype:'',
        productStatus:'',
        // classFList:[],
        // classCList:[],
        // productFClass: "",
        // productCClass: "",
        classshow:false,
        productshow:false,
      productList: [],
      productList2: [],
      currentIndex: -1,
      currentIndex2: -1,
      currentpage: 1,
      currentpage2: 1,
      gotopage: "",
      gotopage2: "",
      membername: "",
      membercode: "",
      loginname:"",
      wxname:"",
      membercontactname:"",
    //   isshowcalender: false,
      show: false,
      isshow: false,
      isshowupdate:false,
      win_height: "",
      currentSID: 0,
      currentSInfo: [],
      noResultFlag:false,
      noResultFlag2:false,
      cansubmit:true
    };
  },
  methods: {
    handleSearch(e) {
      // console.log('chufa')
      var search = this.loginname;
      
      if (search) {
        // console.log('开始提交1')
        axios({
          method: "get",
          params: {
            pname: search,
          },
          url: this.GLOBAL.serverAddress + "/isLoginNameExist/",
        }).then(
          // axios.post('http://localhost:8080/getStorageList').then(
          (response) => {
            console.log(response)
            if(response.data){
              this.$refs.toast.toast("登录账号已存在")
              this.cansubmit=false
              return false
            }else{
              this.cansubmit=true
            }
          },
          (error) => {
            console.log("出现错误", error.message);
            return false;
          }
        );
      }
    },
      getMemberInfo(mname,mcode){
          this.membername=mname;
          this.membercode=mcode;
      },
    setupWS(){
        this.$router.push('/setupwholesale')
    },
    async getSDetail() {
      const res = await this.$get(
        this.GLOBAL.serverAddress + "/getWSProductDetail/",
        {'id':this.currentSID}
      );
      if(res.status===200){
        this.currentSInfo=res.data[0];
      }
      
    },
    
    async stopExcept(pid){
        if(confirm("您确认要解除该商品的返佣限制吗？确认后，该商品的新订单将继续返佣")){
            const res=await this.$post(this.GLOBAL.serverAddress + "/stopExcept/",{id:pid})
            if(res.data==""){
                this.$refs.toast.toast("解除成功")
                this.getProductList()
            }else{
                this.$refs.toast.toast(res.data)
                return
            }
        }
    },
    async delExcept(pid){
        if(confirm("您确认要删除该店铺信息吗？删除店铺，所有绑定该店铺的会员会自动清空。")){
            const res=await this.$post(this.GLOBAL.serverAddress + "/delMember/",{id:pid})
            if(res.data==""){
                this.$refs.toast.toast("删除成功")
                this.getProductList()
            }else{
                this.$refs.toast.toast(res.data)
                return
            }
        }
    },
    screen_click(pid) {
      // 显示筛选
      this.currentSID = pid;
      this.show = true;
      this.isshow = true;

      // this.currentSInfo = 
      this.getSDetail();

      //  console.log(this.currentSInfo)
    },
    screen_hide_click() {
      // 隐藏筛选
      let that = this;
      setTimeout(function () {
        that.show = false;
      }, 300);
      that.isshow = false;
      that.isshowupdate = false;
    },

    
    clearSearch() {
      this.searchpname = "";
      this.searchpsn = "";
    },
    mouseOver(index) {
      // alert(index)
      this.currentIndex = index;
    },
    mouseOut() {
      // alert(index)
      this.currentIndex = -1;
    },
    mouseOver2(index) {
      // alert(index)
      this.currentIndex2 = index;
    },
    mouseOut2() {
      // alert(index)
      this.currentIndex2 = -1;
    },
    // setCClass(){
    //     this.classCList=[]
    //     this.classFList.forEach((v)=>{
    //         if(v.fcid==this.productFClass){
    //             this.productCClass=""
    //             // this.classCList=""
    //             this.classCList=v.cclass
    //         }
    //     })
    //     // alert(this.productFClass)
    // },
    getNewPage(pagenum) {
      if (pagenum >= 1 && pagenum <= this.pagecount) {
        this.currentpage = pagenum;
        // return this.pages[this.currentpage - 1];
      }
    },
    getNewPage2(pagenum) {
      if (pagenum >= 1 && pagenum <= this.pagecount2) {
        this.currentpage2 = pagenum;
        // return this.pages[this.currentpage - 1];
      }
    },

    async saveExcept() {
        if(this.membercode===""){
            this.$refs.toast.toast("请选择团长")
            return false    
        }
        if(this.membername===""){
            this.$refs.toast.toast("请输入店铺名称")
            return false    
        }
        if(this.loginname===""){
            this.$refs.toast.toast("请输入登录账号")
            return false    
        }
        if(!this.cansubmit){
            this.$refs.toast.toast("登录账号已存在")
            return false   
        }
        // if(this.membercode.length>8){
        //     this.$refs.toast.toast("代码超8个字符")
        //     return false
        // }
        
        const res=await this.$post(this.GLOBAL.serverAddress + "/saveMemberInfo/",{membercode:this.membercode,membername:this.membername,loginname:this.loginname})
        if(res.data==""){
            this.$refs.toast.toast("添加成功")
            this.getProductList()
        }else{
            this.$refs.toast.toast(res.data)
            return
        }
        // console.log(res)
        // if(res.data){
        //     this.$router.go(-1)
        //     // this.$refs.toast.toast("备注更新成功")
        // }else{
        //     this.$refs.toast.toast("设置失败，请重新尝试")
        // }
        // console.log(res)
        
    },
    
    async getProductList() {
        // this.etype===""
        // this.productshow=false
        // this.classshow=false
        this.membername=""
        this.membercode=""
      //   if (days) {
      axios({
        method: "get",
        params: {
          spname: this.searchpname,
          spsn: this.searchpsn,
        },
        url: this.GLOBAL.serverAddress + "/getAllDMember/",
      }).then(
        (response) => {
          this.productList = response.data;
          if(this.productList.length > 0){
              this.noResultFlag=false
          }else{
              this.noResultFlag=true
              
          }
        },
        (error) => {
          console.log("出现错误", error.message);
          return false;
        }
      );

      // const res2 = await this.$get(this.GLOBAL.serverAddress + "/getAllProductClass/",{});
      // console.log(res2);

      // if (res2.status === 200) {
      //   this.classFList = res2.data;
      // }
      //   }
    },
    async getReadyMember() {
        this.currentpage2=1
        // this.etype===""
        // this.productshow=false
        // this.classshow=false
        // this.wxname=""
        // this.membername=""
      //   if (days) {
      axios({
        method: "get",
        params: {
          wxname: this.wxname,
          membercontactname: this.membercontactname,
        },
        url: this.GLOBAL.serverAddress + "/getReadyDMember/",
      }).then(
        (response) => {
          this.productList2 = response.data;
          if(this.productList2.length > 0){
              this.noResultFlag2=false
          }else{
              this.noResultFlag2=true
              
          }
        },
        (error) => {
          console.log("出现错误", error.message);
          return false;
        }
      );

      //   }
    },
  },
  computed: {
    currentpagelist() {
      return this.pages[this.currentpage - 1];
    },
    currentpagelist2() {
      return this.pages2[this.currentpage2 - 1];
    },
    recordcount() {
      return this.productList.length;
    },
    recordcount2() {
      return this.productList2.length;
    },
    pagecount() {
      return Math.ceil(this.productList.length / 15);
    },
    pagecount2() {
      return Math.ceil(this.productList2.length / 10);
    },
    pages() {
      const pages = [];
      this.productList.forEach((item, index) => {
        const page = Math.floor(index / 15);
        if (!pages[page]) {
          pages[page] = [];
        }
        pages[page].push(item);
      });
      return pages;
    },
    pages2() {
      const pages = [];
      this.productList2.forEach((item, index) => {
        const page = Math.floor(index / 10);
        if (!pages[page]) {
          pages[page] = [];
        }
        pages[page].push(item);
      });
      return pages;
    },
  },
  mounted() {
    this.haveRights('|0401|')
    this.getProductList();
    this.getReadyMember()
    // window.addEventListener("click", this.hideCalender);
    this.win_height = window.innerHeight;
  },
  beforeDestroy() {
    // 实例销毁之前对点击事件进行解绑
    // window.removeEventListener("click", this.hideCalender);
  },
};
</script>

<style scoped lang="less">
.search_condition {
    display: flex;
    // white-space: nowrap;
    // justify-content:center;
    align-items: center;
    // text-align: right;
    padding:10px;
  .pname {
    //   display: flex;
    flex: 1;
    padding-right: 5px;
    white-space: nowrap;
  }

  .pname_value {
    flex: 4;
    display: flex;
    white-space: nowrap;
    align-items: center;
    flex-direction: column;
  }
  .sltStyle{
    //   flex: 1;
  }
  .blankdiv{
      width:100px;
  }



}
button {
  background-color: #f5f5f5;
  margin-right: 10px;
}
.btmInfo {
  background: none;
  border: none;
  outline: none;
  color: #333;
  font-size: 0.9em;
}
.noresult {
  text-align: center;
  font-size: 2em;
  color: rgb(160, 154, 154);
}
.actived {
  background-color: rgba(190, 221, 140, 0.9);
}
table.dataintable {
  /* margin-top: 15px; */
  border-collapse: collapse;
  border: 1px solid white;
  width: 98%;
  font-size: 0.95em;
  background-color: #e5ebdc;
  line-height: 40px;
}
table.dataintable th {
  vertical-align: middle;
  /* padding:5px 15px 5px 6px; */
  background-color: #e8e8e8;
  border: 1px solid white;
  text-align: center;
  color: rgb(173, 169, 169);
}
table.dataintable td {
  vertical-align: text-top;
  /* padding:6px 15px 6px 6px; */
  border: 1px solid white;
  line-height: 35px;
}

.hidden_view {
  width: 100%;
  background: #698b22;
  opacity: 0.2;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 99;
}
.sltStyle {
    
    padding-left: 5px;
    color:#999;
    select{
      border: 1px solid #E9E9E9;
    font-size: 0.9em;
    outline: none;
    padding: 0.5em 1em;
    color: #999;
    }
  }
</style>