<template>
  <div style="height: 100%;">
    <Toast ref="toast" />
    <div
      ref="curDiv"
      v-show="isshowcalender"
      style="z-index: 999; position: absolute"
    >
      <Calender ref="Calender" v-on:choseDay="clickDay"></Calender>
    </div>
    <div class="grid-form1" style="padding: 0">
      <!-- <h4>问题类别维护</h4> -->
      <!-- <div class="col-md-12" style="margin: 0; padding: 0"> -->
      <div style="margin: 0; padding: 0; vertical-align: middle">
        <!-- <form
                class="form-horizontal"
                action=""
                id="frmSRList"
                method="POST"
              > -->
        <div class="form-group" style="vertical-align: middle">
          
          <div class="search_condition">
              <div class="bdate">
            <label for="txtBDate" class="control-label">下单日期从</label>
          </div>
          <div class="bdate_value">
            <input
              type="text"
              class="form-control1"
              id="txtBDate"
              name="txtBDate"
              readonly
              @click.stop="showcalender($event, 'b')"
              v-model.trim="searchbdate"
            />
          </div>
          <div>
            <select name="sltBHour" class="bdate_value_time" v-model="sBHour">
              <option
                v-for="(item, index) in listhours"
                :key="index"
                :value="item"
              >
                {{ item }}
              </option>
            </select>
          </div>
          <div>
            <select
              name="sltBMinute"
              class="bdate_value_time"
              v-model="sBMinute"
            >
              <option
                v-for="(item, index) in listsecond"
                :key="index"
                :value="item"
              >
                {{ item }}
              </option>
            </select>
          </div>
          <div>
            <select
              name="sltBSecond"
              class="bdate_value_time"
              v-model="sBSecond"
            >
              <option
                v-for="(item, index) in listsecond"
                :key="index"
                :value="item"
              >
                {{ item }}
              </option>
            </select>
          </div>
          <div class="edate">
            <label for="txtEDate" class="control-label">截止到</label>
          </div>
          <div class="edate_value">
            <input
              type="text"
              class="form-control1"
              id="txtEDate"
              name="txtEDate"
              readonly
              @click.stop="showcalender($event, 'e')"
              v-model.trim="searchedate"
            />
          </div>
          <div>
            <select name="sltEHour" class="bdate_value_time" v-model="sEHour">
              <option
                v-for="(item, index) in listhours"
                :key="index"
                :value="item"
              >
                {{ item }}
              </option>
            </select>
          </div>
          <div>
            <select
              name="sltEMinute"
              class="bdate_value_time"
              v-model="sEMinute"
            >
              <option
                v-for="(item, index) in listsecond"
                :key="index"
                :value="item"
              >
                {{ item }}
              </option>
            </select>
          </div>
          <div>
            <select
              name="sltESecond"
              class="bdate_value_time"
              v-model="sESecond"
            >
              <option
                v-for="(item, index) in listsecond"
                :key="index"
                :value="item"
              >
                {{ item }}
              </option>
            </select>
          </div>
          </div>
          <div class="search_condition">
              <div>
                  <label
              for="txtContractNumber"
              class="control-label"
              >排除方式：</label
            >
              </div>
              <div>
                  <input @change="changeEType" type="radio" name="eType" value="1" v-model="etype"><label
              for="txtContractNumber"
              class="control-label"
              >指定商品</label
            >
            &nbsp;&nbsp;
                  <input @change="changeEType" type="radio" name="eType" value="2" v-model="etype"><label
              for="txtContractNumber"
              class="control-label"
              >指定品类</label
            >
              </div>
          <div class="pname" v-show="productshow">&nbsp;&nbsp;
            <label
              for="txtContractNumber"
              class="control-label"
              >商品ID</label
            >
          </div>
          <div class="pname_value" v-show="productshow">
            <input
              type="text"
              class="form-control1"
              id="txtContractNumber"
              name="txtContractNumber"
              v-model.trim="searchpname"
              
            />
          </div>
          <div class="sltStyle" v-show="classshow">
              <label
              for="txtContractNumber"
              class="control-label"
              ></label
            >&nbsp;
            <select name="sltProductClass" v-model="productFClass" @change="setCClass">
              <option value="">请选择商品类别</option>
              <option :value="item.fcid" v-for="(item,index) in classFList" :key="index">{{item.fcname}}</option>
            </select>
            <select name="sltProductCClass" v-model="productCClass">
              <option value="">请选择二级分类</option>
              <option :value="item.ccid" v-for="(item,index) in classCList" :key="index">{{item.ccname}}</option>
            </select>
          </div>
          
          
          
            <div>&nbsp;&nbsp;&nbsp;&nbsp;</div>
          
            
            <button
              class="btn btn-default"
              type="button"
              @click="saveExcept"
            >
              <i class="fa fa-save" aria-hidden="true"></i>&nbsp;保存排除条件
            </button>
        </div>
          
        </div>
        <!-- </form> -->
      </div>
      <!-- </div> -->

      <!-- <div style="padding-top: 0; padding-bottom: 8px">
        <div class="row">
          <div class="col-sm-12 " style="padding-top: 10px;padding-left:50px;">
            <button class="btn btn-default" type="button" @click="setupWS">
              <i class="fa fa-plus-square-o" aria-hidden="true"></i>&nbsp;配置可批发产品
            </button>
            
          </div>
        </div>
      </div> -->
    </div>

    <div class="grid-form1" >
      <!-- <h4>问题类别维护</h4> -->
      <div
        class="tab-content"
        style=" margin-top: 5px; padding: 0"
      >
        <table class="dataintable" v-if="productList.length > 0">
          <tr style="font-weight: bold">
            <th width="50px" >ID</th>
            <th width="100px">排除方式</th>
            <th>排除内容</th>            
            <th width="130px">产品/品类 ID</th>
            <th width="200px">生效时间</th>
            <th width="200px">失效时间</th>

            <th width="80px">操作</th>
          </tr>
          <tr
            v-for="(s, index) in currentpagelist"
            :key="index"
            @mouseover="mouseOver(index)"
            @mouseout="mouseOut"
            
            :class="{ actived: currentIndex == index }"
          >
            <td>{{ s.id }}</td>
            <td>{{ s.etype=="1"?"指定商品":"指定品类" }}</td>
            <td style="text-align: left; padding-left: 5px">{{ s.pname }}</td>
            <td>{{ s.pid }}</td>
            <td>{{ s.adddate }}</td>
            <td>{{ s.deldate }}</td>

            <td style="vertical-align: middle">
              <!-- <a @click.stop="stopExcept(s.id)" title="停止此条限制，继续启用返佣" v-show="s.deldate == ''"
                ><i class="fa fa-stop-circle-o" aria-hidden="true"></i
              ></a>
              &nbsp;&nbsp; -->
              <a @click.stop="delExcept(s.id)" title="删除此条限制"
                ><i class="fa fa-trash" aria-hidden="true"></i
              ></a>
            </td>
          </tr>
        </table>
        <div v-if="noResultFlag" class="noresult">没有找到符合查询条件的不返佣政策！</div>
      </div>
      <div
        class="center_bottom"
        style="padding: 5px; color: #6c757d; border-top: 1px dashed #9acd32"
        v-if="!noResultFlag"
      >
        <span class="btmInfo" style="vertical-align: middle"
          >&nbsp;&nbsp;共有 {{ recordcount }} 条记录，当前第
          {{ currentpage }}/{{ pagecount }} 页</span
        >
        <div style="float: right; padding-right: 30px">
          <button class="btn btn-default" type="button" @click="getNewPage(1)">
            首页
          </button>
          <button
            class="btn btn-default"
            type="button"
            @click="getNewPage(currentpage - 1)"
          >
            上页
          </button>
          <button
            class="btn btn-default"
            type="button"
            @click="getNewPage(currentpage + 1)"
          >
            下页
          </button>
          <button
            class="btn btn-default"
            type="button"
            @click="getNewPage(pagecount)"
          >
            尾页
          </button>
          <span class="btmInfo" style="margin-right: 2px">跳转到</span>
          <input
            type="text"
            size="1"
            id="txtGotoPage"
            autocomplete="off"
            v-int-only
            style="margin-right: 10px; font-size: 0.9em"
            v-model.trim="gotopage"
          />
          <button
            type="button"
            class="btn btn-default"
            @click="getNewPage(gotopage)"
          >
            跳转
          </button>
        </div>
      </div>
    </div>
    <!-- </div> -->
    <!-- </div> -->
  </div>
</template>

<script>
import axios from "axios";

import WSDetail from "../../components/WSDetail";
import UpdateWSDetail from "../../components/UpdateWSDetail";
import Toast from "../../components/Toast";
import Calender from "../../components/vue-calendar-component";

export default {
  name: "policyExcept",
  components: {
        
        WSDetail,
        UpdateWSDetail,
        Toast,
        Calender
  },
  data() {
    return {
        sEHour: "00",
      sEMinute: "00",
      sESecond: "00",
      sBHour: "00",
      sBMinute: "00",
      sBSecond: "00",
        listhours: [
        "00",
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
        "24",
      ],
      listsecond: [
        "00",
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
        "24",
        "25",
        "26",
        "27",
        "28",
        "29",
        "30",
        "31",
        "32",
        "33",
        "34",
        "35",
        "36",
        "37",
        "38",
        "39",
        "40",
        "41",
        "42",
        "43",
        "44",
        "45",
        "46",
        "47",
        "48",
        "49",
        "50",
        "51",
        "52",
        "53",
        "54",
        "55",
        "56",
        "57",
        "58",
        "59",
      ],
      // filename:this.GLOBAL.getToday() + '出库总单.xls',
      searchedate: "",
      searchbdate: "",
      searchedatef: "",
      searchbdatef: "",
        etype:'',
        productStatus:'',
        classFList:[],
        classCList:[],
        productFClass: "",
        productCClass: "",
        classshow:false,
        productshow:false,
      productList: [],
      currentIndex: -1,
      currentpage: 1,
      gotopage: "",
      searchpsn: "",
      searchpname: "",
      isshowcalender: false,
      show: false,
      isshow: false,
      isshowupdate:false,
      win_height: "",
      currentSID: 0,
      currentSInfo: [],
      noResultFlag:false
    };
  },
  methods: {
    setupWS(){
        this.$router.push('/setupwholesale')
    },
    async getSDetail() {
      const res = await this.$get(
        this.GLOBAL.serverAddress + "/getWSProductDetail/",
        {'id':this.currentSID}
      );
      if(res.status===200){
        this.currentSInfo=res.data[0];
      }
      
    },
    
    async stopExcept(pid){
        if(confirm("您确认要解除此条限制吗？确认后，该商品的新订单将重新返佣")){
            const res=await this.$post(this.GLOBAL.serverAddress + "/stopExcept/",{id:pid})
            if(res.data==""){
                this.$refs.toast.toast("解除成功")
                this.getProductList()
            }else{
                this.$refs.toast.toast(res.data)
                return
            }
        }
    },
    async delExcept(pid){
        if(confirm("您确认要删除此条排除政策吗？删除后，该商品的所有订单都会参与返佣")){
            const res=await this.$post(this.GLOBAL.serverAddress + "/delExcept/",{id:pid})
            if(res.data==""){
                this.$refs.toast.toast("删除成功")
                this.getProductList()
            }else{
                this.$refs.toast.toast(res.data)
                return
            }
        }
    },
    hideCalender() {
      this.isshowcalender = false;
    },
    clickDay(data) {
      //   this.searchyxqdate = data;
      // console.log(this.currentDateS)
      if (this.currentDateS === "b") {
        this.searchbdate = data;
      } else {
        this.searchedate = data;
      }
      this.isshowcalender = false;
    },
    showcalender(e, v) {
      var divC = this.$refs.curDiv;
      this.currentDateS = v;
      divC.style.top = e.target.getBoundingClientRect().top + 40 + "px";
      divC.style.left = e.target.getBoundingClientRect().left + "px";

      this.isshowcalender = true;
    },
    screen_click(pid) {
      // 显示筛选
      this.currentSID = pid;
      this.show = true;
      this.isshow = true;

      // this.currentSInfo = 
      this.getSDetail();

      //  console.log(this.currentSInfo)
    },
    screen_hide_click() {
      // 隐藏筛选
      let that = this;
      setTimeout(function () {
        that.show = false;
      }, 300);
      that.isshow = false;
      that.isshowupdate = false;
    },

    
    clearSearch() {
      this.searchpname = "";
      this.searchpsn = "";
    },
    mouseOver(index) {
      // alert(index)
      this.currentIndex = index;
    },
    mouseOut() {
      // alert(index)
      this.currentIndex = -1;
    },
    setCClass(){
        this.classCList=[]
        this.classFList.forEach((v)=>{
            if(v.fcid==this.productFClass){
                this.productCClass=""
                // this.classCList=""
                this.classCList=v.cclass
            }
        })
        // alert(this.productFClass)
    },
    getNewPage(pagenum) {
      if (pagenum >= 1 && pagenum <= this.pagecount) {
        this.currentpage = pagenum;
        // return this.pages[this.currentpage - 1];
      }
    },
    async saveExcept() {
        if(this.searchbdate===""){
            this.$refs.toast.toast("请选择起始日期")
            return false    
        }else{
            this.searchbdatef =
                this.searchbdate +
                " " +
                this.sBHour +
                ":" +
                this.sBMinute +
                ":" +
                this.sBSecond;
        }
        if(this.searchedate===""){
            this.$refs.toast.toast("请选择截止日期")
            return false    
        }else{
            this.searchedatef =
                this.searchedate +
                " " +
                this.sEHour +
                ":" +
                this.sEMinute +
                ":" +
                this.sESecond;
        }
        if(this.searchedatef<this.searchbdatef){
            this.$refs.toast.toast("日期逻辑不正确")
            return false   
        }
        if(this.etype===""){
            this.$refs.toast.toast("请选择排除方式")
            return false    
        }
        let tmpClassID=""
        if(this.etype==="1"){

            if(this.searchpname===""){
                this.$refs.toast.toast("请填写商品ID")
                return false    
            }else{
                tmpClassID =this.searchpname
            }
        }
        if(this.etype==="2"){

            if(this.productFClass===""){
                this.$refs.toast.toast("请选择商品类别")
                return false    
            }else{
                if(this.productCClass){
                    tmpClassID = this.productCClass
                }else{
                    tmpClassID =this.productFClass
                }
            }
        }
        const res=await this.$post(this.GLOBAL.serverAddress + "/savePolicyExcept/",{etype:this.etype,id:tmpClassID,bdate:this.searchbdatef,edate:this.searchedatef})
        if(res.data==""){
            this.$refs.toast.toast("添加成功")
            this.getProductList()
        }else{
            this.$refs.toast.toast(res.data)
            return
        }
        // console.log(res)
        // if(res.data){
        //     this.$router.go(-1)
        //     // this.$refs.toast.toast("备注更新成功")
        // }else{
        //     this.$refs.toast.toast("设置失败，请重新尝试")
        // }
        // console.log(res)
        
    },
    changeEType(){
        if(this.etype==="1"){
            this.productshow=true
            this.classshow=false
            this.productFClass=""
        }else{
            this.productshow=false
            this.classshow=true
            this.searchpname= ""
        }
        console.log(this.etype)
    },
    async getProductList() {
        // this.etype===""
        // this.productshow=false
        // this.classshow=false
        this.productFClass=""
        this.productCClass=""
        this.searchpname=""
        this.searchedate = "";
        this.searchbdate = "";
        this.sEHour = "00";
        this.sEMinute = "00";
        this.sESecond = "00";
        this.sBHour = "00";
        this.sBMinute = "00";
        this.sBSecond = "00";

      //   if (days) {
      axios({
        method: "get",
        params: {
          spname: this.searchpname,
          spsn: this.searchpsn,
        },
        url: this.GLOBAL.serverAddress + "/getAllExceptInfo/",
      }).then(
        (response) => {
            this.productList = response.data;
            console.log(this.productList)
          if(this.productList.length > 0){
              this.noResultFlag=false
          }else{
              this.noResultFlag=true
              
          }
        },
        (error) => {
          console.log("出现错误", error.message);
          return false;
        }
      );

      const res2 = await this.$get(this.GLOBAL.serverAddress + "/getAllProductClass/",{});
      console.log(res2);

      if (res2.status === 200) {
        this.classFList = res2.data;
      }
      //   }
    },
  },
  computed: {
    currentpagelist() {
      return this.pages[this.currentpage - 1];
    },
    recordcount() {
      return this.productList.length;
    },
    pagecount() {
      return Math.ceil(this.productList.length / 15);
    },
    pages() {
      const pages = [];
      this.productList.forEach((item, index) => {
        const page = Math.floor(index / 15);
        if (!pages[page]) {
          pages[page] = [];
        }
        pages[page].push(item);
      });
      return pages;
    },
  },
  mounted() {
    this.haveRights('|0403|')
    this.getProductList();
    window.addEventListener("click", this.hideCalender);
    this.win_height = window.innerHeight;
  },
  beforeDestroy() {
    // 实例销毁之前对点击事件进行解绑
    window.removeEventListener("click", this.hideCalender);
  },
};
</script>

<style scoped lang="less">
.search_condition {
    display: flex;
    // white-space: nowrap;
    // justify-content:center;
    align-items: center;
    // text-align: right;
    padding:10px;
  .pname {
    //   display: flex;
    // flex: 1;
    padding-right: 5px;
  }

  .pname_value {
    // flex: 1;
  }
  .sltStyle{
    //   flex: 1;
  }
  .blankdiv{
      width:100px;
  }



}
button {
  background-color: #f5f5f5;
  margin-right: 10px;
}
.btmInfo {
  background: none;
  border: none;
  outline: none;
  color: #333;
  font-size: 0.9em;
}
.noresult {
  text-align: center;
  font-size: 2em;
  color: rgb(160, 154, 154);
}
.actived {
  background-color: rgba(190, 221, 140, 0.9);
}
table.dataintable {
  /* margin-top: 15px; */
  border-collapse: collapse;
  border: 1px solid white;
  width: 98%;
  font-size: 0.95em;
  background-color: #e5ebdc;
  line-height: 40px;
}
table.dataintable th {
  vertical-align: middle;
  /* padding:5px 15px 5px 6px; */
  background-color: #e8e8e8;
  border: 1px solid white;
  text-align: center;
  color: rgb(173, 169, 169);
}
table.dataintable td {
  vertical-align: text-top;
  /* padding:6px 15px 6px 6px; */
  border: 1px solid white;
  line-height: 35px;
}

.hidden_view {
  width: 100%;
  background: #698b22;
  opacity: 0.2;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 99;
}
.sltStyle {
    
    padding-left: 5px;
    color:#999;
    select{
      border: 1px solid #E9E9E9;
    font-size: 0.9em;
    outline: none;
    padding: 0.5em 1em;
    color: #999;
    }
  }
  .bdate_value_time {
  color: #9c9c9c;
  border: 0px solid #9c9c9c;
}
</style>