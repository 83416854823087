<template>
  <div style="height: 100%">
    <Toast ref="toast" />
    <ul id="keywords" ref="sKeywords" v-show="showNameList">
      <li
        v-for="(s, index) in nameList"
        :key="index"
        @click="getPName(s)"
        @mouseover="mouseOver(index)"
        :class="{ actived: currentIndex == index }"
      >
        {{ s.pname }}<br />规格：{{ s.pguige }} | 售价：{{ s.pwsprice }}
      </li>
    </ul>
    <div class="orderlist">
      <div class="order_item" v-for="(item, index) in orderList" :key="index">
        <div class="order_title">订单详情</div>

        <div class="order_item_briefinfo_top">
          <div class="order_item_briefinfo_back">
            <i
              class="fa fa-arrow-circle-left"
              aria-hidden="true"
              style="color: #9acd32"
              title="返回订单列表"
              @click="goback"
            ></i>
          </div>
          <div class="order_item_briefinfo_ordernum">
            订单ID：{{ item.orderid }}<div class="dkxd_c">{{item.nickname==""?"":"（由" + item.nickname + "代客下单）"}}</div>
          </div>
        </div>
        <div class="order_item_briefinfo">
          <div class="order_item_briefinfo_orderpay">
            下单时间：{{ item.orderdatetime }}
          </div>
          <div class="order_item_briefinfo_orderpay">
            支付状态：{{ item.orderstatus }}
          </div>
          <div class="order_item_briefinfo_orderpay">
            取货状态：{{ item.orderdeliverystatus }}
          </div>
        </div>
        <div class="order_notesinfo">
          <div class="order_notesinfo_title">订单备注：</div>
          <div class="order_notesinfo_content">

            
            <textarea name="txtRemark" id="txtRemark" cols="80" rows="3" class="form-control1" style="height: 120px;" v-model="item.ordernotes" ></textarea> 
     
          </div>
          <div>
          <button
            class="btn btn-default"
            type="button"
            @click="updateRemarks(item.orderid,item.ordernotes)"
            >
            <i class="fa fa-save" aria-hidden="true"></i>&nbsp;更新备注
            </button>
          </div>
        </div>
        <div class="order_item_contact_title">客户信息</div>
        <div class="order_item_contact">
          <div class="ordercontact_name">
            联&nbsp;&nbsp;系&nbsp;&nbsp;人：{{ item.contactname }}
          </div>
          <div class="ordercontact_name">联系电话：{{ item.mobile }}</div>
          <div class="ordercontact_name">客户名称：{{ item.address }}</div>
        </div>
        <div class="order_item_list">批发商品清单</div>

        <div class="order_item_list_title">
          <div class="product_img">商品图片</div>
          <div class="product_name">商品名称/商品条码</div>
          <div class="product_guige">规格</div>
          <div class="product_unitprice">单价</div>
          <div class="product_count">数量</div>
          <div class="product_allprie" style="color: #9c9c9c">小计</div>
        </div>
        <div
          class="order_item_list_title"
          v-for="(item2, index2) in item.plist"
          :key="index2"
        >
          <div class="product_img">
            <img :src="item2.imgurl" />
          </div>

          <div class="product_name">{{ item2.pname }}<br />{{ item2.psn }}</div>
          <div class="product_guige">{{ item2.pguige }}</div>
          <div class="product_unitprice">C${{ item2.wsunitprice }}</div>
          <div class="product_count">
            <div class="product_count_old">
              {{ item2.wscount }}
            </div>
            <div class="product_count_newadd" v-if="item2.showaddtitle">
              <div class="product_count_newadd_kucun">
                可追加：{{ item2.showmaxnum }}
              </div>
              <div class="product_count_newadd_zhuijia">
                <div class="product_count_newadd_zhuijia_l">数量：</div>
                <div class="product_count_newadd_zhuijia_r">
                  <input
                    type="text"
                    class="form-control1"
                    v-float-only
                    v-model.trim="item2.addcount"
                    autocomplete="off"
                  />
                </div>
              </div>

              <div class="product_count_newadd_btn">
                <div class="btn" @click="hiddenAdd(item2.pinorderid)">取消</div>
                <div
                  class="btn"
                  @click="
                    updateNum(item.orderid, item2.pinorderid, item2.showmaxnum)
                  "
                >
                  调增
                </div>
                <div
                  class="btn"
                  @click="
                    reduceNum(item.orderid, item2.pinorderid, item2.wscount)
                  "
                >
                  调减
                </div>
              </div>
            </div>
          </div>
          <div class="product_allprie">
            <div class="product_allprie_o">
              C${{ item2.wsallprice }}
              <div
                class="product_allprie_btn"
                v-if="item.orderstatus === '未支付'"
                @click="adjustPrice(item2.pinorderid)"
              >
                调整
              </div>
            </div>
            <div class="product_allprie_n" v-if="item2.showadjustprice">
              调为&nbsp;
              <input
                type="text"
                class="form-control1"
                v-float-only
                v-model.trim="item2.nwsallprice"
                autocomplete="off"
              />
            </div>
            <div class="product_allprie_btnb" v-if="item2.showadjustprice">
              <div class="product_allprie_btnb_btn" @click="cancelAdjust(item2.pinorderid)">取消</div>
              <div class="product_allprie_btnb_btn" @click="submitAdjust(item2.pinorderid)">提交</div>
            </div>
          </div>
        </div>
        <div class="order_new_product" v-if="showAddFlag">
          <div>查询并选择要追加的商品：</div>
          <div>
            <input
              type="text"
              class="form-control1"
              v-model.trim="searchpname"
              @input="handleSearch($event)"
              autocomplete="off"
            />
          </div>
          <div>&nbsp;&nbsp;</div>
          <div>
            <button class="btn btn-default" type="button" @click="cancelAdd">
              取消追加
            </button>
            <!-- <button
                class="btn btn-default"
                type="button"
                @click="submitAdd"                
                >
                提交追加
                </button> -->
          </div>
        </div>
        <div class="order_item_list_title" v-if="showAddFlag">
          <div class="product_img">
            <img :src="newProductInfo.imgurl" />
          </div>

          <div class="product_name">
            {{ newProductInfo.pname }}<br />
            {{ newProductInfo.pcountnowshow }}<br />
            {{ newProductInfo.limit }}<br />

            <div v-for="(item, index) in newProductInfo.pricelist" :key="index">
              购买数量：{{ item.num }}及以上，价格为：{{ item.price }}
            </div>
          </div>
          <div class="product_guige">{{ newProductInfo.pguige }}</div>
          <div class="product_unitprice">C${{ newProductInfo.wsprice }}</div>
          <div class="product_count">
            <input
              type="text"
              class="form-control1"
              id="txtNumAdd"
              v-model.trim="newAddNum"
              v-float-only
              placeholder="追加数量"
              @input="getAllPrice"
              autocomplete="off"
            />

            <button
              class="btn btn-default"
              type="button"
              @click="submitAdd(orderList[0].orderid)"
              style="margin-top: 10px"
            >
              提交追加
            </button>
          </div>
          <div class="product_allprie">C${{ newProductInfo.allprice }}</div>
        </div>

        <div class="order_item_content_order">
          <div
            class="order_item_content_order_btn"
            v-if="
              item.orderstatus === '未支付' &&
              item.orderdeliverystatus === '未取货'
            "
          >
            <button class="btn btn-default" type="button" @click="showAdd">
              调整商品数量
            </button>
            <button
              class="btn btn-default"
              type="button"
              @click="showAddProduct"
            >
              追加新商品
            </button>
            
          </div>
          <div class="order_item_content_order_right">
            <i
              class="fa fa-arrow-circle-left"
              aria-hidden="true"
              style="color: #9acd32"
              title="返回订单列表"
              @click="goback"
            ></i
            >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;总额：C${{
              item.ordertotalprice
            }}
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- </div> -->
</template>

<script>
import axios from "axios";
import Toast from "../../components/Toast";

export default {
  name: "OrderDetail",
  components: { Toast },
  data() {
    return {
      nameList: [],
      shownoresult: true,
      showNameList: false,
      orderList: [],
      currentIndex: -1,
      currentpage: 1,
      gotopage: "",
      searchpname: "",
      show: false,
      isshow: false,
      //   isshowupdate: false,
      win_height: "",
      currentSID: 0,
      currentDateS: "",
      newAddNum: "",
      nowPrice: "",
      newProductInfo: {},
      showAddFlag: false,
    };
  },
  methods: {
    adjustPrice(pinorderid) {
      this.orderList[0].plist.forEach((v) => {
        if (v.pinorderid === pinorderid) {
          v.showadjustprice = true;
        }
      });
    },
    cancelAdjust(pinorderid) {
      this.orderList[0].plist.forEach((v) => {
        if (v.pinorderid === pinorderid) {
          v.showadjustprice = false
        }
      })
    },
    submitAdjust(pinorderid) {
      this.orderList[0].plist.forEach((v) => {
        if (v.pinorderid === pinorderid) {
          if(v.nwsallprice === ''){
            this.$refs.toast.toast("请输入调后总价")
            return false
          }else{

            if (!this.GLOBAL.isFloat(v.nwsallprice)){
              this.$refs.toast.toast("请输入正确总价")
              return false
            }else{
              if(confirm("您确认要修改订单金额？")){
              v.nwsunitprice=(v.nwsallprice/v.wscount).toFixed(2)
              axios({
                method: "POST",
                data: { orderid: this.orderList[0].orderid, 
                pinorderid:v.pinorderid,
                ouprice:v.wsunitprice,
                nuprice:v.nwsunitprice,
                oaprice:v.wsallprice,
                naprice:v.nwsallprice,
                },
                url: this.GLOBAL.serverAddress + "/updateWSPriceInfo/",
              }).then(
                (response) => {
                  if (response.data === true) {
                    this.$refs.toast.toast("价格修改成功");
                    v.showadjustprice = false
                    this.$router.go(0)
                  } else {
                    this.$refs.toast.toast(response.data);
                  }
                },
                (error) => {
                  alert(error.message);
                  return false;
                }
              );
              }
            }
            // this.$router.go(0)
          }
        }
      })
      
    },
    
    getAllPrice() {
      this.newProductInfo.wsprice = this.nowPrice;
      if (this.newProductInfo.pricelist.length > 0) {
        this.newProductInfo.pricelist.forEach((v) => {
          if (this.newAddNum >= v.num) {
            this.newProductInfo.wsprice = v.price;
          }
        });
      }
      this.newProductInfo.selectedcount = this.newAddNum;
      this.newProductInfo.allprice =
        (this.newAddNum * this.newProductInfo.wsprice).toFixed(2);
    },
    getPName(pinfo) {
      // console.log(sname)
      let cFlag = true;
      this.orderList[0].plist.forEach((v) => {
        // console.log(v.pguige==pinfo.pguige)
        if (v.posn == pinfo.posn && v.pguige == pinfo.pguige) {
          cFlag = false;
        }
      });
      if (cFlag) {
        this.newProductInfo.id = pinfo.id;
        this.newProductInfo.orderid = this.orderList[0].orderid;
        this.newProductInfo.wsprice = pinfo.pwsprice;
        this.newProductInfo.imgurl = pinfo.imgurl;
        this.newProductInfo.pname = pinfo.pname;
        this.newProductInfo.pguige = pinfo.pguige;
        this.newProductInfo.posn = pinfo.posn;
        this.newProductInfo.pcountnow = pinfo.pcountnow;
        this.newProductInfo.pcountnowshow = "剩余库存：" + pinfo.pcountnow;
        this.newProductInfo.pwsprice = pinfo.pwsprice;
        this.newProductInfo.selectedcountmin = pinfo.selectedcountmin;
        this.newProductInfo.selectedcountmax = pinfo.selectedcountmax;
        this.newProductInfo.minnum = pinfo.minnum;
        this.newProductInfo.maxnum = pinfo.maxnum;
        this.newProductInfo.selectedcount = "";
        this.nowPrice = pinfo.pwsprice;
        this.newProductInfo.pid = pinfo.pid;
        this.newProductInfo.pwsunit = pinfo.pwsunit;
        this.newAddNum = "";
        this.newProductInfo.allprice = "";

        // this.newProductInfo.limit= pinfo.maxnum + '/' + pinfo.minnum
        this.showNameList = false;
        this.showAddFlag = true;
        this.newProductInfo.limit = "";
        if (pinfo.minnum != 0) {
          this.newProductInfo.limit = "起购数量：" + pinfo.minnum + "  ";
        }
        if (pinfo.maxnum != 0) {
          this.newProductInfo.limit += "限购数量：" + pinfo.maxnum;
        }
        this.newProductInfo.pricelist = pinfo.steppricelist;
      } else {
        this.$refs.toast.toast("订单已有该商品");
      }
    },
    async updateRemarks(orderid,newremarks){
          const res=await this.$post(this.GLOBAL.serverAddress + "/updateWSRemarksByID/",{id:orderid,remarks:newremarks})
          // console.log(res)
          if(res.data){
              this.$router.go(0)
              // this.$refs.toast.toast("备注更新成功")
          }else{
              this.$refs.toast.toast("设置失败，请重新尝试")
          }
    },
    async getPriceList(id) {
      const res = await this.$get(
        this.GLOBAL.serverAddress + "/getWSPriceListByID/",
        { id: id }
      );

      if (res.status === 200) {
        // this.newProductInfo.pricelist=[]
        this.newProductInfo.pricelist = res.data;
        // console.log(this.newProductInfo.pricelist)
      } else {
        this.newProductInfo.pricelist = [];
      }
    },
    showAddProduct() {
      this.showAddFlag = true;
      this.newProductInfo.pname = "请选择要添加的商品";
    },
    cancelAdd() {
      this.showAddFlag = false;
      this.newProductInfo = {};
    },
    submitAdd(orderid) {
      if (this.newProductInfo.selectedcount === "") {
        this.$refs.toast.toast("请输入追加数量");
        $("#txtNumAdd").focus();
        return false;
      }
      if (this.newAddNum < this.newProductInfo.minnum) {
        this.$refs.toast.toast("未达到起购数量");
        $("#txtNumAdd").focus();
        return false;
      }
      if (
        this.newProductInfo.maxnum != 0 &&
        this.newAddNum > this.newProductInfo.maxnum
      ) {
        this.$refs.toast.toast("已超过限购数量");
        $("#txtNumAdd").focus();
        return false;
      }
      if (this.newAddNum > this.newProductInfo.pcountnow) {
        this.$refs.toast.toast("已超过库存数量");
        $("#txtNumAdd").focus();
        return false;
      }
      if (confirm("您确认要追加该产品吗？")) {
        let newAddInfo = {
          orderid: this.newProductInfo.orderid,
          pid: this.newProductInfo.pid,
          pname: this.newProductInfo.pname,
          pguige: this.newProductInfo.pguige,
          punit: this.newProductInfo.pwsunit,
          wscount: this.newProductInfo.selectedcount,
          wsunitprice: this.newProductInfo.wsprice,
          wsallprice: this.newProductInfo.allprice,
          posn: this.newProductInfo.posn,
        };

        // console.log(newAddInfo);
        axios({
          method: "POST",
          data: { productinfo: newAddInfo },
          url: this.GLOBAL.serverAddress + "/addNewProductInOrder/",
        }).then(
          (response) => {
            if (response.data === true) {
              this.$refs.toast.toast("商品追加成功");
              this.showAddFlag = false;
              this.newProductInfo = {};
              this.search(orderid);
            } else {
              this.$refs.toast.toast(response.data);
            }
          },
          (error) => {
            alert(error.message);
            return false;
          }
        );
      }
    },
    mouseOver(index) {
      // alert(index)
      this.currentIndex = index;
    },
    mouseOut() {
      // alert(index)
      this.currentIndex = -1;
    },
    handleSearch(e) {
      // console.log('chufa')
      var search = this.searchpname;
      // var tsearchpsn = this.searchpsn;
      this.showNameList = false;
      // console.log(e);
      if (search) {
        // console.log('开始提交1')
        axios({
          method: "get",
          params: {
            pname: search,
          },
          url: this.GLOBAL.serverAddress + "/getWSProductListForNewAdd/",
        }).then(
          // axios.post('http://localhost:8080/getStorageList').then(
          (response) => {
            this.nameList = response.data;
            // console.log(this.nameList)
            if (this.nameList.length > 0) {
              var ul = this.$refs.sKeywords; // document.getElementById("keywords");
              // console.log(e.target.getBoundingClientRect().top);
              ul.style.top = e.target.getBoundingClientRect().top + 40 + "px";
              ul.style.left = e.target.getBoundingClientRect().left + 8 + "px";
              this.showNameList = true;
            } else {
              this.showNameList = false;
            }
          },
          (error) => {
            console.log("出现错误", error.message);
            return false;
          }
        );
      }
    },
    updateNum(orderid, pinorderid, maxnum) {
      let addnum = "";
      let newNumSelected = 0;
      // console.log(addnum)
      this.orderList[0].plist.forEach((v) => {
        if (v.pinorderid == pinorderid) {
          addnum = v.addcount;
          newNumSelected = parseFloat(v.addcount) + parseFloat(v.wscount);
        }
      });

      if (addnum != "") {
        if (addnum > maxnum) {
          this.$refs.toast.toast("已超可追加数量");
        } else {
          if (
            confirm("您确认要追加该商品吗？订单价格将根据商品数量重新计算。")
          ) {
            axios({
              method: "POST",
              data: {
                orderid: orderid,
                pinorderid: pinorderid,
                newNumSelected: newNumSelected,
              },
              url: this.GLOBAL.serverAddress + "/updateOrder/",
            }).then(
              (response) => {
                this.$refs.toast.toast("数量追加成功");
                this.search(orderid);
              },
              (error) => {
                alert(error.message);
                return false;
              }
            );
          }
        }
      }
      // console.log(addnum)
    },
    reduceNum(orderid, pinorderid, maxnum) {
      let addnum = "";
      let newNumSelected = 0;
      // console.log(addnum)
      this.orderList[0].plist.forEach((v) => {
        if (v.pinorderid == pinorderid) {
          addnum = v.addcount;
          newNumSelected = parseFloat(v.addcount) ;
        }
      });

      if (addnum != "") {
        if (addnum > maxnum) {
          this.$refs.toast.toast("已超可调减数量");
        } else {
          if (
            confirm("您确认要减少该商品数量吗？订单价格将根据商品数量重新计算。")
          ) {
            axios({
              method: "POST",
              data: {
                orderid: orderid,
                pinorderid: pinorderid,
                newNumSelected: newNumSelected,
              },
              url: this.GLOBAL.serverAddress + "/reduceOrder/",
            }).then(
              (response) => {
                this.$refs.toast.toast("数量调减成功");
                this.search(orderid);
              },
              (error) => {
                alert(error.message);
                return false;
              }
            );
          }
        }
      }
      // console.log(addnum)
    },
    showAdd() {
      this.orderList[0].plist.forEach((v) => (v.showaddtitle = true));
    },
    hiddenAdd(pinorderid) {
      this.orderList[0].plist.forEach((v) => {
        if (v.pinorderid == pinorderid) {
          v.showaddtitle = false;
        }
      });
    },
    goback() {
      this.$router.go(-1);
    },
    
    payOrder(id) {
      if (confirm("您确认已经收到了该订单的款项吗？确认后无法撤回。")) {
        axios({
          method: "put",
          data: { id: id, status: "已支付", type: "1" },
          url: this.GLOBAL.serverAddress + "/updateOrderStatus/",
        }).then(
          (response) => {
            // this.clearSearch();
            this.search();
            alert("订单状态更新完成。");
          },
          (error) => {
            alert(error.message);
            return false;
          }
        );
      }
    },
    deliveryOrder(id) {
      if (confirm("您确认该订单已完成取货吗？确认后无法撤回。")) {
        axios({
          method: "put",
          data: { id: id, status: "已取货", type: "2" },
          url: this.GLOBAL.serverAddress + "/updateOrderStatus/",
        }).then(
          (response) => {
            // this.clearSearch();
            this.search();
            alert("订单状态更新完成。");
          },
          (error) => {
            alert(error.message);
            return false;
          }
        );
      }
    },

    getNewPage(pagenum) {
      if (pagenum >= 1 && pagenum <= this.pagecount) {
        this.currentpage = pagenum;
        return this.pages[this.currentpage - 1];
      }
    },
    async search(id) {
      const res = await this.$get(
        this.GLOBAL.serverAddress + "/getOrderDetailByID/",
        {
          id: id,
        }
      );
      //   console.log(res);

      if (res.status === 200) {
        this.orderList = res.data;
        if (this.orderList.length > 0) {
          this.shownoresult = false;
        } else {
          this.shownoresult = true;
        }
      }
    },
  },
  computed: {
    currentpagelist() {
      return this.pages[this.currentpage - 1];
    },
    recordcount() {
      return this.orderList.length;
    },
    pagecount() {
      return Math.ceil(this.orderList.length / 15);
    },
    pages() {
      const pages = [];
      this.orderList.forEach((item, index) => {
        const page = Math.floor(index / 15);
        if (!pages[page]) {
          pages[page] = [];
        }
        pages[page].push(item);
      });
      return pages;
    },
  },
  mounted() {
    this.search(this.$route.query.id);
    // window.addEventListener("click", this.hideCalender);
    this.win_height = window.innerHeight;
  },
  beforeDestroy() {
    // 实例销毁之前对点击事件进行解绑
    // window.removeEventListener("click", this.hideCalender);
  },
};
</script>

<style scoped lang="less">
.orderlist {
  color: #9c9c9c;

  // padding:10px;
  .order_item {
    //   width:100%;
    margin-top: 15px;
    background-color: #fff;
    .order_title {
      text-align: center;
      font-size: 1.5em;
      padding: 20px;
    }
    .order_item_briefinfo_top {
      display: flex;
      border-bottom: 1px solid #eee;

      .order_item_briefinfo_back {
        font-size: 1.3em;
        padding-left: 50px;
        flex: 1;
        i {
          cursor: pointer;
        }
      }
      .order_item_briefinfo_ordernum {
        text-align: right;
        padding-right: 50px;
        flex: 1;
        // display: flex;
        .dkxd_c{
          color:red;
        }
      }
    }
    // padding-top:15px;
    .order_item_briefinfo {
      //   padding-bottom:10px;
      padding: 10px 50px;

      display: flex;
      justify-content: space-between;

      .order_item_briefinfo_orderpay {
        // text-align: center;
        margin: 10px;
      }
    }

    .order_notesinfo {
      display: flex;
      padding: 10px 60px;
      .order_notesinfo_title {
      }
      .order_notesinfo_content {
      }
    }
    .order_item_contact_title {
      background-color: #eeeed1;
      line-height: 30px;
      padding-left: 40px;
      // border: 1px solid green;
    }
    .order_item_contact {
      padding: 20px 50px;
      .ordercontact_name {
        margin: 10px;
      }
    }
    .order_item_list {
      background-color: #eeeed1;
      line-height: 30px;
      text-align: center;
      //   width: 50%;
      align-self: center;
      //   color: #666;
    }
    .order_new_product {
      display: flex;
      margin-left: 50px;
      align-items: center;
    }
    .order_item_list_title {
      margin: 10px 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-bottom: 1px solid #eee;
      padding-bottom: 10px;
      text-align: center;
      .product_img {
        flex: 1;
        img {
          width: 80%;
          object-fit: contain;
          height: 130px;
          //   object-fit: contain;
        }
      }
      .product_name {
        flex: 3;
      }
      .product_guige {
        flex: 2;
      }
      .product_unitprice {
        flex: 1;
      }
      .product_count {
        flex: 1;
        // border: 1px solid green;
        .product_count_newadd {
          .product_count_newadd_zhuijia {
            display: flex;
            justify-content: center;
            align-items: center;
            .product_count_newadd_zhuijia_l {
              flex: 1;
            }
            .product_count_newadd_zhuijia_r {
              flex: 1;
            }
          }
          .product_count_newadd_btn {
            color: #9acd32;
          }
        }
      }
      .product_allprie {
        flex: 1;
        color: #00bfff;
        .product_allprie_o {
          display: flex;
          align-items: center;
          justify-content: center;

          .product_allprie_btn {
            padding-left: 5px;
            color: #9acd32;
            cursor: pointer;
          }
        }
        .product_allprie_n {
          display: flex;
          align-items: center;
          white-space: nowrap;
          
        }
        .product_allprie_btnb{
          display: flex;
          align-items: center;
          justify-content: space-around;
          .product_allprie_btnb_btn{
            padding: 5px;
            cursor: pointer;
          }
        }
      }
    }

    .order_item_content_order {
      text-align: right;
      margin-right: 80px;
      padding-bottom: 10px;
      font-size: 1.2em;

      display: flex;
      .order_item_content_order_btn {
        flex: 3;
        // display: flex;
      }
      .order_item_content_order_right {
        color: #00bfff;
        flex: 1;
        i {
          cursor: pointer;
        }
      }
    }
  }
}

button {
  background-color: #f5f5f5;
  margin-right: 10px;
}

#keywords {
  position: absolute;
  padding-left: 5px;
  /* top: 40px; */
  background-color: rgba(228, 233, 220, 0.9);
  list-style: none;
  width: 400px;
  z-index: 9999;
  color: #616161;
  font-size: 15px;
  line-height: 25px;
}

.actived {
  background-color: rgba(190, 221, 140, 0.9);
}
</style>