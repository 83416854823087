<template>
  <div style="height: 100%;">
    <Toast ref="toast" />
    <div class="grid-form1" style="padding: 0">
      <!-- <h4>问题类别维护</h4> -->
      <!-- <div class="col-md-12" style="margin: 0; padding: 0"> -->
      <div style="margin: 0; padding: 0; vertical-align: middle">
        <!-- <form
                class="form-horizontal"
                action=""
                id="frmSRList"
                method="POST"
              > -->
        <div class="form-group" style="vertical-align: middle" v-if="briefInfo.length>0">
          
          
          <div class="search_condition">
              <div>
                  <label
              for="txtContractNumber"
              class="control-label"
              >团长：{{briefInfo[0]["membername"]}}</label
            >
            &nbsp;&nbsp;<label
              for="txtContractNumber"
              class="control-label"
              >商品总消费：</label
            >
              </div>
              <div style="color:green;font-size:1.2em;">{{briefInfo[0]["orderprice"]}}</div>
          <div class="pname">&nbsp;&nbsp;
            <label
              for="txtContractNumber"
              class="control-label"
              >总佣金：</label
            >
          </div>
          <div style="color:red;font-size:1.2em;">{{briefInfo[0]["commissions"]}}</div>
          <div class="pname">&nbsp;&nbsp;
            <label
              for="txtContractNumber"
              class="control-label"
              >支付状态：{{briefInfo[0]["ispaid"]}}</label
            >
          </div>
            <div>&nbsp;&nbsp;&nbsp;&nbsp;</div>
          
            
            <button
              class="btn btn-default"
              type="button"
              @click="goBackToList()"
            >
              <i class="fa fa-arrow-circle-o-left" aria-hidden="true"></i>&nbsp;返回结算单列表
            </button>
        </div>
          
        </div>
        <!-- </form> -->
      </div>
      
    </div>

    <div class="grid-form1" >
      <!-- <h4>问题类别维护</h4> -->
      <div
        class="tab-content"
        style=" margin-top: 5px; padding: 0"
      >
        <table class="dataintable" v-if="productList.length > 0">
          <tr style="font-weight: bold">
            <th width="50px" >ID</th>
            <th width="200px">订单编号</th>
            <th width="70px">商品ID</th>
            <th width="150px">商品分类</th>
            <th>商品名称</th>
            <th width="80px">商品总价</th>
            <th width="80px">返佣比例</th>
            <th width="90px">佣金</th>
          </tr>
          <tr
            v-for="(s, index) in currentpagelist"
            :key="index"
            @mouseover="mouseOver(index)"
            @mouseout="mouseOut"
            
            :class="{ actived: currentIndex == index }"
          >
            <td>{{ s.id }}</td>
            <td>{{ s.sno }}</td>
            <td>{{ s.pid }}</td>
            <td>{{ s.fclass }}/{{s.cclass}}</td>
            <td>{{ s.pname }}</td>
            <td>{{ s.orderprice }}</td>
            <td>{{ s.commissionvalue }}%</td>
            <td>{{ s.commissions }}</td>

          </tr>
        </table>
        <div v-if="noResultFlag" class="noresult">没有找到符合查询条件的可批发产品！</div>
      </div>
      <div
        class="center_bottom"
        style="padding: 5px; color: #6c757d; border-top: 1px dashed #9acd32"
        v-if="!noResultFlag"
      >
        <span class="btmInfo" style="vertical-align: middle"
          >&nbsp;&nbsp;共有 {{ recordcount }} 条记录，当前第
          {{ currentpage }}/{{ pagecount }} 页</span
        >
        <div style="float: right; padding-right: 30px">
          <button class="btn btn-default" type="button" @click="getNewPage(1)">
            首页
          </button>
          <button
            class="btn btn-default"
            type="button"
            @click="getNewPage(currentpage - 1)"
          >
            上页
          </button>
          <button
            class="btn btn-default"
            type="button"
            @click="getNewPage(currentpage + 1)"
          >
            下页
          </button>
          <button
            class="btn btn-default"
            type="button"
            @click="getNewPage(pagecount)"
          >
            尾页
          </button>
          <span class="btmInfo" style="margin-right: 2px">跳转到</span>
          <input
            type="text"
            size="1"
            id="txtGotoPage"
            autocomplete="off"
            v-int-only
            style="margin-right: 10px; font-size: 0.9em"
            v-model.trim="gotopage"
          />
          <button
            type="button"
            class="btn btn-default"
            @click="getNewPage(gotopage)"
          >
            跳转
          </button>
        </div>
      </div>
    </div>
    <!-- </div> -->
    <!-- </div> -->
  </div>
</template>

<script>
import axios from "axios";

import WSDetail from "../../components/WSDetail";
import UpdateWSDetail from "../../components/UpdateWSDetail";
import Toast from "../../components/Toast";

export default {
  name: "billDetailList",
  components: {
        
        WSDetail,
        UpdateWSDetail,
        Toast
  },
  data() {
    return {
        etype:'',
        productStatus:'',
        classFList:[],
        classCList:[],
        productFClass: "",
        productCClass: "",
        classshow:false,
        productshow:false,
      productList: [],
      briefInfo:[],
      currentIndex: -1,
      currentpage: 1,
      gotopage: "",
      searchpsn: "",
      searchpname: "",
    //   isshowcalender: false,
      show: false,
      isshow: false,
      isshowupdate:false,
      win_height: "",
      currentSID: 0,
      currentSInfo: [],
      noResultFlag:false
    };
  },
  methods: {
    
    
    mouseOver(index) {
      // alert(index)
      this.currentIndex = index;
    },
    mouseOut() {
      // alert(index)
      this.currentIndex = -1;
    },
    setCClass(){
        this.classCList=[]
        this.classFList.forEach((v)=>{
            if(v.fcid==this.productFClass){
                this.productCClass=""
                // this.classCList=""
                this.classCList=v.cclass
            }
        })
        // alert(this.productFClass)
    },
    getNewPage(pagenum) {
      if (pagenum >= 1 && pagenum <= this.pagecount) {
        this.currentpage = pagenum;
        // return this.pages[this.currentpage - 1];
      }
    },
    
    goBackToList(){
        this.$router.go(-1);
    },
    changeEType(){
        if(this.etype==="1"){
            this.productshow=true
            this.classshow=false
            this.productFClass=""
        }else{
            this.productshow=false
            this.classshow=true
            this.searchpname= ""
        }
        console.log(this.etype)
    },
    async getProductList(id) {
        
      axios({
        method: "get",
        params: {
          id: id
        },
        url: this.GLOBAL.serverAddress + "/getBillDetaisList/",
      }).then(
        (response) => {
            console.log(response)
          this.productList = response.data[0];
          this.briefInfo=response.data[1]
          if(this.productList.length > 0){
              this.noResultFlag=false
          }else{
              this.noResultFlag=true
              
          }
        },
        (error) => {
          console.log("出现错误", error.message);
          return false;
        }
      );

      //   }
    },
  },
  computed: {
    currentpagelist() {
      return this.pages[this.currentpage - 1];
    },
    recordcount() {
      return this.productList.length;
    },
    pagecount() {
      return Math.ceil(this.productList.length / 30);
    },
    pages() {
      const pages = [];
      this.productList.forEach((item, index) => {
        const page = Math.floor(index / 30);
        if (!pages[page]) {
          pages[page] = [];
        }
        pages[page].push(item);
      });
      return pages;
    },
  },
  mounted() {
    this.haveRights('|0403|')
    this.getProductList(this.$route.query.id);
    // window.addEventListener("click", this.hideCalender);
    this.win_height = window.innerHeight;
  },
  beforeDestroy() {
    // 实例销毁之前对点击事件进行解绑
    // window.removeEventListener("click", this.hideCalender);
  },
};
</script>

<style scoped lang="less">
.search_condition {
    display: flex;
    // white-space: nowrap;
    // justify-content:center;
    align-items: center;
    // text-align: right;
    padding:10px;
  .pname {
    //   display: flex;
    // flex: 1;
    padding-right: 5px;
  }

  .pname_value {
    // flex: 1;
  }
  .sltStyle{
    //   flex: 1;
  }
  .blankdiv{
      width:100px;
  }



}
button {
  background-color: #f5f5f5;
  margin-right: 10px;
}
.btmInfo {
  background: none;
  border: none;
  outline: none;
  color: #333;
  font-size: 0.9em;
}
.noresult {
  text-align: center;
  font-size: 2em;
  color: rgb(160, 154, 154);
}
.actived {
  background-color: rgba(190, 221, 140, 0.9);
}
table.dataintable {
  /* margin-top: 15px; */
  border-collapse: collapse;
  border: 1px solid white;
  width: 98%;
  font-size: 0.95em;
  background-color: #e5ebdc;
  line-height: 40px;
}
table.dataintable th {
  vertical-align: middle;
  /* padding:5px 15px 5px 6px; */
  background-color: #e8e8e8;
  border: 1px solid white;
  text-align: center;
  color: rgb(173, 169, 169);
}
table.dataintable td {
  vertical-align: text-top;
  /* padding:6px 15px 6px 6px; */
  border: 1px solid white;
  line-height: 35px;
}

.hidden_view {
  width: 100%;
  background: #698b22;
  opacity: 0.2;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 99;
}
.sltStyle {
    
    padding-left: 5px;
    color:#999;
    select{
      border: 1px solid #E9E9E9;
    font-size: 0.9em;
    outline: none;
    padding: 0.5em 1em;
    color: #999;
    }
  }
</style>