<template>
  <div style="height: 100%;">
      <Toast ref="toast" />
    <div
      ref="curDiv"
      v-show="isshowcalender"
      style="z-index: 999; position: absolute"
    >
      <Calender ref="Calender" v-on:choseDay="clickDay"></Calender>
    </div>

    <div class="grid-form1" style="padding: 0">
      <div style="margin: 0; padding: 0; vertical-align: middle;">
        <div class="search_condition2">
          <div class="pname2">
            <label
              for="txtContractNumber"
              >第一步：选择客户</label
            >
          </div>
          <div class="pname_value2">
            <select name="sltPayStatus" v-model="clientID" >
              <option value="">请选择下单客户</option>
              <option v-for="(item,index) in orderListC" :key="index" :value="item.id">{{item.clientname}}</option>
            </select>
          </div>
          <div class="bdate" >
          <label
            for="txtBDate"
            class="control-label"
            >下单日期&nbsp;</label
          >
          </div>
          <div class="bdate_value" >
            <input
              type="text"
              class="form-control1"
              id="txtBDate"
              name="txtBDate"
              readonly
              @click.stop="showcalender($event,'b')"
              v-model.trim="searchbdate"              
            />
          </div>
          
          
          
          </div>
        
      </div>

    </div>
        <div class="orderlist" >
            <div class="order_item" >
                <div class="search_condition2">
                    <div class="order_item_title"><label
                    for="txtContractNumber"
                    >第二步：选择商品：</label
                    ></div>
                    <div class="pname">
                    <label
                    for="txtContractNumber"
                    class="control-label"
                    >商品名称 </label
                    >
                </div>
                <div class="pname_value">
                    <input
                    type="text"
                    class="form-control1"
                    id="txtContractNumber"
                    name="txtContractNumber"
                    v-model.trim="searchpname"
                    />
                </div>
                <div class="search_btn" style="padding-top: 2px;padding-left:50px;">
                    <button
                    class="btn btn-default"
                    type="button"
                    @click="search"
                    >
                    <i class="fa fa-search" aria-hidden="true"></i>&nbsp;搜索
                    </button>
                </div>
                </div>
                <div v-if="selectedPList.length>0">
                    <div class="selected_product">
                        &nbsp;&nbsp;&nbsp;已选购商品列表
                        <table class="dataintable" >
                    <tr style="font-weight: bold">
                        <th width="150px">商品编码</th>
                        <th>可批发商品名称</th>   
                        <th width="180px">商品规格</th>
                        <th width="90px">批发单位</th>
                        <th width="90px">批发单价</th>
                        <th width="90px">剩余库存</th>
                        <th width="90px">购买数量</th>
                        <th width="90px">商品总价</th>
                    </tr>
                    <tr v-for="(item, index) in selectedPList" :key="index">
                        <td><label style="color:red;" @click="delSelectedProduct(item)">X</label> {{item.posn}}</td>
                        <td>{{item.pname}}</td>
                        <td>{{item.pguige}}</td>
                        <td>{{item.pwsunit}}</td>
                        <td>{{item.pwsprice}}</td>
                        <td>{{parseFloat(item.pcountnow).toFixed(2)}}</td>
                        <td>
                            <input type="text"
                    class="form-control1"
                     v-float-only v-model="item.pbuycount" style="width:90px;background-color:white;" @change="calNewPrice(item)">
                        </td>
                        <td><input type="text"
                    class="form-control1" v-float-only v-model="item.pbuyprice" style="width:90px;background-color:white;"></td>
                    </tr>
                    <tr style="font-weight:bold;">
                      <td colspan="7" style="text-align:right;">订单总价：</td>
                      <td style="text-align:left;color:green;font-size:1.5em;">{{order_allprice}}</td>
                      
                     
                    </tr>
                        </table>
                    </div>
                    <div class="search_condition2">
                    订单备注：
                <div class="pname_value">
                    <textarea
                    class="form-control1"
                    style="height: 70px; width: 400px"
                    v-model.trim="ordernotes"
                    />
                </div>
                    </div>
                    <div  style="padding-top: 10px;text-align:right;padding-right:50px;">
          
            <button class="btn btn-default" type="button" @click="goback">
              <i class="fa fa-remove" aria-hidden="true"></i>&nbsp;取消
            </button>
            <button
              class="btn btn-default"
              type="button"
              :disabled="submitSaving"
              @click="neworder"
            >
              <i class="fa fa-save" aria-hidden="true"></i>&nbsp;
              {{ submitSaving ? "提交中.." : "提交订单" }}
            </button>
          </div>
                    <div>&nbsp;&nbsp;</div>
                </div>
                <div
                    class="tab-content"
                    style=" margin-top: 5px; padding: 0"
                >
                    <table class="dataintable" v-if="orderList.length > 0">
                    <tr style="font-weight: bold">
                  
                        <th width="150px">商品编码</th>
                        <th>可批发商品名称</th>   
                        <th width="180px">商品规格</th>
                        <th width="90px">批发单位</th>
                        <th width="90px">批发单价</th>
                        <th width="90px">剩余库存</th>
                    </tr>
                    <tr v-for="(item, index) in currentpagelist"
                        :key="index"
                        @mouseover="mouseOver(index)"
                        @mouseout="mouseOut"
                        @click="screen_click(item)"
                        :class="{ actived: currentIndex == index }">
                            
                        <td>{{item.posn}}</td>            
                        <td>{{item.pname}}</td>            
                        <td>{{item.pguige}}</td>            
                        <td>{{item.pwsunit}}</td>            
                        <td>{{item.pwsprice}}</td>        
                        <td>{{parseFloat(item.pcountnow).toFixed(2)}}</td>            
                    </tr>
                    </table>
                    <div v-if="shownoresult" class="noresult">没有找到符合查询条件的可批发产品！</div>
                </div>
                <div
        class="pagenum_info"
        v-if="!shownoresult"
      >
        <span class="btmInfo" style="vertical-align: middle"
          >&nbsp;&nbsp;共有 {{ recordcount }} 条记录，当前第
          {{ currentpage }}/{{ pagecount }} 页</span
        >
        <div style="float: right; padding-right: 30px">
          <button class="btn btn-default" type="button" @click="getNewPage(1)">
            首页
          </button>
          <button
            class="btn btn-default"
            type="button"
            @click="getNewPage(currentpage - 1)"
          >
            上页
          </button>
          <button
            class="btn btn-default"
            type="button"
            @click="getNewPage(currentpage + 1)"
          >
            下页
          </button>
          <button
            class="btn btn-default"
            type="button"
            @click="getNewPage(pagecount)"
          >
            尾页
          </button>
          <span class="btmInfo" style="margin-right: 2px">跳转到</span>
          <input
            type="text"
            size="1"
            id="txtGotoPage"
            autocomplete="off"
            v-int-only
            style="margin-right: 10px; font-size: 0.9em"
            v-model.trim="gotopage"
          />
          <button
            type="button"
            class="btn btn-default"
            @click="getNewPage(gotopage)"
          >
            跳转
          </button>
        </div>
      </div>
            </div>
            
            
        </div>
       
      
    </div>
  <!-- </div> -->
</template>

<script>
import axios from "axios";
import Toast from "../../components/Toast";

import Calender from "../../components/vue-calendar-component";

export default {
  name: "NewOrder",
  components: {
    Calender,
    Toast
  },
  data() {
    return {
        searchedate:'',
        searchbdate:'',
        shownoresult:false,
        orderList:[],
      orderListC: [],
      selectedPList:[],
      clientID:'',
      currentIndex: -1,
      currentpage: 1,
      gotopage: "",
      searchpcontact: "",
      searchpname: "",
      paystatus:"未支付",
      devstatus:"未取货",
        isshowcalender: false,
      show: false,
      isshow: false,
      //   isshowupdate: false,
      win_height: "",
      currentSID: 0,
      currentDateS:"",
      ordernotes:'',
      submitSaving: false,
      order_allprice:0
    };
  },
  methods: {
      screen_click(pid) {
          let tmpflag = false
          let tmpcount=1
          let tmpindex=-1

          tmpindex=this.selectedPList.indexOf(pid)
        
          if(tmpindex>=0){
              pid.pbuycount=parseFloat(pid.pbuycount)+1
              pid.pbuyprice=parseFloat(pid.pwsprice)*pid.pbuycount
              Vue.set(this.selectedPList,tmpindex,pid)
              tmpflag=true
          }
          
          if(!tmpflag){
              pid.pbuycount=1
              pid.pbuyprice=pid.pwsprice
              this.selectedPList.push(pid)
          }
          this.getTotalPrice()
    },
    delSelectedProduct(item){
        let tmpindex=-1

        tmpindex=this.selectedPList.indexOf(item)
        
        this.selectedPList.splice(tmpindex,1)
        this.getTotalPrice()
    },
    calNewPrice(item){
      let tmpindex=-1

      tmpindex=this.selectedPList.indexOf(item)
      let tmpPrice=this.selectedPList[tmpindex].pwsprice
      let tmpCount=this.selectedPList[tmpindex].pbuycount
      // console.log(tmpPrice*tmpCount)
      // console.log(parseFloat(tmpPrice)*parseFloat(tmpCount))
      item.pbuyprice=(parseFloat(tmpPrice)*parseFloat(tmpCount)).toFixed(2)

      // this.selectedPList[tmpindex].pbuyprice=parseFloat(tmpPrice)*parseFloat(tmpCount)

      Vue.set(this.selectedPList,tmpindex,item)
      
      // this.selectedPList.splice(tmpindex,1)
      this.getTotalPrice()
    },
      mouseOver(index) {
      // alert(index)
      this.currentIndex = index;
    },
    mouseOut() {
      // alert(index)
      this.currentIndex = -1;
    },
      goback() {
          this.$router.go(-1)
      },
    getTotalPrice() {
      this.order_allprice=0
      this.selectedPList.forEach(v=>{
        this.order_allprice=this.order_allprice+parseFloat(v.pbuyprice)
        })
      this.order_allprice=this.order_allprice.toFixed(2)
    },
    async neworder(){
      if(this.clientID==''){
          this.$refs.toast.toast("请选择下单客户")
          return false
      }
      if(this.searchbdate==''){
          this.$refs.toast.toast("请选择下单日期")
          return false
      }
      this.selectedPList.forEach(v=>{
          if(v.pbuycount=="0"||v.pbuycount==""){
              this.$refs.toast.toast("请输入购买数量")
                return false
          }
          if(v.pbuyprice==""){
              this.$refs.toast.toast("请输入商品总价")
                return false
          }
      })
        if(confirm("您确认要提交该订单吗？")){
            this.submitSaving=true

            let orderDetailInfo=[]
            let totalPrice=0
            this.selectedPList.forEach(v=>{orderDetailInfo.push({
                pid:v.pid,
                pname:v.pname,
                pguige:v.pguige,
                punit:v.pwsunit,
                wscount:v.pbuycount,
                wsunitprice:v.pwsprice,
                wsallprice:v.pbuyprice,
                posn:v.posn, 
                psn:''
            })
            totalPrice=totalPrice+parseFloat(v.pbuyprice)
            }
            )
            // console.log(orderDetailInfo)

            let contactname=''
            let contacttel=''
            let contactaddress=''
            this.orderListC.forEach(v=>{
                if(v.id==this.clientID){
                    contactname=v.contact
                    contacttel=v.tel
                    contactaddress=v.address
                }
            })
                
            let orderInfo={'nickname':sessionStorage.getItem("loginuser"),'clientid':this.clientID,'contactname':contactname,'ordernotes':this.ordernotes,'mobile':contacttel,'address':contactaddress,'zipcode':'','ordertotalprice':totalPrice,'orderdate':this.searchbdate,'ordertype':'2','from':'WSSYS'}
            const res=await this.$post(this.GLOBAL.serverAddress + "/submitOrder/",{orderInfo,orderDetailInfo})
              console.log(res)
            if(res.data===true){
                this.$refs.toast.toast("下单成功")
                setTimeout(()=>{
                    this.$router.push('/wsorderlist')
                    },1500)
                
                // this.$refs.toast.toast("备注更新成功")
            }else{
                alert(res.data)
                this.submitSaving = false
                //   this.$refs.toast.toast("提交失败请重试")
            }
        }

    },
      viewOrderDetail(id){
          this.$router.push('/orderdetail?id='+id)
      },
      
    hideCalender() {
      this.isshowcalender = false;
    },
    clickDay(data) {
    //   this.searchyxqdate = data;
        // console.log(this.currentDateS)
      if(this.currentDateS==='b'){
          this.searchbdate=data
      }else{
          this.searchedate=data
      }
      this.isshowcalender = false;
    },
    showcalender(e,v) {
      var divC = this.$refs.curDiv;
      this.currentDateS=v
      divC.style.top = e.target.getBoundingClientRect().top + 40 + "px";
      divC.style.left = e.target.getBoundingClientRect().left + "px";

      this.isshowcalender = true;
    },

    clearSearch() {
      this.searchpname = ""
      this.searchpcontact = ""
      this.searchedate=""
        this.searchbdate=""
        this.devstatus="未取货"
        this.paystatus="未支付"
    },
    getNewPage(pagenum) {
      if (pagenum >= 1 && pagenum <= this.pagecount) {
        this.currentpage = pagenum;
        // console.log(this.pages)
        // return this.pages[this.currentpage - 1];
      }
    },
    async getClientList(){
        const res = await this.$get(this.GLOBAL.serverAddress + "/getAllClientInfo/", {})
        if (res.status === 200) {
            this.orderListC = res.data
            console.log(res.data)
        }
    },
    async search() {
      const res = await this.$get(this.GLOBAL.serverAddress + "/searchWSProductForOrder/", 
      {'spname':this.searchpname});
      console.log(res);
        
      if (res.status === 200) {
        this.orderList = res.data;
        if(this.orderList.length>0){
            this.shownoresult=false
        }else{
            this.shownoresult=true
        }
      }
    },
  },
  computed: {
    currentpagelist() {
      return this.pages[this.currentpage - 1];
    },
    recordcount() {
      return this.orderList.length;
    },
    pagecount() {
      return Math.ceil(this.orderList.length / 20);
    },
    pages() {
      const pages = [];
      this.orderList.forEach((item, index) => {
        const page = Math.floor(index / 20);
        if (!pages[page]) {
          pages[page] = [];
        }
        pages[page].push(item);
      });
      return pages;
    },
  },
  mounted() {
    this.haveRights('|0201|')
    this.search();
    this.getClientList()
    window.addEventListener("click", this.hideCalender);
    this.win_height = window.innerHeight;
  },
  beforeDestroy() {
    // 实例销毁之前对点击事件进行解绑
    window.removeEventListener("click", this.hideCalender);
  },
};
</script>

<style scoped lang="less">
.search_condition2 {
    display: flex;
    // white-space: nowrap;
    // justify-content:center;
    align-items: center;
    
    padding:10px;
    // padding-bottom: 20px;
  .pname2 {
    //   display: flex;
    // border: 1px solid red;
    // flex: 2;
    padding-left: 10px;
    text-align: right;
    color: #999;
  }

  .pname_value2 {
    // flex: 2;
    padding-left: 5px;
    color:#999;
    select{
      border: 1px solid #E9E9E9;
    font-size: 0.9em;
    outline: none;
    padding: 0.5em 1em;
    color: #999;
    }
  }
  
  .bdate{
      padding-left: 100px;
    //   flex:1;
  }
}
.selected_product{
    // padding: 10px;
}
.search_condition {
    display: flex;
    // white-space: nowrap;
    justify-content:center;
    align-items: center;
    text-align: right;
    padding:10px;
  .pname {
    //   display: flex;
    // border: 1px solid red;
    flex: 1;
    padding-right: 5px;
  }

  .pname_value {
    flex: 3;
    // padding-left: 5px;
  }

  .cname {
    flex: 1;
    padding-right: 5px;
  }

  .cname_value {
    flex: 2;
  }

  .bdate {
    flex: 1;
    padding-right: 5px;
  }

  .bdate_value {
    flex: 1;
  }

  .edate {
    //   border: 1px solid green;
    flex: 1;
    text-align: center;
    // display: flex;
    // align-items: center;
    // justify-content: center;
  }

  .edate_value {
    flex: 1;
  }
}

.orderlist {
  color: #9c9c9c;

  // padding:10px;
  .order_item {
    //   width:100%;
    margin-top: 15px;
    background-color: #fff;
    // padding-top:15px;
    .order_item_title{
        padding: 10px;
    }
    .order_item_briefinfo {
      display: flex;
      border-bottom: 1px solid #eee;
      //   padding-bottom:10px;
      padding: 10px;
      .order_item_briefinfo_ordernum {
        padding-left: 20px;
        flex: 1;
        display: flex;
        .dkxd_c{
          padding-left:10px;
          color:red;
        }
      }

      .order_item_briefinfo_orderdate {
        flex: 1;
      }
      .order_item_briefinfo_orderpay {
        flex: 1;
        text-align: center;
      }
    }

    .order_item_content {
      display: flex;
      .order_item_content_product {
        flex: 3;
        margin: 10px;
        padding-left: 10px;
        display: flex;
        .productinfo_img {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            width: 80%;
            object-fit: contain;
          }
        }

        .productinfo {
          flex: 3;
          margin: 10px;
          padding-left: 5px;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          // align-items: center;
          .productinfo_title {
          }

          .productinfo_guige {
          }

          .productinfo_num {
          }

          .productinfo_price {
          }
        }
      }

      .order_item_content_order {
        flex: 4;
        display: flex;
        .orderprice {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 1.2em;
          color: #6e8b3d;
        }

        .orderstatus {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .ordercontact {
          flex: 3;
          display: flex;
          // align-items: center;
          justify-content: center;
          flex-direction: column;
          padding-left: 10px;
          .ordercontact_name {
          }

          .ordercontact_tel {
          }

          .ordercontact_address {
            display: flex;
            .address_title {
              white-space: nowrap;
            }
          }
        }
      }

      .order_item_content_opr {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        // padding:15px;
        button {
          width: 80%;
          margin: 3px;
        }
      }
    }
  }
}

button {
  background-color: #f5f5f5;
  margin-right: 10px;
}
.btmInfo {
  background: none;
  border: none;
  outline: none;
  color: #333;
  font-size: 0.9em;
}
.noresult {
  text-align: center;
  font-size: 2em;
  color: rgb(160, 154, 154);
}
.pagenum_info{
    background:#fff;
    height: 50px;
    margin-top:20px;
    padding: 10px; color: #6c757d;
    // border-top: 1px dashed #9acd32;
}
.actived {
  background-color: rgba(190, 221, 140, 0.9);
}
table.dataintable {
  /* margin-top: 15px; */
  border-collapse: collapse;
  border: 1px solid white;
  width: 98%;
  font-size: 0.95em;
  background-color: #e5ebdc;
  line-height: 40px;
}
table.dataintable th {
  vertical-align: middle;
  /* padding:5px 15px 5px 6px; */
  background-color: #e8e8e8;
  border: 1px solid white;
  text-align: center;
  color: rgb(173, 169, 169);
}
table.dataintable td {
  vertical-align: text-top;
  /* padding:6px 15px 6px 6px; */
  border: 1px solid white;
  line-height: 35px;
}

.hidden_view {
  width: 100%;
  background: #698b22;
  opacity: 0.2;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 99;
}
</style>