<template>
  <router-view></router-view>
</template>

<script>
import Navigator from "./components/Navigator.vue"
import CopyRight from "./components/CopyRightInfo.vue"
import TopPosition from "./components/TopPosition.vue"

export default {
  name: "App",
  components: {
    Navigator,
    CopyRight,
    TopPosition
  }
  
};
</script>

<style>
* {
  touch-action: pan-y;
}
table {
  border: 1px solid #9c9c9c;
  width: 95%;
  margin: auto;
  text-align: center;
  color:#9c9c9c;
  padding: 0;
  /* margin:0; */
  
}
td {
  border: 1px solid #9c9c9c;
  color: #9c9c9c;
  line-height: 30px;
  font-family:Arial, Helvetica, sans-serif;
  margin:0;
  padding:0;

}
</style>
