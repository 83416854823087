<template>
<transition name="show_view">
  <div class="screen_cent" v-show="isshow">
     <div class="screen_data" transiton="show_view">
       <table class="dataintable" >
         <tr><td style="width:120px;height:80px;vertical-align:middle;">产品名称</td><td style="vertical-align:middle;">{{currentSInfo.pname}}</td></tr>
         <tr><td>商城中的编号</td><td>{{currentSInfo.posn}}</td></tr>
         <tr><td>商品入库编号</td><td>{{currentSInfo.psn}}</td></tr>
         <tr><td>商品规格</td><td>{{currentSInfo.pguige}}</td></tr>
         <tr><td>供应商</td><td>{{currentSInfo.pgys}}</td></tr>
         <tr><td>商品有效期</td><td>{{currentSInfo.pyxq}}</td></tr>
         <tr><td>入库单位</td><td>{{currentSInfo.punit}}</td></tr>
         <tr><td>采购单价</td><td>{{currentSInfo.pprice}}</td></tr>
         <tr><td>入库数量</td><td>{{currentSInfo.pcount}}</td></tr>
         <tr><td>总成本</td><td>{{currentSInfo.pcost}}</td></tr>
         <!-- <tr><td>零售单位</td><td>{{currentSInfo.punitls}}</td></tr>
         <tr><td>可零售数量</td><td>{{currentSInfo.pcountls}}</td></tr>
         <tr><td>零售成本</td><td>{{currentSInfo.pcostls}}</td></tr> -->
         
         
       </table>
     </div>
     
    </div>
</transition>
</template>

<script>
export default {
    name: "StorageDetail",
    data(){
        return{

        }
    },
    props:{
        currentSInfo:{
            type:[],
            required:true,
        },
        isshow:{
            type:Boolean,
            required:true,
        }
    }
}
</script>

<style scoped>

.search_page_list {
  width: 100%;
  position: relative;
}

.hidden_view {
  width: 100%;
  background: #698b22;
  opacity: 0.2;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 99;
}

.screen_cent {
  width: 500px;
  height: 550px;
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 99;
  border-radius: 10px 0 0 10px;
  overflow: hidden;
}

.screen_data {
  padding: 20px;
  width: 100%;
  height: 100%;
  background: #ffffff;
}
.show_view-enter {
  animation: show_view-dialog-fade-in 0.2s ease;
}

.show_view-leave {
  animation: show_view-dialog-fade-out 0.2s ease forwards;
}

.show_view-enter-active {
  animation: show_view-dialog-fade-in 0.2s ease;
}

.show_view-leave-active {
  animation: show_view-dialog-fade-out 0.2s ease forwards;
}

@keyframes show_view-dialog-fade-in {
  0% {
    transform: translateX(500px);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes show_view-dialog-fade-out {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(500px);
  }
}

table.dataintable {
  /* margin-top: 15px; */
  border-collapse: collapse;
  border: 1px solid white;
  width: 98%;
  font-size: 0.95em;
  background-color: #e5ebdc;
  line-height: 40px;
}
table.dataintable th {
  vertical-align: middle;
  /* padding:5px 15px 5px 6px; */
  background-color: #e8e8e8;
  border: 1px solid white;
  text-align: center;
  color: rgb(173, 169, 169);
}
table.dataintable td {
  vertical-align: text-top;
  /* padding:6px 15px 6px 6px; */
  border: 1px solid white;
  line-height: 35px;
}

</style>