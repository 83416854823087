<template>
  <nav class="navbar-default navbar-static-top" role="navigation">
    <div class="navbar-header">
      
      <h1>
        <a class="navbar-brand"  style="font-size: 25px"
          >Foodigo 美味到家</a
        >
      </h1>
    </div>
    <div class="border-bottom" style="background-image: linear-gradient(to right, #6B8E23 , #fff);">
      <div class="full-left" >
        <!-- <section class="full-top" style="border-top: 1px solid blue;">
          <button id="toggle"><i class="fa fa-arrows-alt"></i></button>
        </section> -->
        <!-- <form class="navbar-left-right">
            <input
              type="text"
              value="Search..."
              onfocus="this.value = '';"
              onblur="if (this.value == '') {this.value = 'Search...';}"
            />
            <input type="submit" value="" class="fa fa-search" />
          </form> -->
        <div class="clearfix">&nbsp;&nbsp;&nbsp;&nbsp;</div>
      </div>

      <!-- Brand and toggle get grouped for better mobile display -->

      <!-- Collect the nav links, forms, and other content for toggling -->
      <div class="drop-men" >
        <ul class="nav_1">
          <!-- <li class="dropdown at-drop">
            <a
              href="#"
              class="dropdown-toggle dropdown-at"
              data-toggle="dropdown"
              ><i class="fa fa-globe"></i> <span class="number">5</span></a
            >
            <ul class="dropdown-menu menu1" role="menu">
              <li>
                <a href="#">
                  <div class="user-new">
                    <p>New user registered</p>
                    <span>40 seconds ago</span>
                  </div>
                  <div class="user-new-left">
                    <i class="fa fa-user-plus"></i>
                  </div>
                  <div class="clearfix"></div>
                </a>
              </li>
              <li>
                <a href="#">
                  <div class="user-new">
                    <p>Someone special liked this</p>
                    <span>3 minutes ago</span>
                  </div>
                  <div class="user-new-left">
                    <i class="fa fa-heart"></i>
                  </div>
                  <div class="clearfix"></div>
                </a>
              </li>
              <li>
                <a href="#">
                  <div class="user-new">
                    <p>John cancelled the event</p>
                    <span>4 hours ago</span>
                  </div>
                  <div class="user-new-left">
                    <i class="fa fa-times"></i>
                  </div>
                  <div class="clearfix"></div>
                </a>
              </li>
              <li>
                <a href="#">
                  <div class="user-new">
                    <p>The server is status is stable</p>
                    <span>yesterday at 08:30am</span>
                  </div>
                  <div class="user-new-left">
                    <i class="fa fa-info"></i>
                  </div>
                  <div class="clearfix"></div>
                </a>
              </li>
              <li>
                <a href="#">
                  <div class="user-new">
                    <p>New comments waiting approval</p>
                    <span>Last Week</span>
                  </div>
                  <div class="user-new-left">
                    <i class="fa fa-rss"></i>
                  </div>
                  <div class="clearfix"></div>
                </a>
              </li>
              <li><a href="#" class="view">View all messages</a></li>
            </ul>
          </li> -->
          <li class="dropdown">
            <a
              href="#"
              class="dropdown-toggle dropdown-at"
              data-toggle="dropdown"
              ><span class="name-caret"
                >{{ loginusername }}<i class="caret"></i></span
              ><img src="../../public/images/wo.png" class="img_wo"
            /></a>
            <ul class="dropdown-menu" role="menu">
              <li>
                <router-link to="/updatepwd" class="hvr-bounce-to-right"
                    ><i class="fa fa-user"></i>修改密码</router-link
                  >
              </li>
              <li>
                <a @click="logout"><i class="fa fa-power-off"></i>退出</a>
              </li>
            </ul>
          </li>
        </ul>
      </div>
      <!-- /.navbar-collapse -->
      <div class="clearfix"></div>

      <div class="navbar-default sidebar" role="navigation" >
        <div class="sidebar-nav navbar-collapse">
          <ul class="nav" id="side-menu">
            <!-- <li>
                <a href="index.html" class="hvr-bounce-to-right"
                  ><i class="fa fa-dashboard nav_icon"></i
                  ><span class="nav-label">商品入库</span>
                </a>
              </li> -->

            <li>
              <router-link
                replace
                to="/desktop"
                class="hvr-bounce-to-right "
              >
                <i class="fa fa-laptop nav_icon"></i>工作台
              </router-link>
            </li>
            <!-- <template v-for="(item,index) in menuList" >
            <li  
            v-if="loginusertype==0 || loginuserrole.indexOf('|08|')!=-1 " :key="index">
              <a href="#" class="hvr-bounce-to-right"
                :key="freshFlag"><i :class="item.fcicon" ></i>
                <span class="nav-label">{{item.fcname}}</span
                ><span class="fa arrow"></span
              ></a>
              <ul class="nav nav-second-level">
                <template v-for="(item2,index2) in item.cclass">
                <li  v-if="loginusertype==0 || loginuserrole.indexOf('|0801|')!=-1 " :key="index2">
                  <router-link
                    replace
                    :to="item2.chref"
                    class="hvr-bounce-to-right"
                  >
                    <i :class="item2.ccicon"></i>{{item2.ccname}}</router-link
                  >
                </li>
                </template>
                 <li  v-if="loginusertype==0 || loginuserrole.indexOf('|0801|')!=-1 ">
                  <router-link
                    replace
                    to="/productlist"
                    class="hvr-bounce-to-right"
                  >
                    <i class="fa fa-circle-thin nav_icon"></i>库存管理</router-link
                  >
                </li> 
              </ul>
            </li>
            </template> -->
            <li v-if="loginusertype==0 || loginuserrole.indexOf('|05|')!=-1 ">
              <a href="#" class="hvr-bounce-to-right"
                ><i class="fa fa-shopping-basket nav_icon" ></i>
                <span class="nav-label"></span
                ><span class="fa arrow"></span>零售管理</a
              >
              <ul class="nav nav-second-level">
                <li  v-if="loginusertype==0 || loginuserrole.indexOf('|0501|')!=-1 ">
                  <router-link
                    replace
                    to="/neworderlist"
                    class="hvr-bounce-to-right"
                  >
                    <i class="fa fa-circle-thin nav_icon"></i>待处理订单</router-link
                  >
                </li>
                <li  v-if="loginusertype==0 || loginuserrole.indexOf('|0502|')!=-1 ">
                  <router-link
                    replace
                    to="/phdlist"
                    class="hvr-bounce-to-right"
                  >
                    <i class="fa fa-circle-thin nav_icon"></i>配货单/配送单</router-link
                  >
                </li>
                
                <!-- <li  v-if="loginusertype==0 || loginuserrole.indexOf('|0501|')!=-1 ">
                  <router-link
                    replace
                    to="/printlist"
                    class="hvr-bounce-to-right"
                  >
                    <i class="fa fa-circle-thin nav_icon"></i>订单总表</router-link
                  >
                </li> -->
                <li  v-if="loginusertype==0 || loginuserrole.indexOf('|0503|')!=-1 ">
                  <router-link
                    replace
                    to="/productlist"
                    class="hvr-bounce-to-right"
                  >
                    <i class="fa fa-circle-thin nav_icon"></i>商品列表</router-link
                  >
                </li>
                <li  v-if="loginusertype==0 || loginuserrole.indexOf('|0504|')!=-1 ">
                  <router-link
                    replace
                    to="/newrorder"
                    class="hvr-bounce-to-right"
                  >
                    <i class="fa fa-circle-thin nav_icon"></i>代客下单</router-link
                  >
                </li>
                <li  v-if="loginusertype==0 || loginuserrole.indexOf('|0501|')!=-1 ">
                  <router-link
                    replace
                    to="/retailorderlist"
                    class="hvr-bounce-to-right"
                  >
                    <i class="fa fa-circle-thin nav_icon"></i>所有历史订单</router-link
                  >
                </li>
                <!-- <li  v-if="loginusertype==0 || loginuserrole.indexOf('|0502|')!=-1 ">
                  <router-link
                    replace
                    to="/phdlist"
                    class="hvr-bounce-to-right"
                  >
                    <i class="fa fa-circle-thin nav_icon"></i>所有历史配送单</router-link
                  >
                </li> -->
              </ul>
            </li>

            <li v-if="loginusertype==0 || loginuserrole.indexOf('|01|')!=-1 ">
              <a href="#" class="hvr-bounce-to-right"
                ><i class="fa fa-bank nav_icon" ></i>
                <span class="nav-label"></span
                ><span class="fa arrow"></span>库存管理</a
              >
              <ul class="nav nav-second-level">

                <li  v-if="loginusertype==0 || loginuserrole.indexOf('|0101|')!=-1 ">
                  <router-link
                    replace
                    to="/storageinnew"
                    class="hvr-bounce-to-right"
                    @click.native="setNavInfo('商品入库')"
                  >
                    <i class="fa fa-circle-thin nav_icon"></i>商品入库</router-link
                  >
                </li>
                

                <li v-if="loginusertype==0 || loginuserrole.indexOf('|0102|')!=-1 ">
                  <router-link
                    replace
                    to="/remindlist"
                    class="hvr-bounce-to-right"
                    @click.native="setNavInfo('保质期提醒')"
                    ><i class="fa fa-circle-thin nav_icon"></i
                    >保质期提醒</router-link
                  >
                </li>
                <li v-if="loginusertype==0 || loginuserrole.indexOf('|0103|')!=-1 ">
                  <router-link
                    replace
                    to="/searchstorage"
                    class="hvr-bounce-to-right"
                  >
                    <i class="fa fa-circle-thin nav_icon"></i>入库单查询</router-link
                  >
                </li>
                <li v-if="loginusertype==0 || loginuserrole.indexOf('|0104|')!=-1 ">
                  <router-link
                    replace
                    to="/productstockinfolist"
                    class="hvr-bounce-to-right"
                  >
                    <i class="fa fa-circle-thin nav_icon"></i>库存查询</router-link
                  >
                </li>
                <li v-if="loginusertype==0 || loginuserrole.indexOf('|0105|')!=-1 ">
                  <router-link
                    replace
                    to="/productwastage"
                    class="hvr-bounce-to-right"
                  >
                    <i class="fa fa-circle-thin nav_icon"></i>商品耗损</router-link
                  >
                </li>
                <li v-if="loginusertype==0 || loginuserrole.indexOf('|0105|')!=-1 ">
                  <router-link
                    replace
                    to="/wastagelist"
                    class="hvr-bounce-to-right"
                  >
                    <i class="fa fa-circle-thin nav_icon"></i>商品耗损记录</router-link
                  >
                </li>

                
              </ul>
            </li>
            <li v-if="loginusertype==0 || loginuserrole.indexOf('|02|')!=-1 ">
              <a href="#" class="hvr-bounce-to-right"
                ><i class="fa fa-truck nav_icon" ></i>
                <span class="nav-label"></span
                ><span class="fa arrow"></span>批发管理</a
              >
              <ul class="nav nav-second-level" v-if="loginusertype==0 || loginuserrole.indexOf('|0201|')!=-1 ">
                <li>
                  <router-link to="/wsorderlist" class="hvr-bounce-to-right">
                    <i class="fa fa-circle-thin nav_icon"></i
                    >采购单处理</router-link
                  >
                </li>
                <li v-if="loginusertype==0 || loginuserrole.indexOf('|0202|')!=-1 ">
                  <router-link to="/searchwholesale" class="hvr-bounce-to-right"
                    ><i class="fa fa-circle-thin nav_icon"></i
                    >可批发产品配置</router-link
                  >
                </li>
                <li v-if="loginusertype==0 || loginuserrole.indexOf('|0203|')!=-1 ">
                  <router-link
                    replace
                    to="/clientlist"
                    class="hvr-bounce-to-right"
                  >
                    <i class="fa fa-circle-thin nav_icon"></i>客户管理</router-link
                  >
                </li>

                <li v-if="loginusertype==0 || loginuserrole.indexOf('|0204|')!=-1 ">
                  <router-link
                    to="/wsbenefitlist"
                    class="hvr-bounce-to-right"
                    ><i class="fa fa-circle-thin nav_icon" ></i
                    >批发统计</router-link
                  >
                </li>
              </ul>
            </li>
            <!-- <li>
                <a href="inbox.html" class="hvr-bounce-to-right"
                  ><i class="fa fa-inbox nav_icon"></i>
                  <span class="nav-label">Inbox</span>
                </a>
              </li>

              <li>
                <a href="gallery.html" class="hvr-bounce-to-right"
                  ><i class="fa fa-picture-o nav_icon"></i>
                  <span class="nav-label">Gallery</span>
                </a>
              </li> -->
            
            

            <li v-if="loginusertype==0 || loginuserrole.indexOf('|04|')!=-1 ">
              <a href="#" class="hvr-bounce-to-right"
                :key="freshFlag"><i class="fa fa-american-sign-language-interpreting nav_icon" ></i>
                <span class="nav-label">小团购管理</span
                ><span class="fa arrow"></span
              ></a>
              <ul class="nav nav-second-level">
                <li v-if="loginusertype==0 || loginuserrole.indexOf('|0401|')!=-1 ">
                  <router-link to="/memberlist" class="hvr-bounce-to-right"
                    ><i class="fa fa-circle-thin nav_icon"></i>团长管理</router-link
                  >
                </li>
                
                <li v-if="loginusertype==0 || loginuserrole.indexOf('|0402|')!=-1 ">
                  <router-link to="/policylist" class="hvr-bounce-to-right"
                    ><i class="fa fa-circle-thin nav_icon"></i>佣金政策维护</router-link
                  >
                </li>
                <li v-if="loginusertype==0 || loginuserrole.indexOf('|0403|')!=-1 ">
                  <router-link to="/policyexcept" class="hvr-bounce-to-right"
                    ><i class="fa fa-circle-thin nav_icon"></i>不返佣商品维护</router-link
                  >
                </li>
                <li v-if="loginusertype==0 || loginuserrole.indexOf('|0404|')!=-1 ">
                  <router-link to="/uncalcedorderlist" class="hvr-bounce-to-right"
                    ><i class="fa fa-circle-thin nav_icon"></i>生成结算单</router-link
                  >
                </li>
                <li v-if="loginusertype==0 || loginuserrole.indexOf('|0405|')!=-1 ">
                  <router-link to="/billlist" class="hvr-bounce-to-right"
                    ><i class="fa fa-circle-thin nav_icon"></i>结算单</router-link
                  >
                </li>
                <li v-if="loginusertype==0 || loginuserrole.indexOf('|0406|')!=-1 ">
                  <router-link to="/memberpropertylist" class="hvr-bounce-to-right"
                    ><i class="fa fa-circle-thin nav_icon"></i>团长业绩查询</router-link
                  >
                </li>
                <li v-if="loginusertype==0 || loginuserrole.indexOf('|0401|')!=-1" >
                  <router-link to="/wxacodelist" class="hvr-bounce-to-right"
                    ><i class="fa fa-circle-thin nav_icon"></i>店铺二维码管理</router-link
                  >
                </li>
              </ul>
            </li>
            <li v-if="loginusertype==0 || loginuserrole.indexOf('|09|')!=-1 ">
              <a href="#" class="hvr-bounce-to-right"
                :key="freshFlag"><i class="fa fa-cogs nav_icon" ></i>
                <span class="nav-label">小程序配置</span
                ><span class="fa arrow"></span
              ></a>
              <ul class="nav nav-second-level">
                <li v-if="loginusertype==0 || loginuserrole.indexOf('|0901|')!=-1" >
                  <router-link to="/bannersetup" class="hvr-bounce-to-right"
                    ><i class="fa fa-circle-thin nav_icon"></i>轮播图</router-link
                  >
                </li>
                <li v-if="loginusertype==0 || loginuserrole.indexOf('|0902|')!=-1" >
                  <router-link to="/navsetup" class="hvr-bounce-to-right"
                    ><i class="fa fa-circle-thin nav_icon"></i>首页分类导航</router-link
                  >
                </li>
                <li v-if="loginusertype==0 || loginuserrole.indexOf('|0903|')!=-1" >
                  <router-link to="/reservationsetup" class="hvr-bounce-to-right"
                    ><i class="fa fa-circle-thin nav_icon"></i>预订须知</router-link
                  >
                </li>
                <li v-if="loginusertype==0 || loginuserrole.indexOf('|0904|')!=-1" >
                  <router-link to="/popupad" class="hvr-bounce-to-right"
                    ><i class="fa fa-circle-thin nav_icon"></i>弹窗广告</router-link
                  >
                </li>
                <li v-if="loginusertype==0 || loginuserrole.indexOf('|0905|')!=-1" >
                  <router-link to="/homenote" class="hvr-bounce-to-right"
                    ><i class="fa fa-circle-thin nav_icon"></i>首页公告</router-link
                  >
                </li>
              </ul>
            </li>
            <li v-if="loginusertype==0 || loginuserrole.indexOf('|08|')!=-1 ">
              <a href="#" class="hvr-bounce-to-right"
                :key="freshFlag"><i class="fa fa-users nav_icon" ></i>
                <span class="nav-label">会员管理</span
                ><span class="fa arrow"></span
              ></a>
              <ul class="nav nav-second-level">
                <li v-if="loginusertype==0 || loginuserrole.indexOf('|0801|')!=-1" >
                  <router-link to="/allmember" class="hvr-bounce-to-right"
                    ><i class="fa fa-circle-thin nav_icon"></i>会员列表</router-link
                  >
                </li>
                <li v-if="loginusertype==0 || loginuserrole.indexOf('|0802|')!=-1" >
                  <router-link to="/auditmoney" class="hvr-bounce-to-right"
                    ><i class="fa fa-circle-thin nav_icon"></i>余额审批</router-link
                  >
                </li>
                
                
              </ul>
            </li>
            <li v-if="loginusertype==0 || loginuserrole.indexOf('|03|')!=-1 ">
              <a href="#" class="hvr-bounce-to-right"
                ><i class="fa fa-bar-chart nav_icon" ></i>
                <span class="nav-label"></span
                ><span class="fa arrow"></span>统计报表</a
              >
              <ul class="nav nav-second-level">
                <li v-if="loginusertype==0 || loginuserrole.indexOf('|0301|')!=-1 ">              
                  <router-link to="/saleslist" class="hvr-bounce-to-right"
                    ><i class="fa fa-circle-thin nav_icon"></i>零售销售汇总</router-link
                  >
                </li>

                <li v-if="loginusertype==0 || loginuserrole.indexOf('|0302|')!=-1 ">
                
                  <router-link to="/profitlist" class="hvr-bounce-to-right"
                    ><i class="fa fa-circle-thin nav_icon"></i>零售利润统计</router-link
                  >
                </li>
                <li v-if="loginusertype==0 || loginuserrole.indexOf('|0303|')!=-1 ">              
                  <router-link to="/wssaleslist" class="hvr-bounce-to-right"
                    ><i class="fa fa-circle-thin nav_icon"></i>批发销售汇总</router-link
                  >
                </li>
              </ul>
            </li>
            <li v-if="loginusertype==0">
              <a href="#" class="hvr-bounce-to-right"
                :key="freshFlag"><i class="fa fa-cog nav_icon" ></i>
                <span class="nav-label">系统管理</span
                ><span class="fa arrow"></span
              ></a>
              <ul class="nav nav-second-level">
                <!-- <li>
                  <router-link to="/rolelist" class="hvr-bounce-to-right"
                    ><i class="fa fa-circle-thin nav_icon"></i>角色配置</router-link
                  >
                </li> -->
                <li>
                  <router-link to="/adminlist" class="hvr-bounce-to-right"
                    ><i class="fa fa-circle-thin nav_icon"></i>用户管理</router-link
                  >
                </li>
                
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </div>
    
  </nav>
</template>

<script>
// import func from 'vue-editor-bridge';
// import {*} from './public/js/custom.js'

export default {
  name: "Navigator",
  data(){
    return{
      freshFlag:0,
      menuList:[]
    }
  },
  methods: {
    setNavInfo(nav) {
      this.$bus.$emit("getCurrentNavInfo", nav);
    },
    async logout() {
      // console.log(this.loginusername)
      const res=await this.$get(this.GLOBAL.serverAddress + "/logoutAdmin/",{account:this.loginusername})            
      // return false
      sessionStorage.clear();
      this.$router.replace("/login");
    },
    async getMenuList() {
      
      const res = await this.$get(this.GLOBAL.serverAddress + "/getMenuList/", 
      {});
    //   console.log(res);
        
      if (res.status === 200) {
        this.menuList = res.data;
        
      }
      console.log(res.data)
    },
  },
  props: {
    loginusername: {
      type: String,
    },
    loginusertype: {
      type: String,
    },
    loginuserrole: {
      type: String,
    },
  },
  mounted(){
    // console.log(this.loginuserrole)
    //==0 || this.GLOBAL.loginuserrole.indexOf('|01|')!=-1 )
    // this.getMenuList()
    this.$nextTick(function(){
      $('#side-menu').metisMenu()
    })
    // console.log(this.GLOBAL.token)
  }
  
};
</script>

<style scoped>
.fa-circle-thin{
  font-size:10px;
  padding-left:30px;
}
img {
  /* border: 1px solid #666; */
  /* width: auto; */
  height: auto;
  /* max-width: 100%; */
  max-height: 100%;
  align-self: center;
  width: 60px;
  margin-right: 20px;
}

/* i{
  font-size: 17px;
} */
</style>