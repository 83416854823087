<template>
  <div style="height: 100%;">
    <div
      ref="curDiv"
      v-show="isshowcalender"
      style="z-index: 999; position: absolute"
    >
      <Calender ref="Calender" v-on:choseDay="clickDay"></Calender>
    </div>

    <div class="grid-form1" style="padding: 0">
      <div style="margin: 0; padding: 0; vertical-align: middle;">
        <div class="search_condition">
          <div class="pname">
            <label
              for="txtContractNumber"
              class="control-label"
              >商品名称</label
            >
          </div>
          <div class="pname_value">
            <input
              type="text"
              class="form-control1"
              id="txtContractNumber"
              name="txtContractNumber"
              v-model.trim="searchpname"
              @keyup.enter="search"
            />
          </div>
          
          <div class="bdate" >
          <label
            for="txtBDate"
            class="control-label"
            >下单日期从</label
          >
          </div>
          <div class="bdate_value" >
            <input
              type="text"
              class="form-control1"
              id="txtBDate"
              name="txtBDate"
              readonly
              @click.stop="showcalender($event,'b')"
              v-model.trim="searchbdate"              
            />
          </div>
          <div class="edate" >
          <label
            for="txtEDate"
            class="control-label"
            >截止到</label
          >
          </div>
          <div class="edate_value">
            <input
              type="text"
              class="form-control1"
              id="txtEDate"
              name="txtEDate"
              readonly
              @click.stop="showcalender($event,'e')"
              v-model.trim="searchedate"
              
            />
          </div>
          <div class="cname">
            <label
              for="txtContractName"
              class="control-label"
              >客户名称</label
            >
          </div>
          <div class="cname_value" >
            <input
              type="text"
              class="form-control1"
              id="txtContractName"
              name="txtContractName"
              v-model.trim="searchpcontact"
              @keyup.enter="search"
            />
          </div>
          
        </div>
        <div class="search_condition2">
          <div class="pname2">
            <label
              for="txtContractNumber"
              class="control-label"
              >支付状态</label
            >
          </div>
          <div class="pname_value2">
            <select name="sltPayStatus" v-model="paystatus" >
              <option value="" >所有</option>
              <option value="未支付" >未支付</option>
              <option value="已支付">已支付</option>
            </select>
          </div>
          <div class="pname2">
            <label
              for="txtContractNumber"
              class="control-label"
              >取货状态</label
            >
          </div>
          <div class="pname_value2">
            <select name="sltPayStatus" v-model="devstatus">
              <option value="">所有</option>
              <option value="未取货" >未取货</option>
              <option value="已取货">已取货</option>
              <option value="已退货">已退货</option>
            </select>
          </div>
          <div class="search_btn" style="padding-top: 2px;padding-left:50px;">
          
            <button class="btn btn-default" type="button" @click="clearSearch">
              <i class="fa fa-refresh" aria-hidden="true"></i>&nbsp;清空
            </button>
            <button
              class="btn btn-default"
              type="button"
              @click="search"
            >
              <i class="fa fa-search" aria-hidden="true"></i>&nbsp;搜索
            </button>
            <button
              class="btn btn-default"
              type="button"
              @click="neworder"
            >
              <i class="fa fa-plus-square-o" aria-hidden="true"></i>&nbsp;代客下单
            </button>
          </div>
          </div>
        
      </div>

    </div>
        <div class="orderlist" >
            
            
            <div class="order_item" v-for="(item,index) in currentpagelist" :key="index">
                <div class="order_item_briefinfo">
                    <div class="order_item_briefinfo_ordernum">订单ID：{{item.orderid}}<div class="dkxd_c">{{item.nickname==""?"":"（由" + item.nickname + "代客下单）"}}</div></div>
                    <div class="order_item_briefinfo_orderdate">下单时间：{{item.orderdatetime}}</div>
                    <div class="order_item_briefinfo_orderpay">支付状态：{{item.orderstatus}}</div>
                    <div class="order_item_briefinfo_orderpay">取货状态：{{item.orderdeliverystatus}}</div>
                </div>
                <div class="order_item_content">
                    <div class="order_item_content_product">
                        <div class="productinfo_img">                        
                            <img :src="item.plist[0].imgurl"/>
                        </div>

                        <div class="productinfo">
                            <div class="productinfo_title">{{item.plist[0].pname}}</div>
                            <div class="productinfo_guige">规格：{{item.plist[0].pguige}}</div>
                            <div class="productinfo_num">数量：{{item.plist[0].wscount}}</div>
                            <div class="productinfo_price">商品价格：C${{item.plist[0].wsallprice}}</div>
                        </div>
                    </div>
                    <div class="order_item_content_order">
                        <div class="orderprice">C${{item.ordertotalprice}}</div>
                        <div class="ordercontact">
                            <div class="ordercontact_name">联&nbsp;&nbsp;系&nbsp;&nbsp;人：{{item.contactname}}</div>
                            <div class="ordercontact_tel">联系电话：{{item.mobile}}</div>
                            <div class="ordercontact_address">
                                <div class="address_title">客户名称：</div>
                                <div class="address_content">{{item.address}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="order_item_content_opr">
                        <button
                        class="btn btn-default"
                        type="button"
                        @click="viewOrderDetail(item.orderid)"
                        
                        >
                        <i class="fa fa-newspaper-o" aria-hidden="true"></i>&nbsp;订单详情
                        </button>
                        <button
                        class="btn btn-default"
                        type="button"
                        @click="payOrder(item.orderid)"
                        v-if="item.orderstatus==='未支付'"
                        >
                        <i class="fa fa-credit-card" aria-hidden="true"></i>&nbsp;订单收款
                        </button>
                        <button
                        class="btn btn-default"
                        type="button"
                        @click="cancelOrder(item.orderid)"
                        v-if="item.orderstatus==='未支付'"
                        >
                        <i class="fa fa-trash-o" aria-hidden="true"></i>&nbsp;取消订单
                        </button>
                        <button
                        class="btn btn-default"
                        type="button"
                        @click="deliveryOrder(item.orderid)"
                        v-if="item.orderdeliverystatus==='未取货' && item.orderstatus==='已支付'"
                        >
                        <i class="fa fa-truck" aria-hidden="true"></i>&nbsp;取货
                        </button>
                        <button
                        class="btn btn-default"
                        type="button"
                        @click="backOrder(item.orderid)"
                        v-if="item.orderstatus==='已支付' && item.orderdeliverystatus!='已退货'"
                        >
                        <i class="fa fa-sign-out" aria-hidden="true"></i>&nbsp;退货
                        </button>
                        
                    </div>
                </div>
            </div>
             <div class="noresult" v-if="shownoresult">没有找到符合查询条件的采购单！</div>
        </div>
       
      <div
        class="pagenum_info"
        v-if="!shownoresult"
      >
        <span class="btmInfo" style="vertical-align: middle"
          >&nbsp;&nbsp;共有 {{ recordcount }} 条记录，当前第
          {{ currentpage }}/{{ pagecount }} 页</span
        >
        <div style="float: right; padding-right: 30px">
          <button class="btn btn-default" type="button" @click="getNewPage(1)">
            首页
          </button>
          <button
            class="btn btn-default"
            type="button"
            @click="getNewPage(currentpage - 1)"
          >
            上页
          </button>
          <button
            class="btn btn-default"
            type="button"
            @click="getNewPage(currentpage + 1)"
          >
            下页
          </button>
          <button
            class="btn btn-default"
            type="button"
            @click="getNewPage(pagecount)"
          >
            尾页
          </button>
          <span class="btmInfo" style="margin-right: 2px">跳转到</span>
          <input
            type="text"
            size="1"
            id="txtGotoPage"
            autocomplete="off"
            v-int-only
            style="margin-right: 10px; font-size: 0.9em"
            v-model.trim="gotopage"
          />
          <button
            type="button"
            class="btn btn-default"
            @click="getNewPage(gotopage)"
          >
            跳转
          </button>
        </div>
      </div>
    </div>
  <!-- </div> -->
</template>

<script>
import axios from "axios";

import Calender from "../../components/vue-calendar-component";

export default {
  name: "WSOrderList",
  components: {
    Calender
  },
  data() {
    return {
        searchedate:'',
        searchbdate:'',
        shownoresult:false,
      orderList: [],
      currentIndex: -1,
      currentpage: 1,
      gotopage: "",
      searchpcontact: "",
      searchpname: "",
      paystatus:"",
      devstatus:"",
        isshowcalender: false,
      show: false,
      isshow: false,
      //   isshowupdate: false,
      win_height: "",
      currentSID: 0,
      currentDateS:""
    };
  },
  methods: {
    neworder(){
      this.$router.push('/neworder')
    },
      viewOrderDetail(id){
          this.$router.push('/orderdetail?id='+id)
      },
      payOrder(id){
          if(confirm("您确认已经收到了该订单的款项吗？确认后无法撤回。")){
            
              axios({
                method: "put",
                data: {"id":id,"status": "已支付","type":"1"},
                url: this.GLOBAL.serverAddress + "/updateOrderStatus/",
            }).then(
                (response) => {
                    this.clearSearch()
                    this.search()
                    alert("订单状态更新完成。")
                },
                (error) => {
                    alert(error.message)
                    return false;
                }
            );
          }
      },
      deliveryOrder(id){
          if(confirm("您确认该订单已取货吗？确认后无法撤回。")){
            
              axios({
                method: "put",
                data: {"id":id,"status": "已取货","type":"2"},
                url: this.GLOBAL.serverAddress + "/updateOrderStatus/",
            }).then(
                (response) => {
                    this.clearSearch()
                    this.search()
                    alert("订单状态更新完成。")
                },
                (error) => {
                    alert(error.message)
                    return false;
                }
            );
          }
      },
      cancelOrder(id){
          if(confirm("您确认要取消该订单吗？取消后将无法找回订单。")){
            
              axios({
                method: "put",
                data: {"id":id,"type":"1"},
                url: this.GLOBAL.serverAddress + "/cancelWSOrder/",
            }).then(
                (response) => {
                    this.clearSearch()
                    this.search()
                    alert("订单状态更新完成。")
                },
                (error) => {
                    alert(error.message)
                    return false;
                }
            );
          }
      },
      backOrder(id){
          if(confirm("您确认该订单需要退货吗？")){
            
              axios({
                method: "put",
                data: {"id":id,"type":"2"},
                url: this.GLOBAL.serverAddress + "/cancelWSOrder/",
            }).then(
                (response) => {
                    this.clearSearch()
                    this.search()
                    alert("订单状态更新完成。")
                },
                (error) => {
                    alert(error.message)
                    return false;
                }
            );
          }
      },
    hideCalender() {
      this.isshowcalender = false;
    },
    clickDay(data) {
    //   this.searchyxqdate = data;
        // console.log(this.currentDateS)
      if(this.currentDateS==='b'){
          this.searchbdate=data
      }else{
          this.searchedate=data
      }
      this.isshowcalender = false;
    },
    showcalender(e,v) {
      var divC = this.$refs.curDiv;
      this.currentDateS=v
      divC.style.top = e.target.getBoundingClientRect().top + 40 + "px";
      divC.style.left = e.target.getBoundingClientRect().left + "px";

      this.isshowcalender = true;
    },

    clearSearch() {
      this.searchpname = ""
      this.searchpcontact = ""
      this.searchedate=""
        this.searchbdate=""
        this.devstatus="未取货"
        this.paystatus="未支付"
    },
    getNewPage(pagenum) {
      if (pagenum >= 1 && pagenum <= this.pagecount) {
        this.currentpage = pagenum;
        // console.log(this.pages)
        // return this.pages[this.currentpage - 1];
      }
    },
    async search() {
      const res = await this.$get(this.GLOBAL.serverAddress + "/searchOrder/", 
      {'pname':this.searchpname,
      'contact':this.searchpcontact,
      'bdate':this.searchbdate,
      'edate':this.searchedate,
      'paystatus':this.paystatus,
      'devstatus':this.devstatus});
    //   console.log(res);
        
      if (res.status === 200) {
        this.orderList = res.data;
        if(this.orderList.length>0){
            this.shownoresult=false
        }else{
            this.shownoresult=true
        }
      }
    },
  },
  computed: {
    currentpagelist() {
      return this.pages[this.currentpage - 1];
    },
    recordcount() {
      return this.orderList.length;
    },
    pagecount() {
      return Math.ceil(this.orderList.length / 5);
    },
    pages() {
      const pages = [];
      this.orderList.forEach((item, index) => {
        const page = Math.floor(index / 5);
        if (!pages[page]) {
          pages[page] = [];
        }
        pages[page].push(item);
      });
      return pages;
    },
  },
  mounted() {
    this.haveRights('|0201|')
    this.search();
    window.addEventListener("click", this.hideCalender);
    this.win_height = window.innerHeight;
  },
  beforeDestroy() {
    // 实例销毁之前对点击事件进行解绑
    window.removeEventListener("click", this.hideCalender);
  },
};
</script>

<style scoped lang="less">
.search_condition2 {
    display: flex;
    // white-space: nowrap;
    justify-content:center;
    align-items: center;
    // 
    padding:10px;
    // padding-bottom: 20px;
  .pname2 {
    //   display: flex;
    // border: 1px solid red;
    flex: 1;
    padding-left: 5px;
    text-align: right;
  }

  .pname_value2 {
    flex: 2;
    padding-left: 5px;
    color:#999;
    select{
      border: 1px solid #E9E9E9;
    font-size: 0.9em;
    outline: none;
    padding: 0.5em 1em;
    color: #999;
    }
  }
  .search_btn{
    flex:5;
  }
}
.search_condition {
    display: flex;
    // white-space: nowrap;
    justify-content:center;
    align-items: center;
    text-align: right;
    padding:10px;
  .pname {
    //   display: flex;
    // border: 1px solid red;
    flex: 1;
    padding-right: 5px;
  }

  .pname_value {
    flex: 3;
    // padding-left: 5px;
  }

  .cname {
    flex: 1;
    padding-right: 5px;
  }

  .cname_value {
    flex: 2;
  }

  .bdate {
    flex: 1;
    padding-right: 5px;
  }

  .bdate_value {
    flex: 1;
  }

  .edate {
    //   border: 1px solid green;
    flex: 1;
    text-align: center;
    // display: flex;
    // align-items: center;
    // justify-content: center;
  }

  .edate_value {
    flex: 1;
  }
}

.orderlist {
  color: #9c9c9c;

  // padding:10px;
  .order_item {
    //   width:100%;
    margin-top: 15px;
    background-color: #fff;
    // padding-top:15px;
    .order_item_briefinfo {
      display: flex;
      border-bottom: 1px solid #eee;
      //   padding-bottom:10px;
      padding: 10px;
      .order_item_briefinfo_ordernum {
        padding-left: 20px;
        flex: 2;
        display: flex;
        .dkxd_c{
          padding-left:10px;
          color:red;
        }
      }

      .order_item_briefinfo_orderdate {
        flex: 2;
      }
      .order_item_briefinfo_orderpay {
        flex: 1;
        text-align: center;
      }
    }

    .order_item_content {
      display: flex;
      .order_item_content_product {
        flex: 3;
        margin: 10px;
        padding-left: 10px;
        display: flex;
        .productinfo_img {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            width: 80%;
            object-fit: contain;
          }
        }

        .productinfo {
          flex: 3;
          margin: 10px;
          padding-left: 5px;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          // align-items: center;
          .productinfo_title {
          }

          .productinfo_guige {
          }

          .productinfo_num {
          }

          .productinfo_price {
          }
        }
      }

      .order_item_content_order {
        flex: 4;
        display: flex;
        .orderprice {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 1.2em;
          color: #6e8b3d;
        }

        .orderstatus {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .ordercontact {
          flex: 3;
          display: flex;
          // align-items: center;
          justify-content: center;
          flex-direction: column;
          padding-left: 10px;
          .ordercontact_name {
          }

          .ordercontact_tel {
          }

          .ordercontact_address {
            display: flex;
            .address_title {
              white-space: nowrap;
            }
          }
        }
      }

      .order_item_content_opr {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        // padding:15px;
        button {
          width: 80%;
          margin: 3px;
        }
      }
    }
  }
}

button {
  background-color: #f5f5f5;
  margin-right: 10px;
}
.btmInfo {
  background: none;
  border: none;
  outline: none;
  color: #333;
  font-size: 0.9em;
}
.noresult {
  text-align: center;
  font-size: 2em;
  color: rgb(160, 154, 154);
}
.pagenum_info{
    background:#fff;
    height: 50px;
    margin-top:20px;
    padding: 10px; color: #6c757d;
    // border-top: 1px dashed #9acd32;
}
.actived {
  background-color: rgba(190, 221, 140, 0.9);
}
table.dataintable {
  /* margin-top: 15px; */
  border-collapse: collapse;
  border: 1px solid white;
  width: 98%;
  font-size: 0.95em;
  background-color: #e5ebdc;
  line-height: 40px;
}
table.dataintable th {
  vertical-align: middle;
  /* padding:5px 15px 5px 6px; */
  background-color: #e8e8e8;
  border: 1px solid white;
  text-align: center;
  color: rgb(173, 169, 169);
}
table.dataintable td {
  vertical-align: text-top;
  /* padding:6px 15px 6px 6px; */
  border: 1px solid white;
  line-height: 35px;
}

.hidden_view {
  width: 100%;
  background: #698b22;
  opacity: 0.2;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 99;
}
</style>