<template>
    <div>
        <!-- <div style="page-break-after:always"></div> -->
        <div class="all_order_brief" v-show="showbrief">
            <div style="text-align:center;padding:20px;font-size:1.2em;">商品出库总单</div>
            <div>出库日期：{{exportInfoBrief[0].adate}}</div>
            <div class="all_order_brief_head">
                <div class="all_order_brief_head_item1">商品名称</div>
                <div class="all_order_brief_head_item2">规格</div>
                <div class="all_order_brief_head_item3">数量</div>
                <div class="all_order_brief_head_item4">金额</div>
                <div class="all_order_brief_head_item5">商品类别</div>
            </div>
            <div class="all_order_brief_head" v-for="(itemE,indexE) in exportInfoBrief" :key="indexE">
                <div class="all_order_brief_head_item1">{{itemE.pname}}</div>
                <div class="all_order_brief_head_item2">{{itemE.psize}}</div>
                <div class="all_order_brief_head_item3">{{itemE.psum}}</div>
                <div class="all_order_brief_head_item4">{{itemE.pprice}}</div>
                <div class="all_order_brief_head_item5">{{itemE.pclassname}}</div>
            </div>
            <div style="page-break-after:always"></div>
        </div>
        <div v-for="(item,index) in sheetData" :key="index">
        <div class="divcontain">
            <div class="topinfo">
                <div class="top_left">
                    <div class="toptitle">Foodigo美味到家 商品发货单</div>
                    <div class="briefinfo">
                        <div class="leftinfo">
                            <div class="rinfo">订单编号：{{item.ordersno}}</div>
                            <div class="rinfo">下单日期：{{item.addtime}}</div>
                            <div class="rinfo">收&nbsp;&nbsp;货&nbsp;&nbsp;人：{{item.rname}}</div>
                            <div class="rinfo">联系电话：{{item.rtel}}</div>
                            <div class="rinfo">派送日期：{{item.rdate}}</div>
                        </div>
                        <div class="boxsn">{{item.boxsn}}</div>
                    </div>
                </div>
                <div class="top_right">
                    <div class="minicode" >
                        <img src="../../../public/images/minicode.jpeg" style="width:100%;"/>
                        
                        </div>
                </div>
            </div>
            <div class="raddressinfo">收货地址：{{item.raddress}}</div>
            <div class="productlist">
                <div class="plist_head">
                    <div class="plist_head_num">序号</div>
                    <div class="plist_head_name" style="text-align:center;">商品名称</div>
                    <div class="plist_head_guige">规格</div>
                    <div class="plist_head_price">单价</div>
                    <div class="plist_head_count">数量</div>
                    <div class="plist_head_allprice">金额</div>
                </div>
                <div class="plist_head" v-for="(itemp,indexp) in item.plist" :key="indexp">
                    <div class="plist_head_num">{{indexp+1}}</div>
                    <div class="plist_head_name">{{itemp.pname}}</div>
                    <div class="plist_head_guige">{{itemp.pguige}}</div>
                    <div class="plist_head_price">C${{itemp.pprice}}</div>
                    <div class="plist_head_count">{{itemp.wscount}}</div>
                    <div class="plist_head_allprice">C${{itemp.wsallprice}}</div>
                </div>
            </div>
            <div class="order_remarks">
                <div class="remarks_left">
                    <div class="lefttitle">订单备注：</div>
                    <div class="leftcontent">{{item.remarks}}</div>
                </div>
                <div class="remarks_right">
                    <div class="remarks_right_total">商品件数：{{item.allcount}}</div>
                    <div class="remarks_right_total">支付总价：C${{item.allprice}}</div>
                </div>
            </div>
        </div><div style="page-break-after:always"></div>
        </div>
    </div>
    
</template>
<script>


export default {
    name:"printList",
    data() {
    return {
        outtoday:this.GLOBAL.getToday()
    };
  },
    props:{
        sheetData:[],
        exportInfoBrief:[],
        showbrief:{
            type:Boolean,
            default:true
        }
        // onePageRow:{
        //     type:Number,
        //     default:5
        // },
        // blankLines:{
        //     type:Boolean,
        //     default:true
        // },
        // getC:Function
    },
    computed: {
        pages(){
            return 1
        },
        chineseTotal(){
            return this.getC!=null ?this.getC(this.sheetData.total_amount) :"传入数"
        }
    },
    methods: {
        test(){
            console.log("2345545")
        }
    }
}
</script>
<style scoped lang="less">
.all_order_brief{
    margin: 30px;
    .all_order_brief_head{
        
        display: flex;
        align-content: center;
        justify-content: center;
        border-bottom: 1px solid #9c9c9c;
        text-align: center;
        line-height:35px;
        .all_order_brief_head_item1{
            flex: 5;
        }
        .all_order_brief_head_item2{
            flex: 2;
        }
        .all_order_brief_head_item3{
            flex: 1;
        }
        .all_order_brief_head_item4{
            flex: 1;
        }
        .all_order_brief_head_item5{
            flex: 2;
        }
        
    }
}
.divcontain{
    // border:1px solid #9c9c9c;
    border-radius: 10px;
    .topinfo{
        display: flex;
        .top_left{
            // border: 1px solid red;
            flex:3;
            .toptitle{
                margin:20px;
                text-align:center;
                font-size:20px;
                font-weight:bold;
            }
            .briefinfo{
                display: flex;
                .leftinfo{
                    flex: 5;
                    padding-left:30px;
                    .rinfo{
                        padding: 5px 0;
                    }
                }
                .boxsn{
                    flex: 2;
                    // display: flex;
                    // align-items: center;
                    // justify-content: center;
                    font-size: 50px;
                }
            }
        }
        .top_right{
            flex: 1;
            .minicode{
                padding:5px;
            }
        }
    }
    .raddressinfo{
        padding-left:30px;
    }
    .order_remarks{
        display: flex;
        .remarks_left{
            flex:5;
            padding-left:30px;
            display: flex;
            .lefttitle{
                flex: 1;
            }
            .leftcontent{
                flex: 4;
                padding-right: 50px;
                text-align: left;
            }
        }
        .remarks_right{
            flex:2;
            margin:10px;
        }
    }
    .productlist{
        padding:30px;

        .plist_head{
            display: flex;
            border-bottom: 1px solid #9c9c9c;
            padding:5px 0;
            .plist_head_num{
                flex: 1;
                text-align: center;
                display: flex;
                justify-content: center;
                align-items: center;
            }
            .plist_head_name{
                flex: 6;
            }
            .plist_head_guige{
                flex: 2;
                text-align: center;
            }
            .plist_head_price{
                flex: 2;
                text-align: center;
            }
            .plist_head_count{
                flex: 1;
                text-align: center;
            }
            .plist_head_allprice{
                flex: 2;
                text-align: center;
            }
        }
    }
}

.myc{
    text-align:left;
    font-size:24px;
    padding:10px;

}
@page{
    size:auto A5 landscape;
    margin:5mm;
}
.myc1{
    display: flex;
    align-items: center;
    justify-content: center;
    .myc1_1{
        flex: 1;
    }
    .myc1_2{
        flex: 1;
    }
}
</style>
