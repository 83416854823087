<template>
  <div style="height: 100%">
    <div
      ref="curDiv"
      v-show="isshowcalender"
      style="z-index: 999; position: absolute"
    >
      <Calender ref="Calender" v-on:choseDay="clickDay"></Calender>
    </div>
    <ul id="keywords" ref="sKeywords" v-show="showNameList">
      <li
        v-for="(s, index) in nameList"
        :key="index"
        @click="getPName(s)"
        @mouseover="mouseOver(index)"
        :class="{ actived: currentIndex == index }"
      >
        {{ s.pname }}<br />商品编码：{{ s.posn }} {{s.psn!==""?"商品条码："+s.psn:""}}
      </li>
    </ul>

    <div class="grid-form1" style="padding: 0; height: 120px">
      <div class="form-group" style="vertical-align: middle">
        <div class="step1">第一步：选择商品</div>
        <div>
          <label
            for="txtContractNumber"
            class="col-sm-1 control-label"
            style="padding-top: 10px; text-align: right"
            >商品名称</label
          >
        </div>
        <div class="col-sm-3" style="vertical-align: middle">
          <input
            type="text"
            class="form-control1"
            v-model.trim="searchpname"
            @input="handleSearch($event)"
            autocomplete="off"
          />
        </div>
        <!-- <div>
          <label
            for="txtContractNumber"
            class="col-sm-1 control-label"
            style="padding-top: 10px; text-align: right"
            >商品编码</label
          >
        </div>
        <div class="col-sm-3" style="vertical-align: middle">
          <input
            type="text"
            class="form-control1"
            v-model.trim="searchposn"
            @input="handleSearch($event)"
            autocomplete="off"
          />
        </div> -->
        <div>
          <label
            for="txtContractNumber"
            class="col-sm-1 control-label"
            style="padding-top: 10px; text-align: right"
            >商品条码</label
          >
        </div>
        <div class="col-sm-3" style="vertical-align: middle">
          <input
            type="text"
            class="form-control1"
            v-model.trim="searchpsn"
            @input="handleSearchTM($event)"
            autocomplete="off"
          />
        </div>
        <div class="col-sm-2"></div>
      </div>
      <!-- </form> -->
      <!-- </div> -->
      <!-- </div> -->

      <!-- <div style="padding-top: 0; padding-bottom: 8px">
        <div class="row">
          
        </div>
      </div> -->
    </div>

    <!-- <div class="content-main" style="padding:0;border:1px solid yellow">      -->

    <!-- <div class="grid-form" style="padding:0;border:1px solid red"> -->

    <div class="grid-form1" style="height: 570px; padding: 0">
      <!-- <h4>问题类别维护</h4> -->
      <!-- <div class="form-group" style="vertical-align: middle"> -->
      <div class="step1">第二步：入库信息</div>
      <div class="col-sm-10 infodisp">
        <table class="infotable">
          <tr>
            <td width="120px;">商品名称：</td>
            <td style="text-align: left">
              {{ currentSInfo[0].pname }}
            </td>
            <td width="120px;">商品编码：</td>
            <td width="300px;" style="text-align: left">
              {{ currentSInfo[0].posn }}
            </td>
          </tr>
          <tr>
            <td width="120px;">入库日期：</td>
            <td width="300px;" style="text-align: left">
              <input
              type="text"
              class="form-control1"
              style="width: 100px"
              id="txtSampleName"
              name="txtSampleName"
              readonly
              v-model.trim="searchyxqdate"
              @click.stop="showcalender"
            />
            </td>
            
            <td width="120px;">商品条码：</td>
            <td width="300px;" style="text-align: left">
              <input
                type="text"
                class="form-control1"
                style="width: 250px"
                id="productsn"
                v-model.trim="currentSInfo[0].psn"
              />
              扫码
            </td>
          </tr>
          <tr>
            <td>商品规格：</td>
            <td>
              <input
                type="text"
                class="form-control1"
                style="width: 150px"
                id="productguige"
                v-model.trim="currentSInfo[0].pguige"
              />
              <select name="sltPayStatus" style="border: 1px solid #E9E9E9;margin-left: 5px;padding:5px;" v-model="oldpGuige" v-if="guigelist.length>0" @change="setGuige">
                <option value="">选择已有规格</option>
                <option v-for="(item,index) in guigelist" :key="index" :value="item.guige">{{item.guige}}</option>
              </select>
            </td>
            <td>供应商：</td>
            <td>
              <input
                type="text"
                class="form-control1"
                id="productgys"
                style="width: 250px"
                v-model.trim="currentSInfo[0].pgys"
              />
            </td>
          </tr>
          <tr>
            <td>有效期：</td>
            <td>
              <input
                type="text"
                class="form-control1"
                style="width: 100px"
                id="productyxq"
                v-date-only
                v-model.trim="currentSInfo[0].pyxq"
              />
            </td>
            <td>采购单价：</td>
            <td>
              <input
                type="text"
                class="form-control1"
                id="productprice"
                v-float-only
                style="width: 100px"
                v-model.trim="currentSInfo[0].pprice"
                @input="calCost"
              />
            </td>
          </tr>
          <tr>
            <td>入库单位：</td>
            <td>
              <select
                class="form-control1"
                id="productunit"
                style="width: 100px"
                v-model="currentSInfo[0].punit"
                @change="setunitls"
              >
                <option value=""></option>
                <option
                  v-for="(item, index) in unitlist"
                  :key="index"
                  :value="item.unitname"
                >
                  {{ item.unitname }}
                </option>
              </select>
            </td>
            <td>入库数量：</td>
            <td>
              <input
                type="text"
                class="form-control1"
                style="width: 100px"
                id="productcount"
                v-float-only
                v-model.trim="currentSInfo[0].pcount"
                @input="calCost"
              />
            </td>
          </tr>
          <tr>
            <td>入库备注：</td>
            <td colspan="3">
              <input
                type="text"
                class="form-control1"
                style="width: 700px"
                id="productbz"
                
                v-model.trim="currentSInfo[0].pnotes"
              />
            </td>
            
          </tr>
          <tr>
            <td colspan="4" style="height: 50px"></td>
          </tr>
          <tr>
            <td colspan="4">
              <div class="col-sm-5"></div>
              <div class="col-sm-7" style="text-align: center">
                <button
                  class="btn btn-default"
                  type="button"
                  @click="clearform"
                >
                  <i class="fa fa-refresh" aria-hidden="true"></i>&nbsp;清空
                </button>
                <button
                  class="btn btn-default"
                  id="btnSave"
                  :disabled="submitSaving"
                  @click="saveWSPriceInfo"
                >
                  <i class="fa fa-save" aria-hidden="true"></i>&nbsp;
                  {{ submitSaving ? "提交中.." : "提交入库" }}
                </button>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="4" class="errinfo">{{ errmessage }}</td>
          </tr>

        </table>
      </div>

      <!-- </div> -->
    </div>

    <!-- </div> -->
    <!-- </div> -->
  </div>
</template>

<script>
import axios from "axios";
import Calender from "../../components/vue-calendar-component";
export default {
  name: "StorageInsert",
  components: {Calender},
  data() {
    return {
      unitlist: [],
      guigelist:[],
      errmessage: "",
      nameList: [],
      searchpsn: "",
      searchposn: "",
      searchpname: "",
      showNameList: false,
      currentSID: 0,
      isshowcalender: false,
      searchyxqdate:"",
      oldpGuige:"",
      currentSInfo: [
        {
          poid: "",
          psn: "",
          posn: "",
          pguige: "",
          pgys: "",
          pyxq: "",
          pname: "",
          punit: "",
          punitls: "",
          pprice: "",
          ppricels: "",
          pcost: "",
        //   pcostls: "",
          pcount: "",
          pcountls: "",
          pnotes:"",
          opruser: this.GLOBAL.loginusername,
          
        },
      ],
      submitSaving: false,
      currentIndex: 0,
    };
  },
  
  methods: {
    hideCalender() {
      this.isshowcalender = false;
    },
    clickDay(data) {
      this.searchyxqdate = data;
      this.isshowcalender = false;
    },
    showcalender(e) {
      var divC = this.$refs.curDiv;
      divC.style.top = e.target.getBoundingClientRect().top + 40 + "px";
      divC.style.left = e.target.getBoundingClientRect().left + "px";

      this.isshowcalender = true;
    },
    clearform() {
      if (confirm("您确认要清空填写的所有信息吗？")) {
        this.initData();
      }
    },
    setunitls(){
      
      this.currentSInfo[0].punitls=this.currentSInfo[0].punit
      
    },
    setGuige(){
        console.log(this.oldpGuige)
        this.currentSInfo[0].pguige=this.oldpGuige
    
        // alert(this.productFClass)
    },
    getPName(pinfo) {
      // console.log(sname)
      this.currentSInfo[0].poid = pinfo.poid;
      this.currentSInfo[0].pname = pinfo.pname;
      this.currentSInfo[0].posn = pinfo.posn;
      this.currentSInfo[0].psn = pinfo.psn;
      this.currentSInfo[0].pguige = "";

      this.oldpGuige=""
      this.getGuigeByPOSN(pinfo.posn);
      this.showNameList = false;
    },
    async getGuigeByPOSN(posn) {
      const res = await this.$get(
        this.GLOBAL.serverAddress + "/getGuigeByPOSN/",
        {'posn':posn}
      );
      this.guigelist = res.data;
      console.log(this.guigelist)
    },
    async getStorageInUnit() {
      const res = await this.$get(
        this.GLOBAL.serverAddress + "/getStorageInUnit/",
        {}
      );
      this.unitlist = res.data;
    },
    calCost() {
      if (this.currentSInfo[0].pprice && this.currentSInfo[0].pcount) {
        this.currentSInfo[0].pcost =
          (this.currentSInfo[0].pprice * this.currentSInfo[0].pcount).toFixed(2);
      }
      this.currentSInfo[0].pcountls=this.currentSInfo[0].pcount
      this.currentSInfo[0].ppricels=this.currentSInfo[0].pprice
      // console.log(this.currentSInfo[0].pcountls)
      // console.log(this.currentSInfo[0].ppricels)
    },
    handleSearchTM(e){
      var tsearchpsn = this.searchpsn;
      this.showNameList = false;
      // console.log(e);
      if (tsearchpsn) {
        // console.log('开始提交1')
        axios({
          method: "get",
          params: {
            psn: tsearchpsn,
          },
          url: this.GLOBAL.serverAddress + "/getProductNameListBySN/",
        }).then(
          // axios.post('http://localhost:8080/getStorageList').then(
          (response) => {
            this.nameList = response.data;
            if (this.nameList.length > 0) {
              var ul = this.$refs.sKeywords; // document.getElementById("keywords");
              // console.log(e.target.getBoundingClientRect().top);
              ul.style.top = e.target.getBoundingClientRect().top + 40 + "px";
              ul.style.left = e.target.getBoundingClientRect().left + 8 + "px";
              this.showNameList = true;
            } else {
              this.showNameList = false;
            }
          },
          (error) => {
            console.log("出现错误", error.message);
            return false;
          }
        );
      }
    },
    handleSearch(e) {
      // console.log('chufa')
      var search = this.searchpname;
      this.showNameList = false;
      // console.log(e);
      if (search) {
        // console.log('开始提交1')
        axios({
          method: "get",
          params: {
            pname: search,
          },
          url: this.GLOBAL.serverAddress + "/getProductNameList/",
        }).then(
          // axios.post('http://localhost:8080/getStorageList').then(
          (response) => {
            this.nameList = response.data;
            if (this.nameList.length > 0) {
              var ul = this.$refs.sKeywords; // document.getElementById("keywords");
              // console.log(e.target.getBoundingClientRect().top);
              ul.style.top = e.target.getBoundingClientRect().top + 40 + "px";
              ul.style.left = e.target.getBoundingClientRect().left + 8 + "px";
              this.showNameList = true;
            } else {
              this.showNameList = false;
            }
          },
          (error) => {
            console.log("出现错误", error.message);
            return false;
          }
        );
      }
    },
    goback() {
      this.$router.push("/searchwholesale");
    },
    submitData() {
      this.currentSInfo[0].adddate=this.searchyxqdate
      this.submitSaving = true;
      axios({
        method: "post",
        headers: {
          "Content-Type": "application/json;chartset=UTF-8",
          Authorization: "test",
        },
        data: this.currentSInfo,
        url: this.GLOBAL.serverAddress + "/saveStorageInInfo/",
      }).then(
        // axios.post('http://localhost:8080/getStorageList').then(
        (response) => {
          // console.log('OK',response.data)
          // this.initData();
          alert("保存成功");
          this.submitSaving = false;
          this.initData();
          //   this.$router.push('/searchwholesale')
        },
        (error) => {
          this.errmessage = "保存时出错，请重试。";
          this.submitSaving = false;
          console.log("出现错误", error.message);
        }
      );
    },
    saveWSPriceInfo() {
    //   var submitflag = false;
      // console.log(this.GLOBAL.isFloat(this.currentSInfo[0].pwsprice));
      // this.GLOBAL.showTest()
      if (!this.currentSInfo[0].poid) {
        this.errmessage = "请选择产品后进行入库操作。";
        return false;
      }
      if (!this.currentSInfo[0].pguige) {
        this.errmessage = "请输入商品规格。";
        $(productguige).focus();
        return false;
      }
      if (!this.currentSInfo[0].pprice) {
        this.errmessage = "请输入商品采购单价。";
        $(productprice).focus();
        return false;
      } else {
        if (!this.GLOBAL.isFloat(this.currentSInfo[0].pprice)) {
          this.errmessage = "请输入正确的批发价格。";
          $(productprice).focus();
          return false;
        }
      }
      if (!this.currentSInfo[0].punit) {
        this.errmessage = "请选择商品入库单位。";
        $(productunit).focus();
        return false;
      }
      if (!this.currentSInfo[0].pcount) {
        this.errmessage = "请输入商品入库数量。";
        $(productcount).focus();
        return false;
      } else {
        if (!this.GLOBAL.isFloat(this.currentSInfo[0].pcount)) {
          this.errmessage = "请输入正确的入库数量。";
          $(productcount).focus();
          return false;
        }
      }
      if (!this.currentSInfo[0].punitls) {
        this.errmessage = "请选择商品零售单位。";
        $(productunitls).focus();
        return false;
      }
      if (!this.currentSInfo[0].pcountls) {
        this.errmessage = "请输入商品可零售数量。";
        $(productcountls).focus();
        return false;
      } else {
        if (!this.GLOBAL.isFloat(this.currentSInfo[0].pcountls)) {
          this.errmessage = "请输入正确的可零售数量。";
          $(productcountls).focus();
          return false;
        }
      }

      this.submitData();
    },

    inputsetpnum(id) {
      return "inputsetpnum" + id;
    },
    inputsetpprice(id) {
      return "inputsetpprice" + id;
    },

    getSDetail() {
      let tmpInfo = [];
      this.productList.forEach((item, index) => {
        // console.log(item.pid===this.currentSID)
        if (item.pid === this.currentSID) {
          tmpInfo = this.productList[index];
        }
      });
      return tmpInfo;
    },

    screen_click(pid) {
      // 显示筛选
      this.currentSID = pid;
      this.show = true;
      this.isshow = true;

      this.currentSInfo = this.getSDetail();

      //  console.log(this.currentSInfo)
    },

    initData() {
      this.errmessage = "";
      this.searchpname = "";
      this.searchpsn = "";
      this.oldpGuige="";
      this.currentSInfo = [
        {
          poid: "",
          psn: "",
          posn: "",
          pguige: "",
          pgys: "",
          pyxq: "",
          pname: "",
          punit: "",
          punitls: "",
          pprice: "",
          ppricels: "",
          pcost: "",
        //   pcostls: "",
          pcount: "",
          pcountls: "",
          pnotes:"",
          opruser: this.GLOBAL.loginusername
        },
      ];
    },
    mouseOver(index) {
      // alert(index)
      this.currentIndex = index;
    },
    mouseOut() {
      // alert(index)
      this.currentIndex = -1;
    },
    //处理回车时间，自动跳转到下一个输入框
    addEnterListener() {
      if (window.__completeEnterBind__) return;
      window.addEventListener("keydown", this.enterCallback);
      window.__completeEnterBind__ = true;
    },
    removeEnterListener() {
      window.removeEventListener("keydown", this.enterCallback);
      window.__completeEnterBind__ = false;
    },
    enterCallback(e) {
      function findFormItem(el) {
        //   console.log(el)
        const parent = el.parentElement;
        // console.log(parent);
        return parent;
        
      }
      function findInput(container) {
        let nextEl = container.nextElementSibling;
        // console.log(nextEl)
        if (!nextEl) {
          nextEl = container.parentElement.nextElementSibling;
        }
        // return;
        let input = nextEl.querySelector("input");
        if(!input) {
            nextEl = nextEl.nextElementSibling;            
            input = nextEl.querySelector("input");
        }
        // console.log(input);
        // while (input.id === "el-select") {
        //   nextEl = nextEl.nextElementSibling;
        //   if (!nextEl) return;
        //   input = nextEl.querySelector("input");
        // }
        // if (input.className.includes("el-input__inner")) return input;
        return input;
      }
      // console.log(e.keyCode);
      if (e.keyCode === 13) {
        // console.log('5');
        const container = findFormItem(document.activeElement);
        // console.log(container)
        findInput(container) && findInput(container).focus();
      }
    },
  },
  mounted() {
    this.haveRights('|0101|')
      this.addEnterListener();
      this.searchyxqdate=this.GLOBAL.getToday()
    // this.initData()
    this.getStorageInUnit();
    window.addEventListener("click", this.hideCalender);
  },
  destroy() {
    window.removeEventListener("click", this.hideCalender);
    this.removeEnterListener();
    // console.log("out");
  },
};
</script>

<style scoped>
button {
  background-color: #f5f5f5;
  margin-right: 10px;
}

.actived {
  background-color: rgba(190, 221, 140, 0.9);
}
.step1 {
  margin: 20px;
}
.infotable {
  border: 0;
  width: 95%;
  /* border-left:0; */
}
td {
  height: 45px;
  /* border-top:0;
  border-left: 0;
  border-right: 0; */
  border: 0;
  text-align: left;
}

/* .infotable input{
  border:0;
} */

#keywords {
  position: absolute;
  padding-left: 5px;
  /* top: 40px; */
  background-color: rgba(228, 233, 220, 0.9);
  list-style: none;
  width: 400px;
  z-index: 9999;
  color: #616161;
  font-size: 15px;
  line-height: 25px;
}
.tdmtext {
  text-align: center;
}
.errinfo {
  text-align: center;
  color: red;
  font-size: 15px;
  font-weight: 600;
}
</style>