<template>
  <div class="typo-grid" style="height:800px;">

    <div
      class="hidden_view"
      :style="{ height: win_height + 'px' }"
      v-show="show"
      @click="screen_hide_click"
    ></div>
    <!-- <transition name="show_view"> -->
      <StorageDetail :currentSInfo="currentSInfo" :isshow="isshow"/>   

    <div class="grid_3 grid_5" style="height:100%;">
      <div class="form-group" style="font-size: 20px; text-align: center">
        <label
          class="col-sm-12"
          style="
            color: #9c9c9c;
            font-weight: bold;
            padding-top: 0px;
            padding-bottom: 0px;
          "
          >最近到期产品列表</label
        >
      </div>
      <div class="but_list">
        <div
          class="bs-example bs-example-tabs"
          role="tabpanel"
          data-example-id="togglable-tabs"
        >
          <ul id="myTab" class="nav nav-tabs" role="tablist">
            <li role="presentation" class="active">
              <a
                href="#home"
                id="home-tab"
                role="tab"
                data-toggle="tab"
                aria-controls="home"
                aria-expanded="true"
                @click="getProductList('7')"
                >一周内</a
              >
            </li>
            <li role="presentation">
              <a
                href="#profile"
                role="tab"
                id="profile-tab"
                data-toggle="tab"
                aria-controls="profile"
                @click="getProductList('30')"
                >一月内</a
              >
            </li>
            <li role="presentation">
              <a
                href="#profile"
                role="tab"
                id="profile-tab"
                data-toggle="tab"
                aria-controls="profile"
                @click="getProductList('90')"
                >三月内</a
              >
            </li>
          </ul>
          <div id="myTabContent" class="tab-content">
            <table class="dataintable" v-if="productList.length>0">
              <tr>
                <th style="line-height: 40px">商品名称</th>
                <th width="140px">商品编码</th>
                <th width="120px">规格</th>
                <th width="10%">供应商</th>
                <th width="95px">有效期</th>
                <th width="40px">入库单位</th>
                <th width="40px">入库数量</th>
                <th width="70px">总成本</th>
                <th width="70px">剩余库存</th>
              </tr>
              <tr
                v-for="(s, index) in productList"
                :key="index"   
                @mouseover="mouseOver(index)"
                @mouseout="mouseOut"
                @click="screen_click(s.pid)"
                :class="{actived:currentIndex==index}"                 
                >
                <td style="text-align: left; padding-left: 5px">
                  {{ s.pname }}
                </td>
                <td>{{ s.posn }}</td>
                <td>{{ s.pguige }}</td>
                <td>{{ s.pgys }}</td>
                <td>{{ s.pyxq }}</td>
                <td>{{ s.punit }}</td>
                <td>{{ s.pcount }}</td>
                <td>{{ s.pcost }}</td>
                <td>{{ s.pkucun }}</td>
              </tr>
            </table>
            <div v-else class="noresult">您所选则的时间段内，没有要到期的产品</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import StorageDetail from "../../components/StorageDetail";
export default {
  name: "RemindList",
  components: {
    StorageDetail,
  },
  data() {
    return {
      productList: [],
      currentIndex:-1,
      show: false,
      isshow: false,
      win_height: "",
      currentSID: 0,
      currentSInfo: [],
    };
  },

  methods: {
    getSDetail() {
      let tmpInfo = [];
      this.productList.forEach((item, index) => {
        // console.log(item.pid===this.currentSID)
        if (item.pid === this.currentSID) {
          tmpInfo = this.productList[index];
        }
      });
      return tmpInfo;
    },
    screen_click(pid) {
      // 显示筛选
      this.currentSID = pid;
      this.show = true;
      this.isshow = true;

      this.currentSInfo = this.getSDetail();

      //  console.log(this.currentSInfo)
    },
    screen_hide_click() {
      // 隐藏筛选
      let that = this;
      setTimeout(function () {
        that.show = false;
      }, 300);
      that.isshow = false;
    },

    mouseOver(index){
      // alert(index)
      this.currentIndex=index
    },
    mouseOut(){
      // alert(index)
      this.currentIndex=-1
    },

    getProductList(days) {
      if (days) {
        axios({
          method: "get",
          params: {
            days: days,
          },
          url: this.GLOBAL.serverAddress + "/getOutDateProductList/",
        }).then(
          (response) => {
            this.productList = response.data;
          },
          (error) => {
            console.log("出现错误", error.message);
            return false;
          }
        );
      }
    },
  },
  mounted() {
    this.haveRights('|0102|')
    this.getProductList("7")
    this.win_height = window.innerHeight;
  },
};
</script>

<style scoped>
.noresult{
  text-align: center;
  font-size:2em;
  color:rgb(160, 154, 154);
}
.actived{
  background-color:rgba(190, 221, 140, 0.9);
}
table.dataintable {
  margin-top: 15px;
  border-collapse: collapse;
  border: 1px solid white;
  width: 98%;
  font-size:0.95em;
  background-color:#EEEED1;
}
table.dataintable th {
  vertical-align: middle;
  /* padding:5px 15px 5px 6px; */
  background-color: #e8e8e8;
  border: 1px solid white;
  text-align: center;
  color: rgb(173, 169, 169);
}
table.dataintable td {
  vertical-align: text-top;
  /* padding:6px 15px 6px 6px; */
  border: 1px solid white;
  line-height: 40px;
}
/* table.dataintable tr{
  background-color:#e5ebdc;
} */
/* table.dataintable tr:nth-child(odd) {
  background-color: #f6faf6 ;
}
table.dataintable tr:nth-child(even) {
  background-color: #e5ebdc ;
} */

.hidden_view {
  width: 100%;
  background: #698b22;
  opacity: 0.2;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 99;
}
</style>