exports.install=function(Vue,options){
  Vue.prototype.isSuperAdmin=function(){
    this.initLoginInfo()
    if(this.GLOBAL.loginusertype.toString()!=="0"){
      this.$router.push('/login')
    }
  }
  Vue.prototype.haveRights=function(options){
    this.initLoginInfo()
    if(this.GLOBAL.loginusertype.toString()!=="0" && this.GLOBAL.loginuserrole.indexOf(options)==-1){
      this.$router.push('/login')
    }
  }
  Vue.prototype.canDo=function(options){
    
    if(this.GLOBAL.loginusertype.toString()!=="0" && this.GLOBAL.loginuserrole.indexOf(options)==-1){
      return false
    }else{
      return true
    }
  }
  Vue.prototype.getFullToday=function(options){
    let nowDate = new Date()
    let y = nowDate.getFullYear()
    let m = nowDate.getMonth() + 1 < 10 ? "0" + (nowDate.getMonth() + 1) : nowDate.getMonth() + 1
    let d = nowDate.getDate() < 10 ? "0" + nowDate.getDate() : nowDate.getDate()
    if(options){
      return y + options + m + options + d
    }else{
      return y + '年' + m + '月' + d + '日'
    }
  }
  Vue.prototype.initLoginInfo=function(){
    this.GLOBAL.loginusername=sessionStorage.getItem("loginuser")
    this.GLOBAL.token=sessionStorage.getItem("logintoken")
    this.GLOBAL.loginusertype=sessionStorage.getItem("logintype")
    this.GLOBAL.loginuserrole=sessionStorage.getItem("loginrole")
    
  }
  Vue.prototype.isNumber=function(val){
    var regPos = /^\d+(\.\d+)?$/; //非负浮点数
    var regNeg = /^(-(([0-9]+\.[0-9]*[1-9][0-9]*)|([0-9]*[1-9][0-9]*\.[0-9]+)|([0-9]*[1-9][0-9]*)))$/; //负浮点数
    if(regPos.test(val) || regNeg.test(val)) {
        return true;
    } else {
        return false;
    }
    
  }
}

