<template>
  <div style="height: 100%;">
    <Toast ref="toast" />
    <div
      ref="curDiv"
      v-show="isshowcalender"
      style="z-index: 999; position: absolute"
    >
      <Calender ref="Calender" v-on:choseDay="clickDay"></Calender>
    </div>
    <!-- <div ref="print" id="print" class="printList" >
      test
    </div> -->
    <vueEasyPrint sheetShow ref="easyPrint" >
      <template>
        <printList :sheetData="sheetdata" :exportInfoBrief="exportinfo" :showbrief="false"></printList>
      </template>
    </vueEasyPrint>

    <div class="grid-form1" style="padding: 10px;">
      <div style="margin: 0; padding: 0; vertical-align: middle;">
        <div class="search_condition">
          <div class="pname">
            <label
              for="txtContractNumber"
              class="control-label"
              >送货城市</label
            >
          </div>
          <div class="pname_value">
            <input
              type="text"
              class="form-control1"
              id="txtContractNumber"
              name="txtContractNumber"
              v-model.trim="searchpname"
              @keyup.enter="search"
            />
          </div>
          
          <div class="bdate" >
          <label
            for="txtBDate"
            class="control-label"
            >配货日期从</label
          >
          </div>
          <div class="bdate_value" >
            <input
              type="text"
              class="form-control1"
              id="txtBDate"
              name="txtBDate"
              readonly
              @click.stop="showcalender($event,'b')"
              v-model.trim="searchbdate"              
            />
          </div>
          <div class="edate" >
          <label
            for="txtEDate"
            class="control-label"
            >截止到</label
          >
          </div>
          <div class="edate_value">
            <input
              type="text"
              class="form-control1"
              id="txtEDate"
              name="txtEDate"
              readonly
              @click.stop="showcalender($event,'e')"
              v-model.trim="searchedate"
              
            />
          </div>
          
          <!-- <div class="sltStyle">
            <select name="sltPayStatus" v-model="orderStatus">
              <option value="">订单状态</option>
              <option value="0" >待付款</option>
              <option value="1">待发货</option>
              <option value="2">待收货</option>
              <option value="3">待评论</option>
              <option value="4">退货</option>
              <option value="5">已完成</option>
              <option value="7">已关闭</option>
            </select>
          </div> -->
          <div class="cname">
            <label
              for="txtContractName"
              class="control-label"
              >收货人</label
            >
          </div>
          
          <div class="cname_value" >
            <input
              type="text"
              class="form-control1"
              id="txtContractName"
              name="txtContractName"
              v-model.trim="searchpcontact"
              @keyup.enter="search"
            />
          </div>
          <!-- <div class="cname">
            <label
              for="txtContractName"
              class="control-label"
              >收货城市</label
            >
          </div> -->
          <div class="sltStyle">
            <select
              name="sltProductClass"
              v-model="deliveryCity"
              multiple
            >
              <option value="">收货城市</option>
              <option
                :value="item.rshi"
                v-for="(item, index) in deliveryCityList"
                :key="index"
              >
                {{ item.rshi }}
              </option>
            </select>
            
          </div>
          <div class="sltStyle">
            <select v-model="pickupflag">
                <option value="">所有</option>
                <option value="1">配送</option>
                <option value="2">自提</option>
            </select>
          </div>
          &nbsp;&nbsp;
          <button class="btn btn-default" type="button" @click="clearSearch">
              <i class="fa fa-refresh" aria-hidden="true"></i>&nbsp;清空
            </button>
            <button
              class="btn btn-default"
              type="button"
              @click="search"
            >
              <i class="fa fa-search" aria-hidden="true"></i>&nbsp;搜索
            </button>
        </div>
        <div class="col-sm-12 " style="padding-top: 2px;padding-left:50px;">
            
            <!-- <button
              class="btn btn-default"
              type="button"
              @click="printOrder"
            >
              <i class="fa fa-print" aria-hidden="true"></i>&nbsp;生成配货单
            </button> -->
        </div>
          <div class=" btnexport" >
              <div style="white-space: nowrap;">共选择了{{selectedordernum}}个订单&nbsp;&nbsp;</div>
              <button class="btn btn-default" @click="checkAllList('0')">
            <i class="fa fa-check-square-o" aria-hidden="true"></i
            >&nbsp;全选
          </button>
          <button class="btn btn-default" @click="checkAllList('1')">
            <i class="fa fa-check-square-o" aria-hidden="true"></i
            >&nbsp;全选当日
          </button>
          <button class="btn btn-default" @click="cancelAllList">
            <i class="fa fa-square-o" aria-hidden="true"></i>&nbsp;取消
          </button>
              <download-excel
              class="btn btn-default"
              :fetch="fetchData"
              :fields="jsonFields"
              :name="filename"
            >
              <i class="fa fa-table" aria-hidden="true"></i>&nbsp;导出配送单
            </download-excel>
            &nbsp;&nbsp;
            <download-excel
              class="btn btn-default"
              :fetch="fetchDataR"
              :fields="jsonFieldsR"
              :name="filenameR"
            >
              <i class="fa fa-table" aria-hidden="true"></i>&nbsp;导出餐厅配送单
            </download-excel>
            &nbsp;&nbsp;

            <download-excel
              class="btn btn-default"
              :fetch="fetchDataByCity"
              :fields="jsonFieldsByCity"
              :name="filenameByCity"
            >
              <i class="fa fa-table" aria-hidden="true"></i>&nbsp;导出城市配送单数量
            </download-excel>
            &nbsp;&nbsp;
            
            <button
              class="btn btn-default"
              @click="printPHD"
            >
              <i class="fa fa-print" aria-hidden="true"></i>&nbsp;补打配货单
            
            </button>
            
              <button
                class="btn btn-default"
                @click="setOrderStatus"
              >
              <i class="fa fa-print" aria-hidden="true"></i>&nbsp;设置订单为已发货
            </button>
            
          </div>
          
      </div>

    </div>
        <div class="orderlist" >
            
            
            <div class="order_item" >
                <div class="order_item_briefinfo">
                    <div class="order_item_briefinfo_orderchk">
                      &nbsp;&nbsp;&nbsp;&nbsp;
                    </div>
                    <div class="order_item_briefinfo1">订单编号</div>
                    <div class="order_item_briefinfo2">订单金额</div>
                    <div class="order_item_briefinfo2">订单状态</div>
                    <div class="order_item_briefinfo3">配送日期</div>
                    <div class="order_item_briefinfo6">配送编号</div>
                    <div class="order_item_briefinfo7">配送方式</div>
                    <div class="order_item_briefinfo4">收货城市</div>
                    <div class="order_item_briefinfo5">收货人</div>
                    <div class="order_item_briefinfo7">已配送</div>
                    <div class="order_item_briefinfo8">订单备注</div>
                    <div class="order_item_briefinfo7">操作</div>
                </div>
                <div class="order_item_briefinfo" v-for="(item,index) in currentpagelist" :key="index">
                    <div class="order_item_briefinfo_orderchk">
                      <input
                      type="checkbox"
                      :checked="item.checked"
                      @change="handItemChangeC(item.orderid)"
                    />
                    </div>
                    <div class="order_item_briefinfo1" style="text-align:left;padding-left:10px;"><a @click="viewOrderDetail(item.orderid)" title="点击查看订单详情">{{item.ordersno}}</a></div>
                    <div class="order_item_briefinfo2">{{item.ordertotalprice}}</div>
                    <div class="order_item_briefinfo2">{{item.status}}</div>
                    <div class="order_item_briefinfo3">{{item.allocatedate}}<br/>{{item.dweek}}</div>
                    <div class="order_item_briefinfo6">{{item.boxsn}}</div>
                    <div class="order_item_briefinfo7">{{item.pickupflag=="2"?'自提':'配送'}}</div>
                    <div class="order_item_briefinfo4">{{item.rshi}}</div>
                    <div class="order_item_briefinfo5">{{item.contactname}}（{{item.userid}}）</div>
                    <div class="order_item_briefinfo7">{{item.sendflag==0?'否':'是'}}</div>
                    <div class="order_item_briefinfo8">{{item.remarks}}</div>
                    <div class="order_item_briefinfo3" >
                      <a @click="emptyBoxSN(item.id)" title="点击清空配送单号" v-if="item.sendflag==0" >取消</a>
                    </div>
                    <!-- <div class="order_item_briefinfo3" v-else>
                    </div> -->
                </div>
                <!-- <div class="order_item_content">
                    
                    
                    <div class="order_item_content_opr">
                        <button
                        class="btn btn-default"
                        type="button"
                        @click="viewOrderDetail(item.orderid)"
                        
                        >
                        <i class="fa fa-newspaper-o" aria-hidden="true"></i>&nbsp;订单详情
                        </button>
                        <button
                        class="btn btn-default"
                        type="button"
                        @click="editOrder(item.orderid)"
                        :disabled="item.orderstatus==='订单关闭'||item.orderstatus==='已取消'?true:false"
                        >
                        <i class="fa fa-pencil-square-o" aria-hidden="true"></i>&nbsp;编辑订单
                        </button>
                        <button
                        class="btn btn-default"
                        type="button"
                        @click="payOrder(item.orderid)"
                        v-if="item.orderstatus==='待付款'"
                        >
                        
                        <i class="fa fa-money" aria-hidden="true"></i>&nbsp;确认收款
                        </button>
                        <button
                        class="btn btn-default"
                        type="button"
                        @click="payOrder(item.orderid)"
                        v-if="item.orderstatus==='待发货'"
                        >
                        
                        <i class="fa fa-truck" aria-hidden="true"></i>&nbsp;提交发货
                        </button>
                    </div>
                </div> -->
            </div>
             <div class="noresult" v-if="shownoresult">没有找到符合查询条件的配货单！</div>
        </div>
       
      <div
        class="pagenum_info"
        v-if="!shownoresult"
      >
        <span class="btmInfo" style="vertical-align: middle"
          >&nbsp;&nbsp;共有 {{ recordcount }} 条记录，当前第
          {{ currentpage }}/{{ pagecount }} 页</span
        >
        <div style="float: right; padding-right: 30px">
          <button class="btn btn-default" type="button" @click="getNewPage(1)">
            首页
          </button>
          <button
            class="btn btn-default"
            type="button"
            @click="getNewPage(currentpage - 1)"
          >
            上页
          </button>
          <button
            class="btn btn-default"
            type="button"
            @click="getNewPage(currentpage + 1)"
          >
            下页
          </button>
          <button
            class="btn btn-default"
            type="button"
            @click="getNewPage(pagecount)"
          >
            尾页
          </button>
          <span class="btmInfo" style="margin-right: 2px">跳转到</span>
          <input
            type="text"
            size="1"
            id="txtGotoPage"
            autocomplete="off"
            v-int-only
            style="margin-right: 10px; font-size: 0.9em"
            v-model.trim="gotopage"
          />
          <button
            type="button"
            class="btn btn-default"
            @click="getNewPage(gotopage)"
          >
            跳转
          </button>
        </div>
      </div>
    </div>
  <!-- </div> -->
</template>

<script>
import axios from "axios";
import JsonExcel from 'vue-json-excel'
import Toast from "../../components/Toast";
import vueEasyPrint from "vue-easy-print";
import printList from "./printList";

import Calender from "../../components/vue-calendar-component";

export default {
  name: "phdList",
  components: {
    Calender,
    Toast,
    "download-excel":JsonExcel,
    vueEasyPrint,
    printList
  },
  data() {
    return {
      deliveryCity:[],
      deliveryCityList: [],
        jsonFields: {  //导出Excel表格的表头设置
            '配送日期':'rdate',
            '配送编号': 'boxsn',
            '联系人':'rname',
            '联系电话': 'rtel',
            '地址': 'raddress',
            '城市': 'rshi',
            '备注':'remarks'
        },
        jsonFieldsR: {  //导出Excel表格的表头设置
            '配送编号': 'boxsn',
            '联系人':'rname',
            '联系电话': 'rtel',
            '菜品': 'pname',
            '数量': 'pcount',
            '餐厅名称':'pclassname',
            '备注':'remarks'
        },
        jsonFieldsByCity: {  //导出Excel表格的表头设置
            '城市': 'city',
            '配送单数量':'ordercount'
        },
        filename:this.GLOBAL.getToday() + '配送单.xls',
        filenameR:this.GLOBAL.getToday() + '餐厅配送单.xls',
        filenameByCity:this.GLOBAL.getToday() + '分城市配送单数量.xls',
      selectedordernum:0,
      orderStatus:'',
      exportList:[],
      
        searchedate:'',
        searchbdate:'',
        searchedatef:'',
        searchbdatef:'',
        shownoresult:false,
      orderList: [],
      currentIndex: -1,
      currentpage: 1,
      gotopage: "",
      searchpcontact: "",
      searchpname: "",
        isshowcalender: false,
      show: false,
      isshow: false,
      //   isshowupdate: false,
      win_height: "",
      currentSID: 0,
      currentDateS:"",
      sheetdata:[],
      pickupflag:"",
      exportinfo: []
    };
  },
  methods: {
    checkAllList(flag) {
      // this.orderList.forEach((v) => {
      //   v.checked = true;
      // });
      // this.selectedordernum = this.recordcount;
      if(flag==='1'){

        // let tmpT=new Date()
        let tmpToday=this.getFullToday("-")//tmpT.getFullYear()+"-"+(tmpT.getMonth()+1)+"-"+tmpT.getDate()
        // tmpToday="2022-05-13"
        this.currentpagelist.forEach((v) => {
          
          let findex = this.orderList.findIndex((v2) => v2.orderid === v.orderid );
          if(this.orderList[findex].allocatedate===tmpToday){

            this.orderList[findex].checked = true;
          }
        })
      }else{
        this.currentpagelist.forEach((v) => {
          let findex = this.orderList.findIndex((v2) => v2.orderid === v.orderid);
  
          this.orderList[findex].checked = true;
        })
      }
      this.selectedordernum=0
      this.orderList.forEach((v) => {
        if(v.checked){
          this.selectedordernum+=1
        }
      });

    },
    cancelAllList() {
      this.orderList.forEach((v) => {
        v.checked = false;
      });
      this.selectedordernum = 0;
    },
    viewOrderDetail(id){
          this.$router.push('/retailorderdetail?from=3&id='+id)
      },
      async getAllDeliveryCityList(){
      const res3 = await this.$get(
        this.GLOBAL.serverAddress + "/getAllDeliveryCityList/",
        {}
      );
      // console.log(res2);

      if (res3.status === 200) {
        this.deliveryCityList = res3.data;
      }
    },
    async emptyBoxSN(id){
      if(confirm("您确认要取消该配送单号吗？"))
      {
          const res = await this.$post(this.GLOBAL.serverAddress + "/emptyAllocateBoxSN/", 
            {id:id});
            console.log(res);
            if (res.status === 200 && res.data) {
                this.$refs.toast.toast("取消成功")
                this.$router.go(0)
            }
      }
    },
    printDemo(row){
      // this.sheetdata={"name":"test","age":"13"}
      this.$refs.easyPrint.print()
    },
    
    async setOrderStatus(){
        let vflag = false
        let idlist="''"
      
        this.orderList.forEach((v) => {
            if (v.checked) {
            idlist=idlist+",'"+v.ordersno+"'"
            vflag = true
            }
        })
        let admininfo=sessionStorage.getItem("loginuser")

        if(vflag){

            const res = await this.$get(this.GLOBAL.serverAddress + "/updateOrderStatusDelivery/", 
            {'idlist':idlist,'admininfo':admininfo});
            console.log(res);
            if (res.status === 200 && res.data) {
                this.$refs.toast.toast("更新成功")
                this.$router.go(0)
            }
        }else{
            this.$refs.toast.toast("请选择订单")
            return false
        }
    },
    handItemChangeC(orderid) {
      let tmpList=this.orderList
      let findex = tmpList.findIndex((v) => v.orderid === orderid)
      
      tmpList[findex].checked =!tmpList[findex].checked

      this.orderList = tmpList

      let i=0
      this.orderList.forEach((v) => {
        if (v.checked) {
          i=i+1
        }
      })
      this.selectedordernum=i

      // console.log(this.menulist)
    },
    // printOrder(){
      
    //   let tmpDom=this.$refs.print

    //   console.log(tmpDom)

    //   this.$print(tmpDom)
    // },
      // viewOrderDetail(id){
      //     this.$router.push('/retailorderdetail?id='+id)
      // },
      // editOrder(id){
      //     this.$router.push('/updateretailorder?id='+id)
      // },
      // payOrder(id){
      //     if(confirm("您确认已经收到了该订单的款项吗？确认后无法撤回。")){
            
      //         axios({
      //           method: "put",
      //           data: {"id":id,"status": "已支付","type":"1"},
      //           url: this.GLOBAL.serverAddress + "/22updateOrderStatus/",
      //       }).then(
      //           (response) => {
      //               this.clearSearch()
      //               this.search()
      //               alert("订单状态更新完成。")
      //           },
      //           (error) => {
      //               alert(error.message)
      //               return false;
      //           }
      //       );
      //     }
      // },
      // deliveryOrder(id){
      //     if(confirm("您确认该订单已取货吗吗？确认后无法撤回。")){
            
      //         axios({
      //           method: "put",
      //           data: {"id":id,"status": "已取货","type":"2"},
      //           url: this.GLOBAL.serverAddress + "/updateOrderStatus/",
      //       }).then(
      //           (response) => {
      //               this.clearSearch()
      //               this.search()
      //               alert("订单状态更新完成。")
      //           },
      //           (error) => {
      //               alert(error.message)
      //               return false;
      //           }
      //       );
      //     }
      // },
    hideCalender() {
      this.isshowcalender = false;
    },
    clickDay(data) {
    //   this.searchyxqdate = data;
        // console.log(this.currentDateS)
      if(this.currentDateS==='b'){
          this.searchbdate=data
      }else{
          this.searchedate=data
      }
      this.isshowcalender = false;
    },
    showcalender(e,v) {
      var divC = this.$refs.curDiv;
      this.currentDateS=v
      divC.style.top = e.target.getBoundingClientRect().top + 40 + "px";
      divC.style.left = e.target.getBoundingClientRect().left + "px";

      this.isshowcalender = true;
    },

    clearSearch() {
      this.searchpname = ""
      this.deliveryCity=[]
      this.searchpcontact = ""
      this.searchedate=""
      this.searchbdate=""
      this.selectedordernum=0
      this.orderList.forEach((v) => {
        v.checked= false
        }
      )
    },
    // async printPHD(){
    //     let vflag = false
    //   let idlist='0'
      
    //   this.orderList.forEach((v) => {
    //     if (v.checked) {
    //       idlist=idlist+','+v.orderid
    //       vflag = true
    //     }
    //   })
    //   if(vflag){

    //     const res = await this.$get(this.GLOBAL.serverAddress + "/exportPHDExcel/", 
    //     {'idlist':idlist});
    //     // console.log(res.data);
          
    //     if (res.status === 200) {
    //       if(res.data.length > 0){
    //         this.sheetdata=res.data[0]
    //         this.exportinfo=res.data[1]
    //         setTimeout(()=>{
    //           this.$refs.easyPrint.print()
    //         },1500)
    //         // this.printDemo(0)
    //         // return res.data
    //         // this.exportList=res.data
    //       }else{
    //         this.$refs.toast.toast("未查到相关订单")
    //         return false
    //       }
    //     }
    //   }else{
    //     this.$refs.toast.toast("请选择订单")
    //     return false
    //   }
    // },
    async printPHD(){
        let vflag = false
      let idlist='0'
      
      this.orderList.forEach((v) => {
        if (v.checked) {
          idlist=idlist+','+v.orderid
          vflag = true
        }
      })
      if(vflag){

        const res = await this.$get(this.GLOBAL.serverAddress + "/exportPHDExcel/", 
        {'idlist':idlist});
        // console.log(res.data[0]);
          
        if (res.status === 200) {
          if(res.data.length > 0){
            this.sheetdata=res.data[0]
            this.exportinfo=res.data[1]
            setTimeout(()=>{
              this.$refs.easyPrint.print()
            },1500)
            // this.printDemo(0)
            // return res.data
            // this.exportList=res.data
          }else{
            this.$refs.toast.toast("未查到相关订单")
            return false
          }
        }
      }else{
        this.$refs.toast.toast("请选择订单")
        return false
      }
    },
    async fetchData(){
      
      let vflag = false
      let idlist='0'
      
      this.orderList.forEach((v) => {
        if (v.checked) {
          idlist=idlist+','+v.orderid
          vflag = true
        }
      })
      
      if(vflag){

        const res = await this.$get(this.GLOBAL.serverAddress + "/exportDeveliveryExcel/", 
        {'idlist':idlist});
        // console.log(res.data);
          
        if (res.status === 200) {
          if(res.data.length > 0){
            setTimeout(()=>{
              this.$router.go(0)
            },1500)  
            return res.data
            // this.exportList=res.data
          }else{
            this.$refs.toast.toast("未查到相关订单")
            return false
          }
        }
      }else{
        this.$refs.toast.toast("请选择订单")
        return false
      }
      
      
    },
    async fetchDataR(){
      
      let vflag = false
      let idlist='0'
      
      this.orderList.forEach((v) => {
        if (v.checked) {
          idlist=idlist+','+v.orderid
          vflag = true
        }
      })
      
      if(vflag){

        const res = await this.$get(this.GLOBAL.serverAddress + "/exportDeveliveryExcelR/", 
        {'idlist':idlist});
        console.log(res.data);
          
        if (res.status === 200) {
          if(res.data.length > 0){
            setTimeout(()=>{
              this.$router.go(0)
            },1500)  
            return res.data
            // this.exportList=res.data
          }else{
            this.$refs.toast.toast("未查到相关订单")
            return false
          }
        }
      }else{
        this.$refs.toast.toast("请选择订单")
        return false
      }
      
      
    },
    async fetchDataByCity(){
      
      let vflag = false
      let idlist='0'
      
      this.orderList.forEach((v) => {
        if (v.checked) {
          idlist=idlist+','+v.orderid
          vflag = true
        }
      })
      
      if(vflag){

        const res = await this.$get(this.GLOBAL.serverAddress + "/exportDeveliveryExcelByCity/", 
        {'idlist':idlist});
        console.log(res.data);
          
        if (res.status === 200) {
          if(res.data.length > 0){
            setTimeout(()=>{
              this.$router.go(0)
            },1500)  
            return res.data
            // this.exportList=res.data
          }else{
            this.$refs.toast.toast("未查到相关订单")
            return false
          }
        }
      }else{
        this.$refs.toast.toast("请选择订单")
        return false
      }
      
      
    },
    getNewPage(pagenum) {
      if (pagenum >= 1 && pagenum <= this.pagecount) {
        this.currentpage = pagenum;
        // console.log(this.pages)
        // return this.pages[this.currentpage - 1];
      }
    },
    async search() {
      let tmpCityList=''
      this.currentpage=1
      // console.log('dddddd')
      // console.log(this.deliveryCity.length)
      
      if(this.deliveryCity.length>0){
        tmpCityList=this.deliveryCity.join("','")
        tmpCityList="'"+tmpCityList +"'"
        // console.log(tmpCityList)
        // for(let item of this.deliveryCity){

        //   tmpCityList=tmpCityList+",'" +item+"'"
        // }
        
      }
      // console.log(tmpCityList)
      const res = await this.$get(this.GLOBAL.serverAddress + "/searchAllocateInfo/", 
      {'rcity':this.searchpname,'contact':this.searchpcontact,'bdate':this.searchbdate,'edate':this.searchedate,'rshi':tmpCityList,'pickupflag':this.pickupflag});
      // console.log(res);
        
      if (res.status === 200) {
        this.orderList = res.data;
        console.log(this.orderList)
        if(this.orderList.length>0){
            this.shownoresult=false
        }else{
            this.shownoresult=true
        }
      }
    },
  },
  computed: {
    currentpagelist() {
      return this.pages[this.currentpage - 1];
    },
    recordcount() {
      return this.orderList.length;
    },
    pagecount() {
      return Math.ceil(this.orderList.length / 30);
    },
    pages() {
      const pages = [];
      this.orderList.forEach((item, index) => {
        const page = Math.floor(index / 30);
        if (!pages[page]) {
          pages[page] = [];
        }
        pages[page].push(item);
      });
      return pages;
    },
  },
  mounted() {
    this.haveRights('|0502|')
    this.getAllDeliveryCityList()
    this.search();
    window.addEventListener("click", this.hideCalender);
    this.win_height = window.innerHeight;
  },
  beforeDestroy() {
    // 实例销毁之前对点击事件进行解绑
    window.removeEventListener("click", this.hideCalender);
  },
};
</script>
<!-- Latest compiled and minified CSS -->


<style scoped lang="less">
.btnexport{
  padding-top: 10px;
  padding-left:50px;
  display:flex;
  align-items: center;
}
.printList{
  position: absolute;
  left:300px;
}
.bdate_value_time{
  color: #9c9c9c;
  border: 0px solid #9c9c9c;
}
.search_condition {
    display: flex;
    // white-space: nowrap;
    justify-content:center;
    align-items: center;
    text-align: right;
    padding:10px;
  .pname {
    //   display: flex;
    flex: 1;
    padding-right: 5px;
  }

  .pname_value {
    flex: 1;
  }

  .cname {
    flex: 1;
    padding-right: 5px;
  }

  .cname_value {
    flex: 1;
  }

  .bdate {
    flex: 1;
    padding-right: 5px;
  }

  .bdate_value {
    flex: 1;
  }

  .edate {
    //   border: 1px solid green;
    flex: 1;
    text-align: center;
    // display: flex;
    // align-items: center;
    // justify-content: center;
  }

  .edate_value {
    flex: 1;
  }
}

.orderlist {
  color: #9c9c9c;

  // padding:10px;
  .order_item {
    //   width:100%;
    margin-top: 15px;
    background-color: #fff;
    // padding-top:15px;
    .order_item_briefinfo {
      display: flex;
      border-bottom: 1px solid #eee;
      //   padding-bottom:10px;
      padding: 10px;
      text-align: center;
      align-items: center;
      justify-content: center;
      .order_item_briefinfo_orderchk{
        // display: flex;
        // align-items: center;
        // border:1px solid red;
        input{
          // outline: 1px solid red;
          // border:0px solid black;
          // width:50px;
          transform:scale(1.8, 1.8);
        }

      }
      .order_item_briefinfo1{
          flex:3;
          text-align: center;
      }
      .order_item_briefinfo2
      {
          flex:1;
      }
      .order_item_briefinfo3
      {
          flex:1;
          white-space: nowrap;
      }
      .order_item_briefinfo4
      {
          flex:2;
      }
      .order_item_briefinfo5
      {
          flex:1;
      }
      .order_item_briefinfo6
      {
          flex:1;
          text-align:"center";
      }
      .order_item_briefinfo7
      {
          flex:1;
          // border:1px solid red;
      }
      .order_item_briefinfo8{
          flex:4;
      }
      
    }

    .order_item_content {
      display: flex;
      .order_item_content_product {
        flex: 3;
        margin: 10px;
        padding-left: 10px;
        display: flex;
        .productinfo_img {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            width: 80%;
            max-height: 100px;
            object-fit: contain;
          }
        }

        .productinfo {
          flex: 3;
          margin: 10px;
          padding-left: 5px;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          // align-items: center;
          .productinfo_title {
          }

          .productinfo_guige {
          }

          .productinfo_num {
          }

          .productinfo_price {
          }
        }
      }

      .order_item_content_order {
        flex: 5;
        display: flex;
        .orderremark{
          flex:2;
          padding: 5px;
          display: flex;
          align-items: center;
          color:#FF4500;
        }
        .orderprice {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 1.2em;
          color: #6e8b3d;
        }

        // .orderstatus {
        //   flex: 1;
        //   display: flex;
        //   align-items: center;
        //   justify-content: center;
        // }

        .ordercontact {
          flex: 3;
          display: flex;
          // align-items: center;
          justify-content: center;
          flex-direction: column;
          padding-left: 10px;
          .ordercontact_name {
          }

          .ordercontact_tel {
          }

          .ordercontact_address {
            display: flex;
            .address_title {
              white-space: nowrap;
            }
          }
        }
      }

      .order_item_content_opr {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        // padding:15px;
        button {
          width: 80%;
          margin: 3px;
        }
      }
    }
  }
}

.sltStyle {
    
    padding-left: 5px;
    color:#999;
    select{
      border: 1px solid #E9E9E9;
    font-size: 0.9em;
    outline: none;
    padding: 0.5em 1em;
    color: #999;
    }
  }

button {
  background-color: #f5f5f5;
  margin-right: 10px;
}
.btmInfo {
  background: none;
  border: none;
  outline: none;
  color: #333;
  font-size: 0.9em;
}
.noresult {
  text-align: center;
  font-size: 2em;
  color: rgb(160, 154, 154);
}
.pagenum_info{
    background:#fff;
    height: 50px;
    margin-top:20px;
    padding: 10px; color: #6c757d;
    // border-top: 1px dashed #9acd32;
}
.actived {
  background-color: rgba(190, 221, 140, 0.9);
}
table.dataintable {
  /* margin-top: 15px; */
  border-collapse: collapse;
  border: 1px solid white;
  width: 98%;
  font-size: 0.95em;
  background-color: #e5ebdc;
  line-height: 40px;
}
table.dataintable th {
  vertical-align: middle;
  /* padding:5px 15px 5px 6px; */
  background-color: #e8e8e8;
  border: 1px solid white;
  text-align: center;
  color: rgb(173, 169, 169);
}
table.dataintable td {
  vertical-align: text-top;
  /* padding:6px 15px 6px 6px; */
  border: 1px solid white;
  line-height: 35px;
}

.hidden_view {
  width: 100%;
  background: #698b22;
  opacity: 0.2;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 99;
}
</style>