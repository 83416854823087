<template>
  <div style="height: 720px">
    <div class="grid-form1" style="padding: 0; height: 720px">
      <Toast ref="toast" />
      <div class="admin_info">
        <div class="admin_info_row">
            <div class="admin_info_row_l">
            <label
                for="txtContractNumber"
                class="control-label"
                style="padding-top: 10px; text-align: right"
                >登录账号</label
            >
            </div>
            <div class="admin_info_row_r" >
            <input
                type="text"
                class="form-control1"
                id="txtAccount"
                maxlength="30"
                v-model.trim="adminInfo.account"   
            />
            </div>
        </div>
        <div class="admin_info_row">
            <div class="admin_info_row_l">
            <label
                for="txtContractNumber"
                class="control-label"
                style="padding-top: 10px; text-align: right"
                >登录密码</label
            >
            </div>
            <div class="admin_info_row_r" >
            <input
                type="password"
                id="txtPWD"
                class="form-control1"
                v-model.trim="adminInfo.password"
            />
            </div>
            </div>
        <div class="admin_info_row">
            <div class="admin_info_row_l">
            <label
                for="txtContractNumber"
                class="control-label"
                style="padding-top: 10px; text-align: right"
                >确认密码</label
            >
            </div>
            <div class="admin_info_row_r" >
            <input
                type="password"
                id="txtPWDC"
                class="form-control1"
                v-model.trim="adminInfo.passwordc"
            />
            </div>
            </div>
        <div class="admin_info_row">
            <div class="admin_info_row_l">
            <label
                for="txtContractNumber"
                class="control-label"
                id="txtEmail"
                maxlength="100"
                style="padding-top: 10px; text-align: right;color:#999;"
                >Email</label
            >
            </div>
            <div class="admin_info_row_r" >
            <input
                type="text"
                class="form-control1"
                v-model.trim="adminInfo.email"
            />
            </div>
            </div>
        <div class="admin_info_row">
            <div class="admin_info_row_l">
            <label
                for="txtContractNumber"
                class="control-label"
                id="txtTel"
                maxlength="22"
                style="padding-top: 10px; text-align: right"
                >电话号码</label
            >
            </div>
            <div class="admin_info_row_r" >
            <input
                type="text"
                class="form-control1"
                v-model.trim="adminInfo.tel"
            />
            </div>
            </div>
        
        <div class="admin_info_row">
          <div class="admin_info_row_l">
            <label
              for="txtContractNumber"
              class="control-label"
              style="padding-top: 10px; text-align: right"
              >账号权限</label
            >
          </div>
          <div class="admin_info_row_r">
            <rightsList :roles="adminInfo.role" @changeRoles="getNewRoles"></rightsList>
            <!-- <div class="menulist">
              <div
                class="menulist_node"
                v-for="(item, index) in menulist"
                :key="index"
              >
                <div class="menulist_fnode">
                  <div class="menulist_fnode_chk">
                    <input
                      type="checkbox"
                      :checked="item.checked"
                      @change="handItemChange(item.menuid)"
                    />
                  </div>
                  <div class="menulist_fnode_name">
                    {{ item.menuname }}
                  </div>
                </div>
                <div
                  class="menulist_cnode"
                  v-for="(itemc, indexc) in item.childmenu"
                  :key="indexc"
                >
                  <div class="menulist_cnode_chk">
                    <input
                      type="checkbox"
                      :checked="itemc.checked"
                      @change="handItemChangeC(item.menuid, itemc.menuid)"
                    />
                  </div>
                  <div class="menulist_cnode_name">
                    {{ itemc.menuname }}
                  </div>
                </div>
              </div>
            </div> -->
          </div>
        </div>
        <div class="errinfo">{{ errmessage }}&nbsp;</div>
      </div>
      <div class="col-sm-12" style="text-align: center; padding-top: 20px">
        <button class="btn btn-default" type="button" @click="goback">
          <i class="fa fa-arrow-left" aria-hidden="true"></i>&nbsp;返回列表
        </button>

        <button
          class="btn btn-default"
          id="btnSave"
          :disabled="submitSaving"
          @click="saveAdminInfo"
        >
          <i class="fa fa-save" aria-hidden="true"></i>&nbsp;
          {{ submitSaving ? "创建中.." : "创建账号" }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Toast from "../components/Toast";
import rightsList from "../components/rightsList";

export default {
  name: "AdminAdd",
  components: { Toast,rightsList },
  data() {
    return {
      // vcflag:false,
 
      adminInfo:{
        account:'',
        birthday:'',
        
        password:'',
        passwordc:'',
        type:'2',
        tel:'',
        email:'',
        rolemenulist:''
      },
      // menulist: [
      //   {
      //     menuname: "库存管理",
      //     menuid: "|01|",
      //     checked: false,
      //     childmenu: [
      //       { menuname: "入库管理", menuid: "|0101|", checked: false },
      //       { menuname: "保质期提醒", menuid: "|0102|", checked: false },
      //       { menuname: "入库单查询", menuid: "|0103|", checked: false },
      //       { menuname: "库存查询", menuid: "|0104|", checked: false },
      //     ],
      //   },
      //   {
      //     menuname: "批发管理",
      //     menuid: "|02|",
      //     checked: false,
      //     childmenu: [
      //       { menuname: "采购的处理", menuid: "|0201|", checked: false },
      //       { menuname: "可批发产品配置", menuid: "|0202|", checked: false },
      //       { menuname: "客户管理", menuid: "|0203|", checked: false },
      //       { menuname: "批发统计", menuid: "|0204|", checked: false },
      //     ],
      //   },
      //   {
      //     menuname: "统计报表",
      //     menuid: "|03|",
      //     checked: false,
      //     childmenu: [
      //       { menuname: "销售报表", menuid: "|0301|", checked: false },
      //       { menuname: "利润统计", menuid: "|0302|", checked: false },
      //     ],
      //   },
      //   {
      //     menuname: "零售管理",
      //     menuid: "|05|",
      //     checked: false,
      //     childmenu: [
      //       { menuname: "待处理订单", menuid: "|0501|", checked: false },
      //       { menuname: "配货单/配送单", menuid: "|0502|", checked: false },
      //       { menuname: "商品列表", menuid: "|0503|", checked: false },
      //       { menuname: "代客下单", menuid: "|0504|", checked: false },
      //     ],
      //   },
      //   {
      //     menuname: "小团购管理",
      //     menuid: "|04|",
      //     checked: false,
      //     childmenu: [
      //       { menuname: "团长管理", menuid: "|0401|", checked: false },
      //       { menuname: "佣金政策维护", menuid: "|0402|", checked: false },
      //       { menuname: "不返佣商品维护", menuid: "|0403|", checked: false },
      //       { menuname: "生成结算单", menuid: "|0404|", checked: false },
      //       { menuname: "结算单", menuid: "|0405|", checked: false },
      //       { menuname: "团长业绩查询", menuid: "|0406|", checked: false },
      //     ],
      //   },
      //   {
      //     menuname: "小程序配置",
      //     menuid: "|09|",
      //     checked: false,
      //     childmenu: [
      //       { menuname: "轮播图", menuid: "|0901|", checked: false },
      //       { menuname: "首页分类导航", menuid: "|0902|", checked: false },
      //       { menuname: "预订须知", menuid: "|0903|", checked: false },
      //       { menuname: "弹窗广告", menuid: "|0904|", checked: false },
      //     ],
      //   },
      // ],
      errmessage: "",
      submitSaving: false,
    };
  },
  methods: {
    getNewRoles(roles){
      this.menulist=roles;
    },
    // handItemChange(menuid) {
    //   let menulist = this.menulist;
    //   let index = menulist.findIndex((v) => v.menuid === menuid);
    //   menulist[index].checked = !menulist[index].checked;
    //   if (menulist[index].checked) {
    //     menulist[index].childmenu.forEach((v) => {
    //       v.checked = true;
    //     });
    //   } else {
    //     menulist[index].childmenu.forEach((v) => {
    //       v.checked = false;
    //     });
    //   }
    //   this.menulist = menulist;
    //   // console.log(this.menulist)
    // },
    // handItemChangeC(fmenuid, cmenuid) {
    //   let menulist = this.menulist;
    //   let findex = menulist.findIndex((v) => v.menuid === fmenuid);
    //   let cindex = menulist[findex].childmenu.findIndex(
    //     (v) => v.menuid === cmenuid
    //   );
    //   menulist[findex].childmenu[cindex].checked =
    //     !menulist[findex].childmenu[cindex].checked;

    //   let vflag = false;
    //   menulist[findex].childmenu.forEach((v) => {
    //     if (v.checked) {
    //       vflag = true;
    //     }
    //   });
    //   if (vflag) {
    //     menulist[findex].checked = true;
    //   } else {
    //     menulist[findex].checked = false;
    //   }

    //   this.menulist = menulist;
    //   // console.log(this.menulist)
    // },
    
    getMenuList() {
      this.menulist.forEach((v) => {
        if (v.checked) {
          this.adminInfo.rolemenulist = this.adminInfo.rolemenulist + v.menuid;
        }
        v.childmenu.forEach((vc) => {
          if (vc.checked) {
            this.adminInfo.rolemenulist = this.adminInfo.rolemenulist + vc.menuid;
          }
        });
        // if(vcflag){
        //     this.roleinfo.rolemenulist=this.roleinfo.rolemenulist+v.menuid
        // }
      });
      // console.log(this.roleinfo)
    },

    goback() {
      this.$router.push("/adminlist");
    },
    submitData() {
      //   console.log('tijiao');
      axios({
        method: "post",
        headers: {
          "Content-Type": "application/json;chartset=UTF-8",
          Authorization: "test",
        },
        data: this.adminInfo,
        // [{id:1,psn:'test01'},
        //   {id:2,psn:'test02'}],
        url: this.GLOBAL.serverAddress + "/insertAdmin/",
      }).then(
        // axios.post('http://localhost:8080/getStorageList').then(
        (response) => {
          // console.log('OK',response.data)
          // this.initData();
          this.$refs.toast.toast("账号创建成功");
          setTimeout(()=>{
            this.$router.push("/adminlist");
          },1500)
          this.submitSaving = false;
        },
        (error) => {
          console.log("出现错误", error.message);
        }
      );
    },
    async saveAdminInfo() {
      var submitflag = false;
      // console.log(this.GLOBAL.isFloat(this.currentSInfo[0].pwsprice));
      // this.GLOBAL.showTest()
      if(this.adminInfo.account===""){
          this.errmessage = "请输入登录账号"
          $('#txtAccount').focus()
          return false
      }
      if($('#txtAccount').val().length<3){
          this.errmessage = "登录账号长度至少为3个字符"
          $('#txtAccount').focus()
          return false
      }else{
          const res= await this.$get(this.GLOBAL.serverAddress + "/hasAdmin/",{account:this.adminInfo.account})
            if(res.data){
                this.errmessage = "登录账号已存在，请使用其他账号"
                return false
            }
      }
      if(this.adminInfo.password===""){
          this.errmessage = "请输入登录密码"
          $('#txtPWD').focus()
          return false
      }
      if($('#txtPWD').val().length<6){
          this.errmessage = "登录密码长度至少为6个字符"
          $('#txtPWD').focus()
          return false
      }
      if(this.adminInfo.passwordc===""){
          this.errmessage = "请输入确认密码"
          $('#txtPWDC').focus()
          return false
      }
      if(this.adminInfo.passwordc!==this.adminInfo.password){
          this.errmessage = "两次输入的密码不一致，请重新输入确认密码"
          $('#txtPWDC').focus()
          return false
      }

      this.getMenuList();
      if (this.adminInfo.rolemenulist === "") {
        this.errmessage = "请选择该账号的权限";
        return false;
      }

      //   if(this.adminInfo.role===""){
      //       this.errmessage = "请选择账号角色类型"
      //       return false
      //   }
      submitflag = true;
      if (submitflag) {
        this.submitSaving = true;
        this.submitData();
      }
    },

    
  },
  mounted() {
    this.isSuperAdmin();
    // this.initData();
  },
};
</script>

<style lang="less" scoped>
.admin_info {
  display: flex;
  flex-direction: column;
  // align-items: center;
  // justify-content: center;
  // margin-left: 50px;
  // padding-left: 50px;
  margin-top: 20px;
  .admin_info_row {
    // padding:10px;
    //     line-height:40px;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 50px;
    .admin_info_row_l {
      text-align: right;
      // border-top: 1px solid red;
      flex: 1;
      padding-right: 15px;
    }
    .admin_info_row_r {
      flex: 2;

      input {
        width: 400px;
      }
      .menulist {
        width: 400px;
        display: flex;
        flex-direction: column;
        height: 280px;
        overflow: auto;
        border: 1px solid #eeeeee;
        .menulist_node {
          display: flex;
          flex-direction: column;
          // justify-content:space-around;
          // margin: auto;
          line-height: 30px;
          margin: 10px;
          .menulist_fnode {
            display: flex;
            .menulist_fnode_chk {
              flex: 1;
              input {
                width: 40px;
                line-height: 20px;
              }
            }
            .menulist_fnode_name {
              flex: 10;
              color: #999;
            }
          }
          .menulist_cnode {
            padding-left: 30px;
            line-height: 30px;
            display: flex;
            .menulist_cnode_chk {
              flex: 1;
              input {
                width: 40px;
              }
            }
            .menulist_cnode_name {
              color: #999;
              flex: 10;
            }
          }
        }
      }
    }
  }
}

button {
  background-color: #f5f5f5;
  margin-right: 10px;
}

.errinfo {
  text-align: center;
  color: red;
  font-size: 15px;
  padding-top: 10px;
  line-height: 30px;
  //   font-weight: 600;
}
</style>