<template>
  <div style="height: 100%;">
    <Toast ref="toast" />
    <div
      ref="curDiv"
      v-show="isshowcalender"
      style="z-index: 999; position: absolute"
    >
      <Calender ref="Calender" v-on:choseDay="clickDay"></Calender>
    </div>

    <div class="grid-form1" style="padding: 0">
      <div style="margin: 0; padding: 0; vertical-align: middle;height:120px;">
        <div class="search_condition">
          <div><label
            
            class="control-label"
            >团长：</label
          ></div>
          <div class="sltStyle">
            <select name="sltProductClass" v-model="productFClass">
              <option value="">所有团长</option>
              <option :value="item.membercode" v-for="(item,index) in classFList" :key="index">{{item.membername}}</option>
            </select>
          </div>
          
          
            <div>&nbsp;&nbsp;&nbsp;&nbsp;</div>
          

          <div class="bdate" >
          <label
            for="txtBDate"
            class="control-label"
            >下单日期从：</label
          >
          </div>
          <div class="bdate_value" >
            <input
              type="text"
              class="form-control1"
              id="txtBDate"
              name="txtBDate"
              readonly
              @click.stop="showcalender($event,'b')"
              v-model.trim="searchbdate"              
            />
          </div>
          <div class="edate" >
          <label
            for="txtEDate"
            class="control-label"
            >截止到</label
          >
          </div>
          <div class="edate_value">
            <input
              type="text"
              class="form-control1"
              id="txtEDate"
              name="txtEDate"
              readonly
              @click.stop="showcalender($event,'e')"
              v-model.trim="searchedate"
              
            />
          </div>
          <div>&nbsp;&nbsp;&nbsp;&nbsp;</div>
            <button class="btn btn-default" type="button" @click="clearSearch">
              <i class="fa fa-refresh" aria-hidden="true"></i>&nbsp;清空
            </button>
            <button
              class="btn btn-default"
              type="button"
              @click="search"
            >
              <i class="fa fa-search" aria-hidden="true"></i>&nbsp;搜索
            </button>
        </div>
        <div class="search_condition">
             <!-- <div class="blankdiv">&nbsp;&nbsp;&nbsp;&nbsp;</div> -->
            
            <!-- <div class="blankdiv">&nbsp;&nbsp;&nbsp;&nbsp;</div> -->
            <button
              class="btn btn-default"
              @click="checkAllList"
            >
              <i class="fa fa-check-square-o" aria-hidden="true"></i>&nbsp;一键全选
            </button>
            <button
              class="btn btn-default"
              @click="cancelAllList"
            >
              <i class="fa fa-square-o" aria-hidden="true"></i>&nbsp;一键取消
            </button>
            <button
              class="btn btn-default"
              type="button"
              @click="startCalc()"
            >
              <i class="fa fa-calculator" aria-hidden="true"></i>&nbsp;生成佣金结算单
            </button>
            
        </div>
        <div class="col-sm-12 " style="padding-top: 2px;padding-left:50px;">
            
        </div>
          
      </div>

    </div>
        <div class="orderlist" >
            
            
            <div class="order_item">
                <div class="productinfo_head" v-if="orderList.length>0">
                    <div class="productinfo_head_slt"></div>
                    <div class="productinfo_head_name">订单编号</div>
                    <div class="productinfo_head_class">团长</div>
                    <div class="productinfo_head_name">下单时间</div>
                    <div class="productinfo_head_img">订单状态</div>
                    <div class="productinfo_head_status">订单金额</div>
                    <!-- <div class="productinfo_head_btn">操作</div> -->
                </div>
                <div class="productinfo_head"  v-for="(item,index) in currentpagelist" :key="index">
                    <div class="productinfo_head_slt">
                        <input
                      type="checkbox"
                      :checked="item.checked"
                      @change="handItemChangeC(item.sno)"
                    />
                    </div>
                    <div class="productinfo_head_name">{{item.sno}}</div>
                    <div class="productinfo_head_class">{{item.membername}}</div>
                    <div class="productinfo_head_name">{{item.adddate}}</div>
                    <div class="productinfo_head_img">{{item.status}}</div>
                    <div class="productinfo_head_status">{{item.z_price}}</div>
                    <!-- <div class="productinfo_head_btn">
                        <button
                        class="btn btn-default"
                        type="button"
                        @click="putonProduct(item.id,'2')"
                        v-if="item.pstatus=='已下架'||item.pstatus=='待上架'"
                        >
                        <i class="fa fa-arrow-up" aria-hidden="true"></i>&nbsp;上架
                        </button>
                        <button
                        class="btn btn-default"
                        type="button"
                        @click="putonProduct(item.id,'3')"
                        v-if="item.pstatus=='已上架'"
                        >
                        <i class="fa fa-arrow-down" aria-hidden="true"></i>&nbsp;下架
                        </button>
                    </div> -->
                </div>
                
                
            </div>
             <div class="noresult" v-if="shownoresult">没有找到符合查询条件的订单信息！</div>
        </div>
       
      <div
        class="pagenum_info"
        v-if="!shownoresult"
      >
        <span class="btmInfo" style="vertical-align: middle"
          >&nbsp;&nbsp;共有 {{ recordcount }} 条记录，当前第
          {{ currentpage }}/{{ pagecount }} 页</span
        >
        <div style="float: right; padding-right: 30px">
          <button class="btn btn-default" type="button" @click="getNewPage(1)">
            首页
          </button>
          <button
            class="btn btn-default"
            type="button"
            @click="getNewPage(currentpage - 1)"
          >
            上页
          </button>
          <button
            class="btn btn-default"
            type="button"
            @click="getNewPage(currentpage + 1)"
          >
            下页
          </button>
          <button
            class="btn btn-default"
            type="button"
            @click="getNewPage(pagecount)"
          >
            尾页
          </button>
          <span class="btmInfo" style="margin-right: 2px">跳转到</span>
          <input
            type="text"
            size="1"
            id="txtGotoPage"
            autocomplete="off"
            v-int-only
            style="margin-right: 10px; font-size: 0.9em"
            v-model.trim="gotopage"
          />
          <button
            type="button"
            class="btn btn-default"
            @click="getNewPage(gotopage)"
          >
            跳转
          </button>
        </div>
      </div>
    </div>
  <!-- </div> -->
</template>

<script>
// import axios from "axios";
// import JsonExcel from 'vue-json-excel'
import Toast from "../../components/Toast";
import Calender from "../../components/vue-calendar-component";

export default {
  name: "uncalcedOrderList",
  components: {
    Toast,
    Calender
  },
  data() {
    return {
        
        
        productStatus:'',
        classFList:[],

        shownoresult:false,
        orderList: [],
        pagesize:20,
        currentIndex: -1,
        currentpage: 1,
        gotopage: "",
        productFClass: "",
        productCClass: "",
        isshowcalender: false,
        searchpname: "",
        show: false,
        isshow: false,
        win_height: "",
        currentSID: 0,
        currentDateS:"",
        searchedate:'',
        searchbdate:'',
        plistSlected:"0"
    };
  },
  methods: {
    // printDemo(row){
    //   // this.sheetdata={"name":"test","age":"13"}
    //   this.$refs.easyPrint.print()
    // },
    hideCalender() {
      this.isshowcalender = false;
    },
    clickDay(data) {
    //   this.searchyxqdate = data;
        // console.log(this.currentDateS)
      if(this.currentDateS==='b'){
          this.searchbdate=data
      }else{
          this.searchedate=data
      }
      this.isshowcalender = false;
    },
    showcalender(e,v) {
      var divC = this.$refs.curDiv;
      this.currentDateS=v
      divC.style.top = e.target.getBoundingClientRect().top + 40 + "px";
      divC.style.left = e.target.getBoundingClientRect().left + "px";

      this.isshowcalender = true;
    },
    startCalc(){
    
        let ids=""
        
        
        this.getPListSlt()
        ids=this.plistSlected
        if(this.plistSlected=='0'){
            this.$refs.toast.toast("请选择订单")
            return false    
        }
        
        if(confirm("您确认要对已选择的订单进行佣金的计算吗？")){
            this.uploadCalc(ids)
        }
    },
    async uploadCalc(ids){
        const res = await this.$post(this.GLOBAL.serverAddress + "/startCalc/", 
        {'snolist':ids});
        console.log(res)
        if(res.data){
            this.$refs.toast.toast("佣金计算完成")
            // this.clearSearch()
            setTimeout(()=>{
              this.$router.go(0)
            },1500)
            
            // 
        }else{
            this.$refs.toast.toast("计算失败请重试")
        }
    },
    getPListSlt(){
        let idlist="'GM'"
            
        this.orderList.forEach((v) => {
            if (v.checked) {
            idlist=idlist+",'"+v.sno + "'"
            }
        })
        this.plistSlected=idlist
        
    },
    // setCClass(){
    //     this.classFList.forEach((v)=>{
    //         if(v.fcid==this.productFClass){
    //             this.productCClass=""
    //             // this.classCList=""
    //             this.classCList=v.cclass
    //         }
    //     })
    //     // alert(this.productFClass)
    // },
    handItemChangeC(orderid) {
      let tmpList=this.orderList
      let findex = tmpList.findIndex((v) => v.sno === orderid)
      
      tmpList[findex].checked =!tmpList[findex].checked

      this.orderList = tmpList

    //   let i=0
    //   this.orderList.forEach((v) => {
    //     if (v.checked) {
    //       i=i+1
    //     }
    //   })
    //   this.selectedordernum=i

      // console.log(this.menulist)
    },
    // printOrder(){
      
    //   let tmpDom=this.$refs.print

    //   console.log(tmpDom)

    //   this.$print(tmpDom)
    // },
      viewOrderDetail(id){
          this.$router.push('/retailorderdetail?id='+id)
      },
      editOrder(id){
          this.$router.push('/updateretailorder?id='+id)
      },
      
    hideCalender() {
      this.isshowcalender = false;
    },
    clickDay(data) {
    //   this.searchyxqdate = data;
        // console.log(this.currentDateS)
      if(this.currentDateS==='b'){
          this.searchbdate=data
      }else{
          this.searchedate=data
      }
      this.isshowcalender = false;
    },
    showcalender(e,v) {
      var divC = this.$refs.curDiv;
      this.currentDateS=v
      divC.style.top = e.target.getBoundingClientRect().top + 40 + "px";
      divC.style.left = e.target.getBoundingClientRect().left + "px";

      this.isshowcalender = true;
    },

    clearSearch() {
      this.searchbdate = ""
      this.searchedate=""
      this.productFClass=""

      this.cancelAllList()
    },
    checkAllList(){
        this.orderList.forEach((v) => {
            v.checked= true
            }
        )
        // this.selectedordernum=this.recordcount
    },
    cancelAllList(){
        this.orderList.forEach((v) => {
            v.checked= false
            }
        )
        // this.selectedordernum=0
    },
    
    getNewPage(pagenum) {
      if (pagenum >= 1 && pagenum <= this.pagecount) {
        this.currentpage = pagenum;
        // console.log(this.pages)
        // return this.pages[this.currentpage - 1];
      }
    },
    async search() {
      this.currentpage=1
      let tmpClass=""
      if(this.productCClass!=''){
          tmpClass=this.productCClass
      }else{
          tmpClass=this.productFClass
      }
      const res = await this.$get(this.GLOBAL.serverAddress + "/getAllOrderUnCalc/", 
      {'membercode':this.productFClass,'bdate':this.searchbdate,'edate':this.searchedate});
    //   console.log(res);
        
      if (res.status === 200) {
        this.orderList = res.data;
        if(this.orderList.length>0){
            this.shownoresult=false
        }else{
            this.shownoresult=true
        }
      }
      const res2 = await this.$get(this.GLOBAL.serverAddress + "/getAllDMember/",{});
      console.log(res2);

      if (res2.status === 200) {
        this.classFList = res2.data;
      }

    },
  },
  computed: {
    currentpagelist() {
      return this.pages[this.currentpage - 1];
    },
    recordcount() {
      return this.orderList.length;
    },
    pagecount() {
      return Math.ceil(this.orderList.length / this.pagesize);
    },
    pages() {
      const pages = [];
      this.orderList.forEach((item, index) => {
        const page = Math.floor(index / this.pagesize);
        if (!pages[page]) {
          pages[page] = [];
        }
        pages[page].push(item);
      });
      return pages;
    },
  },
  mounted() {
    this.haveRights('|0404|')
    this.search();
    // this.addEnterListener();
    window.addEventListener("click", this.hideCalender);
    this.win_height = window.innerHeight;
  },
  beforeDestroy() {
    // 实例销毁之前对点击事件进行解绑
    window.removeEventListener("click", this.hideCalender);
  },
};
</script>

<style scoped lang="less">
.btnexport{
  padding-top: 10px;
  padding-left:50px;
  display:flex;
  align-items: center;
}
.printList{
  position: absolute;
  left:300px;
}
.bdate_value_time{
  color: #9c9c9c;
  border: 0px solid #9c9c9c;
}
.search_condition {
    display: flex;
    // white-space: nowrap;
    // justify-content:center;
    align-items: center;
    // text-align: right;
    padding:10px;
  .pname {
    //   display: flex;
    // flex: 1;
    padding-right: 5px;
  }

  .pname_value {
    // flex: 1;
  }
  .sltStyle{
    //   flex: 1;
  }
  .blankdiv{
      width:100px;
  }



}

.orderlist {
  color: #9c9c9c;

  // padding:10px;
  .order_item {
    //   width:100%;
    margin-top: 15px;
    background-color: #fff;
    // padding-top:15px;
    .productinfo_head{
        display: flex;
        padding: 10px;
        border-bottom: 1px solid #eee;
        justify-content: center;
        align-items: center;
        text-align: center;
        .productinfo_head_slt{
            flex:1;
        }
        .productinfo_head_id{
            flex:1;
        }
        .productinfo_head_img{
            flex:2;
            img {
            width: 90%;
            max-height: 50px;
            object-fit: contain;
          }
        }
        .productinfo_head_name{
            flex:6;
        }
        .productinfo_head_class{
            flex:2;
        }
        .productinfo_head_status{
            flex:2;
            display: flex;
            text-align: center;
            align-items: center;
            justify-content: center;
            .productinfo_head_status1{
                
                background-color:#9ACD32;
                border-radius:10px;
                color: #696969;;
                width: 60px;
                height: 30px;
                display: flex;
                align-items: center;
                justify-content: center;
                
            }
            .productinfo_head_status2{
                background-color:#BEBEBE;
                border-radius:10px;
                color: white;
                width: 60px;
                height: 30px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            .productinfo_head_status3{
                background-color:#00BFFF;
                border-radius:10px;
                color: white;
                width: 60px;
                height: 30px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
        .productinfo_head_btn{
            flex:2;
        }
    }
    
  }
}

.sltStyle {
    
    padding-left: 5px;
    color:#999;
    select{
      border: 1px solid #E9E9E9;
    font-size: 0.9em;
    outline: none;
    padding: 0.5em 1em;
    color: #999;
    }
  }

button {
  background-color: #f5f5f5;
  margin-right: 10px;
}
.btmInfo {
  background: none;
  border: none;
  outline: none;
  color: #333;
  font-size: 0.9em;
}
.noresult {
  text-align: center;
  font-size: 2em;
  color: rgb(160, 154, 154);
}
.pagenum_info{
    background:#fff;
    height: 50px;
    margin-top:20px;
    padding: 10px; color: #6c757d;
    // border-top: 1px dashed #9acd32;
}
.actived {
  background-color: rgba(190, 221, 140, 0.9);
}
table.dataintable {
  /* margin-top: 15px; */
  border-collapse: collapse;
  border: 1px solid white;
  width: 98%;
  font-size: 0.95em;
  background-color: #e5ebdc;
  line-height: 40px;
}
table.dataintable th {
  vertical-align: middle;
  /* padding:5px 15px 5px 6px; */
  background-color: #e8e8e8;
  border: 1px solid white;
  text-align: center;
  color: rgb(173, 169, 169);
}
table.dataintable td {
  vertical-align: text-top;
  /* padding:6px 15px 6px 6px; */
  border: 1px solid white;
  line-height: 35px;
}

.hidden_view {
  width: 100%;
  background: #698b22;
  opacity: 0.2;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 99;
}
</style>