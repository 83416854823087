<template>
  <transition name="show_view">
    <div class="screen_cent" v-show="isshow">
      <div class="screen_data" transiton="show_view">
        <table class="dataintable">
          <tr>
            <td style="width: 120px; height: 80px; vertical-align: middle">
              商品名称
            </td>
            <td style="vertical-align: middle">{{ currentSInfoPage.pname }}</td>
          </tr>
          <tr>
            <td>商品编码</td>
            <td>{{ currentSInfoPage.posn }}</td>
          </tr>
          <tr>
            <td>商品条码</td>
            <td>{{ currentSInfoPage.psn }}</td>
          </tr>
          <tr>
            <td>商品规格</td>
            <td>{{ currentSInfoPage.pguige }}</td>
          </tr>
          <tr>
            <td>批发单位</td>
            <td>
              <input type="text" v-model.trim="currentSInfoPage.pwsunit" />
            </td>
          </tr>
          <tr>
            <td>批发单价</td>
            <td>
              <input
                type="text"
                v-float-only
                v-model.trim="currentSInfoPage.pwsprice"
              />
            </td>
          </tr>
          <tr>
            <td>起购数量</td>
            <td>
              <input
                type="text"
                v-int-only
                v-model.trim="currentSInfoPage.minnum"
              />
            </td>
          </tr>
          <tr>
            <td>限购数量</td>
            <td>
              <input
                type="text"
                v-int-only
                v-model.trim="currentSInfoPage.maxnum"
              />
            </td>
          </tr>
          <tr>
            <td colspan="2">
                <div class="steppricelist">
                <div class="steppricetitle">如果有阶梯批发价格，请在下边填写，若没有则不需要填写。</div>
                <div class="stepprice_item" v-for="(s, index) in currentSInfoPage.steppricelist"
                  :key="index">
                    <div class="stepprice_item_num_title">批发数量</div>
                    <div class="stepprice_item_num">
                        <input
                      type="text"
                      class="form-control1"
                      :id="inputsetpnum(index)"
                      v-int-only
                      v-model.trim="currentSInfoPage.steppricelist[index].num"
                    />
                    </div>
                    <div class="stepprice_item_num_title2">及以上，价格为：</div>
                    <div class="stepprice_item_num2">
                        <input
                      type="text"
                      class="form-control1"
                      style="text-align:left;"
                      :id="inputsetpprice(index)"
                      v-float-only
                      v-model.trim="currentSInfoPage.steppricelist[index].price"
                    />
                    </div>
                </div>  
                </div>            
            </td>
          </tr>
        </table>
        <div class="detail_btnsave">
          <button class="btn btn-default" @click="cancelUpdate">
            <i class="fa fa-circle-thin" aria-hidden="true"></i>&nbsp; 取消
          </button>
          &nbsp;
          <button
            class="btn btn-default"
            @click="saveStorage"
            id="btnSave"
            :disabled="submitSaving"
          >
            <i class="fa fa-save" aria-hidden="true"></i>&nbsp;
            {{ submitSaving ? "更新中.." : "更新批发设置" }}
          </button>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import axios from "axios";
export default {
  name: "UpdateWSDetail",
  data() {
    return {
      submitSaving: false,
      currentSInfoPage:[]
    //   currentWSInfo: [
    //     {
    //       pid: "",
    //       pname: "",
    //       pwsunit: "",
    //       pwsprice: "",
    //       minnum: "0",
    //       maxnum: "0",
    //       pprice: "",
    //       opruser: this.GLOBAL.loginusername,
    //       pricestep: [
    //         { num: "", price: "" },
    //         { num: "", price: "" },
    //         { num: "", price: "" },
    //         { num: "", price: "" },
    //       ],
    //     },
    //   ],
    };
  },
  props: {
    currentSInfo: {
      type: [],
      required: true,
    },

    isshow: {
      type: Boolean,
      required: true,
    },
  },
  watch:{
      currentSInfo(n,o){
          if(n){
              this.currentSInfoPage=JSON.parse(JSON.stringify(this.currentSInfo))
              this.initStepPrie()
            //   console.log(this.currentSInfoPage.steppricelist)
          }
      }
  },
  methods: {
    inputsetpnum(id) {
      return "inputsetpnum" + id;
    },
    inputsetpprice(id) {
      return "inputsetpprice" + id;
    },
    cancelUpdate() {
      this.$emit("clickT");
    },
    checkInput() {
      let oprflag = false;
      let iCount = 0;
      if (this.currentSInfoPage.pwsunit === "") {
        alert("请输入批发单位！");
        return false;
      }
      if (this.currentSInfoPage.pwsprice === "") {
        alert("请输入商品的批发单价！");
        return false;
      }
      if (this.currentSInfoPage.minnum === "") {
        this.currentSInfoPage.minnum=0
      }
      if (this.currentSInfoPage.maxnum === "") {
        this.currentSInfoPage.maxnum=0
      }
      oprflag = true;
      return oprflag;
    },
    cancelUpdate() {
      this.$emit("clickT");
    },
    saveStorage() {
      if (this.checkInput()) {
        this.submitSaving = true;
        axios({
          method: "POST",
          headers: {
            // "Content-Type": "application/x-www-form-urlencoded",
            "Content-Type": "application/json;chartset=UTF-8",
            Authorization: "test",
          },
          data: this.currentSInfoPage,
          // data:this.currentSInfo,
          url: this.GLOBAL.serverAddress + "/updateWSInfo/",
        }).then(
          (response) => {
            this.$emit("clickT");
            this.submitSaving = false;
            alert("更新成功");
          },
          (error) => {
            console.log("出现错误", error.message);
          }
        );
      }
    },
    //处理回车时间，自动跳转到下一个输入框
    addEnterListener() {
      if (window.__completeEnterBind__) return;
      window.addEventListener("keydown", this.enterCallback);
      window.__completeEnterBind__ = true;
    },
    removeEnterListener() {
      window.removeEventListener("keydown", this.enterCallback);
      window.__completeEnterBind__ = false;
    },
    enterCallback(e) {
      function findFormItem(el) {
        const parent = el.parentElement;
        // console.log(parent);
        return parent;
        // if (!parent) return document.body;
        // if (
        //   parent.className.includes("form-group")
        //   // &&
        //   // parent.className.includes("el-form-item--small")
        // ) {
        //   return parent;
        // }
        // return findFormItem(parent);
      }
      function findInput(container) {
        let nextEl = container.nextElementSibling;
        // console.log(nextEl);
        if (!nextEl) {
          nextEl = container.parentElement.nextElementSibling;
        }
        // return;
        let input = nextEl.querySelector("input");
        // while (input.id === "el-select") {
        //   nextEl = nextEl.nextElementSibling;
        //   if (!nextEl) return;
        //   input = nextEl.querySelector("input");
        // }
        // if (input.className.includes("el-input__inner")) return input;
        return input;
      }
      // console.log(e.keyCode);
      if (e.keyCode === 13) {
        // console.log('5');
        const container = findFormItem(document.activeElement);
        findInput(container) && findInput(container).focus();
      }
    },
    initStepPrie(){
        let tmpL=this.currentSInfoPage.steppricelist.length
        for (let i = tmpL; i < 4; i++){
            this.currentSInfoPage.steppricelist.push({'num':'','price':''})
        }
    }
  },
  mounted() {
    this.addEnterListener();
     
  },
  destroy() {
    this.removeEnterListener();
  },
};
</script>

<style lang="less" scoped>
.steppricelist{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .stepprice_item{
        border-top: 1px solid rgb(122, 128, 122);
        width:90%;
        display: flex;
        justify-content: center;
        align-content: center;
        
        .stepprice_item_num_title{
            flex: 1;
        }
        .stepprice_item_num_title2{
            flex: 2;
        }
        .stepprice_item_num{
            flex: 1;
        }
        .stepprice_item_num2{
            flex: 2;
            text-align: left;
        }

    }

    

}
    

.infotable {
  border: 0px solid red;
  width: 95%;
  /* border-left:0; */
}
input {
  border: 0px solid #666;
  padding: 5px 8px;
  /* color: #616161; */
  width:90%;
  font-size: 0.85em;
  font-weight: 300;
  height: 30px;
  -webkit-appearance: none;
  outline: none;
  font-family: "Muli-Regular";
  background: none;
  box-shadow: none !important;
  text-align: center;
}
.search_page_list {
  width: 100%;
  position: relative;
}

.detail_btnsave {
  text-align: center;
  margin-top: 10px;
}
.hidden_view {
  width: 100%;
  background: #698b22;
  opacity: 0.2;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 99;
}

.screen_cent {
  width: 500px;
  height: 600px;
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 99;
  border-radius: 10px 0 0 10px;
  overflow: hidden;
}

.screen_data {
  padding: 20px;
  width: 100%;
  height: 100%;
  background: #ffffff;
}
.show_view-enter {
  animation: show_view-dialog-fade-in 0.2s ease;
}

.show_view-leave {
  animation: show_view-dialog-fade-out 0.2s ease forwards;
}

.show_view-enter-active {
  animation: show_view-dialog-fade-in 0.2s ease;
}

.show_view-leave-active {
  animation: show_view-dialog-fade-out 0.2s ease forwards;
}

@keyframes show_view-dialog-fade-in {
  0% {
    transform: translateX(500px);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes show_view-dialog-fade-out {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(500px);
  }
}

table.dataintable {
  /* margin-top: 15px; */
  border-collapse: collapse;
  border: 1px solid white;
  width: 98%;
  font-size: 0.95em;
  background-color: #e5ebdc;
  line-height: 40px;
}
table.dataintable th {
  vertical-align: middle;
  /* padding:5px 15px 5px 6px; */
  background-color: #e8e8e8;
  border: 1px solid white;
  text-align: center;
  color: rgb(173, 169, 169);
}
table.dataintable td {
  vertical-align: text-top;
  /* padding:6px 15px 6px 6px; */
  border: 1px solid white;
  line-height: 35px;
}
</style>