<template>
  <div class="menulist">
        <div
        class="menulist_node"
        v-for="(item, index) in menulist"
        :key="index"
        >
        <div class="menulist_fnode">
            <div class="menulist_fnode_chk">
            <input
                type="checkbox"
                :checked="item.checked"
                @change="handItemChange(item.menuid)"
            />
            </div>
            <div class="menulist_fnode_name">
            {{ item.menuname }}
            </div>
        </div>
        <div
            class="menulist_cnode"
            v-for="(itemc, indexc) in item.childmenu"
            :key="indexc"
        >
            <div class="menulist_cnode_chk">
            <input
                type="checkbox"
                :checked="itemc.checked"
                @change="handItemChangeC(item.menuid, itemc.menuid)"
            />
            </div>
            <div class="menulist_cnode_name">
            {{ itemc.menuname }}
            </div>
        </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "rightsList",
    props: {
        
    },
  data() {
    return {
      adminInfo:{},
      menulist: [
        {
          menuname: "库存管理",
          menuid: "|01|",
          checked: false,
          childmenu: [
            { menuname: "入库管理", menuid: "|0101|", checked: false },
            { menuname: "保质期提醒", menuid: "|0102|", checked: false },
            { menuname: "入库单查询", menuid: "|0103|", checked: false },
            { menuname: "库存查询", menuid: "|0104|", checked: false },
            { menuname: "商品核销", menuid: "|0105|", checked: false },
          ],
        },
        {
          menuname: "批发管理",
          menuid: "|02|",
          checked: false,
          childmenu: [
            { menuname: "采购的处理", menuid: "|0201|", checked: false },
            { menuname: "可批发产品配置", menuid: "|0202|", checked: false },
            { menuname: "客户管理", menuid: "|0203|", checked: false },
            { menuname: "批发统计", menuid: "|0204|", checked: false },
          ],
        },
        {
          menuname: "统计报表",
          menuid: "|03|",
          checked: false,
          childmenu: [
            { menuname: "零售销售汇总", menuid: "|0301|", checked: false },
            { menuname: "零售利润统计", menuid: "|0302|", checked: false },
            { menuname: "批发销售汇总", menuid: "|0303|", checked: false },
          ],
        },
        {
          menuname: "零售管理",
          menuid: "|05|",
          checked: false,
          childmenu: [
            { menuname: "待处理订单", menuid: "|0501|", checked: false },
            { menuname: "配货单/配送单", menuid: "|0502|", checked: false },
            { menuname: "商品列表", menuid: "|0503|", checked: false },
            { menuname: "代客下单", menuid: "|0504|", checked: false },
          ],
        },
        {
          menuname: "小团购管理",
          menuid: "|04|",
          checked: false,
          childmenu: [
            { menuname: "团长管理", menuid: "|0401|", checked: false },
            { menuname: "佣金政策维护", menuid: "|0402|", checked: false },
            { menuname: "不返佣商品维护", menuid: "|0403|", checked: false },
            { menuname: "生成结算单", menuid: "|0404|", checked: false },
            { menuname: "结算单", menuid: "|0405|", checked: false },
            { menuname: "团长业绩查询", menuid: "|0406|", checked: false },
          ],
        },
        {
          menuname: "小程序配置",
          menuid: "|09|",
          checked: false,
          childmenu: [
            { menuname: "轮播图", menuid: "|0901|", checked: false },
            { menuname: "首页分类导航", menuid: "|0902|", checked: false },
            { menuname: "预订须知", menuid: "|0903|", checked: false },
            { menuname: "弹窗广告", menuid: "|0904|", checked: false },
            { menuname: "首页公告", menuid: "|0905|", checked: false },
          ],
        },
        {
          menuname: "会员管理",
          menuid: "|08|",
          checked: false,
          childmenu: [
            { menuname: "会员列表", menuid: "|0801|", checked: false },
            { menuname: "充余额", menuid: "|080101|", checked: false },
            { menuname: "余额审批", menuid: "|0802|", checked: false },
          ],
        },
      ],
    };
  },
  props: {
    roles: {
      type: String,
      default:''
    },
    // toastText: {
    //   type: String,
    //   required: true,
    // },
  },
  watch: {
      roles:function(){
          this.initMenulist()
      }
  },
  methods: {
      handItemChange(menuid) {
      let menulist = this.menulist;
      let index = menulist.findIndex((v) => v.menuid === menuid);
      menulist[index].checked = !menulist[index].checked;
      if (menulist[index].checked) {
        menulist[index].childmenu.forEach((v) => {
          v.checked = true;
        });
      } else {
        menulist[index].childmenu.forEach((v) => {
          v.checked = false;
        });
      }
      this.menulist = menulist;
      this.$emit('changeRoles', this.menulist)
      // console.log(this.menulist)
    },
    handItemChangeC(fmenuid, cmenuid) {
      let menulist = this.menulist;
      let findex = menulist.findIndex((v) => v.menuid === fmenuid);
      let cindex = menulist[findex].childmenu.findIndex(
        (v) => v.menuid === cmenuid
      );
      menulist[findex].childmenu[cindex].checked =
        !menulist[findex].childmenu[cindex].checked;

      let vflag = false;
      menulist[findex].childmenu.forEach((v) => {
        if (v.checked) {
          vflag = true;
        }
      });
      if (vflag) {
        menulist[findex].checked = true;
      } else {
        menulist[findex].checked = false;
      }

      this.menulist = menulist;
      this.$emit('changeRoles', this.menulist)
      // console.log(this.menulist)
    },
      initMenulist(){
        //   console.log(this.roles)
        //   console.log("Oooooooooooooooo")
      // console.log(this.adminInfo.role.indexOf('|02|'))
      this.menulist.forEach((v) => {
        if(this.roles.indexOf(v.menuid)!=-1){
          v.checked=true
        }
        v.childmenu.forEach((v1) => {
          if(this.roles.indexOf(v1.menuid)!=-1){
            v1.checked=true
          }
        })
      })
    },
      
  },
  mounted() {
      this.initMenulist()
  }
}
</script>

<style scoped lang="less">
.menulist {
        width: 400px;
        display: flex;
        flex-direction: column;
        height: 280px;
        overflow: auto;
        border: 1px solid #eeeeee;
        .menulist_node {
          display: flex;
          flex-direction: column;
          // justify-content:space-around;
          // margin: auto;
          line-height: 30px;
          margin: 10px;
          .menulist_fnode {
            display: flex;
            .menulist_fnode_chk {
              flex: 1;
              input {
                width: 40px;
                line-height: 20px;
              }
            }
            .menulist_fnode_name {
              flex: 10;
            }
          }
          .menulist_cnode {
            padding-left: 30px;
            line-height: 30px;
            display: flex;
            .menulist_cnode_chk {
              flex: 1;
              input {
                width: 40px;
              }
            }
            .menulist_cnode_name {
              flex: 10;
            }
          }
        }
      }
.toast{
    position:fixed;
    z-index: 2000;
    left:50%;
    top:45%;
    transition:all .5s;
    -webkit-transform:translateX(-50%) translateY(-50%);
    -moz-transform:translateX(-50%) translateY(-50%);
    -ms-transform:translateX(-50%) translateY(-50%);
    -o-transform:translateX(-50%) translateY(-50%);
    transform:translateX(-50%) translateY(-50%);
    text-align: center;
    border-radius: 5px;
    color: #FFF;
    background: rgba(17,17,17,0.7);
    height: 45px;
    line-height:45px;
    padding:0 15px;
    max-width: 150px;
}
</style>