import Vue from 'vue'
import App from './App.vue'

import 'font-awesome/css/font-awesome.css'
import './assets/iconfont/iconfont.css'

import VueRouter from 'vue-router'

import router from './router/index'
import $ from 'jquery'
//引入全局变量
import global_ from './components/Global'

import {post,get,wxpost} from '../public/js/request'

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI)

Vue.config.productionTip = false
Vue.prototype.GLOBAL = global_ //挂在全局变量

import basefunc from './basefunc'
Vue.use(basefunc)

import Print from './components/print/print'
Vue.use(Print)

Vue.prototype.$post=post
Vue.prototype.$get=get
Vue.prototype.$wxpost=wxpost

// Vue.prototype.$mountComponent=function(Component, propsData){
//   const Ctor = Vue.extend(Component);
// 		const vm = new Ctor({ propsData: propsData }).$mount();
// 		return vm;
// }

Vue.directive(
  'intOnly', {
    bind(el) {
      el.handler = function () {
        el.value = el.value.replace(/\D+/g, "");
      };
      el.addEventListener("input", el.handler);
    },
    unbind(el) {
      el.removeEventListener("input", el.handler);
    },
  }
)
Vue.directive(
  'floatOnly', {
    bind(el) {
      el.handler = function () {
        el.value = el.value.replace(/[^\d.]/g, "");
        el.value = el.value.replace(/\.{2,}/, ".");
        el.value = el.value.replace(/^\./g, "0.");
      };
      el.addEventListener("input", el.handler);
    },
    unbind(el) {
      el.removeEventListener("input", el.handler);
    },
  }
)
Vue.directive(   
  'dateOnly', {
    bind(el) {
      el.handler = function () {
        el.value = el.value.replace(/[^\d-]/g, "");
        el.value = el.value.replace(/-{2,}/, ".");
        el.value = el.value.replace(/^-/g, "");
      };
      el.addEventListener("input", el.handler);
    },
    unbind(el) {
      el.removeEventListener("input", el.handler);
    },
  }
)


Vue.use(VueRouter)

new Vue({
  el:'#app',
  render: h => h(App),
  router: router,
  beforeCreate(){
    Vue.prototype.$bus = this //安装全局事件总线
    // router.beforeEach((to,from,next) => {
    //   if(to.meta.isNeedLogin){
    //     next({
    //       path:'/login',
    //       query:{
    //         redirect: to.fullPath
    //       }
    //     })
    //   }
    // })
  },
  
})
