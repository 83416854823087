import { render, staticRenderFns } from "./ClientAdd.vue?vue&type=template&id=5ae9e1e1&scoped=true&"
import script from "./ClientAdd.vue?vue&type=script&lang=js&"
export * from "./ClientAdd.vue?vue&type=script&lang=js&"
import style0 from "./ClientAdd.vue?vue&type=style&index=0&id=5ae9e1e1&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5ae9e1e1",
  null
  
)

export default component.exports