<template>
  <div style="height: 100%;">
    <Toast ref="toast" />
    <div
      ref="curDiv"
      v-show="isshowcalender"
      style="z-index: 999; position: absolute"
    >
      <Calender ref="Calender" v-on:choseDay="clickDay"></Calender>
    </div>
    
    <div class="grid-form1" style="padding: 0">
      <div style="margin: 0; padding: 0; vertical-align: middle;height:120px;">
        <div class="search_condition">
          <div><label
            
            class="control-label"
            >团长：</label
          ></div>
          <div class="sltStyle">
            <select name="sltProductClass" v-model="productFClass">
              <option value="">所有团长</option>
              <option :value="item.membercode" v-for="(item,index) in classFList" :key="index">{{item.membername}}</option>
            </select>
          </div>
          
          
            <div>&nbsp;&nbsp;&nbsp;&nbsp;</div>
          

          <div class="bdate" >
          <label
            for="txtBDate"
            class="control-label"
            >结算日期从：</label
          >
          </div>
          <div class="bdate_value" >
            <input
              type="text"
              class="form-control1"
              id="txtBDate"
              name="txtBDate"
              readonly
              @click.stop="showcalender($event,'b')"
              v-model.trim="searchbdate"              
            />
          </div>
          <div class="edate" >
          <label
            for="txtEDate"
            class="control-label"
            >截止到</label
          >
          </div>
          <div class="edate_value">
            <input
              type="text"
              class="form-control1"
              id="txtEDate"
              name="txtEDate"
              readonly
              @click.stop="showcalender($event,'e')"
              v-model.trim="searchedate"
              
            />
          </div>
          <div class="sltStyle">
              <label
            for="sltProductStatus"
            class="control-label"
            >支付状态：</label
          >
            <select name="sltProductStatus" id="sltProductStatus" v-model="productStatus">
              <option value="">所有</option>
              <option value="0">未支付</option>
              <option value="1">已支付</option>
            </select>
          </div>
          <div>&nbsp;&nbsp;&nbsp;&nbsp;</div>
            <button class="btn btn-default" type="button" @click="clearSearch">
              <i class="fa fa-refresh" aria-hidden="true"></i>&nbsp;清空
            </button>
            <button
              class="btn btn-default"
              type="button"
              @click="search"
            >
              <i class="fa fa-search" aria-hidden="true"></i>&nbsp;搜索
            </button>
            
        </div>
        <div class="search_condition">
             <!-- <div class="blankdiv">&nbsp;&nbsp;&nbsp;&nbsp;</div> -->
            
            <!-- <div class="blankdiv">&nbsp;&nbsp;&nbsp;&nbsp;</div> -->
            <button
              class="btn btn-default"
              @click="checkAllList"
            >
              <i class="fa fa-check-square-o" aria-hidden="true"></i>&nbsp;一键全选
            </button>
            <button
              class="btn btn-default"
              @click="cancelAllList"
            >
              <i class="fa fa-square-o" aria-hidden="true"></i>&nbsp;一键取消
            </button>
            <button
              class="btn btn-default"
              type="button"
              @click="payABill()"
            >
              <i class="fa fa-dollar" aria-hidden="true"></i>&nbsp;批量支付佣金
            </button>
            
        </div>
        <div class="col-sm-12 " style="padding-top: 2px;padding-left:50px;">
            
        </div>
          
      </div>

    </div>
        <div class="orderlist" >
            
            
            <div class="order_item">
                <div class="productinfo_head" v-if="orderList.length>0">
                    <div class="productinfo_head_slt"></div>
                    <div class="productinfo_head_slt">ID</div>
                    <div class="productinfo_head_name">团长</div>
                    <div class="productinfo_head_class">销售金额</div>
                    <div class="productinfo_head_class">应付佣金</div>
                    <div class="productinfo_head_name">结算时间</div>
                    <div class="productinfo_head_class">支付状态</div>
                    <div class="productinfo_head_name">支付时间</div>
                    <div class="productinfo_head_opr">操作</div>
                    <!-- <div class="productinfo_head_btn">操作</div> -->
                </div>
                <div class="productinfo_head"  v-for="(item,index) in currentpagelist" :key="index">
                    <div class="productinfo_head_slt">
                        <input
                      type="checkbox"
                      :checked="item.checked"
                      @change="handItemChangeC(item.id)"
                      v-if="item.ispaid=='未支付'"
                    />
                    </div>
                    <div class="productinfo_head_slt">{{item.id}}</div>
                    <div class="productinfo_head_name">{{item.membername}}</div>
                    <div class="productinfo_head_class">{{item.orderprice}}</div>
                    <div class="productinfo_head_class">{{item.commissions}}</div>
                    <div class="productinfo_head_name">{{item.cdate}}</div>
                    <div class="productinfo_head_class">{{item.ispaid}}</div>
                    <div class="productinfo_head_name">{{item.paydate}}</div>
                    <div class="productinfo_head_opr">
                        <button
                        class="btn btn-default"
                        type="button"
                        @click="payBill(item.id)"
                        v-if="item.ispaid=='未支付'"
                        >
                        <i class="fa fa-dollar" aria-hidden="true"></i>&nbsp;支付
                        </button>
                        <button
                        class="btn btn-default"
                        type="button"
                        @click="openList(item.id)"
                        
                        >
                        <i class="fa fa-list-ul" aria-hidden="true"></i>&nbsp;明细
                        </button><download-excel
                          
                          :fetch="fetchDataR"
                          :fields="jsonFieldsR"
                          :name="filenameR"
                        >
                          <button
                          class="btn btn-default"
                        type="button"
                        @click="setBID(item.membername,item.id)"
                        
                          ><i class="fa fa-table" aria-hidden="true"></i>&nbsp;导出明细</button>
                        </download-excel>
                    </div>
                </div>
                
                
            </div>
             <div class="noresult" v-if="shownoresult">没有找到符合查询条件的结算单！</div>
        </div>
       
      <div
        class="pagenum_info"
        v-if="!shownoresult"
      >
        <span class="btmInfo" style="vertical-align: middle"
          >&nbsp;&nbsp;共有 {{ recordcount }} 条记录，当前第
          {{ currentpage }}/{{ pagecount }} 页</span
        >
        <div style="float: right; padding-right: 30px">
          <button class="btn btn-default" type="button" @click="getNewPage(1)">
            首页
          </button>
          <button
            class="btn btn-default"
            type="button"
            @click="getNewPage(currentpage - 1)"
          >
            上页
          </button>
          <button
            class="btn btn-default"
            type="button"
            @click="getNewPage(currentpage + 1)"
          >
            下页
          </button>
          <button
            class="btn btn-default"
            type="button"
            @click="getNewPage(pagecount)"
          >
            尾页
          </button>
          <span class="btmInfo" style="margin-right: 2px">跳转到</span>
          <input
            type="text"
            size="1"
            id="txtGotoPage"
            autocomplete="off"
            v-int-only
            style="margin-right: 10px; font-size: 0.9em"
            v-model.trim="gotopage"
          />
          <button
            type="button"
            class="btn btn-default"
            @click="getNewPage(gotopage)"
          >
            跳转
          </button>
        </div>
      </div>
    </div>
  <!-- </div> -->
</template>

<script>
// import axios from "axios";
// import JsonExcel from 'vue-json-excel'
import Toast from "../../components/Toast";
import Calender from "../../components/vue-calendar-component";
import JsonExcel from 'vue-json-excel'

export default {
  name: "billList",
  components: {
    Toast,
    Calender,
    "download-excel":JsonExcel,
    
  },
  data() {
    return {
        
        
        productStatus:'',
        classFList:[],

        shownoresult:false,
        orderList: [],
        pagesize:20,
        currentIndex: -1,
        currentpage: 1,
        gotopage: "",
        productFClass: "",
        productCClass: "",
        isshowcalender: false,
        searchpname: "",
        show: false,
        isshow: false,
      isshowupdate:false,
        win_height: "",
        currentSID: 0,
        currentDateS:"",
        searchedate:'',
        searchbdate:'',
        plistSlected:"0",
        currentSInfo:[],
        jsonFieldsR: {  //导出Excel表格的表头设置
            '订单日期':'addtime',
            '订单编号':'sno',
            '商品名称': 'pname',
            '商品类别': 'fclass',
            '商品子类别': 'cclass',
            '商品总价': 'orderprice',
            '返佣比例': 'commissionvalue',
            '佣金数量': 'commissions',
            '备注': 'remarks'
        },
        filenameR:'',
        BID:'',
        BName:''
    };
  },
  methods: {
    setBID(name,id) {
      this.BID=id;
      this.BName=name;
    },
    async fetchDataR(){
      // this.$refs.toast.toast("未查到相关订单")
        this.filenameR=this.GLOBAL.getToday() + '_'+this.BName+'结算单.xls'
        
        const res = await this.$get(this.GLOBAL.serverAddress + "/getBillDetaisList/", 
        {'id':this.BID});
        console.log(res.data);
          
        if (res.status === 200) {
          if(res.data[0].length > 0){
            // setTimeout(()=>{
            //   this.$router.go(0)
            // },1500)  
            return res.data[0]
            // this.exportList=res.data
          }else{
            this.$refs.toast.toast("未查到相关订单")
            return false
          }
        }
      
      
    },
    // printDemo(row){
    //   // this.sheetdata={"name":"test","age":"13"}
    //   this.$refs.easyPrint.print()
    // },
    hideCalender() {
      this.isshowcalender = false;
    },
    clickDay(data) {
    //   this.searchyxqdate = data;
        // console.log(this.currentDateS)
      if(this.currentDateS==='b'){
          this.searchbdate=data
      }else{
          this.searchedate=data
      }
      this.isshowcalender = false;
    },
    showcalender(e,v) {
      var divC = this.$refs.curDiv;
      this.currentDateS=v
      divC.style.top = e.target.getBoundingClientRect().top + 40 + "px";
      divC.style.left = e.target.getBoundingClientRect().left + "px";

      this.isshowcalender = true;
    },
    payABill(){
        
        let ids=""
        
        
        this.getPListSlt()
        ids=this.plistSlected
        if(this.plistSlected=='0'){
            this.$refs.toast.toast("请选择结算单")
            return false    
        }
        
        if(confirm("您确认已完成选中结算单的佣金支付吗？确认后无法撤回。")){
            this.uploadCalc(ids)
        }
    },
    payBill(id){
        
        if(confirm("您确认已完成该结算单的佣金支付吗？确认后无法撤回。")){
            this.uploadCalc(id)
        }
    },
    async uploadCalc(ids){
        const res = await this.$post(this.GLOBAL.serverAddress + "/payBills/", 
        {'ids':ids});
        console.log(res)
        if(res.data){
            this.$refs.toast.toast("佣金支付完成")
            // this.clearSearch()
            setTimeout(()=>{
              this.$router.go(0)
            },1500)
            
            // 
        }else{
            this.$refs.toast.toast("支付失败请重试")
        }
    },
    getPListSlt(){
        let idlist="0"
            
        this.orderList.forEach((v) => {
            if (v.checked && v.ispaid=="未支付") {
            idlist=idlist+","+v.id
            }
        })
        this.plistSlected=idlist
        
    },
    // setCClass(){
    //     this.classFList.forEach((v)=>{
    //         if(v.fcid==this.productFClass){
    //             this.productCClass=""
    //             // this.classCList=""
    //             this.classCList=v.cclass
    //         }
    //     })
    //     // alert(this.productFClass)
    // },
    handItemChangeC(orderid) {
      let tmpList=this.orderList
      let findex = tmpList.findIndex((v) => v.id === orderid)
      
      tmpList[findex].checked =!tmpList[findex].checked

      this.orderList = tmpList

    //   let i=0
    //   this.orderList.forEach((v) => {
    //     if (v.checked) {
    //       i=i+1
    //     }
    //   })
    //   this.selectedordernum=i

      // console.log(this.menulist)
    },
    // printOrder(){
      
    //   let tmpDom=this.$refs.print

    //   console.log(tmpDom)

    //   this.$print(tmpDom)
    // },
      viewOrderDetail(id){
          this.$router.push('/retailorderdetail?id='+id)
      },
      editOrder(id){
          this.$router.push('/updateretailorder?id='+id)
      },
      
    hideCalender() {
      this.isshowcalender = false;
    },
    clickDay(data) {
    //   this.searchyxqdate = data;
        // console.log(this.currentDateS)
      if(this.currentDateS==='b'){
          this.searchbdate=data
      }else{
          this.searchedate=data
      }
      this.isshowcalender = false;
    },
    showcalender(e,v) {
      var divC = this.$refs.curDiv;
      this.currentDateS=v
      divC.style.top = e.target.getBoundingClientRect().top + 40 + "px";
      divC.style.left = e.target.getBoundingClientRect().left + "px";

      this.isshowcalender = true;
    },
    openList(pid){
      this.$router.push('/billdetaillist?id='+pid)
    },
    async getBillList(){

    },
    screen_hide_click() {
      // 隐藏筛选
      let that = this;
      setTimeout(function () {
        that.show = false;
      }, 300);
      that.isshow = false;
      that.isshowupdate = false;
    },
    clearSearch() {
      this.searchbdate = ""
      this.searchedate=""
      this.productFClass=""
      this.productStatus=""

      this.cancelAllList()
    },
    checkAllList(){
        this.orderList.forEach((v) => {
            v.checked= true
            }
        )
        // this.selectedordernum=this.recordcount
    },
    cancelAllList(){
        this.orderList.forEach((v) => {
            v.checked= false
            }
        )
        // this.selectedordernum=0
    },
    
    getNewPage(pagenum) {
      if (pagenum >= 1 && pagenum <= this.pagecount) {
        this.currentpage = pagenum;
        // console.log(this.pages)
        // return this.pages[this.currentpage - 1];
      }
    },
    async search() {
      this.currentpage=1
    //   let tmpClass=""
    //   if(this.productCClass!=''){
    //       tmpClass=this.productCClass
    //   }else{
    //       tmpClass=this.productFClass
    //   }
      const res = await this.$get(this.GLOBAL.serverAddress + "/getAllBillList/", 
      {'membercode':this.productFClass,'bdate':this.searchbdate,'edate':this.searchedate,'paystatus':this.productStatus});
    //   console.log(res);
        
      if (res.status === 200) {
        this.orderList = res.data;
        if(this.orderList.length>0){
            this.shownoresult=false
        }else{
            this.shownoresult=true
        }
      }
      const res2 = await this.$get(this.GLOBAL.serverAddress + "/getAllDMember/",{});
      console.log(res2);

      if (res2.status === 200) {
        this.classFList = res2.data;
      }

    },
  },
  computed: {
    currentpagelist() {
      return this.pages[this.currentpage - 1];
    },
    recordcount() {
      return this.orderList.length;
    },
    pagecount() {
      return Math.ceil(this.orderList.length / this.pagesize);
    },
    pages() {
      const pages = [];
      this.orderList.forEach((item, index) => {
        const page = Math.floor(index / this.pagesize);
        if (!pages[page]) {
          pages[page] = [];
        }
        pages[page].push(item);
      });
      return pages;
    },
  },
  mounted() {
    this.haveRights('|0405|')
    this.search();
    // this.addEnterListener();
    window.addEventListener("click", this.hideCalender);
    this.win_height = window.innerHeight;
  },
  beforeDestroy() {
    // 实例销毁之前对点击事件进行解绑
    window.removeEventListener("click", this.hideCalender);
  },
};
</script>

<style scoped lang="less">
.btnexport{
  padding-top: 10px;
  padding-left:50px;
  display:flex;
  align-items: center;
}
.printList{
  position: absolute;
  left:300px;
}
.bdate_value_time{
  color: #9c9c9c;
  border: 0px solid #9c9c9c;
}
.search_condition {
    display: flex;
    // white-space: nowrap;
    // justify-content:center;
    align-items: center;
    // text-align: right;
    padding:10px;
  .pname {
    //   display: flex;
    // flex: 1;
    padding-right: 5px;
  }

  .pname_value {
    // flex: 1;
  }
  .sltStyle{
    //   flex: 1;
  }
  .blankdiv{
      width:100px;
  }



}

.orderlist {
  color: #9c9c9c;

  // padding:10px;
  .order_item {
    //   width:100%;
    margin-top: 15px;
    background-color: #fff;
    // padding-top:15px;
    .productinfo_head{
        display: flex;
        padding: 10px;
        border-bottom: 1px solid #eee;
        justify-content: center;
        align-items: center;
        text-align: center;
        .productinfo_head_slt{
            flex:1;
        }
        .productinfo_head_id{
            flex:1;
        }
        .productinfo_head_img{
            flex:2;
            img {
            width: 90%;
            max-height: 50px;
            object-fit: contain;
          }
        }
        .productinfo_head_name{
            flex:5;
        }
        .productinfo_head_class{
            flex:2;
        }
        .productinfo_head_opr{
            display: flex;
            text-align: center;
            justify-content: center;
            flex:7;
        }
        .productinfo_head_status{
            flex:2;
            display: flex;
            text-align: center;
            align-items: center;
            justify-content: center;
            .productinfo_head_status1{
                
                background-color:#9ACD32;
                border-radius:10px;
                color: #696969;;
                width: 60px;
                height: 30px;
                display: flex;
                align-items: center;
                justify-content: center;
                
            }
            .productinfo_head_status2{
                background-color:#BEBEBE;
                border-radius:10px;
                color: white;
                width: 60px;
                height: 30px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            .productinfo_head_status3{
                background-color:#00BFFF;
                border-radius:10px;
                color: white;
                width: 60px;
                height: 30px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
        .productinfo_head_btn{
            flex:2;
        }
    }
    
  }
}

.sltStyle {
    
    padding-left: 5px;
    color:#999;
    select{
      border: 1px solid #E9E9E9;
    font-size: 0.9em;
    outline: none;
    padding: 0.5em 1em;
    color: #999;
    }
  }

button {
  background-color: #f5f5f5;
  margin-right: 10px;
}
.btmInfo {
  background: none;
  border: none;
  outline: none;
  color: #333;
  font-size: 0.9em;
}
.noresult {
  text-align: center;
  font-size: 2em;
  color: rgb(160, 154, 154);
}
.pagenum_info{
    background:#fff;
    height: 50px;
    margin-top:20px;
    padding: 10px; color: #6c757d;
    // border-top: 1px dashed #9acd32;
}
.actived {
  background-color: rgba(190, 221, 140, 0.9);
}
table.dataintable {
  /* margin-top: 15px; */
  border-collapse: collapse;
  border: 1px solid white;
  width: 98%;
  font-size: 0.95em;
  background-color: #e5ebdc;
  line-height: 40px;
}
table.dataintable th {
  vertical-align: middle;
  /* padding:5px 15px 5px 6px; */
  background-color: #e8e8e8;
  border: 1px solid white;
  text-align: center;
  color: rgb(173, 169, 169);
}
table.dataintable td {
  vertical-align: text-top;
  /* padding:6px 15px 6px 6px; */
  border: 1px solid white;
  line-height: 35px;
}

.hidden_view {
  width: 100%;
  background: #698b22;
  opacity: 0.2;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 99;
}
</style>