<template>
  <div style="height: 100%;">
    <Toast ref="toast" />
    <div class="grid-form1" style="padding: 0">
      <!-- <h4>问题类别维护</h4> -->
      <!-- <div class="col-md-12" style="margin: 0; padding: 0"> -->
      <div style="margin: 0; padding: 0; vertical-align: middle">
        
        
        <div class="form-group" style="vertical-align: middle">
            <div class="search_condition">
            
            <div class="">&nbsp;&nbsp;
            <label
              for="txtNiCheng"
              class="control-label"
              style="font-weight: bold;font-size:1.2em;"
              >以下是店铺 【{{dpname}}】 的成员绑定及订单汇总信息：</label
            >
          </div>
            </div>
            <div class="search_condition">
          
          <div class="">&nbsp;&nbsp;
            <label
              for="txtNiCheng"
              class="control-label"
              >微信昵称：</label
            >
          </div>
          <div class="">
            <input
              type="text"
              class="form-control1"
              id="txtNiCheng"
              name="txtContractNumber"
              style="width:200px;"
              v-model.trim="wxname"              
            />
          </div>
          <div>&nbsp;&nbsp;&nbsp;&nbsp;</div>
        
            
            <button
              class="btn btn-default"
              type="button"
              @click="getProductList"
            >
              <i class="fa fa-search" aria-hidden="true"></i>&nbsp;查询成员信息
            </button>
            <button
              class="btn btn-default"
              type="button"
              @click="goBack"
            >
              <i class="fa fa-level-up" aria-hidden="true"></i>&nbsp;返回所有团长业绩列表
            </button>
            </div>
          <div
        class="tab-content"
        style=" margin-top: 5px; padding: 0"
      >
        
      </div>
      
          
          
            
          
          
        </div>
      </div>
      
    </div>

    <div class="grid-form1" >
      <div
        class="tab-content"
        style=" margin-top: 5px; padding: 0"
      >
        <table class="dataintable" v-if="productList.length > 0">
          <tr style="font-weight: bold">
            <th width="100px" >会员ID</th>
            <th >店铺会员微信</th>      
            <th width="60px">头像</th>      
            <th width="180px">是否解除绑定</th>
            <th width="100px">订单总数</th>
            <th width="150px">购物金额</th>
            <!-- <th width="80px">操作</th> -->
          </tr>
          <tr
            v-for="(s, index) in currentpagelist"
            :key="index"
            @mouseover="mouseOver(index)"
            @mouseout="mouseOut"
            @click="openMemberOrderList(s.user_id,s.ordercount, s.dpname,dpcode,s.wxname)"
            :class="{ actived: currentIndex == index }"
          >
            <td>{{ s.user_id }}</td>
            <td>{{ s.wxname }}</td>
            <td><img :src="s.imgurl" style="height:60px; width:60px"></td>
            <td>{{ s.stillbind==1?"否":"是" }}</td>
            <td><div  style="color:black;">{{ s.ordercount }}</div></td>
            <td><div  style="color:red;">{{ s.orderprice }}</div></td>

            <!-- <td style="vertical-align: middle">
              &nbsp;&nbsp;
              <a @click.stop="delExcept(s.id)" title="删除该店铺" 
                ><i class="fa fa-trash" aria-hidden="true"></i
              ></a>
            </td> -->
          </tr>
        </table>
        <div v-if="noResultFlag" class="noresult">没有找到符合查询条件的成员信息！</div>
      </div>
      <div
        class="center_bottom"
        style="padding: 5px; color: #6c757d; border-top: 1px dashed #9acd32"
        v-if="!noResultFlag"
      >
        <span class="btmInfo" style="vertical-align: middle"
          >&nbsp;&nbsp;共有 {{ recordcount }} 条记录，当前第
          {{ currentpage }}/{{ pagecount }} 页</span
        >
        <div style="float: right; padding-right: 30px">
          <button class="btn btn-default" type="button" @click="getNewPage(1)">
            首页
          </button>
          <button
            class="btn btn-default"
            type="button"
            @click="getNewPage(currentpage - 1)"
          >
            上页
          </button>
          <button
            class="btn btn-default"
            type="button"
            @click="getNewPage(currentpage + 1)"
          >
            下页
          </button>
          <button
            class="btn btn-default"
            type="button"
            @click="getNewPage(pagecount)"
          >
            尾页
          </button>
          <span class="btmInfo" style="margin-right: 2px">跳转到</span>
          <input
            type="text"
            size="1"
            id="txtGotoPage"
            autocomplete="off"
            v-int-only
            style="margin-right: 10px; font-size: 0.9em"
            v-model.trim="gotopage"
          />
          <button
            type="button"
            class="btn btn-default"
            @click="getNewPage(gotopage)"
          >
            跳转
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

import WSDetail from "../../components/WSDetail";
import UpdateWSDetail from "../../components/UpdateWSDetail";
import Toast from "../../components/Toast";

export default {
  name: "memberorderList",
  components: {
        
        WSDetail,
        UpdateWSDetail,
        Toast
  },
  data() {
    return {
        etype:'',
        productStatus:'',
        dpname:'',
        // classFList:[],
        // classCList:[],
        // productFClass: "",
        // productCClass: "",
        classshow:false,
        productshow:false,
      productList: [],
    //   productList2: [],
      currentIndex: -1,
    //   currentIndex2: -1,
      currentpage: 1,
    //   currentpage2: 1,
      gotopage: "",
    //   gotopage2: "",
      membername: "",
      membercode: "",
      dpid:"",
      dpcode:"",
      dpname:"",
      wxname:"",
      membercontactname:"",
    //   isshowcalender: false,
      show: false,
      isshow: false,
      isshowupdate:false,
      win_height: "",
      currentSID: 0,
      currentSInfo: [],
      noResultFlag:false,
    //   noResultFlag2:false
    };
  },
  methods: {
      goBack(){
      this.$router.go(-1)
    },
      
    
    
    openMemberOrderList(userid,ordercount,dpname,dpcode,wxname){
        if(ordercount>0){
            this.$router.push("/dporderlist?userid=" + userid+ "&dpname=" + dpname+ "&wxname=" + wxname + "&dpcode=" + dpcode);
        }
    },
    
    clearSearch() {
      this.searchpname = "";
      this.searchpsn = "";
    },
    mouseOver(index) {
      // alert(index)
      this.currentIndex = index;
    },
    mouseOut() {
      // alert(index)
      this.currentIndex = -1;
    },
    
    getNewPage(pagenum) {
      if (pagenum >= 1 && pagenum <= this.pagecount) {
        this.currentpage = pagenum;
        // return this.pages[this.currentpage - 1];
      }
    },
    
    
    async getProductList() {
        // this.etype===""
        // this.productshow=false
        // this.classshow=false
        // this.membername=""
        // this.membercode=""
      //   if (days) {
      axios({
        method: "get",
        params: {
          wxname: this.wxname,
          dpid:this.dpid,
          dpcode:this.dpcode
        },
        url: this.GLOBAL.serverAddress + "/getMemberAndOrderList/",
      }).then(
        (response) => {
          this.productList = response.data;
          if(this.productList.length > 0){
              this.noResultFlag=false
          }else{
              this.noResultFlag=true
              
          }
        },
        (error) => {
          console.log("出现错误", error.message);
          return false;
        }
      );

    //   const res2 = await this.$get(this.GLOBAL.serverAddress + "/getAllProductClass/",{});
    //   console.log(res2);

    //   if (res2.status === 200) {
    //     this.classFList = res2.data;
    //   }
      //   }
    },
    // async getReadyMember() {
    //     this.currentpage=1
    //     // this.etype===""
    //     // this.productshow=false
    //     // this.classshow=false
    //     // this.wxname=""
    //     // this.membername=""
    //   //   if (days) {
    //   axios({
    //     method: "get",
    //     params: {
    //       wxname: this.wxname,
    //       membercontactname: this.membercontactname,
    //     },
    //     url: this.GLOBAL.serverAddress + "/getReadyDMember/",
    //   }).then(
    //     (response) => {
    //       this.productList2 = response.data;
    //       if(this.productList2.length > 0){
    //           this.noResultFlag2=false
    //       }else{
    //           this.noResultFlag2=true
              
    //       }
    //     },
    //     (error) => {
    //       console.log("出现错误", error.message);
    //       return false;
    //     }
    //   );

    //   //   }
    // },
  },
  computed: {
    currentpagelist() {
      return this.pages[this.currentpage - 1];
    },
    currentpagelist2() {
      return this.pages2[this.currentpage2 - 1];
    },
    recordcount() {
      return this.productList.length;
    },
    recordcount2() {
      return this.productList2.length;
    },
    pagecount() {
      return Math.ceil(this.productList.length / 15);
    },
    pagecount2() {
      return Math.ceil(this.productList2.length / 10);
    },
    pages() {
      const pages = [];
      this.productList.forEach((item, index) => {
        const page = Math.floor(index / 15);
        if (!pages[page]) {
          pages[page] = [];
        }
        pages[page].push(item);
      });
      return pages;
    },
    pages2() {
      const pages = [];
      this.productList2.forEach((item, index) => {
        const page = Math.floor(index / 10);
        if (!pages[page]) {
          pages[page] = [];
        }
        pages[page].push(item);
      });
      return pages;
    },
  },
  mounted() {
    this.haveRights('|0406|')
    this.dpid=this.$route.query.dpid
    this.dpcode=this.$route.query.dpcode
    this.dpname=this.$route.query.dpname

    this.getProductList();
    // this.getReadyMember()
    // window.addEventListener("click", this.hideCalender);
    this.win_height = window.innerHeight;
  },
  beforeDestroy() {
    // 实例销毁之前对点击事件进行解绑
    // window.removeEventListener("click", this.hideCalender);
  },
};
</script>

<style scoped lang="less">
.search_condition {
    display: flex;
    // white-space: nowrap;
    // justify-content:center;
    align-items: center;
    // text-align: right;
    padding:10px;
  .pname {
    //   display: flex;
    flex: 1;
    padding-right: 5px;
    white-space: nowrap;
  }

  .pname_value {
    flex: 3;
  }
  .sltStyle{
    //   flex: 1;
  }
  .blankdiv{
      width:100px;
  }



}
button {
  background-color: #f5f5f5;
  margin-right: 10px;
}
.btmInfo {
  background: none;
  border: none;
  outline: none;
  color: #333;
  font-size: 0.9em;
}
.noresult {
  text-align: center;
  font-size: 2em;
  color: rgb(160, 154, 154);
}
.actived {
  background-color: rgba(190, 221, 140, 0.9);
}
table.dataintable {
  /* margin-top: 15px; */
  border-collapse: collapse;
  border: 1px solid white;
  width: 98%;
  font-size: 0.95em;
  background-color: #e5ebdc;
  line-height: 40px;
}
table.dataintable th {
  vertical-align: middle;
  /* padding:5px 15px 5px 6px; */
  background-color: #e8e8e8;
  border: 1px solid white;
  text-align: center;
  color: rgb(173, 169, 169);
}
table.dataintable td {
  vertical-align: text-top;
  /* padding:6px 15px 6px 6px; */
  border: 1px solid white;
  line-height: 35px;
  div{
      font-size:1.3em;
      cursor: pointer;
  }
}


.hidden_view {
  width: 100%;
  background: #698b22;
  opacity: 0.2;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 99;
}
.sltStyle {
    
    padding-left: 5px;
    color:#999;
    select{
      border: 1px solid #E9E9E9;
    font-size: 0.9em;
    outline: none;
    padding: 0.5em 1em;
    color: #999;
    }
  }
</style>