<template>
  <div class="copy">
          <p>
            &copy; {{thisYear}} Foodigo. All Rights Reserved 
          </p>
        </div>
</template>

<script>
export default {
    name: "CopyRight",
    computed:{
      thisYear:{
        get(){ 
          let d = new Date()
          return d.getFullYear()
        }
      }
    }
}
</script>

<style>

</style>