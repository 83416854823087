<template>
  <div style="height: 100%;">
    <Toast ref="toast" />
    <div
      class="hidden_view"
      :style="{ height: win_height + 'px' }"
      v-show="show"
      @click="screen_hide_click"
    ></div>
    <MoneyHistory :currentSInfo="currentSInfo" :currentUserName="currentUserName"  :isshow="isshowupdate" @clickT="screen_hide_click"/>  
    <div class="grid-form1" style="padding: 0">
        <div style="margin: 0; padding: 0; vertical-align: middle">
        <!-- <form
                class="form-horizontal"
                action=""
                id="frmSRList"
                method="POST"
              > -->
        <div class="form-group" style="vertical-align: middle;margin-top:10px;">
          <div>
            <label
              for="txtContractNumber"
              class="col-sm-1 control-label"
              style="padding-top: 10px; text-align: right"
              >会员信息</label
            >
          </div>
          <div class="col-sm-3" style="vertical-align: middle">
            <input
              type="text"
              class="form-control1"
              id="txtContractNumber"
              name="txtContractNumber"
              placeholder="会员昵称/会员ID"
              v-model.trim="wxname"
              @keyup.enter="getReadyMember"
            />
            
          </div>
          <div >
              <button class="btn btn-default" type="button" @click="clearSearch">
              <i class="fa fa-refresh" aria-hidden="true"></i>&nbsp;清空
            </button>
            <button
              class="btn btn-default"
              type="button"
              @click="getReadyMember"
            >
              <i class="fa fa-search" aria-hidden="true"></i>&nbsp;搜索
            </button>
          </div>
        </div>
        <!-- </form> -->
      </div>
      <!-- </div> -->

      
    </div>
    <div class="grid-form1" style="padding: 0">
        
      <!-- <h4>问题类别维护</h4> -->
      <!-- <div class="col-md-12" style="margin: 0; padding: 0"> -->
      <div style="margin: 0; padding: 0; vertical-align: middle">
        <!-- <form
                class="form-horizontal"
                action=""
                id="frmSRList"
                method="POST"
              > -->
        
        <div class="form-group" style="vertical-align: middle">
            
          <div
        class="tab-content"
        style=" margin-top: 5px; padding: 0"
      >
            <div class="order_item_briefinfo">
                <!-- <div class="order_item_briefinfo_orderchk">
                    &nbsp;&nbsp;
                </div> -->
                <div class="order_item_briefinfo1">会员ID</div>
                <div class="order_item_briefinfo2">会员头像</div>
                <div class="order_item_briefinfo3">会员昵称</div>
                <div class="order_item_briefinfo4">账户余额</div>
                <!-- <div class="order_item_briefinfo4">Credit</div> -->
                <div class="order_item_briefinfo5">有效订单</div>
                <div class="order_item_briefinfo6">交易金额</div>
                <div class="order_item_briefinfo7">所属店铺</div>
                <div class="order_item_briefinfo8">操作</div>
            </div>
            <div class="order_item_briefinfo" v-for="(s, index) in currentpagelist2"
            :key="index"
            >
                <!-- <div class="order_item_briefinfo_orderchk">
                    &nbsp;&nbsp;
                </div> -->
                <div class="order_item_briefinfo1">{{ s.userid }}</div>
                <div class="order_item_briefinfo2"><img :src="s.imgurl" style="height:60px; width:60px"></div>
                <div class="order_item_briefinfo3">{{ s.username }}</div>
                <div class="order_item_briefinfo4">
                  <a @click.stop="openMoneyInfo(s.userid,s.username)" title="浏览余额记录"
                >{{ s.money }}</a></div>
                <!-- <div class="order_item_briefinfo4">{{ s.credit }}</div> -->
                <div class="order_item_briefinfo5">{{ s.orders }}</div>
                <div class="order_item_briefinfo6">{{ s.orderprice }}</div>
                <div class="order_item_briefinfo7">{{ s.membername }}</div>
                <div class="order_item_briefinfo8">
                    <div v-if="s.showopr">
                        <input
                        type="text"
                        placeholder="请输入大于0的数量"
                        class="form-control1"
                        id="txtContractNumber"
                        name="txtContractNumber"
                        v-model.trim="s.oprvalue"
                        />
                    </div>
                    <div class="sltStyle" v-if="s.showopr" >
                        <select name="sltPayStatus" v-model="s.oprtype">
                        <option value="">操作类别</option>
                        <option value="5">退款</option>
                        <option value="14">充值</option>
                        <option value="11">扣款</option>
                        </select>
                    </div>
                    <div v-if="canDo('|0802|') && !s.showopr" class="opr_btn_style">
                        <button class="btn btn-default btn-now"  type="button" @click="chargeMoney(s.userid,1)">
                        余额操作
                        </button>
                        <!-- <div @click.stop="chargeMoney(s.userid,1)" >余额操作</div> -->
                    </div>
                    <div class="opr_btn_style" v-if="canDo('|080101|') && s.showopr">
                        <button class="btn btn-default btn-now"  type="button" @click.stop="submitMoney(s.userid)">提交审核</button>
                        <button class="btn btn-default btn-now"  type="button" @click.stop="chargeMoney(s.userid,0)">取消</button>
                    </div>
                </div>
            </div>
        
        <div v-if="noResultFlag2" class="noresult">没有找到符合查询条件的会员信息！</div>
      </div>
      <div
        class="center_bottom"
        style="padding: 5px; color: #6c757d; border-top: 1px dashed #9acd32"
        v-if="!noResultFlag2"
      >
        <span class="btmInfo" style="vertical-align: middle"
          >&nbsp;&nbsp;共有 {{ recordcount2 }} 条记录，当前第
          {{ currentpage2 }}/{{ pagecount2 }} 页</span
        >
        <div style="float: right; padding-right: 30px">
          <button class="btn btn-default" type="button" @click="getNewPage2(1)">
            首页
          </button>
          <button
            class="btn btn-default"
            type="button"
            @click="getNewPage2(currentpage2 - 1)"
          >
            上页
          </button>
          <button
            class="btn btn-default"
            type="button"
            @click="getNewPage2(currentpage2 + 1)"
          >
            下页
          </button>
          <button
            class="btn btn-default"
            type="button"
            @click="getNewPage2(pagecount2)"
          >
            尾页
          </button>
          <span class="btmInfo" style="margin-right: 2px">跳转到</span>
          <input
            type="text"
            size="1"
            id="txtGotoPage"
            autocomplete="off"
            v-int-only
            style="margin-right: 10px; font-size: 0.9em"
            v-model.trim="gotopage2"
          />
          <button
            type="button"
            class="btn btn-default"
            @click="getNewPage2(gotopage2)"
          >
            跳转
          </button>
        </div>
      </div>
          
          
          
        </div>
      </div>
      
    </div>

  </div>
</template>

<script>
import axios from "axios";

// import WSDetail from "../../components/WSDetail";
// import UpdateWSDetail from "../../components/UpdateWSDetail";
import Toast from "../../components/Toast";
import MoneyHistory from "../../components/MoneyHistory";

export default {
  name: "allmember",
  components: {
        
        // WSDetail,
        // UpdateWSDetail,
        MoneyHistory,
        Toast
  },
  data() {
    return {
        etype:'',
        productStatus:'',
        // classFList:[],
        // classCList:[],
        // productFClass: "",
        // productCClass: "",
        classshow:false,
        productshow:false,
    //   productList: [],
      productList2: [],
    //   currentIndex: -1,
      currentIndex2: -1,
    //   currentpage: 1,
      currentpage2: 1,
    //   gotopage: "",
      gotopage2: "",
      membername: "",
      membercode: "",
      wxname:"",
      membercontactname:"",
    //   isshowcalender: false,
      show: false,
      isshow: false,
      isshowupdate:false,
      win_height: "",
      currentUserName:"",
      currentSID: 0,
      currentSInfo: [],
    //   noResultFlag:false,
      noResultFlag2:false,
      logedinuser:''
    };
  },
  methods: {
    openMoneyInfo(pid,username){
      this.currentSID = pid;
      this.currentUserName = username;
      this.show = true;
      this.isshowupdate = true;

      this.currentSInfo = this.getSDetail();
    },
      getMemberInfo(mname,mcode){
          this.membername=mname;
          this.membercode=mcode;
      },
    setupWS(){
        this.$router.push('/setupwholesale')
    },
    async getSDetail() {
      const res = await this.$get(
        this.GLOBAL.serverAddress + "/getUserMoneyInfo/",
        {'id':this.currentSID}
      );
      if(res.status===200){
        console.log(res.data);
        this.currentSInfo=res.data;
      }
      
    },
    
    
    // screen_click(pid) {
    //   // 显示筛选
    //   this.currentSID = pid;
    //   this.show = true;
    //   this.isshow = true;

    //   // this.currentSInfo = 
    //   this.getSDetail();

    //   //  console.log(this.currentSInfo)
    // },
    screen_hide_click() {
      // 隐藏筛选
      let that = this;
      setTimeout(function () {
        that.show = false;
      }, 300);
      that.isshow = false;
      that.isshowupdate = false;
    },

    async submitMoney(id){
        
        let findex = this.currentpagelist2.findIndex((v) => v.userid === id)
        
        let tmpMoney=this.currentpagelist2[findex].oprvalue
        let tmpOprType=this.currentpagelist2[findex].oprtype
        if(tmpMoney==''){
            this.$refs.toast.toast("请输入数量")
            return false  
        }
        if(tmpOprType==''){
            this.$refs.toast.toast("请选择操作类别")
            return false  
        }

        if(this.isNumber(tmpMoney)&&tmpMoney>0){
            
            
            if(confirm("您确认要提交对该会员的余额数据操作吗？确认后等待审核，审核通过后会员余额数据才会发生改变。")){
                const res=await this.$post(this.GLOBAL.serverAddress + "/submitMoneyAudit/",{id:id,money:tmpMoney,opruser:this.logedinuser,oprtype:tmpOprType})
                if(res.data){
                    this.$refs.toast.toast("提交成功")
                    
                    this.$router.go(0)
                    
                }else{
                    this.$refs.toast.toast(res.data)
                    return
                }
            }
   
        }else{
            this.$refs.toast.toast("请输入正确数量")
            return false  
        }

    },
    chargeMoney(id,status){
        
        let findex = this.currentpagelist2.findIndex((v) => v.userid === id)
        
        this.currentpagelist2[findex].showopr =status
        this.currentpagelist2[findex].oprvalue=''

    },
    // isNumber(val) {
    //   var regPos = /^\d+(\.\d+)?$/; //非负浮点数
    //   var regNeg = /^(-(([0-9]+\.[0-9]*[1-9][0-9]*)|([0-9]*[1-9][0-9]*\.[0-9]+)|([0-9]*[1-9][0-9]*)))$/; //负浮点数
    //   if(regPos.test(val) || regNeg.test(val)) {
    //       return true;
    //   } else {
    //       return false;
    //   }
    // },
    clearSearch() {
      this.wxname = "";
    },
    
    getNewPage2(pagenum) {
      if (pagenum >= 1 && pagenum <= this.pagecount2) {
        this.currentpage2 = pagenum;
        // return this.pages[this.currentpage - 1];
      }
    },

    async saveExcept() {
        if(this.membercode===""){
            this.$refs.toast.toast("请选择团长")
            return false    
        }
        if(this.membername===""){
            this.$refs.toast.toast("请输入店铺名称")
            return false    
        }
        // if(this.membercode.length>8){
        //     this.$refs.toast.toast("代码超8个字符")
        //     return false
        // }
        
        const res=await this.$post(this.GLOBAL.serverAddress + "/saveMemberInfo/",{membercode:this.membercode,membername:this.membername})
        if(res.data==""){
            this.$refs.toast.toast("添加成功")
            this.getProductList()
        }else{
            this.$refs.toast.toast(res.data)
            return
        }
        // console.log(res)
        // if(res.data){
        //     this.$router.go(-1)
        //     // this.$refs.toast.toast("备注更新成功")
        // }else{
        //     this.$refs.toast.toast("设置失败，请重新尝试")
        // }
        // console.log(res)
        
    },
    
    
    async getReadyMember() {
        this.currentpage2=1
        // this.etype===""
        // this.productshow=false
        // this.classshow=false
        // this.wxname=""
        // this.membername=""
      //   if (days) {
      axios({
        method: "get",
        params: {
          wxname: this.wxname,
          membercontactname: this.membercontactname,
        },
        url: this.GLOBAL.serverAddress + "/getAllMemberList/",
      }).then(
        (response) => {
          this.productList2 = response.data;
          if(this.productList2.length > 0){
              this.noResultFlag2=false
          }else{
              this.noResultFlag2=true
              
          }
        },
        (error) => {
          console.log("出现错误", error.message);
          return false;
        }
      );

      //   }
    },
  },
  computed: {
    // currentpagelist() {
    //   return this.pages[this.currentpage - 1];
    // },
    currentpagelist2() {
      return this.pages2[this.currentpage2 - 1];
    },
    // recordcount() {
    //   return this.productList.length;
    // },
    recordcount2() {
      return this.productList2.length;
    },
    // pagecount() {
    //   return Math.ceil(this.productList.length / 15);
    // },
    pagecount2() {
      return Math.ceil(this.productList2.length / 10);
    },
    // pages() {
    //   const pages = [];
    //   this.productList.forEach((item, index) => {
    //     const page = Math.floor(index / 15);
    //     if (!pages[page]) {
    //       pages[page] = [];
    //     }
    //     pages[page].push(item);
    //   });
    //   return pages;
    // },
    pages2() {
      const pages = [];
      this.productList2.forEach((item, index) => {
        const page = Math.floor(index / 10);
        if (!pages[page]) {
          pages[page] = [];
        }
        pages[page].push(item);
      });
      return pages;
    },
  },
  mounted() {
    this.haveRights('|0801|')
    // this.getProductList();
    this.getReadyMember()
    this.logedinuser=sessionStorage.getItem("loginuser")
    // window.addEventListener("click", this.hideCalender);
    this.win_height = window.innerHeight;
  },
  beforeDestroy() {
    // 实例销毁之前对点击事件进行解绑
    // window.removeEventListener("click", this.hideCalender);
  },
};
</script>

<style scoped lang="less">
.btn-now{
    padding: 5px 10px;
    border-radius:5px;
}
.search_condition {
    display: flex;
    // white-space: nowrap;
    // justify-content:center;
    align-items: center;
    // text-align: right;
    padding:10px;
  .pname {
    //   display: flex;
    flex: 1;
    padding-right: 5px;
    white-space: nowrap;
  }

  .pname_value {
    flex: 3;
  }
  .sltStyle{
    //   flex: 1;
  }
  .blankdiv{
      width:100px;
  }



}
button {
  background-color: #f5f5f5;
  margin-right: 10px;
}
.btmInfo {
  background: none;
  border: none;
  outline: none;
  color: #333;
  font-size: 0.9em;
}
.noresult {
  text-align: center;
  font-size: 2em;
  color: rgb(160, 154, 154);
}
.actived {
  background-color: rgba(190, 221, 140, 0.9);
}
table.dataintable {
  /* margin-top: 15px; */
  border-collapse: collapse;
  border: 1px solid white;
  width: 98%;
  font-size: 0.95em;
  background-color: #e5ebdc;
  line-height: 40px;
}
table.dataintable th {
  vertical-align: middle;
  /* padding:5px 15px 5px 6px; */
  background-color: #e8e8e8;
  border: 1px solid white;
  text-align: center;
  color: rgb(173, 169, 169);
}
table.dataintable td {
  vertical-align: text-top;
  /* padding:6px 15px 6px 6px; */
  border: 1px solid white;
  line-height: 35px;
}

.hidden_view {
  width: 100%;
  background: #698b22;
  opacity: 0.2;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 99;
}
.sltStyle {
    
    // padding-left: 5px;
    margin:5px 0;
    color:#999;
    select{
      border: 1px solid #E9E9E9;
    font-size: 0.9em;
    outline: none;
    padding: 0.5em 1em;
    color: #999;
    }
  }
  .order_item_briefinfo {
      color: #9c9c9c;
      display: flex;
      border-bottom: 1px solid #eee;
      //   padding-bottom:10px;
      padding: 10px;
      text-align: center;
      align-items: center;
      justify-content: center;
      .order_item_briefinfo_orderchk{
        // display: flex;
        // align-items: center;
        // border:1px solid red;
        input{
          // outline: 1px solid red;
          // border:0px solid black;
          // width:50px;
          transform:scale(1.8, 1.8);
        }

      }
      .order_item_briefinfo1{
          flex:1;
          text-align: center;
      }
      .order_item_briefinfo2
      {
          flex:1;
          text-align: center;
      }
      .order_item_briefinfo3
      {
          flex:3;
        //   white-space: nowrap;
          text-align: center;
      }
      .order_item_briefinfo4
      {
          flex:2;
      }
      .order_item_briefinfo5
      {
          flex:2;
      }
      .order_item_briefinfo6
      {
          flex:2;
      }
      .order_item_briefinfo7
      {
          flex:2;
      }
      .order_item_briefinfo8
      {
          flex:2;
      }
      
    }
    
    .opr_btn_style{
        display: flex;
        justify-content:center;
        
        div{
            margin:5px 5px;
            padding:0 10px;
            border-radius:10px;
            border:1px solid #ccc;
        }
    }
</style>