<template>
  <div class="banner">
    <h2>
      <a href="index.html">Home</a>
      <i class="fa fa-angle-right"></i>
      <span>{{title}}</span>
    </h2>
  </div>
</template>

<script>
export default {
  name: "TopPosition",
  data(){
      return{
        navInfo:''
      }
  },
  props:['title'],
  methods:{
      setNavInfo(nav){
          this.navInfo=nav
      }
  },
  mounted(){
      this.$bus.$on('getCurrentNavInfo',this.setNavInfo)
  },
  beforeDestroy(){
      this.$bus.$off('getCurrentNavInfo')
  }

};
</script>

<style>
</style>