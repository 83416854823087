<template>
  <div style="height: 100%;">
    <Toast ref="toast" />
    <!-- <el-dialog
    title="提示"
    :visible.sync="dialogVisible"
    width="30%"
    :before-close="handleClose">
    <span>您确定要删除该轮播图吗？</span>
    <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
    </span>
    </el-dialog> -->
    <div class="grid-form1" style="padding: 0">
      <div style="margin: 0; padding: 0; vertical-align: middle;height:60px;">          
          <div class="col-sm-2 btnexport"><button
              class="btn btn-default"
             @click="newBanner"
            >
              <i class="fa fa-flag-checkered" aria-hidden="true"></i>&nbsp;新增弹窗广告
            <!-- </button> -->
            </button></div>
      </div>
    </div>
        <div class="orderlist" >
            
            <div class="order_item" >
                <div class="order_item_briefinfo">
                    <div class="order_item_briefinfo1">序号</div>
                    <div class="order_item_briefinfo2">图片</div>
                    <div class="order_item_briefinfo6">状态</div>
                    <div class="order_item_briefinfo3">跳转类型</div>
                    <div class="order_item_briefinfo4">链接</div>
                    <div class="order_item_briefinfo3">开始日期</div>
                    <div class="order_item_briefinfo3">结束日期</div>
                    <div class="order_item_briefinfo5">操作</div>
                </div>
                <div class="order_item_briefinfo" v-for="(item,index) in currentpagelist" :key="index">
                    <div class="order_item_briefinfo1">{{item.id}}</div>
                    <div class="order_item_briefinfo2"><img :src="item.imgurl"/></div>
                    <div class="order_item_briefinfo6">
                        
                        <i class="fa fa-toggle-on" style="color:#66CD00;cursor:pointer;" v-if="item.isopen==1" title="点击关闭" @click="setBannerStatus(item.id,0)"></i>
                        <i class="fa fa-toggle-off" style="cursor:pointer;" title="点击开启" v-else @click="setBannerStatus(item.id,1)"></i>
                    </div>

                    <div class="order_item_briefinfo3">{{item.opentype}}</div>
                    <div class="order_item_briefinfo4">{{item.url}}</div>
                    <div class="order_item_briefinfo3">{{item.bdate}}</div>
                    <div class="order_item_briefinfo3">{{item.edate}}</div>
                    <div class="order_item_briefinfo5">
                        <button
                        class="btn btn-default"
                        @click="setSort(item.id,item.sort,1,index)"
                        v-if="item.showflag==1"
                        >
                        <i class="fa fa-long-arrow-up" aria-hidden="true"></i>&nbsp;靠前
                        <!-- </button> -->
                        </button>
                        <button
                        class="btn btn-default"
                        @click="setSort(item.id,item.sort,-1,index)"
                        v-if="item.showflag==1"
                        >
                        <i class="fa fa-long-arrow-down" aria-hidden="true"></i>&nbsp;靠后
                
                        </button>

                        <!-- <button
                        class="btn btn-default"
                        @click="editBanner(item.id)"
                        >
                        <i class="fa fa-pencil-square-o" aria-hidden="true"></i>&nbsp;编辑
                      
                        </button> -->
                        <button
                        class="btn btn-default"
                        @click="delBanner(item.id)"
                        >
                        <i class="fa fa-trash-o" aria-hidden="true"></i>&nbsp;删除
         
                        </button>
                    </div>
                </div>
            </div>
        </div>
       
      
    </div>
  <!-- </div> -->
</template>

<script>
import axios from "axios";
import Toast from "../../components/Toast";


export default {
  name: "popupAd",
  components: {
    
    Toast
  },
  data() {
    return {
        dialogVisible:false,
        orderStatus:'',
      
        shownoresult:false,
      orderList: [],
      currentIndex: -1,
      currentpage: 1,
      gotopage: "",
      //   isshowupdate: false,
      win_height: ""
    };
  },
  methods: {
      newBanner()
      {
          this.$router.push('/newad')
      },
    async setSort(id,sort,flag,index)
    {
        let tmpID=0
        let tmpSort=0
        if(flag==1 && index==0) 
        {
            // console.log(this.currentpagelist.length)
            return false
        }
        if(flag==-1 && (this.currentpagelist.length-1)==index) 
        {
            return false
        }
        if(flag==-1 && this.currentpagelist[index+1].showflag==0){
            return false
        }
        if(flag==1){
            tmpSort=this.currentpagelist[index-1].sort
            tmpID=this.currentpagelist[index-1].id
        }else{
            tmpSort=this.currentpagelist[index+1].sort
            tmpID=this.currentpagelist[index+1].id
        }

        const res = await this.$post(this.GLOBAL.serverAddress + "/setBannerSort/", 
        {'aid':id,'asort':sort,'bid':tmpID,'bsort':tmpSort});
        if (res.status === 200 && res.data) {
            this.$router.go(0)
        }

        // this.currentpagelist[index]=this.currentpagelist[index+1]
        // this.currentpagelist[index+1]=tmpV
        console.log(this.currentpagelist)
        // this.currentpagelist.forEach(v=>{
        //     if(v.)
        // })
    },
    async delBanner(id)
    {
        if(confirm("您确定要删除该广告弹窗吗？")){
            const res = await this.$post(this.GLOBAL.serverAddress + "/delAd/", 
            {'id':id});
            if (res.status === 200 && res.data) {
                this.$router.go(0)
            }
        }
    },
    editBanner(id){
      this.$router.push('/updatebanner?id='+id)
    },
    
    
    async setBannerStatus(id,status){
        const res = await this.$post(this.GLOBAL.serverAddress + "/setAdStatus/", 
        {'id':id,'status':status});
        if (res.status === 200 && res.data) {
            this.$router.go(0)
        }
    },
      viewOrderDetail(id){
          this.$router.push('/retailorderdetail?id='+id)
      },
      editOrder(id){
          this.$router.push('/updateretailorder?id='+id)
      },
      
      
    getNewPage(pagenum) {
      if (pagenum >= 1 && pagenum <= this.pagecount) {
        this.currentpage = pagenum;
        // console.log(this.pages)
        // return this.pages[this.currentpage - 1];
      }
    },
    async search() {
      this.currentpage=1
      
      const res = await this.$get(this.GLOBAL.serverAddress + "/getAdList/", {});
      // console.log(res);
        
      if (res.status === 200) {
        this.orderList = res.data;
        console.log(this.orderList)
        // if(this.orderList.length>0){
        //     this.shownoresult=false
        // }else{
        //     this.shownoresult=true
        // }
      }
    },
  },
  computed: {
    currentpagelist() {
      return this.pages[this.currentpage - 1];
    },
    recordcount() {
      return this.orderList.length;
    },
    pagecount() {
      return Math.ceil(this.orderList.length / 50);
    },
    pages() {
      const pages = [];
      this.orderList.forEach((item, index) => {
        const page = Math.floor(index / 50);
        if (!pages[page]) {
          pages[page] = [];
        }
        pages[page].push(item);
      });
      return pages;
    },
  },
  mounted() {
    this.haveRights('|0901|')
    this.search();
    window.addEventListener("click", this.hideCalender);
    this.win_height = window.innerHeight;
  },
  beforeDestroy() {
  },
};
</script>

<style scoped lang="less">
.btnexport{
  padding-top: 10px;
  padding-left:50px;
  display:flex;
  align-items: center;
}
.printList{
  position: absolute;
  left:300px;
}
.bdate_value_time{
  color: #9c9c9c;
  border: 0px solid #9c9c9c;
}
.search_condition {
    display: flex;
    // white-space: nowrap;
    justify-content:center;
    align-items: center;
    text-align: right;
    padding:10px;
  .pname {
    //   display: flex;
    flex: 1;
    padding-right: 5px;
  }

  .pname_value {
    flex: 3;
  }

  .cname {
    flex: 1;
    padding-right: 5px;
  }

  .cname_value {
    flex: 2;
  }

  .bdate {
    flex: 1;
    padding-right: 5px;
  }

  .bdate_value {
    flex: 1;
  }

  .edate {
    //   border: 1px solid green;
    flex: 1;
    text-align: center;
    // display: flex;
    // align-items: center;
    // justify-content: center;
  }

  .edate_value {
    flex: 1;
  }
}

.orderlist {
  color: #9c9c9c;

  // padding:10px;
  .order_item {
    //   width:100%;
    margin-top: 15px;
    background-color: #fff;
    // padding-top:15px;
    .order_item_briefinfo {
      display: flex;
      border-bottom: 1px solid #eee;
      //   padding-bottom:10px;
      padding: 10px;
      text-align: center;
      align-items: center;
      justify-content: center;
      .order_item_briefinfo_orderchk{
        // input{
        //   outline: 1px solid red;
        //   border:0px solid black;
        // }
      }
      .order_item_briefinfo1{
          flex:1;
          text-align: center;
      }
      .order_item_briefinfo2
      {
          display: flex;
          justify-content: center;
          align-items: center;
          img{
              width: 90%;
          }
          flex:2;
      }
      .order_item_briefinfo3
      {
          flex:2;
      }
      .order_item_briefinfo4
      {
          flex:6;
      }
      .order_item_briefinfo5
      {
          flex:1;
      }
      .order_item_briefinfo6
      {
          flex:1;
      }
      .order_item_briefinfo7
      {
          flex:1;
      }
      .order_item_briefinfo8{
          flex:2;
      }
      
    }

    .order_item_content {
      display: flex;
      .order_item_content_product {
        flex: 3;
        margin: 10px;
        padding-left: 10px;
        display: flex;
        .productinfo_img {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            width: 80%;
            max-height: 100px;
            object-fit: contain;
          }
        }

        .productinfo {
          flex: 3;
          margin: 10px;
          padding-left: 5px;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          // align-items: center;
          .productinfo_title {
          }

          .productinfo_guige {
          }

          .productinfo_num {
          }

          .productinfo_price {
          }
        }
      }

      .order_item_content_order {
        flex: 5;
        display: flex;
        .orderremark{
          flex:2;
          padding: 5px;
          display: flex;
          align-items: center;
          color:#FF4500;
        }
        .orderprice {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 1.2em;
          color: #6e8b3d;
        }

        // .orderstatus {
        //   flex: 1;
        //   display: flex;
        //   align-items: center;
        //   justify-content: center;
        // }

        .ordercontact {
          flex: 3;
          display: flex;
          // align-items: center;
          justify-content: center;
          flex-direction: column;
          padding-left: 10px;
          .ordercontact_name {
          }

          .ordercontact_tel {
          }

          .ordercontact_address {
            display: flex;
            .address_title {
              white-space: nowrap;
            }
          }
        }
      }

      .order_item_content_opr {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        // padding:15px;
        button {
          width: 80%;
          margin: 3px;
        }
      }
    }
  }
}

.sltStyle {
    
    padding-left: 5px;
    color:#999;
    select{
      border: 1px solid #E9E9E9;
    font-size: 0.9em;
    outline: none;
    padding: 0.5em 1em;
    color: #999;
    }
  }

button {
  background-color: #f5f5f5;
  margin-right: 10px;
}
.btmInfo {
  background: none;
  border: none;
  outline: none;
  color: #333;
  font-size: 0.9em;
}
.noresult {
  text-align: center;
  font-size: 2em;
  color: rgb(160, 154, 154);
}
.pagenum_info{
    background:#fff;
    height: 50px;
    margin-top:20px;
    padding: 10px; color: #6c757d;
    // border-top: 1px dashed #9acd32;
}
.actived {
  background-color: rgba(190, 221, 140, 0.9);
}
table.dataintable {
  /* margin-top: 15px; */
  border-collapse: collapse;
  border: 1px solid white;
  width: 98%;
  font-size: 0.95em;
  background-color: #e5ebdc;
  line-height: 40px;
}
table.dataintable th {
  vertical-align: middle;
  /* padding:5px 15px 5px 6px; */
  background-color: #e8e8e8;
  border: 1px solid white;
  text-align: center;
  color: rgb(173, 169, 169);
}
table.dataintable td {
  vertical-align: text-top;
  /* padding:6px 15px 6px 6px; */
  border: 1px solid white;
  line-height: 35px;
}

.hidden_view {
  width: 100%;
  background: #698b22;
  opacity: 0.2;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 99;
}
</style>