import VueRouter from "vue-router"

// import StorageIn from '../pages/StorageIn'

// import StorageOut from '../pages/StorageOut'
import RemindList from '../pages/storage/RemindList'
import StorageSearch from '../pages/storage/StorageSearch'
import LogIn from '../components/LogIn'
import Home from '../components/Home'
import WholeSaleSetup from '../pages/wholesale/WholeSaleSetup'
import WholeSaleProductList from '../pages/wholesale/WholeSaleProductList'
import StorageInsert from '../pages/storage/StorageInsert'
import WSOrderList from '../pages/wholesale/WSOrderList'
import OrderDetail from '../pages/wholesale/OrderDetail'

import ProductCostList from '../pages/reports/ProductCostList'
import WSBenefitList from '../pages/wholesale/WSBenefitList'
import Desktop from '../pages/Desktop'
// import NewAdmin from '../syspages/NewAdmin'
import AdminAdd from '../syspages/AdminAdd'
import AdminList from '../syspages/AdminList'
import RoleSetup from '../syspages/RoleSetup'
import RoleList from '../syspages/RoleList'
import UpdatePWD from '../syspages/UpdatePWD'
import UpdateAdmin from '../syspages/UpdateAdmin'
import wxaCodeList from '../syspages/wxaCodeList'

import SKUStock from '../pages/storage/SKUStock'
import ClientList from '../pages/wholesale/ClientList'
import ClientAdd from '../pages/wholesale/ClientAdd'
import ClientUpdate from '../pages/wholesale/ClientUpdate'
// import RetailOrderList from '../pages/retail/RetailOrderList'
import RetailOrderDetail from '../pages/retail/RetailOrderDetail'
import UpdateRetailOrder from '../pages/retail/UpdateOrder'
import printList from '../pages/retail/printList'
import phdList from '../pages/retail/phdList'
import NewOrder from '../pages/wholesale/NewOrder'
import newBanner from '../pages/minisetup/newBanner'
import newNav from '../pages/minisetup/newNav'
import updateNav from '../pages/minisetup/updateNav'
import updateBanner from '../pages/minisetup/updateBanner'
import newROrder from '../pages/retail/newROrder'


import bannerSetup from '../pages/minisetup/bannerSetup'
import navSetup from '../pages/minisetup/navSetup'
import reservationSetup from '../pages/minisetup/reservationSetup'
import popupAd from '../pages/minisetup/popupAd'
import newAd from '../pages/minisetup/newAd'
import homenote from '../pages/minisetup/homenote'

import policyExcept from '../pages/distribution/policyExcept'
import uncalcedOrderList from '../pages/distribution/uncalcedOrderList'
import billList from '../pages/distribution/billList'
import billDetailList from '../pages/distribution/billDetailList'
import memberList from '../pages/distribution/memberList'
import policyList from '../pages/distribution/policyList'
import memberPropertyList from '../pages/distribution/memberPropertyList'
import memberorderList from '../pages/distribution/memberorderList'
import dporderList from '../pages/distribution/dporderList'

import allmember from '../pages/member/allmember'
import auditMoney from '../pages/member/auditMoney'


export default new VueRouter({
    mode:"history",
    routes:[
        {
            path:'/',
            component:Home,
            // meta:{
            //     keepAlive:true
            // }
            children: [
                {
                    path:'/desktop',
                    component:Desktop,
                    meta:{
                        keepAlive:false                        
                    }
                },
                // {
                //     path:'/storagein',
                //     component:StorageIn,
                //     meta:{
                //         keepAlive:true                        
                //     }
                // },
                {
                    path:'/storageinnew',
                    component:StorageInsert,
                    meta:{
                        keepAlive:true                        
                    }
                },
                {
                    path:'/wsorderlist',
                    component:WSOrderList,
                    meta:{
                        keepAlive:false                        
                    }
                },
                {
                    path:'/wxacodelist',
                    component:wxaCodeList,
                    meta:{
                        keepAlive:false                        
                    }
                },
                {
                    path:'/retailorderlist',
                    name:'/retailorderlist',
                    component:resolve=>require(['../pages/retail/RetailOrderList'],resolve),
                    
                },
                {
                    path:'/neworderlist',
                    name:'/neworderlist',
                    component:resolve=>require(['../pages/retail/newOrderList'],resolve),
                    
                },
                {
                    path:'/profitlist',
                    name:'/profitlist',
                    component:resolve=>require(['../pages/reports/profitList'],resolve),
                    
                },
                {
                    path:'/saleslist',
                    name:'/saleslist',
                    component:resolve=>require(['../pages/reports/saleslist'],resolve),
                    
                },
                {
                    path:'/productwastage',
                    name:'/productwastage',
                    component:resolve=>require(['../pages/storage/productwastage'],resolve),
                    
                },
                {
                    path:'/homenote',
                    name:'/homenote',
                    component:resolve=>require(['../pages/minisetup/homenote'],resolve),
                    
                },
                {
                    path:'/wastagelist',
                    name:'/wastagelist',
                    component:resolve=>require(['../pages/storage/wastagelist'],resolve),
                    
                },
                {
                    path:'/wssaleslist',
                    name:'/wssaleslist',
                    component:resolve=>require(['../pages/reports/wssaleslist'],resolve),
                    
                },
                {
                    path:'/productlist',
                    component:resolve=>require(['../pages/retail/ProductList'],resolve),
                    
                },
                {
                    path:'/allmember',
                    component:allmember,
                    meta:{
                        keepAlive:false                        
                    }
                    
                },
                {
                    path:'/auditmoney',
                    component:auditMoney,
                    meta:{
                        keepAlive:false                        
                    }
                    
                },
                {
                    path:'/remindlist',
                    component:RemindList,
                    meta:{
                        keepAlive:false                        
                    }
                },
                {
                    path:'/searchstorage',
                    component:StorageSearch,
                    meta:{
                        keepAlive:false
                    }
                },
                {
                    path:'/setupwholesale',
                    component:WholeSaleSetup,
                    meta:{
                        keepAlive:false
                    }
                },
                {
                    path:'/bannersetup',
                    component:bannerSetup,
                    meta:{
                        keepAlive:false
                    }
                },
                {
                    path:'/reservationsetup',
                    component:reservationSetup,
                    meta:{
                        keepAlive:false
                    }
                },
                {
                    path:'/newad',
                    component:newAd,
                    meta:{
                        keepAlive:false
                    }
                },
                {
                    path:'/policyexcept',
                    component:policyExcept,
                    meta:{
                        keepAlive:false
                    }
                },
                {
                    path:'/policylist',
                    component:policyList,
                    meta:{
                        keepAlive:false
                    }
                },
                {
                    path:'/memberlist',
                    component:memberList,
                    meta:{
                        keepAlive:false
                    }
                },
                {
                    path:'/popupad',
                    component:popupAd,
                    meta:{
                        keepAlive:false
                    }
                },
                {
                    path:'/memberpropertylist',
                    component:memberPropertyList,
                    meta:{
                        keepAlive:false
                    }
                },
                {
                    path:'/memberorderlist',
                    component:memberorderList,
                    meta:{
                        keepAlive:false
                    }
                },
                {
                    path:'/dporderlist',
                    component:dporderList,
                    meta:{
                        keepAlive:false
                    }
                },
                {
                    path:'/uncalcedorderlist',
                    component:uncalcedOrderList,
                    meta:{
                        keepAlive:false
                    }
                },
                {
                    path:'/billlist',
                    component:billList,
                    meta:{
                        keepAlive:false
                    }
                },
                {
                    path:'/billdetaillist',
                    component:billDetailList,
                    meta:{
                        keepAlive:false
                    }
                },
                {
                    path:'/navsetup',
                    component:navSetup,
                    meta:{
                        keepAlive:false
                    }
                },
                {
                    path:'/neworder',
                    component:NewOrder,
                    meta:{
                        keepAlive:false
                    }
                },
                {
                    path:'/newrorder',
                    component:newROrder,
                    meta:{
                        keepAlive:false
                    }
                },
                {
                    path:'/newbanner',
                    component:newBanner,
                    meta:{
                        keepAlive:false
                    }
                },
                {
                    path:'/newnav',
                    component:newNav,
                    meta:{
                        keepAlive:false
                    }
                },
                {
                    path:'/updatenav',
                    component:updateNav,
                    meta:{
                        keepAlive:false
                    }
                },
                {
                    path:'/updatebanner',
                    component:updateBanner,
                    meta:{
                        keepAlive:false
                    }
                },
                {
                    path:'/searchwholesale',
                    component:WholeSaleProductList,
                    meta:{
                        keepAlive:false
                    }
                },
                {
                    path:'/productstockinfolist',
                    component:SKUStock,
                    meta:{
                        keepAlive:false
                    }
                },
                {
                    path:'/orderdetail',
                    component:OrderDetail,
                    meta:{
                        keepAlive:false
                    }
                },
                {
                    path:'/productcostlist',
                    component:ProductCostList,
                    meta:{
                        keepAlive:false
                    }
                },
                {
                    path:'/wsbenefitlist',
                    component:WSBenefitList,
                    meta:{
                        keepAlive:false
                    }
                },
                {
                    path:'/newadmin',
                    component:AdminAdd,
                    meta:{
                        keepAlive:false
                    }
                },
                {
                    path:'/newclient',
                    component:ClientAdd,
                    meta:{
                        keepAlive:false
                    }
                },
                {
                    path:'/updateclient',
                    component:ClientUpdate,
                    meta:{
                        keepAlive:false
                    }
                },
                {
                    path:'/updatepwd',
                    component:UpdatePWD,
                    meta:{
                        keepAlive:false
                    }
                },
                {
                    path:'/updateadmin',
                    component:UpdateAdmin,
                    meta:{
                        keepAlive:false
                    }
                },
                {
                    path:'/clientlist',
                    component:ClientList,
                    meta:{
                        keepAlive:false
                    }
                },
                {
                    path:'/adminlist',
                    component:AdminList,
                    meta:{
                        keepAlive:false
                    }
                },
                {
                    path:'/rolesetup',
                    component:RoleSetup,
                    meta:{
                        keepAlive:false
                    }
                },
                
                {
                    path:'/rolelist',
                    component:RoleList,
                    meta:{
                        keepAlive:false
                    }
                },
                
                {
                    path:'/retailorderdetail',
                    component:RetailOrderDetail,
                    meta:{
                        keepAlive:false
                    }
                },
                {
                    path:'/updateretailorder',
                    component:UpdateRetailOrder,
                    meta:{
                        keepAlive:false
                    }
                },
                {
                    path:'/phdlist',
                    component:phdList,
                    meta:{
                        keepAlive:false
                    }
                },
                {
                    path:'/printlist',
                    component:printList,
                    meta:{
                        keepAlive:false
                    }
                },
            ]
        },
        
        
        
        {
            path:'/login',
            component:LogIn,
            meta:{
                keepAlive:false
            }
        }
    ]
})