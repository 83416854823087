<template>
  <div style="height: 720px">
    <div class="grid-form1" style="padding: 0; height: 720px">
      <Toast ref="toast" />
      <div class="admin_info">
        <div class="admin_info_row">
            <div class="admin_info_row_l">
            <label
                for="txtContractNumber"
                class="control-label"
                style="padding-top: 10px; text-align: right"
                >登录账号</label
            >
            </div>
            <div class="admin_info_row_r" >
            <input
                type="text"
                class="form-control1"
                id="txtAccount"
                maxlength="30"
                v-model.trim="adminInfo.account"   
            />
            </div>
        </div>
        <div class="admin_info_row">
            <div class="admin_info_row_l">
            <label
                for="txtContractNumber"
                class="control-label"
                style="padding-top: 10px; text-align: right"
                >客户名称</label
            >
            </div>
            <div class="admin_info_row_r" >
            <input
                type="text"
                class="form-control1"
                id="txtClientName"
                maxlength="30"
                v-model.trim="adminInfo.clientname"   
            />
            </div>
        </div>
        <div class="admin_info_row">
            <div class="admin_info_row_l">
            <label
                for="txtContractNumber"
                class="control-label"
                style="padding-top: 10px; text-align: right"
                >登录密码</label
            >
            </div>
            <div class="admin_info_row_r" >
            <input
                type="text"
                id="txtPWD"
                class="form-control1"
                v-model.trim="adminInfo.password"
            />
            </div>
            </div>
        
            <div class="admin_info_row">
            <div class="admin_info_row_l">
            <label
                for="txtContractNumber"
                class="control-label"
                style="padding-top: 10px; text-align: right"
                >联 系 人</label
            >
            </div>
            <div class="admin_info_row_r" >
            <input
                type="text"
                class="form-control1"
                id="txtContact"
                maxlength="30"
                v-model.trim="adminInfo.contact"   
            />
            </div>
        </div>
        <div class="admin_info_row">
            <div class="admin_info_row_l">
            <label
                for="txtContractNumber"
                class="control-label"
                style="padding-top: 10px; text-align: right"
                >联系电话</label
            >
            </div>
            <div class="admin_info_row_r" >
            <input
                type="text"
                class="form-control1"
                id="txtTel"
                maxlength="30"
                v-model.trim="adminInfo.tel"   
            />
            </div>
        </div>
        <div class="admin_info_row">
            <div class="admin_info_row_l">
            <label
                for="txtContractNumber"
                class="control-label"
                id="txtEmail"
                maxlength="100"
                style="padding-top: 10px; text-align: right"
                >Email</label
            >
            </div>
            <div class="admin_info_row_r" >
            <input
                type="text"
                class="form-control1"
                v-model.trim="adminInfo.email"
            />
            </div>
            </div>
        <div class="admin_info_row">
            <div class="admin_info_row_l">
            <label
                for="txtContractNumber"
                class="control-label"
                id="txtTel"
                maxlength="22"
                style="padding-top: 10px; text-align: right"
                >联系地址</label
            >
            </div>
            <div class="admin_info_row_r" >
            <input
                type="text"
                class="form-control1"
                v-model.trim="adminInfo.address"
            />
            </div>
            </div>
        
        
        <div class="errinfo">{{ errmessage }}&nbsp;</div>
      </div>
      <div class="col-sm-12" style="text-align: center; padding-top: 20px">
        <button class="btn btn-default" type="button" @click="goback">
          <i class="fa fa-arrow-left" aria-hidden="true"></i>&nbsp;返回列表
        </button>

        <button
          class="btn btn-default"
          id="btnSave"
          :disabled="submitSaving"
          @click="saveAdminInfo"
        >
          <i class="fa fa-save" aria-hidden="true"></i>&nbsp;
          {{ submitSaving ? "创建中.." : "创建账号" }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Toast from "../../components/Toast";

export default {
  name: "ClientAdd",
  components: { Toast },
  data() {
    return {
      // vcflag:false,
 
      adminInfo:{
        account:'',
        address:'',
        password:'',
        tel:'',
        email:'',
        contact:'',
        clientname:'',
        status:'2',
        applystatus:'2'
      },
      
      errmessage: "",
      submitSaving: false,
    };
  },
  methods: {
    
    goback() {
      this.$router.push("/clientlist");
    },
    submitData() {
      //   console.log('tijiao');
      axios({
        method: "post",
        headers: {
          "Content-Type": "application/json;chartset=UTF-8",
          Authorization: "test",
        },
        data: this.adminInfo,
        // [{id:1,psn:'test01'},
        //   {id:2,psn:'test02'}],
        url: this.GLOBAL.serverAddress + "/insertAccount/",
      }).then(
        // axios.post('http://localhost:8080/getStorageList').then(
        (response) => {
          // console.log('OK',response.data)
          // this.initData();
          this.$refs.toast.toast("账号创建成功")
          setTimeout(()=>{
              this.$router.push("/clientlist")
          },1500)
          
          this.submitSaving = false;
        },
        (error) => {
          console.log("出现错误", error.message);
        }
      );
    },
    async saveAdminInfo() {
      var submitflag = false;
      // console.log(this.GLOBAL.isFloat(this.currentSInfo[0].pwsprice));
      // this.GLOBAL.showTest()
      if(this.adminInfo.account===""){
          this.errmessage = "请输入登录账号"
          $('#txtAccount').focus()
          return false
      }
      if($('#txtAccount').val().length<3){
          this.errmessage = "登录账号长度至少为3个字符"
          $('#txtAccount').focus()
          return false
      }else{
          const res= await this.$get(this.GLOBAL.serverAddress + "/hasAdmin/",{account:this.adminInfo.account})
            if(res.data){
                this.errmessage = "登录账号已存在，请使用其他账号"
                return false
            }
      }
      if(this.adminInfo.clientname===""){
          this.errmessage = "请输入客户名称"
          $('#txtClientName').focus()
          return false
      }
      if(this.adminInfo.password===""){
          this.errmessage = "请输入登录密码"
          $('#txtPWD').focus()
          return false
      }
      if($('#txtPWD').val().length<6){
          this.errmessage = "登录密码长度至少为6个字符"
          $('#txtPWD').focus()
          return false
      }
      if(this.adminInfo.contact===""){
          this.errmessage = "请输入联系人"
          $('#txtContact').focus()
          return false
      }
      if(this.adminInfo.tel===""){
          this.errmessage = "请输入联系电话"
          $('#txtTel').focus()
          return false
      }
      submitflag = true;
      if (submitflag) {
        this.submitSaving = true;
        this.submitData();
      }
    },

    
  },
  mounted() {
    this.haveRights('|0203|')
    // this.initData();
  },
};
</script>

<style lang="less" scoped>
.admin_info {
  display: flex;
  flex-direction: column;
  // align-items: center;
  // justify-content: center;
  // margin-left: 50px;
  // padding-left: 50px;
  margin-top: 20px;
  .admin_info_row {
    // padding:10px;
    //     line-height:40px;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 50px;
    .admin_info_row_l {
      text-align: right;
      // border-top: 1px solid red;
      flex: 1;
      padding-right: 15px;
    }
    .admin_info_row_r {
      flex: 2;

      input {
        width: 400px;
      }
      .menulist {
        width: 400px;
        display: flex;
        flex-direction: column;
        height: 280px;
        overflow: auto;
        border: 1px solid #eeeeee;
        .menulist_node {
          display: flex;
          flex-direction: column;
          // justify-content:space-around;
          // margin: auto;
          line-height: 30px;
          margin: 10px;
          .menulist_fnode {
            display: flex;
            .menulist_fnode_chk {
              flex: 1;
              input {
                width: 40px;
                line-height: 20px;
              }
            }
            .menulist_fnode_name {
              flex: 10;
            }
          }
          .menulist_cnode {
            padding-left: 30px;
            line-height: 30px;
            display: flex;
            .menulist_cnode_chk {
              flex: 1;
              input {
                width: 40px;
              }
            }
            .menulist_cnode_name {
              flex: 10;
            }
          }
        }
      }
    }
  }
}

button {
  background-color: #f5f5f5;
  margin-right: 10px;
}

.errinfo {
  text-align: center;
  color: red;
  font-size: 15px;
  padding-top: 10px;
  line-height: 30px;
  //   font-weight: 600;
}
</style>