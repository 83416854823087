<template>
<div style="height: 100%;"><Toast ref="toast" /> 


    <div class="grid-form1" style="padding: 0;height:500px;">
      <div style="margin: 0; padding: 0; vertical-align: middle;height:400px;">
          <div class="search_condition2">
            <div class="pname2">
            <label
            class="control-label"
              for="txtContractNumber"
              >导航名称：</label
            >
            </div>
            <div>
            <input
                    type="text"
                    class="form-control1"
                    id="txtContractNumber"
                    name="txtContractNumber"
                    style="width:320px;"
                    v-model.trim="pcid"
                    />
          </div>
          </div>
        <div class="search_condition2">
          <div class="pname2" >
            <label
            class="control-label"
              for="txtContractNumber"
              >设置图片：<br>120*120px</label
            >
          </div>
          <div class="pname2">
                
                <img :src="item"  v-for="(item,index) in list" :key="index">
        
            </div>
            <div class="pname2">
                <input type="file" name="avatar" accept="image/gif,image/jpeg,image/jpg,image/png" @change="changeImage($event)" ref="avatarInput">
            </div>
        </div>
        <div class="search_condition2">
            <div class="pname2">
            <label
            class="control-label"
              for="txtContractNumber"
              >跳转类型：</label
            >
          </div>
          <div class="pname_value2">
            <select name="sltPayStatus" v-model="openType" @change="setOpenType">
              <option value="0"></option>
              <option value="1">商品列表</option>
              <option value="2">全部商品分类</option>
              <option value="3">领券中心</option>
            </select>
          </div>
        </div>
        <div class="search_condition2">
            <div class="pname2">
            <label
            class="control-label"
              for="txtContractNumber"
              >参数设置：</label
            >
            </div>
            <!-- <div>
            <input
                    type="text"
                    class="form-control1"
                    id="txtContractNumber"
                    name="txtContractNumber"
                    style="width:320px;"
                    v-model.trim="pcid"
                    v-if="pcidshow"
                    />
          </div> -->
          <div class="pname_value2" v-if="pclassshow">
            <select name="sltProductClass" v-model="productFClass" @change="setCClass">
              <option value="">请选择商品类别</option>
              <option :value="item.fcid" v-for="(item,index) in classFList" :key="index">{{item.fcname}}</option>
            </select>
            <select name="sltProductCClass" v-model="productCClass">
              <option value="">请选择二级分类</option>
              <option :value="item.ccid" v-for="(item,index) in classCList" :key="index">{{item.ccname}}</option>
            </select>
          </div>
        </div>
        <div class="search_condition2">
            <div class="search_btn" style="padding-top: 2px;padding-left:50px;">
                <button
                class="btn btn-default"
                type="button"
                @click="goBack"
                >
                <i class="fa fa-search" aria-hidden="true"></i>&nbsp;返回
                </button>
                <button
                class="btn btn-default"
                type="button"
                @click="saveBanner"
                >
                <i class="fa fa-search" aria-hidden="true"></i>&nbsp;保存
                </button>
            </div>
        </div>
      </div>

    </div>
        
            
          
       
      
 </div>
</template>

<script>
import axios from "axios";
import Toast from "../../components/Toast";


export default {
  name: "newNav",
  components: {
   
    Toast
  },
  data() {
    return {
        classFList:[],
        classCList:[],
        productFClass: "",
        productCClass: "",
        openType:"",
        openTypeValue:"",
        pcid:'',
        openConfigValue:"",
        list:[],
        imgURL:'',
        pcidshow:false,
        pclassshow:false
    }
  },
  methods: {
    goBack(){
      this.$router.go(-1)
    },
    saveBanner(){
      if(this.pcid==""){
        this.$refs.toast.toast("请输入导航名称")
        return false
      }
      if(this.list.length==0){
        this.$refs.toast.toast("请选择图片")
        return false
      }
      if(this.openType==""){ 
        this.$refs.toast.toast("请选择跳转类型")
        return false
      }
    //   if(this.openType=="1" && this.pcid==""){ 
    //     this.$refs.toast.toast("请输入产品ID")
    //     return false
    //   }
      if(this.openType=="1" && this.productFClass==""){ 
        this.$refs.toast.toast("请选择产品类别")
        return false
      }
      // alert(this.openType)
      switch(this.openType){
        
        case "1":

          this.openTypeValue="/pages/goods/goods"
          this.openConfigValue="?cid=" + (this.productCClass==""?this.productFClass:this.productCClass)
          break
        case "2":
          // alert("dddd")
          this.openTypeValue="/pages/tabBar/allGoods"
          this.openConfigValue=""
          break
        case "3":
          this.openTypeValue="/pagesA/shop/coupon"
          this.openConfigValue=""
          break      
      }
      // alert(this.openConfigValue)
      // alert(this.openTypeValue)
      this.uploadBanner()
      setTimeout(()=>{
        this.$router.push('/navsetup')
      },1500)

    },
    getPicName(file){
      let tmpName=file.lastModified
      let tmpSuffix=file.type.replace("image/","")
      return tmpName+"."+tmpSuffix
    },
      uploadBanner() {
      // 修改了头像
        if (this.$refs.avatarInput.files.length !== 0) 
        {
          var image = new FormData()
          console.log(this.$refs.avatarInput.files[0])
          image.append('file_obj', this.$refs.avatarInput.files[0])
          image.append('file_name', this.getPicName(this.$refs.avatarInput.files[0]))
          image.append('image_type', 'nav')
          image.append('open_type', this.openTypeValue)
          image.append('open_url', this.openConfigValue)
          image.append('nav_name', this.pcid)
          axios.post(this.GLOBAL.serverAddress +'/uploadFileAJAX/', image, {
            headers: {
              "Content-Type": "multipart/form-data"
            }
          }).then(
            this.$refs.toast.toast("导航保存成功")
          )
        }
      }, 
        changeImage(e) {
          this.list=[]
        for(let item of e.target.files){
                if (!/image\/\w+/.test(item.type)) {
                        alert("只能选择图片");
                        return;
          }
                    var _this = this;
                    let reader = new FileReader();
                    // readAsDataURL方法可以将上传的图片格式转为base64,然后在存入到图片路径,这样就可以上传任意位置的图片
                    reader.readAsDataURL(item);
                    reader.addEventListener('load',function(){
                        _this.list.push(this.result)
                    })
            }
        },
      async ajaxUpload(){
        let formdata=new FormData()
        let btnName="myfile"
        let file_obj=$('[name=myfiledxsm]')[0].files[0];
        console.log(file_obj.lastModified)
        formdata.append('file_obj',file_obj);
        formdata.append('file_name',file_obj.lastModified);
        // console.log(formdata)
          const res2 = await this.$post(this.GLOBAL.serverAddress + "/uploadFileAJAX/",{'file_obj':this.form,'filename':file_obj.lastModified})
            // console.log(e.target.files);
            console.log(res2)
            if (res2.status === 200) {
                // this.classFList = res2.data
                console.log('dd')
            }
      },
      async upload(e){
          let tmp=''
          for(let item of e.target.files){
            if (!/image\/\w+/.test(item.type)) {
                    alert("只能选择图片");
                    return;
            }
            var _this = this;
            let reader = new FileReader();
            // readAsDataURL方法可以将上传的图片格式转为base64,然后在存入到图片路径,这样就可以上传任意位置的图片
            reader.readAsDataURL(item);
            reader.addEventListener('load',function(){
                _this.list.push(this.result)
            })
          }
        //   const res2 = await this.$post("https://storeadmin.foodigo.ca/index.php?module=banner&action=Add",{data:tmp})
        //     console.log(e.target.files);
        //     console.log(res2)
        //     if (res2.status === 200) {
        //         // this.classFList = res2.data
        //         console.log('dd')
        //     }
      },
    
      setOpenType(){
        // this.pcid=""
        if(this.openType=="1" ){
        //   this.pcidshow=false
          this.pclassshow=true
        // }else if(this.openType=="2"){
        //   this.pcidshow=false
        //   this.pclassshow=true
        }else{
        //   this.pcidshow=false
          this.pclassshow=false
        }
      },
      setCClass(){
        this.classFList.forEach((v)=>{
            if(v.fcid==this.productFClass){
                this.productCClass=""
                // this.classCList=""
                this.classCList=v.cclass
            }
        })
        // alert(this.productFClass)
    },

      
      goback() {
          this.$router.go(-1)
      },
    
  async getClassList(){
      const res2 = await this.$get(this.GLOBAL.serverAddress + "/getAllProductClass/",{})
      // console.log(res2);

      if (res2.status === 200) {
        this.classFList = res2.data
      }
    }
  },
  computed: {
    
  },
  mounted() {
    this.haveRights('|0901|')
    this.getClassList()
    this.win_height = window.innerHeight;
  },
  beforeDestroy() {
    // 实例销毁之前对点击事件进行解绑
    window.removeEventListener("click", this.hideCalender);
  },
};
</script>

<style scoped lang="less">
.search_condition2 {
    display: flex;
    // white-space: nowrap;
    // justify-content:center;
    align-items: center;
    
    padding:10px;
    // padding-bottom: 20px;
  .pname2 {
      display: flex;
      align-items: center;
    // border: 1px solid red;
    // flex: 2;
    padding-left: 10px;
    text-align: right;
    color: #999;
    height: 40px;
    img {
            width: 80%;
            max-height: 80px;
            object-fit: contain;
          }
  }

  .pname_value2 {
    // flex: 2;
    padding-left: 5px;
    color:#999;
    select{
      border: 1px solid #E9E9E9;
    font-size: 0.9em;
    outline: none;
    padding: 0.5em 1em;
    color: #999;
    }
  }
  
  .bdate{
      padding-left: 100px;
    //   flex:1;
  }
}
.selected_product{
    // padding: 10px;
}
.search_condition {
    display: flex;
    // white-space: nowrap;
    justify-content:center;
    align-items: center;
    text-align: right;
    padding:10px;
  .pname {
    //   display: flex;
    // border: 1px solid red;
    flex: 1;
    padding-right: 5px;
  }

  .pname_value {
    flex: 3;
    // padding-left: 5px;
  }

  .cname {
    flex: 1;
    padding-right: 5px;
  }

  .cname_value {
    flex: 2;
  }

  .bdate {
    flex: 1;
    padding-right: 5px;
  }

  .bdate_value {
    flex: 1;
  }

  .edate {
    //   border: 1px solid green;
    flex: 1;
    text-align: center;
    // display: flex;
    // align-items: center;
    // justify-content: center;
  }

  .edate_value {
    flex: 1;
  }
}

.orderlist {
  color: #9c9c9c;

  // padding:10px;
  .order_item {
    //   width:100%;
    margin-top: 15px;
    background-color: #fff;
    // padding-top:15px;
    .order_item_title{
        padding: 10px;
    }
    .order_item_briefinfo {
      display: flex;
      border-bottom: 1px solid #eee;
      //   padding-bottom:10px;
      padding: 10px;
      .order_item_briefinfo_ordernum {
        padding-left: 20px;
        flex: 1;
        display: flex;
        .dkxd_c{
          padding-left:10px;
          color:red;
        }
      }

      .order_item_briefinfo_orderdate {
        flex: 1;
      }
      .order_item_briefinfo_orderpay {
        flex: 1;
        text-align: center;
      }
    }

    .order_item_content {
      display: flex;
      .order_item_content_product {
        flex: 3;
        margin: 10px;
        padding-left: 10px;
        display: flex;
        .productinfo_img {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            width: 80%;
            object-fit: contain;
          }
        }

        .productinfo {
          flex: 3;
          margin: 10px;
          padding-left: 5px;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          // align-items: center;
          .productinfo_title {
          }

          .productinfo_guige {
          }

          .productinfo_num {
          }

          .productinfo_price {
          }
        }
      }

      .order_item_content_order {
        flex: 4;
        display: flex;
        .orderprice {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 1.2em;
          color: #6e8b3d;
        }

        .orderstatus {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .ordercontact {
          flex: 3;
          display: flex;
          // align-items: center;
          justify-content: center;
          flex-direction: column;
          padding-left: 10px;
          .ordercontact_name {
          }

          .ordercontact_tel {
          }

          .ordercontact_address {
            display: flex;
            .address_title {
              white-space: nowrap;
            }
          }
        }
      }

      .order_item_content_opr {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        // padding:15px;
        button {
          width: 80%;
          margin: 3px;
        }
      }
    }
  }
}

button {
  background-color: #f5f5f5;
  margin-right: 10px;
}
.btmInfo {
  background: none;
  border: none;
  outline: none;
  color: #333;
  font-size: 0.9em;
}
.noresult {
  text-align: center;
  font-size: 2em;
  color: rgb(160, 154, 154);
}
.pagenum_info{
    background:#fff;
    height: 50px;
    margin-top:20px;
    padding: 10px; color: #6c757d;
    // border-top: 1px dashed #9acd32;
}
.actived {
  background-color: rgba(190, 221, 140, 0.9);
}
table.dataintable {
  /* margin-top: 15px; */
  border-collapse: collapse;
  border: 1px solid white;
  width: 98%;
  font-size: 0.95em;
  background-color: #e5ebdc;
  line-height: 40px;
}
table.dataintable th {
  vertical-align: middle;
  /* padding:5px 15px 5px 6px; */
  background-color: #e8e8e8;
  border: 1px solid white;
  text-align: center;
  color: rgb(173, 169, 169);
}
table.dataintable td {
  vertical-align: text-top;
  /* padding:6px 15px 6px 6px; */
  border: 1px solid white;
  line-height: 35px;
}

.hidden_view {
  width: 100%;
  background: #698b22;
  opacity: 0.2;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 99;
}

  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
</style>