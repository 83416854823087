<template>
  <div class="typo-grid" style="height: 760px">
    <div class="desktop_brief">
      <div class="desktop_brief_item">
        <div class="desktop_brief_item_icon" style="background-color: #ff7256">
          <i class="fa fa-bell-o"></i>
        </div>
        <div class="desktop_brief_item_right">
          <div class="desktop_brief_item_right_title">本周过期产品</div>
          <div class="desktop_brief_item_right_value" style="color: #ff7256">
            {{desktopInfoList.outdate7}}
          </div>
        </div>
      </div>
      <div class="desktop_brief_item">
        <div class="desktop_brief_item_icon" style="background-color: #ee9a00">
          <i class="fa fa-bell-o"></i>
        </div>
        <div class="desktop_brief_item_right">
          <div class="desktop_brief_item_right_title">本月过期产品</div>
          <div class="desktop_brief_item_right_value" style="color: #ee9a00">
            {{desktopInfoList.outdate30}}
          </div>
        </div>
      </div>
      <div class="desktop_brief_item">
        <div class="desktop_brief_item_icon" style="background-color: #00bfff">
          <i class="fa fa-usd"></i>
        </div>
        <div class="desktop_brief_item_right">
          <div class="desktop_brief_item_right_title">待收款订单</div>
          <div class="desktop_brief_item_right_value" style="color: #00bfff">
            {{desktopInfoList.unpayorder}}
          </div>
        </div>
      </div>
      <div class="desktop_brief_item">
        <div class="desktop_brief_item_icon" style="background-color: #00e5ee">
          <i class="fa fa-truck"></i>
        </div>
        <div class="desktop_brief_item_right">
          <div class="desktop_brief_item_right_title">待发货订单</div>
          <div class="desktop_brief_item_right_value" style="color: #00e5ee">
            {{desktopInfoList.undeleveryorder}}
          </div>
        </div>
      </div>
      <div class="desktop_brief_item">
        <div class="desktop_brief_item_icon" style="background-color: #9acd32">
          <i class="fa fa-bullhorn"></i>
        </div>
        <div class="desktop_brief_item_right">
          <div class="desktop_brief_item_right_title">零库存产品</div>
          <div class="desktop_brief_item_right_value" style="color: #9acd32">
            {{desktopInfoList.zerostock}}
          </div>
        </div>
      </div>
    </div>
    <div class="desktop_top">
      <div class="desktop_top_left">
        <div class="lef_top">
          <div class="lef_top_l">
            <div class="desktop_top_left_title">
              <div class="title_name">今日订单数：</div>
              <div class="title_value">{{desktopInfoList.todayorder}}</div>
            </div>
            <div class="desktop_top_left_title">
              <div class="title_name">今日营业额：</div>
              <div class="title_value">{{desktopInfoList.todayincome}}</div>
            </div>
          </div>

          <div class="lef_top_r">
            <div class="title_name">今日利润额</div>
            <div class="title_value">{{desktopInfoList.todaybenefit}}</div>
          </div>
        </div>
        <div class="lef_bottom">
          <div class="desktop_top_bottom_title">
            <div class="title_name">累计订单数</div>
            <div class="title_value">{{desktopInfoList.allorder}}</div>
          </div>
          <div class="desktop_top_bottom_title">
            <div class="title_name">累计营业额</div>
            <div class="title_value">{{desktopInfoList.allincome}}</div>
          </div>
          <div class="desktop_top_bottom_title">
            <div class="title_name">累计利润额</div>
            <div class="title_value">{{desktopInfoList.allbenefit}}</div>
          </div>
        </div>
      </div>
      <div class="desktop_top_right">
        <ve-line
          
          height="280px"
          class="chart_order"
            :title="chartTitle.titleL"
          :x-axis="xAxis"
            :series="seriesL"
        ></ve-line>
      </div>
    </div>
    <div class="desktop_bottom">
      <div class="desktop_bottom_left">
        <div class="desktop_bottom_left_chart">
          <ve-column
            :tooltip-visible="true"
            :title="chartTitle.titleB"
            :x-axis="xAxis"
            height="370px"
            
            :series="series"
          ></ve-column>
        </div>
      </div>
      <div class="desktop_bottom_right">
        <div class="desktop_bottom_right_title">Top5 畅销商品</div>
        <div class="top_productlist">
          <div
            class="top_productlist_product"
            v-for="(item, index) in desktopInfoList.top5product"
            :key="index"
          >
            <!-- <div class="top_productlist_img">
              <img
                :src="item.img"
              />
            </div> -->
            <div class="top_productlist_content">
            <div class="top_productlist_pname">{{item.pname}}</div>
            <div class="top_productlist_saleinfo">
              <div class="top_productlist_bar">
                  <div class="bar_salecount" :style="{width:(item.allincome/desktopInfoList.top5product[0].allincome)*100+'%'}"></div>{{item.allincome}}
              </div>
            </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import VeLine from "v-charts/lib/line.common";
import VeColumn from "v-charts/lib/histogram.common";
import  "echarts/lib/component/title"

export default {
  name: "Desktop",
  components: { VeLine, VeColumn },
  data() {
    return {
        refresh:0,
        desktopInfoList:{},
        chartTitle:{
            titleL:{
                text:"近一周每日订单数",
                left:"40%",
                    
                textStyle:{
                    color:'#9c9c9c',
                }
            },
            titleB:{
                text:"近一周每日交易额",
                left:"40%",
                    
                textStyle:{
                    color:'#9c9c9c',
                }
            }
        },
      series: [
        {
          name: "当日营业额",
          type: "bar",
          barWidth: "60%", //这里是改变柱子的宽度
          data: [],
          itemStyle: {
            //通常情况下：
            normal: {
              //每个柱子的颜色即为colorList数组里的每一项，如果柱子数目多于colorList的长度，则柱子颜色循环使用该数组
              color: function (params) {
                var colorList = [
                  "#65d186",
                  "#f67287",
                  "#00E5EE",
                  "#c05bdd",
                  "#f29e3c",
                  "#7a65f2",
                  "#00BFFF",
                  "#f67287",
                ]; //每根柱子的颜色
                return colorList[params.dataIndex];
              },
            },
          },
        },
      ],
      seriesL: [
        {
          name: "当日订单量",
          type: "line",
          barWidth: "60%", //这里是改变柱子的宽度
          data: [],
          itemStyle: {
            //通常情况下：
            normal: {
              //每个柱子的颜色即为colorList数组里的每一项，如果柱子数目多于colorList的长度，则柱子颜色循环使用该数组
              color: "#00BFFF"
            },
          },
        },
      ],
      xAxis: [
        {
          data: [],
          name: "日期",
          axisLabel: {
            show: true,
            textStyle: {
              color: "#9c9c9c",
              fontSize: 14,
            },
          },
        },
      ],      
    };
  },

  methods: {
      getRecentOrderData(){

      },
    async getDesktopInfo() {
      const res = await this.$get(this.GLOBAL.serverAddress + "/getDesktopInfo/", 
      {'pname':this.searchpname,'contact':this.searchpcontact,'bdate':this.searchbdate,'edate':this.searchedate});
    //   console.log(res.data);
        
      if (res.status === 200) {
        this.desktopInfoList = res.data;    
        this.series[0].data = res.data.recentorderincome
        this.xAxis[0].data=res.data.recentorderdate
        this.seriesL[0].data=res.data.recentordercount
      }
    },
  },
  mounted() {
    this.getDesktopInfo()
    
  },
};
</script>


<style lang="less" scoped>
.desktop_bottom {
  color: #9c9c9c;
  display: flex;
  margin-top: 10px;
  .desktop_bottom_left {
    // margin-right:20px;
    flex: 1;
    height: 382px;
    background-color: #fff;
    // display: flex;
    flex-direction: column;
    // justify-content: center;
    align-items: center;
    // border: 1px solid red;
    
    .desktop_bottom_left_chart {
      margin-top:30px ;
      
      // flex:1;
    }
  }

  .desktop_bottom_right {
    background-color: #fff;
    margin-left: 13px;
    flex: 1;
    .desktop_bottom_right_title {
      text-align: center;
      padding: 10px;
    }
    .top_productlist {
      padding: 0px 20px;
      .top_productlist_product:nth-child(5){
          border-bottom: 0;
      }
      .top_productlist_product {
        display: flex;
        //   padding-top: 5px;
        height: 65px;
        border-bottom: 1px solid #eee;
        justify-content: center;
        align-items: center;
        // margin-bottom: 5px;
        .top_productlist_img {
          flex: 1;
          img {
            //   width:80%;
            height: 50px;
            object-fit: cover;
          }
        }
        .top_productlist_content{
            flex: 8;
        .top_productlist_pname {
          padding-bottom: 5px;
        }
        .top_productlist_saleinfo {
            display: flex;
            align-items: center;
          .top_productlist_bar {
            flex: 5;
            background-color:#eee;
            height: 10px;
            display: flex;
            align-items: center;
            .bar_salecount{
                // border:1px solid red;
                height: 10px;                
                background-color: #c5e1a5;
            }
          }
        }
        }
      }
    }
  }
}
.desktop_top {
  color: #9c9c9c;
  display: flex;
  .desktop_top_left {
    // padding: 15px;
    flex: 1;
    background-color: #fff;
    display: flex;
    flex-direction: column;

    //   justify-content: center;
    //   align-items: center;
    .lef_top {
      display: flex;

      font-weight: 600;
      font-size: 1.1em;
      margin: 10px;
      .lef_top_l {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        padding-left: 20px;
        .desktop_top_left_title {
          //   padding: 10px;
          display: flex;
          align-items: center;
          text-align: center;
          .title_name {
            flex: 1;
          }

          .title_value {
            flex: 1;
            font-size: 1.4em;
            text-align: center;
          }
        }
      }
      .lef_top_r {
        flex: 1;
        // border: 1px solid green;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        border-left: 1px solid #eee;
        padding: 20px;
        .title_name {
          // text-align: center;
          padding: 10px;
          color: #4f4f4f;
          font-size: 1.2em;
        }
        .title_value {
          padding: 10px;
          font-size: 2em;
          color: #00b2ee;
        }
      }
    }

    .lef_bottom {
      border-top: 1px solid #eee;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      padding: 20px;
      .desktop_top_bottom_title {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        height: 60px;
        border-right: 1px solid #eee;

        .title_name {
          // border: 1px solid #eee;
          flex: 1;
        }

        .title_value {
          flex: 1;
          font-size: 1.2em;
        }
      }
      .desktop_top_bottom_title:nth-child(3) {
        border-right: 0;
      }
    }
  }

  .desktop_top_right {
    flex: 1;
    margin-left: 13px;
    background-color: #fff;
    // display: flex;

    .chart_order {
      // height: 300px;
      padding-top: 35px;
    }
  }
}

.desktop_brief {
  display: flex;
  width: 100%;
  // border: 1px solid green;
  // background-color: green;
  margin-bottom: 15px;
  justify-content: space-between;
  align-items: center;
  .desktop_brief_item {
    flex: 1;
    width: 80%;
    display: flex;
    margin-right: 15px;
    height: 70px;
    color: #668b8b;

    .desktop_brief_item_icon {
      flex: 1;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      i {
        color: #fff;
        font-size:30px;
      }
    }
    .desktop_brief_item_right {
      flex: 2;
      background-color: #fff;
      text-align: center;
      // width: 80%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      font-weight: 600;
      .desktop_brief_item_right_title {
      }
      .desktop_brief_item_right_value {
        font-size: 1.6em;
      }
    }
  }
  .desktop_brief_item:nth-child(5) {
    margin-right: 0;
  }
}

.noresult {
  text-align: center;
  font-size: 2em;
  color: rgb(160, 154, 154);
}
.actived {
  background-color: rgba(190, 221, 140, 0.9);
}
table.dataintable {
  margin-top: 15px;
  border-collapse: collapse;
  border: 1px solid white;
  width: 98%;
  font-size: 0.95em;
  background-color: #eeeed1;
}
table.dataintable th {
  vertical-align: middle;
  /* padding:5px 15px 5px 6px; */
  background-color: #e8e8e8;
  border: 1px solid white;
  text-align: center;
  color: rgb(173, 169, 169);
}
table.dataintable td {
  vertical-align: text-top;
  /* padding:6px 15px 6px 6px; */
  border: 1px solid white;
  line-height: 40px;
}
/* table.dataintable tr{
  background-color:#e5ebdc;
} */
/* table.dataintable tr:nth-child(odd) {
  background-color: #f6faf6 ;
}
table.dataintable tr:nth-child(even) {
  background-color: #e5ebdc ;
} */

.hidden_view {
  width: 100%;
  background: #698b22;
  opacity: 0.2;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 99;
}
</style>