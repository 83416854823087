<template>
  <div style="height: 100%;">
      <Toast ref="toast" />
    <div
      ref="curDiv"
      v-show="isshowcalender"
      style="z-index: 999; position: absolute"
    >
      <Calender ref="Calender" v-on:choseDay="clickDay"></Calender>
    </div>
    <div class="grid-form1" style="padding: 0">
      <div class="neworderinfo">
        <label>客人信息</label>
        <div class="ni_user">
          
          <div class="deliverytype">
            <label>客人微信：{{user_wxname+"("+user_id+")"}}</label>
          <div><input @change="changeDeliver" type="radio" name="deliveryType" value="1" v-model="deliverytype"/> 配送</div>     
          <div style="padding-left:10px;"><input @change="changeDeliver" type="radio" name="deliveryType" value="2"  v-model="deliverytype"/> 自提</div>
          </div>
          <div v-if="deliverytype=='1'">

          <label> 收货人：
            <input
                    type="text"
                    class="form-control1"
                    style="width:100px;"
                    id="txtUserName"
                    name="txtContractNumber"
                    placeholder="请输入联系人姓名"
                    v-model.trim="user_contactname"
                    />
            </label>
          <label>电话：
            <input
                    type="text"
                    class="form-control1"
                    style="width:130px;"
                    id="txtUserTel"
                    name="txtContractNumber"
                    placeholder="请输入联系人电话"
                    v-model.trim="user_tel"
                    /></label>
          <label>收货地址：
            省<input
                    type="text"
                    class="form-control1"
                    style="width:50px;"
                    id="txtUserTel"
                    name="txtContractNumber"
                    placeholder="请输入"
                    v-model.trim="user_sheng"
                    />
                    市<input
                    type="text"
                    class="form-control1"
                    style="width:120px;"
                    id="txtUserTel"
                    name="txtContractNumber"
                    placeholder="请输入"
                    v-model.trim="user_city"
                    />地址<input
                    type="text"
                    class="form-control1"
                    style="width:300px;"
                    id="txtUserTel"
                    name="txtContractNumber"
                    placeholder="请输入详细地址"
                    v-model.trim="user_address"
                    /></label>
          </div>
          <div v-if="deliverytype=='2'">
            <label> 取货人：
            <input
                    type="text"
                    class="form-control1"
                    style="width:100px;"
                    id="txtPickupUserName"
                    name="txtContractNumber"
                    placeholder="请输入联系人姓名"
                    v-model.trim="user_contactname"
                    />
            </label>
          <label>电话：
            <input
                    type="text"
                    class="form-control1"
                    style="width:130px;"
                    id="txtPickupUserTel"
                    name="txtContractNumber"
                    placeholder="请输入联系人电话"
                    v-model.trim="user_tel"
                    /></label>
          <label>取货地点：
            <select name="sltPickupCity" v-model="pickupcity">
              <option value="">请选择自取地点</option>
              <option :value="item.pickupid" v-for="(item,index) in pickupList" :key="index">{{item.cityname}}</option>
            </select>
          </label>
          </div>
        </div>
      </div>
      <div class="neworderinfo">
        <label>商品信息</label>
        <div v-if="selectedPList.length>0">
                    <div class="selected_product">
                        <table class="dataintable" >
                    <tr style="font-weight: bold">
                        <th width="50px">删除</th>
                        <th>商品名称</th>   
                        <th width="180px">商品规格</th>
                        <th width="140px">售价<br>税费/押金/小费</th>
                        <th width="80px">剩余库存</th>
                        <th width="40px">购买数量</th>
                        <th width="60px">税费</th>
                        <th width="60px">押金</th>
                        <th width="60px">小费</th>
                        <th width="90px">商品总价</th>
                    </tr>
                    <tr v-for="(item, index) in selectedPList" :key="index">
                        <td><label style="color:red;" @click="delSelectedProduct(item)">X</label> {{item.posn}}</td>
                        <td>{{item.pname}}</td>
                        <td>{{item.guigename+':'+item.guige}}</td>
                        <td>{{item.price}}<br>{{item.taxesname}}/{{item.deposit}}/{{item.tips}}<span style="color:#999;margin:0px;padding:0px;">%</span></td>
                        <td>{{item.guigenum}}</td>
                        <td>
                            <input type="text"
                    class="form-control1"
                     v-float-only v-model="item.pbuycount" style="width:40px;background-color:white;" @change="calNewPrice(item)">
                        </td>
                        <td><input type="text"
                    class="form-control1" v-float-only v-model="item.alltaxes" style="width:60px;background-color:white;" @change="calNewPrice2(item)"></td>
                        <td><input type="text"
                    class="form-control1" v-float-only v-model="item.alldeposit" style="width:60px;background-color:white;" @change="calNewPrice2(item)"></td>
                        <td><input type="text"
                    class="form-control1" v-float-only v-model="item.alltips" style="width:60px;background-color:white;" @change="calNewPrice2(item)"></td>
                        <td><input type="text"
                    class="form-control1" v-float-only v-model="item.pbuyprice" style="width:90px;background-color:white;" @change="getOrderTotalPrice"></td>
                    </tr>
                    <tr style="font-weight:bold;text-align:center;">
 
                      <td colspan="2" style="text-align:right;">订单总价：</td>
                      <td style="text-align:center;color:green;font-size:1.5em;">{{order_allprice}}</td>
                      <td style="text-align:right;">运费：</td>
                      <td style="text-align:left;color:red;font-size:1.5em;">
                        <input type="text"
                    class="form-control1" v-float-only v-model="real_freight" style="width:90px;background-color:white;" @change="getOrderTotalPrice2">
                    </td>
                      <td style="text-align:right;">合计</td>
                      <td  style="text-align:center;">{{order_alltaxes}}</td>
                      <td  style="text-align:center;">{{order_alldeposit}}</td>
                      <td  style="text-align:center;">{{order_alltips}}</td>
                      <td style="text-align:center;color:blue;font-size:1.3em;">{{order_spprice}}</td>
                    </tr>
                        </table>
                    </div>
                    <div class="search_condition2">
                    订单备注：
                <div class="pname_value">
                    <textarea
                    class="form-control1"
                    style="height: 60px; width: 600px"
                    v-model.trim="ordernotes"
                    />
                </div>
                    </div>
                    <div  style="padding-top: 10px;text-align:right;padding-right:50px;">
          
                    <button class="btn btn-default" type="button" @click="clearOrder">
                      <i class="fa fa-remove" aria-hidden="true"></i>&nbsp;全部清空
                    </button>
                    <button
                      class="btn btn-default"
                      type="button"
                      :disabled="submitSaving"
                      @click="neworder"
                    >
                      <i class="fa fa-save" aria-hidden="true"></i>&nbsp;
                      {{ submitSaving ? "提交中.." : "提交订单" }}
                    </button>
                  </div>
                    <div>&nbsp;&nbsp;</div>
                </div>
      </div>
    </div>
    <div class="grid-form1" style="padding: 0">
      <div style="margin: 0; padding: 0; vertical-align: middle;">
        <div class="search_condition2">
          <div class="pname2">
            <label
              for="txtContractNumber"
              >第一步：选择客人</label
            >
          </div>
          <!-- <div class="pname_value2"> -->
            <div class="client_search">
                    <label
                    for="txtContractNumber"
                    class="control-label"
                    >客人信息 </label
                    ><input
                    type="text"
                    class="form-control1"
                    style="width:260px;"
                    id="txtContractNumber"
                    name="txtContractNumber"
                    placeholder="请输入微信昵称/联系人姓名/客人ID"
                    v-model.trim="searchwxname"
                    @keyup.enter="searchAllUser"
                    />
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <button
                    class="btn btn-default"
                    type="button"
                    @click="searchAllUser"
                    >
                    <i class="fa fa-search" aria-hidden="true"></i>&nbsp;搜索
                    </button>
                </div>
          <!-- </div> -->
        </div>
        <div
            class="tab-content"
            style=" margin-top: 5px; padding: 0"
            v-if="isshow"
        >
            
            <div >
            <table class="dataintable" v-if="orderList2.length > 0">
            <tr style="font-weight: bold">
            
                <th width="80px">客人ID</th>
                <th width="180px">微信昵称</th>
                <th width="60px">微信头像</th>
                <th width="180px">客人姓名</th>   
                <th width="180px">客人电话</th>   
                <th width="180px">收货城市</th>              
                <th width="90px">操作</th>
            </tr>
            <tr v-for="(item, index) in currentpagelist2"
                :key="index"
                @mouseover="mouseOver2(index)"
                @mouseout="mouseOut2"
                @click="screen_click2(item)"
                :class="{ actived: currentIndex2 == index }">
                    
                <td>{{item.userid}}</td>            
                <td>{{item.wxname}}</td>            
                <td><img :src="item.headimgurl" style="height:60px; width:60px"></td>            
                <td>{{item.contactname}}</td>            
                <td>{{item.tel}}</td>            
                <td>{{item.city}}</td>        
                <td></td>            
            </tr>
            </table>
            </div>
            <div v-if="shownoresult2" class="noresult">没有找到符合查询条件的客人信息！</div>
            <div
            class="pagenum_info"
            v-if="!shownoresult2"
          >
            <span class="btmInfo" style="vertical-align: middle"
              >&nbsp;&nbsp;共有 {{ recordcount2 }} 条记录，当前第
              {{ currentpage2 }}/{{ pagecount2 }} 页</span
            >
            <div style="float: right; padding-right: 30px">
            <button class="btn btn-default" type="button" @click="getNewPage2(1)">
              首页
            </button>
            <button
              class="btn btn-default"
              type="button"
              @click="getNewPage2(currentpage2 - 1)"
            >
              上页
            </button>
            <button
              class="btn btn-default"
              type="button"
              @click="getNewPage2(currentpage2 + 1)"
            >
              下页
            </button>
            <button
              class="btn btn-default"
              type="button"
              @click="getNewPage2(pagecount2)"
            >
              尾页
            </button>
            <span class="btmInfo" style="margin-right: 2px">跳转到</span>
            <input
              type="text"
              size="1"
              id="txtGotoPage"
              autocomplete="off"
              v-int-only
              style="margin-right: 10px; font-size: 0.9em"
              v-model.trim="gotopage2"
            />
            <button
              type="button"
              class="btn btn-default"
              @click="getNewPage2(gotopage2)"
            >
              跳转
            </button>
            </div>
          </div>
        </div>
        
      </div>

    </div>
        <div class="orderlist" >
            <div class="order_item" >
                <div class="search_condition2">
                    <div class="order_item_title"><label
                    for="txtContractNumber"
                    >第二步：选择商品：</label
                    ></div>
                    <div class="pname">
            <label
              for="txtContractNumber"
              class="control-label"
              >商品名称</label
            >
          </div>
          <div class="pname_value">
            <input
              type="text"
              class="form-control1"
              id="txtContractNumber"
              name="txtContractNumber"
              v-model.trim="searchpname"
              @keyup.enter="search"
            />
          </div>
          <div class="sltStyle">
            <select name="sltProductClass" v-model="productFClass" @change="setCClass">
              <option value="">请选择商品类别</option>
              <option :value="item.fcid" v-for="(item,index) in classFList" :key="index">{{item.fcname}}</option>
            </select>
            <select name="sltProductCClass" v-model="productCClass">
              <option value="">请选择二级分类</option>
              <option :value="item.ccid" v-for="(item,index) in classCList" :key="index">{{item.ccname}}</option>
            </select>
          </div>
          
          <!-- <div class="sltStyle">
            <select name="sltProductStatus" v-model="productStatus">
              <option value="">请选择产品状态</option>
              <option value="1">待上架</option>
              <option value="2">已上架</option>
              <option value="3">已下架</option>
            </select>
          </div> -->
          
            <div>&nbsp;&nbsp;&nbsp;&nbsp;</div>
          
            <button class="btn btn-default" type="button" @click="clearSearch">
              <i class="fa fa-refresh" aria-hidden="true"></i>&nbsp;清空
            </button>
            <button
              class="btn btn-default"
              type="button"
              @click="search"
            >
              <i class="fa fa-search" aria-hidden="true"></i>&nbsp;搜索
            </button>
                </div>
                

                

                <div
                    class="tab-content"
                    style=" margin-top: 5px; padding: 0"
                    v-if="show"
                >
                    <table class="dataintable" v-if="orderList.length > 0">
                    <tr style="font-weight: bold">
                  
                        <th width="60px">商品ID</th>
                        <th width="90px">商品图片</th>   
                        <th >商品名称</th>
                        <th width="200px">所属类别</th>
                        <th width="150px">规格</th>
                        <th width="80px">价格</th>
                        <th width="60px">税费</th>
                        <th width="80px">押金</th>
                        <th width="80px">小费</th>
                        <th width="80px">剩余库存</th>
                    </tr>
                    <tr v-for="(item, index) in currentpagelist"
                        :key="index"
                        @mouseover="mouseOver(index)"
                        @mouseout="mouseOut"
                        @click="screen_click(item)"
                        :class="{ actived: currentIndex == index }">
                            
                        <td>{{item.id}}</td>            
                        <td class="productinfo_head_img"><img  :src="item.imgurl"/></td>            
                        <td>{{item.pname}}</td>            
                        <td>{{item.pclass}}</td>          
                        <td>{{item.guigename+':'+item.guige}}</td>          
                        <td>{{item.price}}</td>          
                        <td>{{item.taxesname}}</td>          
                        <td>{{item.deposit}}</td>          
                        <td>{{item.tips}}<span style="color:#999;margin:0px;padding:0px;" v-if="item.tipstype=='1'">%</span></td>          
                        <td>{{item.guigenum}}</td>          
                    </tr>
                    </table>
                    <div v-if="shownoresult" class="noresult">没有找到符合查询条件的可批发产品！</div>
                    <div
                  class="pagenum_info"
                  v-if="!shownoresult"
                >
                  <span class="btmInfo" style="vertical-align: middle"
                    >&nbsp;&nbsp;共有 {{ recordcount }} 条记录，当前第
                    {{ currentpage }}/{{ pagecount }} 页</span
                  >
                  <div style="float: right; padding-right: 30px">
                  <button class="btn btn-default" type="button" @click="getNewPage(1)">
                    首页
                  </button>
                  <button
                    class="btn btn-default"
                    type="button"
                    @click="getNewPage(currentpage - 1)"
                  >
                    上页
                  </button>
                  <button
                    class="btn btn-default"
                    type="button"
                    @click="getNewPage(currentpage + 1)"
                  >
                    下页
                  </button>
                  <button
                    class="btn btn-default"
                    type="button"
                    @click="getNewPage(pagecount)"
                  >
                    尾页
                  </button>
                  <span class="btmInfo" style="margin-right: 2px">跳转到</span>
                  <input
                    type="text"
                    size="1"
                    id="txtGotoPage"
                    autocomplete="off"
                    v-int-only
                    style="margin-right: 10px; font-size: 0.9em"
                    v-model.trim="gotopage"
                  />
                  <button
                    type="button"
                    class="btn btn-default"
                    @click="getNewPage(gotopage)"
                  >
                    跳转
                  </button>
                </div>
                </div>
                
              </div>
            </div>
            
            
        </div>
       
      
    </div>
  <!-- </div> -->
</template>

<script>
import axios from "axios";
import Toast from "../../components/Toast";

import Calender from "../../components/vue-calendar-component";

export default {
  name: "newROrder",
  components: {
    Calender,
    Toast
  },
  data() {
    return {
      deliverytype:'1',
        showClient:false,
        searchedate:'',
        // searchbdate:'',
        shownoresult:false,
        shownoresult2:true,
        orderList:[],
      orderListC: [],
      selectedPList:[],
      orderList2: [],
      clientID:'',
      currentIndex: -1,
      currentIndex2: -1,
      currentpage: 1,
      currentpage2:1,
      gotopage: "",
      gotopage2: "",
      searchpcontact: "",
      searchpname: "",
      searchwxname:"",
      paystatus:"未支付",
      devstatus:"未取货",
        isshowcalender: false,
      show: false,
      isshow: false,
      //   isshowupdate: false,
      win_height: "",
      currentSID: 0,
      currentDateS:"",
      ordernotes:'',
      order_allnum:0,
      submitSaving: false,
      user_id:'',
      user_wxname:'',
      user_contactname:'',
      user_tel:'',
      user_sheng:'',
      user_city:'',
      user_address:'',
      user_full_address:'',
      order_spprice:0,
      order_alltaxes:0,
      order_alltips:0,
      order_alldeposit:0,
      order_allprice:0,
      classFList:[],
      classCList:[],
      pickupList:[],
      productFClass: "",
      productCClass: "",
      productStatus: "",
      pickupcity:"",
      base_freight: 0,
      freight:0,
      real_freight:0
    };
  },
  methods: {
    // selectClient(){
    //     this.showClient=true
    // },
    clearSearch() {
      this.searchpname = ""
      this.productStatus=""
      this.productFClass=""
      this.productCClass=""
      this.classCList=[]
      // this.cancelAllList()
    },
    setCClass(){
        this.classFList.forEach((v)=>{
            if(v.fcid==this.productFClass){
                this.productCClass=""
                // this.classCList=""
                this.classCList=v.cclass
            }
        })
        // alert(this.productFClass)
    },
    screen_click(pid) {
        let tmpflag = false
        let tmpcount=1
        let tmpindex=-1

        tmpindex=this.selectedPList.indexOf(pid)
      
        if(tmpindex>=0){
            // pid.pbuycount=parseFloat(pid.pbuycount)+1
            // pid.pbuyprice=parseFloat(pid.pwsprice)*pid.pbuycount
            // Vue.set(this.selectedPList,tmpindex,pid)
            tmpflag=true
        }
        
        if(!tmpflag){
            // pid.pbuycount=1
            // pid.pbuyprice=pid.pwsprice
            this.selectedPList.push(pid)
        }
        
    },
    changeDeliver(){
      if(this.deliverytype=="1"){
        this.getCurrentFreight(this.user_city)
      
      }else{
        this.base_freight=0
        this.freight=0
      }
      
      this.calFreight()
    },
    screen_click2(pid) {
        let tmpflag = false
        let tmpcount=1
        let tmpindex=-1

        tmpindex=this.currentpagelist2.indexOf(pid)
        console.log(pid)
        if(tmpindex>=0){
            this.user_id=pid.userid
            this.user_wxname=pid.wxname
            this.user_contactname=pid.contactname
            this.user_tel=pid.tel
            this.user_sheng=pid.sheng
            this.user_city=pid.city
            this.user_address=pid.address
            this.user_full_address=pid.sheng + ' ' + pid.city + ' ' + pid.address
            // Vue.set(this.selectedPList,tmpindex,pid)
            this.isshow=false

            if(this.deliverytype=="1"){
              this.getCurrentFreight(pid.city)
            }
        }
        
        // if(!tmpflag){
        //     pid.pbuycount=1
        //     pid.pbuyprice=pid.pwsprice
        //     this.selectedPList.push(pid)
        // }
        
    },
    delSelectedProduct(item){
        let tmpindex=-1

        tmpindex=this.selectedPList.indexOf(item)
        
        this.selectedPList.splice(tmpindex,1)
        this.getOrderTotalPrice()
    },
    calNewPrice(item){
      let tmpindex=-1

      tmpindex=this.selectedPList.indexOf(item)
      let tmpPrice=this.selectedPList[tmpindex].price
      let tmpCount=this.selectedPList[tmpindex].pbuycount
      let tmpCountRest=this.selectedPList[tmpindex].guigenum
      let tmpTaxes=this.selectedPList[tmpindex].taxesvalue
      let tmpDeposit=this.selectedPList[tmpindex].deposit
      let tmpTips=this.selectedPList[tmpindex].tips
      let tmpTipsType=this.selectedPList[tmpindex].tipstype

      if(tmpCount>tmpCountRest){
        this.$refs.toast.toast("购买数量超库存")
        return false
      }
      
      // console.log(tmpPrice*tmpCount)
      // console.log(parseFloat(tmpPrice)*parseFloat(tmpCount))
      if(tmpCount){
        item.alltaxes=(parseFloat(tmpPrice)*parseFloat(tmpTaxes)*parseFloat(tmpCount)).toFixed(2)
        item.alldeposit=(parseFloat(tmpDeposit)*parseFloat(tmpCount)).toFixed(2)
        if(tmpTipsType=='1'){
          item.alltips=(parseFloat(tmpPrice)*parseFloat(tmpTips)*parseFloat(tmpCount)).toFixed(2)
          if(item.alltips>0){
            item.alltips=(item.alltips/100).toFixed(2)
          }
        }else{

          item.alltips=(parseFloat(tmpTips)*parseFloat(tmpCount)).toFixed(2)
        }
        item.pbuyprice=(parseFloat(tmpPrice)*parseFloat(tmpCount)+parseFloat(item.alltaxes)+parseFloat(item.alldeposit)+parseFloat(item.alltips)).toFixed(2)
      }else{
        item.pbuyprice=0
      }

      // this.selectedPList[tmpindex].pbuyprice=parseFloat(tmpPrice)*parseFloat(tmpCount)

      Vue.set(this.selectedPList,tmpindex,item)
      
      // this.selectedPList.splice(tmpindex,1)
      this.getOrderTotalPrice()
    },
    calNewPrice2(item){
      let tmpindex=-1

      tmpindex=this.selectedPList.indexOf(item)
      let tmpPrice=this.selectedPList[tmpindex].price
      let tmpCount=this.selectedPList[tmpindex].pbuycount
      let tmpCountRest=this.selectedPList[tmpindex].guigenum
      let tmpTaxes=this.selectedPList[tmpindex].taxesvalue
      let tmpDeposit=this.selectedPList[tmpindex].deposit
      let tmpTips=this.selectedPList[tmpindex].tips
      if(item.alltaxes==''){
        item.alltaxes=0
      }
      if(item.alldeposit==''){
        item.alldeposit=0
      }
      if(item.alltips==''){
        item.alltips=0
      }
      
      // console.log(tmpPrice*tmpCount)
      // console.log(parseFloat(tmpPrice)*parseFloat(tmpCount))
      // if(tmpCount){
        // item.alltaxes=(parseFloat(tmpPrice)*parseFloat(tmpTaxes)*parseFloat(tmpCount)).toFixed(2)
        // item.alldeposit=(parseFloat(tmpDeposit)*parseFloat(tmpCount)).toFixed(2)
        // item.alltips=(parseFloat(tmpTips)*parseFloat(tmpCount)).toFixed(2)
        item.pbuyprice=(parseFloat(tmpPrice)*parseFloat(tmpCount)+parseFloat(item.alltaxes)+parseFloat(item.alldeposit)+parseFloat(item.alltips)).toFixed(2)
      // }else{
      //   item.pbuyprice=0
      // }

      // this.selectedPList[tmpindex].pbuyprice=parseFloat(tmpPrice)*parseFloat(tmpCount)

      Vue.set(this.selectedPList,tmpindex,item)
      
      // this.selectedPList.splice(tmpindex,1)
      this.getOrderTotalPrice()
    },
    getOrderTotalPrice2(){
      if(this.real_freight==''){
        this.real_freight=0
      }
      this.order_allprice=(parseFloat(this.order_spprice)+parseFloat(this.real_freight)).toFixed(2)
    },
    getOrderTotalPrice(){
      let tmpP=0
      let tmpTaxes=0
      let tmpTips=0
      let tmpDeposit=0
      this.selectedPList.forEach((v)=>{
        if(v.pbuyprice){
          tmpP+=parseFloat(v.pbuyprice)
          tmpTaxes+=parseFloat(v.alltaxes)
          tmpDeposit+=parseFloat(v.alldeposit)
          tmpTips+=parseFloat(v.alltips)
        }
      })
      this.order_spprice=parseFloat(tmpP).toFixed(2)
      this.order_alltaxes=parseFloat(tmpTaxes).toFixed(2)
      this.order_alldeposit=parseFloat(tmpDeposit).toFixed(2)
      this.order_alltips=parseFloat(tmpTips).toFixed(2)

      this.calFreight()

    },
    calFreight(){
      if(parseFloat(this.order_spprice)>=parseFloat(this.freight)||this.deliverytype=="2"){
        this.real_freight=0
      }else{
        this.real_freight=this.base_freight
      }
      this.order_allprice=(parseFloat(this.order_spprice)+parseFloat(this.real_freight)).toFixed(2)
    },
      mouseOver(index) {
      // alert(index)
      this.currentIndex = index;
    },
    mouseOver2(index) {
      // alert(index)
      this.currentIndex2 = index;
    },
    mouseOut() {
      // alert(index)
      this.currentIndex = -1;
    },
    mouseOut2() {
      // alert(index)
      this.currentIndex2 = -1;
    },
    clearOrder(){
      this.user_id=""
      this.user_wxname=""
      this.user_contactname=""
      this.user_tel=""
      this.user_sheng=""
      this.user_city=""
      this.user_address=""
      this.ordernotes=""
      this.real_freight=0
      this.freight=0
      this.base_freight=0
      this.order_spprice=0
      this.order_alltaxes=0
      this.order_alltips=0
      this.order_alldeposit=0
      this.order_allprice=0
      this.selectedPList.forEach((v)=>{
        v.pbuyprice=0
        v.pbuycount=0
        v.alltaxes=0
        v.alldeposit=0
        v.alltips=0
      })
      this.selectedPList=[]
    },
      // goback() {
      //     this.$router.go(-1)
      // },
    async neworder(){
      
      let flag=true
      if(this.user_id==''){
          this.$refs.toast.toast("请选择下单客人")
          return false
      }
      if(this.user_contactname==''){
          this.$refs.toast.toast("请输入联系人")
          return false
      }
      if(this.user_tel==''){
          this.$refs.toast.toast("请输入联系电话")
          return false
      }
      if(this.deliverytype=="2" && this.pickupcity==""){
          this.$refs.toast.toast("请选择取货地点")
          return false
      }
      // console.log(this.selectedPList[0].pbuycount)
      this.selectedPList.forEach((v)=>{
        
          if(v.pbuycount==""||v.pbuycount=="0"){
              this.$refs.toast.toast("请输入购买数量")
                flag= false
                return
          }
          if(v.pbuycount>v.guigenum){
              this.$refs.toast.toast("购买数量超库存")
                flag= false
                return
          }
          if(v.pbuyprice==""){
              this.$refs.toast.toast("请输入商品总价")
                flag= false
                return
          }
      })
      if(flag){
        if(confirm("您确认要提交该订单吗？")){
            this.submitSaving=true

            let orderDetailInfo=this.selectedPList
            // let totalPrice=0
            this.order_allnum=0
            this.selectedPList.forEach(v=>{
              this.order_allnum+=parseInt(v.pbuycount)
            })
            // console.log(orderDetailInfo)

            // let contactname=''
            // let contacttel=''
            // let contactaddress=''
            // this.orderListC.forEach(v=>{
            //     if(v.id==this.clientID){
            //         contactname=v.contact
            //         contacttel=v.tel
            //         contactaddress=v.address
            //     }
            // })
                
            let orderInfo={"user_id":this.user_id,"user_wxname":this.user_wxname,"user_contactname":this.user_contactname,
              "user_tel":this.user_tel,"user_sheng":this.user_sheng,"user_city":this.user_city,"user_address":this.user_address,
              "real_freight":this.real_freight,"order_spprice":this.order_spprice,"order_spprice":this.order_spprice,
              "order_allprice":this.order_allprice,"remarks":this.ordernotes,"num":this.order_allnum,"deliverytype":this.deliverytype,"pickupcity":this.pickupcity,"pickupconfig":""}
            const res=await this.$post(this.GLOBAL.serverAddress + "/submitDKOrder/",{orderInfo,orderDetailInfo,})
            console.log(res)
            if(res.data===true){
                this.$refs.toast.toast("下单成功")
                setTimeout(()=>{
                    this.$router.go(0)
                    },1500)
                
                // this.$refs.toast.toast("备注更新成功")
            }else{
                alert(res.data)
                this.submitSaving = false
                //   this.$refs.toast.toast("提交失败请重试")
            }
          }
        }

    },
      viewOrderDetail(id){
          this.$router.push('/orderdetail?id='+id)
      },
      
    hideCalender() {
      this.isshowcalender = false;
    },
    clickDay(data) {
    //   this.searchyxqdate = data;
        // console.log(this.currentDateS)
      if(this.currentDateS==='b'){
          this.searchbdate=data
      }else{
          this.searchedate=data
      }
      this.isshowcalender = false;
    },
    showcalender(e,v) {
      var divC = this.$refs.curDiv;
      this.currentDateS=v
      divC.style.top = e.target.getBoundingClientRect().top + 40 + "px";
      divC.style.left = e.target.getBoundingClientRect().left + "px";

      this.isshowcalender = true;
    },

    // clearSearch() {
    //   this.searchpname = ""
    //   this.searchpcontact = ""
    //   this.searchedate=""
    //     this.searchbdate=""
    //     this.devstatus="未取货"
    //     this.paystatus="未支付"
    // },
    getNewPage(pagenum) {
      if (pagenum >= 1 && pagenum <= this.pagecount) {
        this.currentpage = pagenum;
        // console.log(this.pages)
        // return this.pages[this.currentpage - 1];
      }
    },
    getNewPage2(pagenum) {
      if (pagenum >= 1 && pagenum <= this.pagecount2) {
        this.currentpage2 = pagenum;
        // console.log(this.pages)
        // return this.pages[this.currentpage - 1];
      }
    },
    async getCurrentFreight(city){
        const res2 = await this.$get(this.GLOBAL.serverAddress + "/getCurrentFreight/",{'city':city});
      console.log(res2);

      if (res2.status === 200) {  
        this.base_freight = res2.data.base_freight;
        this.freight = res2.data.freight;

        this.calFreight()
      }

    },
    async getClientList(){
        const res2 = await this.$get(this.GLOBAL.serverAddress + "/getAllProductClass/",{});
      console.log(res2);

      if (res2.status === 200) {
        this.classFList = res2.data;
      }

    },
    async getPickupList(){
        const res2 = await this.$get(this.GLOBAL.serverAddress + "/getAllPickupConfig/",{});
      console.log(res2);

      if (res2.status === 200) {
        this.pickupList = res2.data;
      }

    },
    async searchAllUser() {
        this.currentpage2=1
        // this.etype===""
        // this.productshow=false
        // this.classshow=false
        // this.wxname=""
        // this.membername=""
      //   if (days) {
      axios({
        method: "get",
        params: {
          wxname: this.searchwxname
        },
        url: this.GLOBAL.serverAddress + "/searchAllUser/",
      }).then(
        (response) => {
          console.log(response)
          this.orderList2 = response.data;
          this.isshow=true
          if(this.orderList2.length > 0){
              this.shownoresult2=false
          }else{
              this.shownoresult2=true
              
          }
        },
        (error) => {
          console.log("出现错误", error.message);
          return false;
        }
      );

      //   }
    },
    async search() {
      this.currentpage=1
      let tmpClass=""
      if(this.productCClass!=''){
          tmpClass=this.productCClass
      }else{
          tmpClass=this.productFClass
      }
      const res = await this.$get(this.GLOBAL.serverAddress + "/searchAllProductForOrder/", 
      // const res = await this.$get(this.GLOBAL.serverAddress + "/getCurrentFreight/", 
      {'pname':this.searchpname,'pclass':tmpClass});
    //   console.log(res);
        
      if (res.status === 200) {
        this.orderList = res.data;
        this.show=true
        if(this.orderList.length>0){
            this.shownoresult=false
        }else{
            this.shownoresult=true
        }
      }

    },
  },
  computed: {
    currentpagelist2() {
      return this.pages2[this.currentpage2 - 1];
    },
    currentpagelist() {
      return this.pages[this.currentpage - 1];
    },
    recordcount() {
      return this.orderList.length;
    },
    recordcount2() {
      return this.orderList2.length;
    },
    pagecount() {
      return Math.ceil(this.orderList.length / 20);
    },
    pagecount2() {
      return Math.ceil(this.orderList2.length / 10);
    },
    pages() {
      const pages = [];
      this.orderList.forEach((item, index) => {
        const page = Math.floor(index / 20);
        if (!pages[page]) {
          pages[page] = [];
        }
        pages[page].push(item);
      });
      return pages;
    },
    pages2() {
      const pages = [];
      this.orderList2.forEach((item, index) => {
        const page = Math.floor(index / 10);
        if (!pages[page]) {
          pages[page] = [];
        }
        pages[page].push(item);
      });
      return pages;
    },
  },
  mounted() {
    this.haveRights('|0504|')
    // this.search();
    this.getClientList()
    this.getPickupList()
    window.addEventListener("click", this.hideCalender);
    this.win_height = window.innerHeight;
  },
  beforeDestroy() {
    // 实例销毁之前对点击事件进行解绑
    window.removeEventListener("click", this.hideCalender);
  },
};
</script>

<style scoped lang="less">
.neworderinfo{
  margin:20px;
  color: #999;
  .ni_user{
    padding-left: 30px;
    label{
      padding-right: 50px;
      font-weight:normal;
    }
    .deliverytype{
      display:flex;
    }
  }
  
}

.search_condition2 {
    display: flex;
    // white-space: nowrap;
    // justify-content:center;;
    align-items: center;
    
    padding:10px;
    // padding-bottom: 20px;
  .pname2 {
    //   display: flex;
    // // border: 1px solid red;
    // // flex: 2;
    // align-items: center;
    padding-left: 10px;
    text-align: right;
    color: #999;
  }

  .pname_value2 {
    // flex: 2;
    padding-left: 5px;
    color:#999;
    select{
      border: 1px solid #E9E9E9;
    font-size: 0.9em;
    outline: none;
    // padding: 0.5em 1em;
    color: #999;
    }
  }
  .client_search{
      padding-left: 20px;
      display: flex;
      align-items: center;
  }
  .bdate{
      padding-left: 100px;
    //   flex:1;
  }
  .sltStyle {
    
    padding-left: 5px;
    color:#999;
    select{
      border: 1px solid #E9E9E9;
    font-size: 0.9em;
    outline: none;
    padding: 0.5em 1em;
    color: #999;
    }
  }
}
.selected_product{
    // padding: 10px;
}
.search_condition {
    display: flex;
    // white-space: nowrap;
    justify-content:center;
    align-items: center;
    text-align: right;
    padding:10px;
  .pname {
    //   display: flex;
    // border: 1px solid red;
    flex: 1;
    padding-right: 5px;
  }

  .pname_value {
    flex: 3;
    // padding-left: 5px;
  }

  .cname {
    flex: 1;
    padding-right: 5px;
  }

  .cname_value {
    flex: 2;
  }
  
  .bdate {
    flex: 1;
    padding-right: 5px;
  }

  .bdate_value {
    flex: 1;
  }

  .edate {
    //   border: 1px solid green;
    flex: 1;
    text-align: center;
    // display: flex;
    // align-items: center;
    // justify-content: center;
  }

  .edate_value {
    flex: 1;
  }
}

.orderlist {
  color: #9c9c9c;

  // padding:10px;
  .order_item {
    //   width:100%;
    margin-top: 15px;
    background-color: #fff;
    // padding-top:15px;
    .order_item_title{
        padding: 10px;
    }
    .order_item_briefinfo {
      display: flex;
      border-bottom: 1px solid #eee;
      //   padding-bottom:10px;
      padding: 10px;
      .order_item_briefinfo_ordernum {
        padding-left: 20px;
        flex: 1;
        display: flex;
        .dkxd_c{
          padding-left:10px;
          color:red;
        }
      }

      .order_item_briefinfo_orderdate {
        flex: 1;
      }
      .order_item_briefinfo_orderpay {
        flex: 1;
        text-align: center;
      }
    }

    .order_item_content {
      display: flex;
      .order_item_content_product {
        flex: 3;
        margin: 10px;
        padding-left: 10px;
        display: flex;
        .productinfo_img {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            width: 80%;
            object-fit: contain;
          }
        }

        .productinfo {
          flex: 3;
          margin: 10px;
          padding-left: 5px;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          // align-items: center;
          .productinfo_title {
          }

          .productinfo_guige {
          }

          .productinfo_num {
          }

          .productinfo_price {
          }
        }
      }

      .order_item_content_order {
        flex: 4;
        display: flex;
        .orderprice {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 1.2em;
          color: #6e8b3d;
        }

        .orderstatus {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .ordercontact {
          flex: 3;
          display: flex;
          // align-items: center;
          justify-content: center;
          flex-direction: column;
          padding-left: 10px;
          .ordercontact_name {
          }

          .ordercontact_tel {
          }

          .ordercontact_address {
            display: flex;
            .address_title {
              white-space: nowrap;
            }
          }
        }
      }

      .order_item_content_opr {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        // padding:15px;
        button {
          width: 80%;
          margin: 3px;
        }
      }
    }
  }
}

button {
  background-color: #f5f5f5;
  margin-right: 10px;
}
.btmInfo {
  background: none;
  border: none;
  outline: none;
  color: #333;
  font-size: 0.9em;
}
.noresult {
  text-align: center;
  font-size: 2em;
  color: rgb(160, 154, 154);
}
.pagenum_info{
    background:#fff;
    height: 50px;
    margin-top:20px;
    padding: 10px; color: #6c757d;
    // border-top: 1px dashed #9acd32;
}
.actived {
  background-color: rgba(190, 221, 140, 0.9);
}
table.dataintable {
  /* margin-top: 15px; */
  border-collapse: collapse;
  border: 1px solid white;
  width: 98%;
  font-size: 0.95em;
  background-color: #e5ebdc;
  line-height: 23px;
}
table.dataintable th {
  vertical-align: middle;
  /* padding:5px 15px 5px 6px; */
  background-color: #e8e8e8;
  border: 1px solid white;
  text-align: center;
  color: rgb(173, 169, 169);
}
table.dataintable td {
  vertical-align: text-center;
  /* padding:6px 15px 6px 6px; */
  border: 1px solid white;
  line-height: 20px;
}
.productinfo_head_img{
            flex:2;
            img {
            width: 90%;
            max-height: 50px;
            object-fit: contain;
          }
        }
.hidden_view {
  width: 100%;
  background: #698b22;
  opacity: 0.2;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 99;
}
</style>