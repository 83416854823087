<template>
  <div style="height: 100%;">

    <div
      class="hidden_view"
      :style="{ height: win_height + 'px' }"
      v-show="show"
      @click="screen_hide_click"
    ></div>
    <!-- <transition name="show_view"> -->
        <Toast ref="toast"/>
      <WSDetail :currentSInfo="currentSInfo" :isshow="isshow" />      
      <UpdateWSDetail :currentSInfo="currentSInfo" :isshow="isshowupdate" @clickT="screen_hide_click"/>      
    <!-- </transition> -->

    <!-- <div class="content-main" style="padding:0;border:1px solid yellow">      -->

    <!-- <div class="grid-form" style="padding:0;border:1px solid red"> -->
    <div class="grid-form1" style="padding: 0">
      <!-- <h4>问题类别维护</h4> -->
      <!-- <div class="col-md-12" style="margin: 0; padding: 0"> -->
      
      <div style="padding-top: 0; padding-bottom: 8px">
        <div class="row">
          <div class="pic_show">
            <!-- 店铺二维码： -->
            <img :src="imgSrc" style="width:380px;height:380px;">
            <div class="dp_name_onpic">{{dp_name}}</div>
          </div>
        </div>
      </div>
    </div>

    <div class="grid-form1" style="height: 678px">
      <!-- <h4>问题类别维护</h4> -->
      <div
        class="tab-content"
        style="height: 605px; margin-top: 5px; padding: 0"
      >
        <table class="dataintable" v-if="productList.length > 0">
          <tr style="font-weight: bold">
            <th width="50px" >ID</th>
            <th>店铺名称</th>
            <th width="300px">店铺代码</th>      
            <th width="200px">添加时间</th>
            <th width="200px">绑定会员数</th>
            <th width="150px">操作</th>
          </tr>
          <tr
            v-for="(s, index) in currentpagelist"
            :key="index"
            @mouseover="mouseOver(index)"
            @mouseout="mouseOut"
            
            :class="{ actived: currentIndex == index }"
          >
            <td>{{ s.id }}</td>
            <td>{{ s.membername }}</td>
            <td>{{ s.membercode }}</td>
            <td>{{ s.adddate }}</td>
            <td>{{ s.membercount }}</td>

            <td style="vertical-align: middle">
              <!-- <a @click.stop="stopExcept(s.id)" title="停止此条限制，继续启用返佣" v-show="s.membercount==0"
                ><i class="fa fa-stop-circle-o" aria-hidden="true"></i
              ></a> -->
              &nbsp;&nbsp;
              <a @click.stop="getWXQRCode(s.membercode,s.membername)" title="生成该店铺二维码" 
                >生成二维码</a>
            </td>
          </tr>
        </table>
        <div v-if="noResultFlag" class="noresult">没有找到符合查询条件的可批发产品！</div>
      </div>
      <div
        class="center_bottom"
        style="padding: 5px; color: #6c757d; border-top: 1px dashed #9acd32"
      >
        <span class="btmInfo" style="vertical-align: middle"
          >&nbsp;&nbsp;共有 {{ recordcount }} 条记录，当前第
          {{ currentpage }}/{{ pagecount }} 页</span
        >
        <div style="float: right; padding-right: 30px">
          <button class="btn btn-default" type="button" @click="getNewPage(1)">
            首页
          </button>
          <button
            class="btn btn-default"
            type="button"
            @click="getNewPage(currentpage - 1)"
          >
            上页
          </button>
          <button
            class="btn btn-default"
            type="button"
            @click="getNewPage(currentpage + 1)"
          >
            下页
          </button>
          <button
            class="btn btn-default"
            type="button"
            @click="getNewPage(pagecount)"
          >
            尾页
          </button>
          <span class="btmInfo" style="margin-right: 2px">跳转到</span>
          <input
            type="text"
            size="1"
            id="txtGotoPage"
            autocomplete="off"
            v-int-only
            style="margin-right: 10px; font-size: 0.9em"
            v-model.trim="gotopage"
          />
          <button
            type="button"
            class="btn btn-default"
            @click="getNewPage(gotopage)"
          >
            跳转
          </button>
        </div>
      </div>
    </div>
    <!-- </div> -->
    <!-- </div> -->
  </div>
</template>

<script>
import axios from "axios";

import Calender from "../components/vue-calendar-component";
import WSDetail from "../components/WSDetail";
import UpdateWSDetail from "../components/UpdateWSDetail";
import Toast from "../components/Toast";

export default {
  name: "wxacodeList",
  components: {
    Calender,
    WSDetail,
    UpdateWSDetail,
    Toast
  },
  data() {
    return {
        
      productList: [],
      currentIndex: -1,
      currentpage: 1,
      gotopage: "",
    //   searchpsn: "",
    //   searchpname: "",
    //   isshowcalender: false,
      show: false,
      isshow: false,
      isshowupdate:false,
      win_height: "",
      currentSID: 0,
      currentSInfo: [],
      noResultFlag:false,
      imgSrc:'',
      dp_name:'此处显示店铺二维码'
    };
  },
  methods: {
      
    addAdmin(){
        this.$router.push('/newadmin')
    },
    async getSDetail() {
      const res = await this.$get(
        this.GLOBAL.serverAddress + "/getWSProductDetail/",
        {'id':this.currentSID}
      );
      if(res.status===200){
        this.currentSInfo=res.data[0];
      }
      
    },
    async getWXQRCode(dpcode,membername) {
      const res = await this.$get(
        this.GLOBAL.wxserverAddress + "/cgi-bin/token",
        {'grant_type':"client_credential",
        'appid':"wx39a1161591a1566f",
        'secret':"f21510f6ccd51cfff8c032f135b22629"
        }
      );
      
      if(res.status===200){
          let tmpToken=res.data.access_token
          const res2 = await this.$wxpost(
            this.GLOBAL.wxserverAddress + "/wxa/getwxacode?access_token=" +tmpToken ,
            {'path':'pages/index/index?access_id='+dpcode,
            'width':'380',
            'auto_color':true}
            
            );
            this.imgSrc="data:image/png;base64,"+this.arrayBufferToBase64(res2.data)
            console.log(res2.data)
        // console.log(res.data.access_token);
      }
      this.dp_name=membername
      
    },
    arrayBufferToBase64(buffer){
        var binary = ''
        var bytes = new Uint8Array(buffer)
        var len = bytes.byteLength
        for (var i = 0; i < len; i++) {
            binary += String.fromCharCode(bytes[i])
        }
        return window.btoa(binary)
    },

    async resetStatus(id,status){
        const res=await this.$post(this.GLOBAL.serverAddress + "/updateAdmin/",{id:id,type:"2",status:status,pwd:''})
            console.log(res)
            if(res.data){
                this.$router.go(0)
                this.$refs.toast.toast("设置成功")
                // this.toast("")
            }else{
                this.$refs.toast.toast("设置失败，请重新尝试")
            }
        
    },
    updateAdmin(id){
      this.$router.push('/updateadmin/?id='+id)
    },
    async resetPWD(id){
        if(confirm("您确认将该账号的登录密码重置为\"@02!Vancouver\"?")){
            const res=await this.$post(this.GLOBAL.serverAddress + "/updateAdmin/",{id:id,type:"1",status:'',pwd:"@02!Vancouver"})
            if(res.data){
                this.$refs.toast.toast("密码重置成功")
            }else{
                this.$refs.toast.toast("密码重置失败，请重新尝试")
            }
        }
    },
    openUpadate(pid){
      this.currentSID = pid;
      this.show = true;
      this.isshowupdate = true;

      // this.currentSInfo = 
      this.getSDetail();
    },
    screen_click(pid) {
      // 显示筛选
      this.currentSID = pid;
      this.show = true;
      this.isshow = true;

      // this.currentSInfo = 
      this.getSDetail();

      //  console.log(this.currentSInfo)
    },
    screen_hide_click() {
      // 隐藏筛选
      let that = this;
      setTimeout(function () {
        that.show = false;
      }, 300);
      that.isshow = false;
      that.isshowupdate = false;
    },

    
    clearSearch() {
      this.searchpname = "";
      this.searchpsn = "";
    },
    mouseOver(index) {
      // alert(index)
      this.currentIndex = index;
    },
    mouseOut() {
      // alert(index)
      this.currentIndex = -1;
    },
    getNewPage(pagenum) {
      if (pagenum >= 1 && pagenum <= this.pagecount) {
        this.currentpage = pagenum;
        // return this.pages[this.currentpage - 1];
      }
    },
    async getProductList() {
        // this.etype===""
        // this.productshow=false
        // this.classshow=false
        this.membername=""
        this.membercode=""
      //   if (days) {
      axios({
        method: "get",
        params: {
          spname: this.searchpname,
          spsn: this.searchpsn,
        },
        url: this.GLOBAL.serverAddress + "/getAllDMember/",
      }).then(
        (response) => {
          this.productList = response.data;
          if(this.productList.length > 0){
              this.noResultFlag=false
          }else{
              this.noResultFlag=true
              
          }
        },
        (error) => {
          console.log("出现错误", error.message);
          return false;
        }
      );

      // const res2 = await this.$get(this.GLOBAL.serverAddress + "/getAllProductClass/",{});
      // console.log(res2);

      // if (res2.status === 200) {
      //   this.classFList = res2.data;
      // }
      //   }
    },
  },
  computed: {
    currentpagelist() {
      return this.pages[this.currentpage - 1];
    },
    recordcount() {
      return this.productList.length;
    },
    pagecount() {
      return Math.ceil(this.productList.length / 15);
    },
    pages() {
      const pages = [];
      this.productList.forEach((item, index) => {
        const page = Math.floor(index / 15);
        if (!pages[page]) {
          pages[page] = [];
        }
        pages[page].push(item);
      });
      return pages;
    },
  },
  mounted() {
    
        this.isSuperAdmin()
        this.getProductList();
    // window.addEventListener("click", this.hideCalender);
    this.win_height = window.innerHeight;
  },
  beforeDestroy() {
    // 实例销毁之前对点击事件进行解绑
    // window.removeEventListener("click", this.hideCalender);
  },
};
</script>

<style scoped lang="less">
.pic_show{
  display: flex;
  padding-top: 10px;
  padding-left:50px;
  flex-direction: column;
  align-items:center;
  .dp_name_onpic{
    position:relative;
    top:-145px;
    color:#ccc;
  }
}
button {
  background-color: #f5f5f5;
  margin-right: 10px;
}

.btmInfo {
  background: none;
  border: none;
  outline: none;
  color: #333;
  font-size: 0.9em;
}
.noresult {
  text-align: center;
  font-size: 2em;
  color: rgb(160, 154, 154);
}
.actived {
  background-color: rgba(190, 221, 140, 0.9);
}
table.dataintable {
  /* margin-top: 15px; */
  border-collapse: collapse;
  border: 1px solid white;
  width: 98%;
  font-size: 0.95em;
  background-color: #e5ebdc;
  line-height: 40px;
}
table.dataintable th {
  vertical-align: middle;
  /* padding:5px 15px 5px 6px; */
  background-color: #e8e8e8;
  border: 1px solid white;
  text-align: center;
  color: rgb(173, 169, 169);
}
table.dataintable td {
  vertical-align: text-top;
  /* padding:6px 15px 6px 6px; */
  border: 1px solid white;
  line-height: 35px;
}

.hidden_view {
  width: 100%;
  background: #698b22;
  opacity: 0.2;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 99;
}

</style>