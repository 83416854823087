<template>
  <div style="height: 100%;">
    
    <div
      class="hidden_view"
      :style="{ height: win_height + 'px' }"
      v-show="show"
      @click="screen_hide_click"
    ></div>
    <!-- <transition name="show_view"> -->
      <WSDetail :currentSInfo="currentSInfo" :isshow="isshow" />      
      <UpdateWSDetail :currentSInfo="currentSInfo" :isshow="isshowupdate" @clickT="screen_hide_click"/>      
    <!-- </transition> -->

    <!-- <div class="content-main" style="padding:0;border:1px solid yellow">      -->

    <!-- <div class="grid-form" style="padding:0;border:1px solid red"> -->
    <div class="grid-form1" style="padding: 0">
      <!-- <h4>问题类别维护</h4> -->
      <!-- <div class="col-md-12" style="margin: 0; padding: 0"> -->
      <div style="margin: 0; padding: 0; vertical-align: middle">
        <!-- <form
                class="form-horizontal"
                action=""
                id="frmSRList"
                method="POST"
              > -->
        <div class="form-group" style="vertical-align: middle">
          <div>
            <label
              for="txtContractNumber"
              class="col-sm-1 control-label"
              style="padding-top: 10px; text-align: right"
              >商品名称</label
            >
          </div>
          <div class="col-sm-2" style="vertical-align: middle">
            <input
              type="text"
              class="form-control1"
              id="txtContractNumber"
              name="txtContractNumber"
              v-model.trim="searchpname"
              @keyup.enter="getProductList"
            />
          </div>
          <label
            for="txtSampleName"
            class="col-sm-1 control-label"
            style="padding-top: 10px; text-align: right"
            >商品条码</label
          >
          <div class="col-sm-2" style="margin: 0; padding: 0">
            <input
              type="text"
              class="form-control1"
              id="txtSampleName"
              name="txtSampleName"
              
              v-model.trim="searchpsn"
              @keyup.enter="getProductList"
            />
          </div>
          <div class="col-sm-5 " style="padding-top: 2px">
            <button class="btn btn-default" type="button" @click="clearSearch">
              <i class="fa fa-refresh" aria-hidden="true"></i>&nbsp;清空
            </button>
            <button
              class="btn btn-default"
              type="button"
              @click="getProductList"
            >
              <i class="fa fa-search" aria-hidden="true"></i>&nbsp;搜索
            </button>
          </div>
          
        </div>
        <!-- </form> -->
      </div>
      <!-- </div> -->

      <div style="padding-top: 0; padding-bottom: 8px">
        <div class="row">
          <div class="col-sm-12 " style="padding-top: 10px;padding-left:50px;">
            <button class="btn btn-default" type="button" @click="setupWS">
              <i class="fa fa-plus-square-o" aria-hidden="true"></i>&nbsp;配置可批发产品
            </button>
            
          </div>
        </div>
      </div>
    </div>

    <div class="grid-form1" >
      <!-- <h4>问题类别维护</h4> -->
      <div
        class="tab-content"
        style=" margin-top: 5px; padding: 0"
      >
        <table class="dataintable" v-if="productList.length > 0">
          <tr style="font-weight: bold">
            <th width="40px" >ID</th>
            <th width="150px">商品编码</th>
            <th>可批发商品名称</th>            
            <th width="150px">商品条码</th>
            <th width="130px">商品规格</th>
            <th width="90px">批发单位</th>
            <th width="90px">批发单价</th>
            <th width="90px">设置日期</th>
            <th width="80">操作</th>
          </tr>
          <tr
            v-for="(s, index) in currentpagelist"
            :key="index"
            @mouseover="mouseOver(index)"
            @mouseout="mouseOut"
            @click="screen_click(s.id)"
            :class="{ actived: currentIndex == index }"
          >
            <td>{{ s.id }}</td>
            <td>{{ s.posn }}</td>
            <td style="text-align: left; padding-left: 5px">{{ s.pname }}</td>
            <td>{{ s.psn }}</td>
            <td>{{ s.pguige }}</td>

            <td>{{ s.pwsunit }}</td>

            <td>{{ s.pwsprice }}</td>
            <td>{{ s.oprdatetime }}</td>

            <td style="vertical-align: middle">
              <a @click.stop="openUpadate(s.id)" title="修改入库信息"
                ><i class="fa fa-pencil-square-o" aria-hidden="true"></i
              ></a>
            </td>
          </tr>
        </table>
        <div v-if="noResultFlag" class="noresult">没有找到符合查询条件的可批发产品！</div>
      </div>
      <div
        class="center_bottom"
        style="padding: 5px; color: #6c757d; border-top: 1px dashed #9acd32"
        v-if="!noResultFlag"
      >
        <span class="btmInfo" style="vertical-align: middle"
          >&nbsp;&nbsp;共有 {{ recordcount }} 条记录，当前第
          {{ currentpage }}/{{ pagecount }} 页</span
        >
        <div style="float: right; padding-right: 30px">
          <button class="btn btn-default" type="button" @click="getNewPage(1)">
            首页
          </button>
          <button
            class="btn btn-default"
            type="button"
            @click="getNewPage(currentpage - 1)"
          >
            上页
          </button>
          <button
            class="btn btn-default"
            type="button"
            @click="getNewPage(currentpage + 1)"
          >
            下页
          </button>
          <button
            class="btn btn-default"
            type="button"
            @click="getNewPage(pagecount)"
          >
            尾页
          </button>
          <span class="btmInfo" style="margin-right: 2px">跳转到</span>
          <input
            type="text"
            size="1"
            id="txtGotoPage"
            autocomplete="off"
            v-int-only
            style="margin-right: 10px; font-size: 0.9em"
            v-model.trim="gotopage"
          />
          <button
            type="button"
            class="btn btn-default"
            @click="getNewPage(gotopage)"
          >
            跳转
          </button>
        </div>
      </div>
    </div>
    <!-- </div> -->
    <!-- </div> -->
  </div>
</template>

<script>
import axios from "axios";

import Calender from "../../components/vue-calendar-component";
import WSDetail from "../../components/WSDetail";
import UpdateWSDetail from "../../components/UpdateWSDetail";

export default {
  name: "WholeSaleProductList",
  components: {
    Calender,
    WSDetail,
    UpdateWSDetail
  },
  data() {
    return {
      productList: [],
      currentIndex: -1,
      currentpage: 1,
      gotopage: "",
      searchpsn: "",
      searchpname: "",
    //   isshowcalender: false,
      show: false,
      isshow: false,
      isshowupdate:false,
      win_height: "",
      currentSID: 0,
      currentSInfo: [],
      noResultFlag:false
    };
  },
  methods: {
    setupWS(){
        this.$router.push('/setupwholesale')
    },
    async getSDetail() {
      const res = await this.$get(
        this.GLOBAL.serverAddress + "/getWSProductDetail/",
        {'id':this.currentSID}
      );
      if(res.status===200){
        this.currentSInfo=res.data[0];
      }
      
    },
    
    openUpadate(pid){
      this.currentSID = pid;
      this.show = true;
      this.isshowupdate = true;

      // this.currentSInfo = 
      this.getSDetail();
    },
    screen_click(pid) {
      // 显示筛选
      this.currentSID = pid;
      this.show = true;
      this.isshow = true;

      // this.currentSInfo = 
      this.getSDetail();

      //  console.log(this.currentSInfo)
    },
    screen_hide_click() {
      // 隐藏筛选
      let that = this;
      setTimeout(function () {
        that.show = false;
      }, 300);
      that.isshow = false;
      that.isshowupdate = false;
    },

    
    clearSearch() {
      this.searchpname = "";
      this.searchpsn = "";
    },
    mouseOver(index) {
      // alert(index)
      this.currentIndex = index;
    },
    mouseOut() {
      // alert(index)
      this.currentIndex = -1;
    },
    getNewPage(pagenum) {
      if (pagenum >= 1 && pagenum <= this.pagecount) {
        this.currentpage = pagenum;
        // return this.pages[this.currentpage - 1];
      }
    },
    getProductList() {
      //   if (days) {
      axios({
        method: "get",
        params: {
          spname: this.searchpname,
          spsn: this.searchpsn,
        },
        url: this.GLOBAL.serverAddress + "/searchWholeSaleList/",
      }).then(
        (response) => {
          this.productList = response.data;
          if(this.productList.length > 0){
              this.noResultFlag=false
          }else{
              this.noResultFlag=true
              
          }
        },
        (error) => {
          console.log("出现错误", error.message);
          return false;
        }
      );
      //   }
    },
  },
  computed: {
    currentpagelist() {
      return this.pages[this.currentpage - 1];
    },
    recordcount() {
      return this.productList.length;
    },
    pagecount() {
      return Math.ceil(this.productList.length / 15);
    },
    pages() {
      const pages = [];
      this.productList.forEach((item, index) => {
        const page = Math.floor(index / 15);
        if (!pages[page]) {
          pages[page] = [];
        }
        pages[page].push(item);
      });
      return pages;
    },
  },
  mounted() {
    this.haveRights('|0202|')
    this.getProductList();
    // window.addEventListener("click", this.hideCalender);
    this.win_height = window.innerHeight;
  },
  beforeDestroy() {
    // 实例销毁之前对点击事件进行解绑
    // window.removeEventListener("click", this.hideCalender);
  },
};
</script>

<style scoped>
button {
  background-color: #f5f5f5;
  margin-right: 10px;
}
.btmInfo {
  background: none;
  border: none;
  outline: none;
  color: #333;
  font-size: 0.9em;
}
.noresult {
  text-align: center;
  font-size: 2em;
  color: rgb(160, 154, 154);
}
.actived {
  background-color: rgba(190, 221, 140, 0.9);
}
table.dataintable {
  /* margin-top: 15px; */
  border-collapse: collapse;
  border: 1px solid white;
  width: 98%;
  font-size: 0.95em;
  background-color: #e5ebdc;
  line-height: 40px;
}
table.dataintable th {
  vertical-align: middle;
  /* padding:5px 15px 5px 6px; */
  background-color: #e8e8e8;
  border: 1px solid white;
  text-align: center;
  color: rgb(173, 169, 169);
}
table.dataintable td {
  vertical-align: text-top;
  /* padding:6px 15px 6px 6px; */
  border: 1px solid white;
  line-height: 35px;
}

.hidden_view {
  width: 100%;
  background: #698b22;
  opacity: 0.2;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 99;
}

</style>