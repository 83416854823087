<template>
  <div style="height: 100%">
    <Toast ref="toast" />
    <ul id="keywords" ref="sKeywords" v-show="showNameList">
      <li
        v-for="(s, index) in nameList"
        :key="index"
        @click="getPName(s)"
        @mouseover="mouseOver(index)"
        :class="{ actived: currentIndex == index }"
      >
        {{ s.pname }}<br />规格：{{ s.pguige }} | 售价：{{ s.pwsprice }} | 税费：{{ s.ptaxesname}} | 押金：{{ s.pdeposit}} | 小费：{{ s.ptips}}<span v-if="s.ptipstype=='1'">%</span>
      </li>
    </ul>
    <div class="orderlist">
      <div class="order_item" v-for="(item, index) in orderList" :key="index">
        <div class="order_title">订单详情</div>

        <div class="order_item_briefinfo_top">
          <div class="order_item_briefinfo_back">
            <i
              class="fa fa-arrow-circle-left"
              aria-hidden="true"
              style="color: #9acd32"
              title="返回订单列表"
              @click="goback"
            ></i>
          </div>
          <div class="order_item_briefinfo_ordernum">
            订单号：{{ item.ordersno }}
          </div>
        </div>
        <div class="order_item_briefinfo">
              
          <div class="order_item_briefinfo_orderpay">
            下单时间：{{ item.orderdatetime }}
          </div>
          <div class="order_item_briefinfo_orderpay">
            支付方式：{{ item.paytype }}
          </div>
          <div class="order_item_briefinfo_orderpay">
            支付时间：{{ item.paytime===null?'暂无':item.paytime }}
          </div>
          <div class="order_item_briefinfo_orderpay">
            订单状态：{{ item.orderstatus }}
          </div>
        </div>
        <div class="order_item_briefinfo">
              
          <div class="order_item_briefinfo_orderpay">
            发货时间：{{ item.devlivertime ===null?'暂无':item.devlivertime}}
          </div>
          <div class="order_item_briefinfo_orderpay">
            收货时间：{{ item.arrivetime===null?'暂无':item.arrivetime}}
          </div>
          
          <div class="order_item_briefinfo_orderpay">
            配送编号：{{ item.icard_name }} {{ item.boxsn }}
          </div>
          
          <div class="order_item_briefinfo_orderpay">
            <div class="ordercontact_name">所属店铺：
              <select name="sltPayStatus" v-model="item.membercode">
                <option value="">无</option>
                <option :value="idm.membercode" v-for="(idm,idmi) in DMemberList" :key="idmi">{{idm.membername}}</option>
              </select>
            </div>
          </div>
        </div>
        <div class="order_notesinfo">
          <div class="order_notesinfo_title">订单备注：</div>
          <div class="order_notesinfo_content">
              <textarea name="txtRemark" id="txtRemark" cols="80" rows="3" class="form-control1" style="height: 120px;" v-model="item.remarks" ></textarea>
          </div>
          <div>
          <button
            class="btn btn-default"
            type="button"
            @click="updateRemarks(item.orderid,item.remarks)"
            >
            <i class="fa fa-save" aria-hidden="true"></i>&nbsp;更新备注
            </button>
          </div>
        </div>
        <div class="order_item_contact_title">收货信息</div>
        <div class="order_item_contact">

          <div class="ordercontact_name">指定配送日期：
            <select name="sltPayStatus" v-model="deliverWeekDay">
              <option value="">正常配送</option>
              
              <option value="周一">周一</option>
              <option value="周二">周二</option>
              <option value="周三">周三</option>
              <option value="周四">周四</option>
              <option value="周五">周五</option>
              <option value="周六">周六</option>
              <option value="周日">周日</option>
            </select>
          </div>
          <div class="ordercontact_name">
            联&nbsp;&nbsp;系&nbsp;&nbsp;人：{{ item.contactname }}（{{ item.userid}}）
          </div>
          <div class="ordercontact_name">联系电话：{{ item.mobile }}</div>
          <div class="ordercontact_name">配送地址：{{ item.address }}</div>
        </div>
        <div class="order_item_list">批发商品清单</div>

        <div class="order_item_list_title">
          <div class="product_img">商品图片</div>
          <div class="product_name">商品名称/商品条码</div>
          <div class="product_guige">规格</div>
          <div class="product_unitprice">单价</div>
          <div class="product_unitprice">税费</div>
          <div class="product_unitprice">押金</div>
          <div class="product_unitprice">小费</div>
          <div class="product_count">数量</div>
          <div class="product_allprie" style="color: #9c9c9c">小计</div>
        </div>
        <div
          class="order_item_list_title"
          v-for="(item2, index2) in item.plist"
          :key="index2"
        >
          <div class="product_img">
            <img :src="item2.imgurl" />
          </div>

          <div class="product_name">{{ item2.pname }}<br />{{ item2.psn }}</div>
          <div class="product_guige">{{ item2.pguige }}</div>
          <div class="product_unitprice">C${{ item2.pprice }}</div>
          <div class="product_count">
          <div class="product_unitprice">C${{ item2.taxes }}</div>
          <div class="product_allprie" v-if="item2.showadjustprice">
              <!-- <div class="product_count_newadd_kucun">
                可追加：{{ item2.showmaxnum }}
              </div> -->
              <!-- <div class="product_count_newadd_zhuijia"> -->
                <div class="product_allprie_n">调为
                
                  <input
                    type="text"
                    class="form-control1"
                    v-float-only
                    v-model.trim="item2.newalltaxes"
                    autocomplete="off"
                  />
              </div>
             
          </div>
          </div>
          <div class="product_count">
          <div class="product_unitprice">C${{ item2.deposit }}</div>

          <div class="product_allprie" v-if="item2.showadjustprice">
              <!-- <div class="product_count_newadd_kucun">
                可追加：{{ item2.showmaxnum }}
              </div> -->
              <!-- <div class="product_count_newadd_zhuijia"> -->
                <div class="product_allprie_n">调为
                
                  <input
                    type="text"
                    class="form-control1"
                    v-float-only
                    v-model.trim="item2.newalldeposit"
                    autocomplete="off"
                  />
              </div>
             
          </div>
          </div>
          <div class="product_count">
          <div class="product_unitprice">C${{ item2.tips }}</div>

          <div class="product_allprie" v-if="item2.showadjustprice">
              <!-- <div class="product_count_newadd_kucun">
                可追加：{{ item2.showmaxnum }}
              </div> -->
              <!-- <div class="product_count_newadd_zhuijia"> -->
                <div class="product_allprie_n">调为
                
                  <input
                    type="text"
                    class="form-control1"
                    v-float-only
                    v-model.trim="item2.newalltips"
                    autocomplete="off"
                  />
              </div>
             
          </div>
          </div>
          <div class="product_count">
            <div class="product_count_old">
              {{ item2.wscount }}
            </div>
            <div class="product_allprie" v-if="item2.showadjustprice">
              <!-- <div class="product_count_newadd_kucun">
                可追加：{{ item2.showmaxnum }}
              </div> -->
              <!-- <div class="product_count_newadd_zhuijia"> -->
                <div class="product_allprie_n">调为
                
                  <input
                    type="text"
                    class="form-control1"
                    v-float-only
                    v-model.trim="item2.newnum"
                    autocomplete="off"
                  />
              </div>
              <div class="product_allprie_btnb" v-if="item2.showadjustprice">
              <div class="product_allprie_btnb_btn" @click="cancelAdjust(item2.odid)">取消</div>
            </div>
              
            </div>
          </div>
          <div class="product_allprie">
            <div class="product_allprie_o">
              C${{ item2.wsallprice }}
              <div
                class="product_allprie_btn"
                
                @click="adjustPrice(item2.odid)"
                
              >
                调整
              </div>
            </div>
            <div class="product_allprie_n" v-if="item2.showadjustprice">
              调为&nbsp;
              <input
                type="text"
                class="form-control1"
                v-float-only
                v-model.trim="item2.nwsallprice"
                autocomplete="off"
              />
            </div>
            <div class="product_allprie_btnb" v-if="item2.showadjustprice">
              <!-- <div class="product_allprie_btnb_btn" @click="cancelAdjust(item2.odid)">取消</div> -->
              <div class="product_allprie_btnb_btn" @click="submitAdjust(item2.odid)">提交</div>
            </div>
          </div>
        </div>
        <div class="order_new_product" v-if="showAddFlag">
          <div>查询并选择要追加的商品：</div>
          <div>
            <input
              type="text"
              class="form-control1"
              v-model.trim="searchpname"
              @input="handleSearch($event)"
              autocomplete="off"
              id="txtSearchPName"
            />
          </div>
          <div>&nbsp;&nbsp;</div>
          <div>
            <!-- <button class="btn btn-default" type="button" @click="cancelAdd">
              取消追加
            </button> -->
            <!-- <button
                class="btn btn-default"
                type="button"
                @click="submitAdd"                
                >
                提交追加
                </button> -->
          </div>
        </div>
        <div class="order_item_list_title" v-if="showAddFlag">
          <div class="product_img">
            <img :src="newProductInfo.imgurl" />
          </div>

          <div class="product_name">
            {{ newProductInfo.pname }}<br />
            {{ newProductInfo.pcountnowshow }}<br />
            {{ newProductInfo.limit }}<br />

            <div v-for="(item, index) in newProductInfo.pricelist" :key="index">
              购买数量：{{ item.num }}及以上，价格为：{{ item.price }}
            </div>
          </div>
          <div class="product_guige">{{ newProductInfo.pguige }}</div>
          <div class="product_unitprice">单价{{ newProductInfo.wsprice }}</div>
          <div class="product_unitprice">税费{{ newProductInfo.ptaxesname }}
            <input
              type="text"
              class="form-control1"
              id="txtPriceAddT"
              v-model.trim="newProductInfo.alltaxes"
              @input="getAllPrice2"
              v-float-only
              placeholder="总税费"
              autocomplete="off"
            />
          </div>
          <div class="product_unitprice">押金{{ newProductInfo.pdeposit }}
            <input
              type="text"
              class="form-control1"
              id="txtPriceAddD"
              v-model.trim="newProductInfo.alldeposit"
              @input="getAllPrice2"
              v-float-only
              placeholder="总押金"
              autocomplete="off"
            />
          </div>
          <div class="product_unitprice">小费{{ newProductInfo.ptips }}<span v-if="newProductInfo.ptipstype=='1'">%</span>
            <input
              type="text"
              class="form-control1"
              id="txtPriceAddP"
              v-model.trim="newProductInfo.alltips"
              @input="getAllPrice2"
              v-float-only
              placeholder="总小费"
              autocomplete="off"
            />
          </div>
          <div class="product_allprie">
            <input
              type="text"
              class="form-control1"
              id="txtNumAdd"
              v-model.trim="newAddNum"
              v-float-only
              placeholder="追加数量"
              @input="getAllPrice"
              autocomplete="off"
            />
            <button class="btn btn-default" type="button" @click="cancelAdd" style="margin-top: 10px">
              取消追加
            </button>
          </div>
          <div class="product_allprie" >
            <input
              type="text"
              class="form-control1"
              id="txtPriceAdd"
              v-model.trim="newProductInfo.allprice"
              v-float-only
              placeholder="商品总价"
              autocomplete="off"
            />
            <button
              class="btn btn-default"
              type="button"
              @click="submitAdd()"
              style="margin-top: 10px"
            >
              提交追加
            </button>
            </div>
        </div>

        <div class="order_item_content_order">
          <div
            class="order_item_content_order_btn"
            v-if="item.orderstatus=='待付款' || item.orderstatus=='待发货'"
          >
            <!-- <button class="btn btn-default" type="button" @click="showAdd">
              追加商品数量
            </button> -->
            <button
              class="btn btn-default"
              type="button"
              @click="showAddProduct"
            >
              追加新商品
            </button>
            
          </div>
          <div class="order_item_content_order_right">
            <!-- <i
              class="fa fa-arrow-circle-left"
              aria-hidden="true"
              style="color: #9acd32"
              title="返回订单列表"
              @click="goback"
            ></i
            >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; -->
            总额：C${{
              item.ordertotalprice
            }}(运费：{{item.freight}}/优惠：{{ item.yh_price}})
            &nbsp;&nbsp;
            <div class="order_item_briefinfo_orderpay" style="color:red;" v-if="ordercredit<0">
            待退款：{{ Math.abs(ordercredit) }}
            <button class="btn btn-default" type="button" @click="returnMoney">
              <i class="fa fa-credit-card" aria-hidden="true"></i>&nbsp;退款
            </button>
          </div>
          <div class="order_item_briefinfo_orderpay" style="color:red;" v-else-if="ordercreditds>0">
            手工扣余额：C${{Math.abs(ordermpay).toFixed(2)}}&nbsp;&nbsp;
            待收款：{{ Math.abs(ordercreditds) }}&nbsp;&nbsp;
            <button
              class="btn btn-default"
              type="button"
              @click="payOrderRest(item.orderid)"
            >
              <i class="fa fa-money" aria-hidden="true"></i>&nbsp;确认收款
            </button>
          </div>
          <div class="order_item_briefinfo_orderpay" style="color:red;" v-else>
          </div>
            <div style="color:red;" v-if="item.orderstatus=='待付款'">已支付：C${{Math.abs(ordermpay).toFixed(2)}}&nbsp;&nbsp;待支付：C${{(item.ordertotalprice-Math.abs(ordermpay)).toFixed(2)}}</div>
          </div>
          
        </div>
        <div v-if="usermoney>0 && (item.orderstatus=='待付款' || ordercreditds>0)">
        <div class="order_item_contact_title">收款操作</div>
        <div class="order_item_contact">

          <div class="">账户余额：{{usermoney}}&nbsp;&nbsp;&nbsp;&nbsp;此次扣除：
            <input
              type="text"
              class="form-control1"
              id="txtMoneyReduce"
              v-model.trim="creditnum"
              v-float-only
              placeholder="扣除金额"
              autocomplete="off"
              style="width:100px;"
            />&nbsp;&nbsp;&nbsp;&nbsp;
            
            <button class="btn btn-default" type="button" @click="payByMoney(item.orderstatus,item.ordertotalprice-Math.abs(ordermpay))">
          <i class="fa fa-credit-card" aria-hidden="true"></i>&nbsp;扣除
        </button>
          </div>  
          
        </div>
        </div>
      
          <div style="text-align: center; padding: 10px 0;">
        <button class="btn btn-default" type="button" @click="goback">
          <i class="fa fa-arrow-left" aria-hidden="true"></i>&nbsp;取消
        </button>
        <button
          class="btn btn-default"
          id="btnSave"
          :disabled="submitSaving"
          @click="minusOrderFreight(item.orderid)"
          v-if="item.freight>0"
        >
          <i class="fa fa-shopping-basket" aria-hidden="true"></i>&nbsp;
          {{ submitSaving ? "更新中.." : "减免运费" }}
        </button>
        <button
          class="btn btn-default"
          id="btnSave"
          :disabled="submitSaving"
          @click="updateOrderInfo(item.orderid)"
        >
          <i class="fa fa-save" aria-hidden="true"></i>&nbsp;
          {{ submitSaving ? "更新中.." : "更新订单" }}
        </button>
      </div>
      </div>
    </div>
  </div>
  <!-- </div> -->
</template>

<script>
import axios from "axios";
import Toast from "../../components/Toast";

export default {
  name: "UpdateOrder",
  components: { Toast },
  data() {
    return {
      deliverWeekDay:'',
      nameList: [],
      DMemberList:[],
      shownoresult: true,
      showNameList: false,
      orderList: [],
      currentIndex: -1,
      currentpage: 1,
      gotopage: "",
      searchpname: "",
      show: false,
      isshow: false,
      //   isshowupdate: false,
      win_height: "",
      currentSID: 0,
      currentDateS: "",
      newAddNum: "",
      nowPrice: "",
      newProductInfo: {},
      showAddFlag: false,
      page:1,
      from:1,
      logedinuser: "",
      submitSaving: false,
      ordercredit:"",
      ordercreditds:"",
      usermoney:0,
      creditnum:0,
      ordermpay:0
    };
  },
  methods: {
    async getAllDMember() {
       
      axios({
        method: "get",
        params: {
          
        },
        url: this.GLOBAL.serverAddress + "/getAllDMember/",
      }).then(
        (response) => {
          this.DMemberList = response.data;
          if(this.DMemberList.length > 0){
              this.noResultFlag=false
          }else{
              this.noResultFlag=true
              
          }
        },
        (error) => {
          console.log("出现错误", error.message);
          return false;
        }
      )
      },
    adjustPrice(pinorderid) {
      this.orderList[0].plist.forEach((v) => {
        if (v.odid === pinorderid) {
          v.showadjustprice = true;
        }
      });
    },
    cancelAdjust(pinorderid) {
      this.orderList[0].plist.forEach((v) => {
        if (v.odid === pinorderid) {
          v.showadjustprice = false
        }
      })
    },
    async getCreditByOrderID(id){ 
      const res = await this.$get(
        this.GLOBAL.serverAddress + "/getCreditByOrderID/",
        {
          id: id,
        }
      );
      //   console.log(res);

      if (res.status === 200) {
        this.ordercredit = res.data;
        // console.log(res.data)
      }
    },
    async getCreditDSByOrderID(id){ 
      const res = await this.$get(
        this.GLOBAL.serverAddress + "/getCreditDSByOrderID/",
        {
          id: id,
        }
      );
      //   console.log(res);

      if (res.status === 200) {
        this.ordercreditds = res.data;
        // console.log(res.data)
      }
    },
    async getMPayByOrderID(id){ 
      const res = await this.$get(
        this.GLOBAL.serverAddress + "/getMPayByOrderID/",
        {
          id: id,
        }
      );
      //   console.log(res);

      if (res.status === 200) {
        this.ordermpay = res.data;
        // console.log(res.data)
      }
    },
    async getMoneyByMemberID(id){ 
      const res = await this.$get(
        this.GLOBAL.serverAddress + "/getMoneyByMemberID/",
        {
          memberid: id,
        }
      );
      //   console.log(res);

      if (res.status === 200) {
        this.usermoney = res.data;
        // console.log(res.data)
      }
    },
    async payByMoney(status,orderprice){
      // console.log(parseFloat(this.creditnum) <this.usermoney)
      if(this.creditnum === ''||this.creditnum===0){
        this.$refs.toast.toast("请输入扣除数量")
        return false
      }
      if(status=="待付款"){
        if(parseFloat(this.creditnum) >parseFloat(orderprice)){
          this.$refs.toast.toast("扣款大于待收款")
          return false
        }
      }else{

        if(parseFloat(this.creditnum) >parseFloat(this.ordercreditds)){
          this.$refs.toast.toast("扣款大于待收款")
          return false
        }
      }
      if(parseFloat(this.creditnum) >parseFloat(this.usermoney)){
        this.$refs.toast.toast("余额不足")
        return false
      }
      if(confirm("您确认要扣除会员的余额吗？确认后无法撤回。")){
        const res = await this.$post(
          this.GLOBAL.serverAddress + "/chargeBalanceByMemberID/",
          {
            userid: this.orderList[0].userid,
            money:this.creditnum,
            orderid: this.orderList[0].orderid,
            opruser:this.logedinuser

          }
        );
        //   console.log(res);

        if (res.status === 200) {
          this.$router.go(0)
          // console.log(res.data)
        }
      }


    },
    async returnMoney(){
      // console.log(parseFloat(this.creditnum) <this.usermoney)
      if(this.ordercredit === 0){
        this.$refs.toast.toast("不存在退款项")
        return false
      }
      
      if(confirm("您确认要给该会员退款吗？确认后将退还至会员余额中。")){
        const res = await this.$post(
          this.GLOBAL.serverAddress + "/returnBalanceByMemberID/",
          {
            userid: this.orderList[0].userid,
            money:Math.abs(this.ordercredit),
            orderid: this.orderList[0].orderid,
            opruser:this.logedinuser

          }
        );
        //   console.log(res);

        if (res.status === 200) {
          this.$refs.toast.toast("退款成功")
          setTimeout(()=>{
              this.$router.go(0)
            },1500)
          // console.log(res.data)
        }
      }


    },
    async payOrderRest(id){
      // console.log(parseFloat(this.creditnum) <this.usermoney)
      
      if(confirm("您确认已经收到该订单的剩余款项吗？确认后无法撤回。")){
        const res = await this.$post(
          this.GLOBAL.serverAddress + "/payOrderRest/",
          {
            userid: this.orderList[0].userid,
            money:Math.abs(this.ordercredit),
            orderid: this.orderList[0].orderid,
            opruser:this.logedinuser
          }
        );
        //   console.log(res);

        if (res.status === 200) {
          this.$refs.toast.toast("状态修改成功")
          setTimeout(()=>{
              this.$router.go(0)
            },1500)
          // console.log(res.data)
        }
      }


    },
    submitAdjust(pinorderid) {
      // console.log(this.GLOBAL.loginusername+'eeeeeeee')
      this.orderList[0].plist.forEach((v) => {
        if (v.odid === pinorderid) {
          if(v.newalltaxes === ''){
            this.$refs.toast.toast("请输入调后税费")
            return false
          }
          if(v.newalldeposit === ''){
            this.$refs.toast.toast("请输入调后押金")
            return false
          }
          if(v.newalltips === ''){
            this.$refs.toast.toast("请输入调后小费")
            return false
          }
          if(v.newnum === ''){
            this.$refs.toast.toast("请输入调后数量")
            return false
          }
          if (!this.GLOBAL.isInt(v.newnum)){
            this.$refs.toast.toast("请输入正确数量")
            return false
          }
          if(v.nwsallprice === ''){
            this.$refs.toast.toast("请输入调后价格")
            return false
          }

          if (!this.GLOBAL.isFloat(v.nwsallprice)){
            this.$refs.toast.toast("请输入正确总价")
            return false
          }

          if(confirm("您确认要修改订单信息吗？修改后无法撤回。")){
            v.nwsunitprice=(v.nwsallprice/v.wscount).toFixed(2)
            axios({
              method: "POST",
              data: { orderid: this.orderList[0].orderid, 
              orderdetailsid:v.odid,
              oldnum:v.wscount,
              newnum:v.newnum,
              oldprice:v.wsallprice,
              newprice:v.nwsallprice,
              newtaxes:v.newalltaxes,
              newdepoist:v.newalldeposit,
              newtips:v.newalltips,
              opruser:this.logedinuser,
              },
              url: this.GLOBAL.serverAddress + "/updateOrderDetailsInfoPC/",
            }).then(
              (response) => {
                if (response.data === true) {
                  this.$refs.toast.toast("修改成功");
                  v.showadjustprice = false
                  this.$router.go(0)
                } else {
                  this.$refs.toast.toast(response.data);
                }
              },
              (error) => {
                alert(error.message);
                return false;
              }
            );
          }
            
            // this.$router.go(0)
          
        }
      })
      
    },
    getAllPrice2(){
      if(this.newProductInfo.alltaxes==''){
        this.newProductInfo.alltaxes=0
      }
      if(this.newProductInfo.alldeposit==''){
        this.newProductInfo.alldeposit=0
      }
      if(this.newProductInfo.alltips==''){
        this.newProductInfo.alltips=0
      }
      if(this.newAddNum!= ""){
        this.newProductInfo.allprice =
        (this.newAddNum * this.newProductInfo.wsprice+parseFloat(this.newProductInfo.alltaxes)+parseFloat(this.newProductInfo.alldeposit)+parseFloat(this.newProductInfo.alltips)).toFixed(2);
    
      }
    },
    getAllPrice() {
      this.newProductInfo.wsprice = this.nowPrice;
      // if (this.newProductInfo.pricelist.length > 0) {
      //   this.newProductInfo.pricelist.forEach((v) => {
      //     if (this.newAddNum >= v.num) {
      //       this.newProductInfo.wsprice = v.price;
      //     }
      //   });
      // }
      let tmpAllTaxes=(this.newAddNum*this.newProductInfo.ptaxesvalue*this.newProductInfo.wsprice).toFixed(2);
      let tmpAllDepoist=(this.newAddNum*this.newProductInfo.pdeposit).toFixed(2);

      let tmpAllTips=0
      if(this.newProductInfo.ptipstype=='1'){
        tmpAllTips=this.newProductInfo.wsprice*this.newAddNum*this.newProductInfo.ptips
        if(tmpAllTips>0){
          tmpAllTips=(tmpAllTips/100).toFixed(2)
        }
      }else{

        tmpAllTips=(this.newAddNum*this.newProductInfo.ptips).toFixed(2);
      }


      this.newProductInfo.selectedcount = this.newAddNum;
      this.newProductInfo.alltaxes = tmpAllTaxes
      this.newProductInfo.alldeposit =tmpAllDepoist
      this.newProductInfo.alltips = tmpAllTips
      this.newProductInfo.allprice =
        (this.newAddNum * this.newProductInfo.wsprice+parseFloat(tmpAllTaxes)+parseFloat(tmpAllDepoist)+parseFloat(tmpAllTips)).toFixed(2);
    },
    getPName(pinfo) {
      // console.log(sname)
      let cFlag = true;
      this.orderList[0].plist.forEach((v) => {
        // console.log(v.pguige==pinfo.pguige)
        if (v.pid == pinfo.pid && v.pguige == pinfo.pguige) {
          cFlag = false;
        }
      });
      if (cFlag) {
        this.newProductInfo.id = pinfo.id;
        this.newProductInfo.orderid = this.orderList[0].orderid;
        this.newProductInfo.wsprice = pinfo.pwsprice;
        this.newProductInfo.imgurl = pinfo.imgurl;
        this.newProductInfo.pname = pinfo.pname;
        this.newProductInfo.pguige = pinfo.pguige;
        this.newProductInfo.posn = pinfo.posn;
        this.newProductInfo.pcountnow = pinfo.pcountnow;
        this.newProductInfo.pcountnowshow = "剩余库存：" + pinfo.pcountnow;
        this.newProductInfo.pwsprice = pinfo.pwsprice;
        this.newProductInfo.sid = pinfo.sid;
        // this.newProductInfo.selectedcountmin = pinfo.selectedcountmin;
        // this.newProductInfo.selectedcountmax = pinfo.selectedcountmax;
        // this.newProductInfo.minnum = pinfo.minnum;
        // this.newProductInfo.maxnum = pinfo.maxnum;
        this.newProductInfo.selectedcount = "";
        this.nowPrice = pinfo.pwsprice;
        this.newProductInfo.pid = pinfo.pid;
        this.newProductInfo.pwsunit = pinfo.pwsunit;
        this.newAddNum = "";
        this.newProductInfo.allprice = "";
        this.newProductInfo.alltaxes = 0;
        this.newProductInfo.alldeposit = 0;
        this.newProductInfo.alltips = 0;
        this.newProductInfo.ptaxesname=pinfo.ptaxesname;
        this.newProductInfo.ptaxesvalue=pinfo.ptaxesvalue;
        this.newProductInfo.pdeposit=pinfo.pdeposit;
        this.newProductInfo.ptips=pinfo.ptips;
        this.newProductInfo.ptipstype=pinfo.ptipstype;

        // this.newProductInfo.limit= pinfo.maxnum + '/' + pinfo.minnum
        this.showNameList = false;
        this.showAddFlag = true;
        // this.newProductInfo.limit = "";
        // if (pinfo.minnum != 0) {
        //   this.newProductInfo.limit = "起购数量：" + pinfo.minnum + "  ";
        // }
        // if (pinfo.maxnum != 0) {
        //   this.newProductInfo.limit += "限购数量：" + pinfo.maxnum;
        // }
        this.newProductInfo.pricelist = pinfo.steppricelist;
      } else {
        this.$refs.toast.toast("订单已有该商品");
      }
    },
    async getPriceList(id) {
      const res = await this.$get(
        this.GLOBAL.serverAddress + "/getWSPriceListByID/",
        { id: id }
      );

      if (res.status === 200) {
        // this.newProductInfo.pricelist=[]
        this.newProductInfo.pricelist = res.data;
        // console.log(this.newProductInfo.pricelist)
      } else {
        this.newProductInfo.pricelist = [];
      }
    },
    showAddProduct() {
      this.showAddFlag = true;
      this.newProductInfo.pname = "请选择要添加的商品";
    },
    cancelAdd() {
      this.showAddFlag = false;
      this.newProductInfo = {};
    },
    async updateRemarks(orderid,newremarks){
          const res=await this.$post(this.GLOBAL.serverAddress + "/updateRemarksByID/",{id:orderid,remarks:newremarks})
          // console.log(res)
          if(res.data){
              this.$router.go(0)
              // this.$refs.toast.toast("备注更新成功")
          }else{
              this.$refs.toast.toast("设置失败，请重新尝试")
          }
    },
    async updateOrderInfo(orderid){
      console.log(this.orderList[0].membercode)

      if(confirm("您确定要更新订单信息吗？")){

        var params={
          id:orderid,
          deliveryDate:this.deliverWeekDay,
          membercode:this.orderList[0].membercode
        }
        const res=await this.$post(this.GLOBAL.serverAddress + "/updateRetailOrderInfoPC/",params)
        // console.log(res)
        if(res.data){
            // this.$router.go(-1)
            this.$router.push({name:'/retailorderlist',params:{page:this.page}})
            // this.$refs.toast.toast("备注更新成功")
        }else{
            this.$refs.toast.toast("设置失败，请重新尝试")
        }
      }
    },
    async minusOrderFreight(orderid){
      // console.log(this.orderList[0].membercode)

      if(confirm("您确定要减免该订单的运费吗？")){

        var params={
          id:orderid,
          adminname:this.logedinuser
          // deliveryDate:this.deliverWeekDay,
          // membercode:this.orderList[0].membercode
        }
        const res=await this.$post(this.GLOBAL.serverAddress + "/minusRetailOrderFreight/",params)
        // console.log(res)
        if(res.data){
            this.$router.go(0)
            // this.$router.push({name:'/retailorderlist',params:{page:this.page}})
            // this.$refs.toast.toast("备注更新成功")
        }else{
            this.$refs.toast.toast("设置失败，请重新尝试")
        }
      }
    },
    submitAdd() {
      
      if (!this.newProductInfo.pid) {
        this.$refs.toast.toast("请选择商品");
        $("#txtSearchPName").focus();
        return false;
      }
      if (this.newAddNum === "") {
        this.$refs.toast.toast("请输入追加数量");
        $("#txtNumAdd").focus();
        return false;
      }
      // if (this.newAddNum < this.newProductInfo.minnum) {
      //   this.$refs.toast.toast("未达到起购数量");
      //   $("#txtNumAdd").focus();
      //   return false;
      // }
      // if (
      //   this.newProductInfo.maxnum != 0 &&
      //   this.newAddNum > this.newProductInfo.maxnum
      // ) {
      //   this.$refs.toast.toast("已超过限购数量");
      //   $("#txtNumAdd").focus();
      //   return false;
      // }
      if (this.newAddNum > this.newProductInfo.pcountnow) {
        this.$refs.toast.toast("已超过库存数量");
        $("#txtNumAdd").focus();
        return false;
      }
      if (this.newProductInfo.allprice === "") {
        this.$refs.toast.toast("请输入商品总价");
        $("#txtPriceAdd").focus();
        return false;
      }
      
      if (confirm("您确认要追加该产品吗？")) {
        let newAddInfo = {
          orderid: this.newProductInfo.orderid,
          pid: this.newProductInfo.pid,
          pname: this.newProductInfo.pname,
          pguige: this.newProductInfo.pguige,
          punit: this.newProductInfo.pwsunit,
          wscount: this.newProductInfo.selectedcount,
          wsunitprice: this.newProductInfo.wsprice,
          wsallprice: this.newProductInfo.allprice,
          wsalltaxes: this.newProductInfo.alltaxes,
          wsalldeposit: this.newProductInfo.alldeposit,
          wsalltips: this.newProductInfo.alltips,
          posn: this.newProductInfo.posn,
          sid: this.newProductInfo.sid,
        };

        // console.log(newAddInfo);
        axios({
          method: "POST",
          data: { productinfo: newAddInfo ,opruser:this.logedinuser},
          url: this.GLOBAL.serverAddress + "/addNewProductInRetailOrder/",
        }).then(
          (response) => {
            if (response.data === true) {
              this.$refs.toast.toast("商品追加成功");
              this.showAddFlag = false;
              this.newProductInfo = {};
              this.$router.go(0)
            } else {
              this.$refs.toast.toast(response.data);
            }
          },
          (error) => {
            alert(error.message);
            return false;
          }
        );
      }
    },
    mouseOver(index) {
      // alert(index)
      this.currentIndex = index;
    },
    mouseOut() {
      // alert(index)
      this.currentIndex = -1;
    },
    handleSearch(e) {
      // console.log('chufa')
      var search = this.searchpname;
      // var tsearchpsn = this.searchpsn;
      this.showNameList = false;
      // console.log(e);
      if (search) {
        // console.log('开始提交1')
        axios({
          method: "get",
          params: {
            pname: search,
          },
          url: this.GLOBAL.serverAddress + "/getRetailProductListForNewAdd/",
        }).then(
          // axios.post('http://localhost:8080/getStorageList').then(
          (response) => {
            this.nameList = response.data;
            // console.log(this.nameList)
            if (this.nameList.length > 0) {
              var ul = this.$refs.sKeywords; // document.getElementById("keywords");
              // console.log(e.target.getBoundingClientRect().top);
              ul.style.top = e.target.getBoundingClientRect().top + 40 + "px";
              ul.style.left = e.target.getBoundingClientRect().left + 8 + "px";
              this.showNameList = true;
            } else {
              this.showNameList = false;
            }
          },
          (error) => {
            console.log("出现错误", error.message);
            return false;
          }
        );
      }
    },
    updateNum(orderid, pinorderid, maxnum) {
      let addnum = "";
      let newNumSelected = 0;
      // console.log(addnum)
      this.orderList[0].plist.forEach((v) => {
        if (v.pinorderid == pinorderid) {
          addnum = v.addcount;
          newNumSelected = parseFloat(v.addcount) + parseFloat(v.wscount);
        }
      });

      if (addnum != "") {
        if (addnum > maxnum) {
          this.$refs.toast.toast("已超可追加数量");
        } else {
          if (
            confirm("您确认要追加该商品吗？订单价格将根据商品数量重新计算。")
          ) {
            axios({
              method: "POST",
              data: {
                orderid: orderid,
                pinorderid: pinorderid,
                newNumSelected: newNumSelected,
              },
              url: this.GLOBAL.serverAddress + "/updateOrder/",
            }).then(
              (response) => {
                this.$refs.toast.toast("数量追加成功");
                this.search(orderid);
              },
              (error) => {
                alert(error.message);
                return false;
              }
            );
          }
        }
      }
      // console.log(addnum)
    },
    showAdd() {
      this.orderList[0].plist.forEach((v) => (v.showaddtitle = true));
    },
    hiddenAdd(pinorderid) {
      this.orderList[0].plist.forEach((v) => {
        if (v.pinorderid == pinorderid) {
          v.showaddtitle = false;
        }
      });
    },
    goback() {
      // this.$router.go(-1);
      if(this.from =="1"){

        this.$router.push({name:'/neworderlist',params:{page:this.page}})
      }else{

        this.$router.push({name:'/retailorderlist',params:{page:this.page}})
      }
      // this.$router.push({name:'/retailorderlist',params:{page:this.page}})
    },
    viewOrderDetail(id) {
      this.$router.push("/setupwholesale");
    },
    payOrder(id) {
      if (confirm("您确认已经收到了该订单的款项吗？确认后无法撤回。")) {
        axios({
          method: "put",
          data: { id: id, status: "已支付", type: "1" },
          url: this.GLOBAL.serverAddress + "/updateOrderStatus/",
        }).then(
          (response) => {
            // this.clearSearch();
            this.search();
            alert("订单状态更新完成。");
          },
          (error) => {
            alert(error.message);
            return false;
          }
        );
      }
    },
    deliveryOrder(id) {
      if (confirm("您确认该订单已完成取货吗？确认后无法撤回。")) {
        axios({
          method: "put",
          data: { id: id, status: "已取货", type: "2" },
          url: this.GLOBAL.serverAddress + "/updateOrderStatus/",
        }).then(
          (response) => {
            // this.clearSearch();
            this.search();
            alert("订单状态更新完成。");
          },
          (error) => {
            alert(error.message);
            return false;
          }
        );
      }
    },

    getNewPage(pagenum) {
      if (pagenum >= 1 && pagenum <= this.pagecount) {
        this.currentpage = pagenum;
        return this.pages[this.currentpage - 1];
      }
    },
    async search(id) {
      const res = await this.$get(
        this.GLOBAL.serverAddress + "/getRetailOrderDetailByID/",
        {
          id: id,
        }
      );
      //   console.log(res);

      if (res.status === 200) {
        this.orderList = res.data;
        // console.log(res.data)
        if (this.orderList.length > 0) {
          this.shownoresult = false;
        } else {
          this.shownoresult = true;
        }
      }
      this.getCreditByOrderID(id);
      this.getCreditDSByOrderID(id);
      this.getMPayByOrderID(id)
      this.getMoneyByMemberID(this.orderList[0].userid)
      this.deliverWeekDay=this.orderList[0].icard_name
    },
  },
  computed: {
    currentpagelist() {
      return this.pages[this.currentpage - 1];
    },
    recordcount() {
      return this.orderList.length;
    },
    pagecount() {
      return Math.ceil(this.orderList.length / 15);
    },
    pages() {
      const pages = [];
      this.orderList.forEach((item, index) => {
        const page = Math.floor(index / 15);
        if (!pages[page]) {
          pages[page] = [];
        }
        pages[page].push(item);
      });
      return pages;
    },
  },
  mounted() {
    this.page=this.$route.query.page
    this.from=this.$route.query.from
    this.search(this.$route.query.id);
    this.getAllDMember()
    
    // window.addEventListener("click", this.hideCalender);
    this.win_height = window.innerHeight;
    this.logedinuser=sessionStorage.getItem("loginuser")
  },
  beforeDestroy() {
    // 实例销毁之前对点击事件进行解绑
    // window.removeEventListener("click", this.hideCalender);
  },
};
</script>

<style scoped lang="less">
.orderlist {
  color: #9c9c9c;

  // padding:10px;
  .order_item {
    //   width:100%;
    margin-top: 15px;
    background-color: #fff;
    .order_title {
      text-align: center;
      font-size: 1.2em;
      padding: 10px;
    }
    .order_item_briefinfo_top {
      display: flex;
      border-bottom: 1px solid #eee;

      .order_item_briefinfo_back {
        font-size: 1.3em;
        padding-left: 50px;
        flex: 1;
        i {
          cursor: pointer;
        }
      }
      .order_item_briefinfo_ordernum {
        text-align: right;
        padding-right: 50px;
        flex: 1;
      }
    }
    // padding-top:15px;
    .order_item_briefinfo {
      //   padding-bottom:10px;
      padding: 10px 50px;

      display: flex;
      justify-content: space-between;
      align-items: center;

      .order_item_briefinfo_orderpay {
        // text-align: center;
        flex: 1;
        margin:0 10px;
      }
    }

    .order_notesinfo {
      display: flex;
      padding: 0 60px;
      
      .order_notesinfo_title {
      }
      .order_notesinfo_content {
          textarea{
              width: 100%;
          }
      }
    }
    .order_item_contact_title {
      background-color: #eeeed1;
      line-height: 30px;
      padding-left: 40px;
      // border: 1px solid green;
    }
    .order_item_contact {
      padding: 10px 50px;
      .ordercontact_name {
        margin:0 10px;
      }
    }
    .order_item_list {
      background-color: #eeeed1;
      line-height: 30px;
      text-align: center;
      //   width: 50%;
      align-self: center;
      //   color: #666;
    }
    .order_new_product {
      display: flex;
      margin-left: 50px;
      align-items: center;
    }
    .order_item_list_title {
      margin: 5px 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-bottom: 1px solid #eee;
      padding-bottom: 10px;
      text-align: center;
      .product_img {
        flex: 1;
        img {
          width: 80%;
          object-fit: contain;
          height: 50px;
          //   object-fit: contain;
        }
      }
      .product_name {
        flex: 3;
      }
      .product_guige {
        flex: 1;
      }
      .product_unitprice {
        flex: 1;
      }
      .product_count {
        flex: 1;
        // border: 1px solid green;
        .product_count_newadd {
          .product_count_newadd_zhuijia {
            display: flex;
            justify-content: center;
            align-items: center;
            .product_count_newadd_zhuijia_l {
              flex: 1;
            }
            .product_count_newadd_zhuijia_r {
              flex: 1;
            }
          }
          .product_count_newadd_btn {
            color: #9acd32;
          }
        }
      }
      .product_allprie {
        flex: 1;
        color: #00bfff;
        .product_allprie_o {
          display: flex;
          align-items: center;
          justify-content: center;

          .product_allprie_btn {
            padding-left: 5px;
            color: #9acd32;
            cursor: pointer;
          }
        }
        .product_allprie_n {
          display: flex;
          align-items: center;
          white-space: nowrap;
          
        }
        .product_allprie_btnb{
          display: flex;
          align-items: center;
          justify-content: space-around;
          .product_allprie_btnb_btn{
            padding: 5px;
            cursor: pointer;
          }
        }
      }
    }

    .order_item_content_order {
      text-align: right;
      margin-right: 80px;
      padding-bottom: 10px;
      font-size: 1.2em;

      display: flex;
      .order_item_content_order_btn {
        flex: 1;
        // display: flex;
      }
      .order_item_content_order_right {
        color: #00bfff;
        flex: 2;
        i {
          cursor: pointer;
        }
      }
    }
  }
}

button {
  background-color: #f5f5f5;
  margin-right: 10px;
}

#keywords {
  position: absolute;
  padding-left: 5px;
  /* top: 40px; */
  background-color: rgba(228, 233, 220, 0.9);
  list-style: none;
  width: 550px;
  z-index: 9999;
  color: #616161;
  font-size: 15px;
  line-height: 25px;
}

.actived {
  background-color: rgba(190, 221, 140, 0.9);
}
</style>